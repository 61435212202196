import { Pipe, PipeTransform } from '@angular/core';
import { Question } from 'app/shared/common-ux/models/QuestionAnswer';
import { UtilitiesService } from 'app/shared/utilities/utilities.service';

@Pipe({ name: 'multiAnswerDisplayAdmin' })
export class MultiAnswerDisplayAdminPipe implements PipeTransform {

    constructor() {
    }
    transform(answerText: string, answerAttrs: string): string {
        const multiAttr = answerAttrs ? answerAttrs['multiInputType'] : null;
        const optionalText = answerAttrs ? answerAttrs['optionalText'] : null;
        let textToReturn = answerText;

        switch (multiAttr) {
            case 'amsId':
                textToReturn = 'AMS ID: ' + answerText;
                break;
            case 'amsAddress':
                textToReturn = 'AMS address: ' + answerText;
                break;
            case 'userInputAddress':
                textToReturn = 'User input AMS address';
                break;
            case 'dispatchAddress':
                textToReturn = 'Dispatch desk number';
                break;
            case 'opsAddress':
                textToReturn = 'Ops coordinator desk number';
                break;
        }

        return optionalText ? textToReturn + '  (' + optionalText + ')' : textToReturn;
    }
}
