import { IReportHistory, IReportHistoryFilter, IReportHistoryOptions, ReportHistory } from './history.model';
import * as historyActions from './history.actions';
import * as _ from 'lodash';

export interface IHistoryList {
    isLoading: boolean;
    list: {
        all: IReportHistory[],
        selectedReport: IReportHistory,
        options: IReportHistoryOptions
    };
    error: string;
}

export interface HistoryStateModel {
    myReports: IHistoryList;
    allReports: IHistoryList;
    todoReports: IHistoryList;
    todoLists: IHistoryList;
    selectedList: string;
}

class HistoryList implements IHistoryList {
    isLoading: boolean;
    list: {
        all: IReportHistory[],
        selectedReport: IReportHistory,
        options: IReportHistoryOptions
    };
    error: string;

    constructor() {
        this.isLoading = null;
        this.list = {
            all: [],
            selectedReport: null,
            options: {
                filter: new IReportHistoryFilter(),
                sort: { id: 'submitDate', start: 'desc', disableClear: true },
                pageIndex: 0
            }
        };
        this.error = null;
    }
}

export class InitialState implements HistoryStateModel {
    myReports = new HistoryList();
    allReports = new HistoryList();
    todoReports = new HistoryList();
    todoLists = new HistoryList();
    selectedList = 'myReports';
}

export function reducer(state = new InitialState(), action: historyActions.Actions) {
    switch (action.type) {
        case historyActions.LOAD_MY_HISTORY:
            return {
                ...state,
                myReports: {
                    ...state.myReports,
                    isLoading: true,
                    error: null
                },
            };

        case historyActions.POPULATE_MY_HISTORY:
            return {
                ...state,
                myReports: {
                    ...state.myReports,
                    isLoading: false,
                    list: {
                        ...state.myReports.list,
                        all: action.payload
                    },
                    error: null
                },
            };

        case historyActions.FILTER_MY_HISTORY:
            return {
                ...state,
                myReports: {
                    ...state.myReports,
                    isLoading: false,
                    list: {
                        ...state.myReports.list,
                        options: action.payload
                    },
                    error: null
                },
            };

        case historyActions.ERROR_MY_HISTORY:
            return {
                ...state,
                myReports: {
                    ...state.myReports,
                    isLoading: false,
                    error: action.payload
                },
            };

        case historyActions.LOAD_ALL_HISTORY:
            return {
                ...state,
                allReports: {
                    ...state.allReports,
                    isLoading: true,
                    error: null
                },
            };

        case historyActions.POPULATE_ALL_HISTORY:
            return {
                ...state,
                allReports: {
                    ...state.allReports,
                    isLoading: false,
                    list: {
                        ...state.allReports.list,
                        all: action.payload,
                    },
                    error: null
                },
            };

        case historyActions.FILTER_ALL_HISTORY:
            return {
                ...state,
                allReports: {
                    ...state.allReports,
                    isLoading: false,
                    list: {
                        ...state.allReports.list,
                        options: action.payload
                    },
                    error: null
                },
            };

        case historyActions.ERROR_ALL_HISTORY:
            return {
                ...state,
                allReports: {
                    ...state.allReports,
                    isLoading: false,
                    error: action.payload
                },
            };

        case historyActions.LOAD_TODO_HISTORY:
            return {
                ...state,
                todoReports: {
                    ...state.todoReports,
                    isLoading: true,
                    error: null
                },
            };

        case historyActions.POPULATE_TODO_HISTORY:
            return {
                ...state,
                todoReports: {
                    ...state.todoReports,
                    isLoading: false,
                    list: {
                        ...state.todoReports.list,
                        all: action.payload
                    },
                    error: null
                },
            };

        case historyActions.FILTER_TODO_HISTORY:
            return {
                ...state,
                todoReports: {
                    ...state.todoReports,
                    isLoading: false,
                    list: {
                        ...state.todoReports.list,
                        options: action.payload
                    },
                    error: null
                },
            };

        case historyActions.ERROR_TODO_HISTORY:
            return {
                ...state,
                todoReports: {
                    ...state.todoReports,
                    isLoading: false,
                    error: action.payload
                },
            };

        case historyActions.LOAD_TODO_LIST:
            return {
                ...state,
                todoLists: {
                    ...state.todoLists,
                    isLoading: true,
                    error: null
                },
            };
            case historyActions.POPULATE_TODO_LIST:
                return {
                    ...state,
                    todoLists: {
                        ...state.todoLists,
                        isLoading: false,
                        list: {
                            ...state.todoLists.list,
                            all: action.payload
                        },
                        error: null
                    },
                };
                case historyActions.FILTER_TODO_LIST:
                    return {
                        ...state,
                        todoLists: {
                            ...state.todoLists,
                            isLoading: false,
                            list: {
                                ...state.todoLists.list,
                                options: action.payload
                            },
                            error: null
                        },
                    };
                    case historyActions.SELECTED_TODO:
                        return {
                            ...state,
                            todoLists: {
                                ...state.todoLists,
                                isLoading: true,
                                list: {
                                    ...state.todoLists.list,
                                    selectedReport: action.payload,

                                },
                                error: null
                            },
                            };
                    case historyActions.RESET_TODO:
                        return {
                            ...state,
                            todoLists: {
                                ...state.todoLists,
                                list: {
                                    ...state.todoLists.list,
                                    selectedReport: action.payload,

                                },
                            }
                        };
                case historyActions.ERROR_TODO_LIST:
                    return {
                        ...state,
                        todoLists: {
                            ...state.todoLists,
                            isLoading: false,
                            error: action.payload
                        },
                    };
        case historyActions.SET_SELECTED_LIST:
            return {
                ...state,
                selectedList: action.payload
            };

        case historyActions.REMOVE_CLOSED_REPORT:
            return {
                ...state,
                allReports: {
                    ...state.allReports,
                    list: {
                        ...state.allReports.list,
                        all: _.filter(state.allReports.list.all, (o) => { return o.reportId !== action.payload; })
                    }
                },
                todoReports: {
                    ...state.todoReports,
                    list: {
                        ...state.todoReports.list,
                        all: _.filter(state.todoReports.list.all, (o) => { return o.reportId !== action.payload; })
                    }
                }
            };

        case historyActions.RESET:
            return new InitialState();

        default:
            return state;
    }
}

// SELECTORS
export const getMyHistory = state => state.reportHistory.myReports;
export const getAllHistory = state => state.reportHistory.allReports;
export const getTodoHistory = state => state.reportHistory.todoReports;
export const getTodoList = state => state.reportHistory.todoLists;
export const getSelectedList = state => state.reportHistory.selectedList;
export const getTodo = state => state.reportHistory.todoLists.list.selectedReport;
