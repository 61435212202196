import { Injectable } from '@angular/core';
import { IQuestionSync, IAnswerSync, IFollowupSync } from './offline-sync.service';
import { IQuestion, IAnswer } from 'app/new-report/new-report-store/wizard';
import { ICategory } from 'app/new-report/new-report-store/list';
import * as _ from 'lodash';
import { EncrDecrService } from './encrDecryption/encrDecr.service';

export interface ISyncData {
    profile: number;
    lastSynced: Date;
}
@Injectable()
export class SyncDatabaseService {
    databaseName = 'offlineSync';
    questionStoreName = 'questions';
    answerStoreName = 'answer';
    followupStoreName = 'followup';
    categoriesStoreName = 'categories';
    miscStoreName = 'misc';

    constructor(private cryptService: EncrDecrService) { }


    async getAllQuestionsAndAnswers(profileId: number, categoryId: number, groupName: string): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                const questions = this.getAllQuestionsAndAnswersRecursive(profileId, categoryId, groupName);
                resolve(questions);
            } catch (err) {
                console.log(err);
            }
        });
    }

    getAllQuestionsAndAnswersRecursive(profileId: number, categoryId: number, groupName: string, answerId?: number): Promise<Array<IQuestion>> {
        return new Promise(async (resolve, reject) => {
            let questions: IQuestion[] = [];
            if (!answerId) {
                const temp = await this.getRootQuestionsWithAnswers(profileId, categoryId, groupName);
                questions = temp.questions;
                for (const question of questions) {
                    if (question.answerInputType.toLowerCase() !== 'textbox') {
                        for (const answer of question.answers) {
                            if (answer) {
                                try {
                                    this.getAllQuestionsAndAnswersRecursive(profileId, categoryId, groupName, answer.answerId).then((followups) => {
                                        if (followups.length > 0) {
                                            answer.followupQuestions = followups;
                                        }
                                    });
                                } catch (err) {
                                    console.log(err);
                                }
                            }
                        }
                    }
                }
            } else {
                const temp = await this.getFollowupQuestionsWithAnswers(profileId, categoryId, answerId, groupName);
                questions = temp.questions;
                for (const question of questions) {
                    if (question.answerInputType.toLowerCase() !== 'textbox') {
                        for (const answer of question.answers) {
                            if (answer) {
                                try {
                                    this.getAllQuestionsAndAnswersRecursive(profileId, categoryId, groupName, answer.answerId).then((followups) => {
                                        if (followups.length > 0) {
                                            answer.followupQuestions = followups;
                                        }
                                    });
                                } catch (err) {
                                    console.log(err);
                                }
                            }
                        }
                    }
                }
            }
            resolve(questions);
        });
    }

    openDatabase(): Promise<IDBDatabase> {
        return new Promise((resolve, reject) => {
            if (!window.indexedDB) {
                reject('IndexedDB not supported');
            }
            const req = window.indexedDB.open(this.databaseName, 1);
            req.onsuccess = (evt: any) => {
                resolve(req.result);
            };
            req.onerror = (evt: any) => {
                reject(`error opening offlineSync DB:", ${evt.target.errorCode}`);
            };

            req.onupgradeneeded = async (event: any) => {
                if (!(event && event.target)) {
                    return;
                }
                const db = event.target.result;
                // create all the tables for objects
                this.createObjectStores(db).then((idb) => resolve(idb)).catch((e) => console.error('error creating stores', e));
            };
        });
    }

    // createObjectStores - creates store objects (aka tables) under the database to hold questions, answers, and followups
    createObjectStores(db: IDBDatabase): Promise<IDBDatabase> {
        return new Promise((resolve, reject) => {
            if (!db.objectStoreNames.contains(this.questionStoreName)) {
                // the unique key value for a question is the QuestionMapping ID
                const questionStore = db.createObjectStore(this.questionStoreName, { keyPath: 'questionMappingId' });

                // categoryId is used to query and search the questions
                questionStore.createIndex('categoryId', 'categoryId', { unique: false });

                // callbacks for error or complete
                questionStore.transaction.onerror = (event: Event) => reject(`open db transaction error, ${event}`);
                questionStore.transaction.oncomplete = (event) => {
                    db.transaction(this.questionStoreName, 'readwrite').objectStore(this.questionStoreName);
                    resolve(db);
                };
            }

            if (!db.objectStoreNames.contains(this.answerStoreName)) {
                // the key for answers are generated incrementally. answer store must be cleared before sync
                const answerStore = db.createObjectStore(this.answerStoreName, { autoIncrement: true });

                // QuestionMappingID is used to query and search the answers
                answerStore.createIndex('questionMappingId', 'questionMappingId', { unique: false });

                // callbacks for error or complete
                answerStore.transaction.onerror = (event: Event) => reject(`open db transaction error, ${event}`);
                answerStore.transaction.oncomplete = (event) => {
                    db.transaction(this.answerStoreName, 'readwrite').objectStore(this.answerStoreName);
                    resolve(db);
                };
            }

            if (!db.objectStoreNames.contains(this.followupStoreName)) {
                // the key for followups are generated incrementally. followups store must be cleared before sync
                const followupStore = db.createObjectStore(this.followupStoreName, { autoIncrement: true });

                // AnswerMappingID is used to query and search the questions
                followupStore.createIndex('answerMappingId', 'answerMappingId', { unique: false });

                // callbacks for error or complete
                followupStore.transaction.onerror = (event: Event) => reject(`open db transaction error, ${event}`);
                followupStore.transaction.oncomplete = (event) => {
                    db.transaction(this.followupStoreName, 'readwrite').objectStore(this.followupStoreName);
                    resolve(db);
                };
            }

            if (!db.objectStoreNames.contains(this.categoriesStoreName)) {
                // the unique key value for a category is its ID
                const categoryStore = db.createObjectStore(this.categoriesStoreName, { keyPath: 'Id' });

                // callbacks for error or complete
                categoryStore.transaction.onerror = (event: Event) => reject(`open db transaction error, ${event}`);
                categoryStore.transaction.oncomplete = (event) => {
                    db.transaction(this.categoriesStoreName, 'readwrite').objectStore(this.categoriesStoreName);
                    resolve(db);
                };
            }

            if (!db.objectStoreNames.contains(this.miscStoreName)) {
                // the unique key for details must be given when addings
                const detailsStore = db.createObjectStore(this.miscStoreName, { keyPath: 'key' });

                // callbacks for error or complete
                detailsStore.transaction.onerror = (event: Event) => reject(`open db transaction error, ${event}`);
                detailsStore.transaction.oncomplete = (event) => {
                    db.transaction(this.miscStoreName, 'readwrite').objectStore(this.miscStoreName);
                    resolve(db);
                };
            }
        });
    }

    syncCategories(categories: ICategory[]) {
        return new Promise((resolve, reject) => {
            this.openDatabase().then((db) => {
                const store = db.transaction(this.categoriesStoreName, 'readwrite').objectStore(this.categoriesStoreName);
                // store needs to be cleared
                const clear_req = store.clear();

                clear_req.onerror = (err) => { console.log(err); reject(err); };
                clear_req.onsuccess = async () => {
                    for (let i = 0; i < categories.length; i++) {
                        const cat = categories[i];
                        try {
                            await this.syncCategory(store, cat);
                        } catch (err) {
                            reject(err);
                        }
                    }
                    resolve('Finished syncing all categories');
                };
            });
        });
    }

    syncCategory(store: IDBObjectStore, category: ICategory) {
        return new Promise((resolve, reject) => {
            const req = store.openCursor(category.id); // try to get the category from DB to check if it has been added
            req.onerror = (e: any) => {
                if (e && e.target) {
                    console.error(`Error syncing category: ${e.target.error}`, e.target);
                    reject(e);
                }
            };
            req.onsuccess = (e: any) => {
                const cursor: IDBCursor = e.target.result;
                const q_req = (cursor) ? cursor.update(category) : store.add(category);
                q_req.onerror = (err) => { console.log('error adding category', err); reject(err); };
                q_req.onsuccess = () => resolve('Finished syncing category');
            };
        });
    }

    syncAllQuestions(questions: IQuestionSync[]) {
        return new Promise((resolve, reject) => {
            this.openDatabase().then((db) => {
                const store = db.transaction(this.questionStoreName, 'readwrite').objectStore(this.questionStoreName);
                // store needs to be cleared
                const clear_req = store.clear();

                clear_req.onerror = (err) => { console.log(err); reject(err); };
                clear_req.onsuccess = async () => {
                    for (let i = 0; i < questions.length; i++) {
                        const q = questions[i];
                        try {
                            await this.syncQuestion(store, q);
                        } catch (e) {
                            reject(e);
                        }
                    }
                    resolve('Finished syncing all questions');
                };
            });
        });
    }

    syncQuestion(store: IDBObjectStore, question: IQuestionSync) {
        return new Promise((resolve, reject) => {
            const req = store.openCursor(question.questionMappingId); // try to get the question from DB to check if it has been added
            req.onerror = (e: any) => {
                if (e && e.target) {
                    console.error(`Error syncing question: ${e.target.error}`, e.target);
                    reject(e);
                }
            };
            req.onsuccess = (e: any) => {
                const cursor: IDBCursor = e.target.result;
                const q_req = (cursor) ? cursor.update(question) : store.add(question);
                q_req.onerror = (err) => { console.log('error adding question', err); reject(err); };
                q_req.onsuccess = () => resolve('Finished syncing question');
            };
        });
    }

    syncAllAnswers(answers: IAnswerSync[]) {
        return new Promise((resolve, reject) => {
            this.openDatabase().then(async (db) => {
                const store = db.transaction(this.answerStoreName, 'readwrite').objectStore(this.answerStoreName);
                // store needs to be cleared
                const clear_req = store.clear();

                clear_req.onerror = (err) => { console.log(err); reject(err); };
                clear_req.onsuccess = async () => {
                    for (let i = 0; i < answers.length; i++) {
                        const a = answers[i];
                        try {
                            await this.syncAnswer(store, a);
                        } catch (err) {
                            reject(err);
                        }
                    }
                    resolve('Finished syncing all answers');
                };
            });
        });
    }

    syncAnswer(store: IDBObjectStore, answer: IAnswerSync) {
        return new Promise((resolve, reject) => {
            const q_req = store.add(answer);
            q_req.onsuccess = () => resolve('Finished syncing answer');
            q_req.onerror = (err) => {
                console.log('error adding answer', err);
                reject(err);
            };
        });
    }

    syncAllFollowups(followups: IFollowupSync[]) {
        return new Promise((resolve, reject) => {
            this.openDatabase().then((db) => {
                const store = db.transaction(this.followupStoreName, 'readwrite').objectStore(this.followupStoreName);
                const clear = store.clear();
                clear.onerror = (err) => { console.log('error clearing followups store'); reject(err); };
                clear.onsuccess = async () => {
                    for (let i = 0; i < followups.length; i++) {
                        const f = followups[i];
                        try {
                            await this.syncFollowup(store, f);
                        } catch (err) {
                            reject(err);
                        }
                    }
                    resolve('Finished syncing all followups');
                };
            });
        });
    }

    syncFollowup(store: IDBObjectStore, followup: IFollowupSync) {
        return new Promise((resolve, reject) => {
            const req = store.openCursor(followup.id); // try to get the followup from DB to check if it has been added
            req.onerror = (e: any) => {
                if (e && e.target) {
                    console.error(`Error syncing followup: ${e.target.error}`, e.target);
                    reject(e);
                }
            };
            req.onsuccess = (e: any) => {
                const cursor: IDBCursor = e.target.result;
                const q_req = (cursor) ? cursor.update(followup) : store.add(followup);
                q_req.onerror = (err) => { console.log('error adding followup', err); reject(err); };
                q_req.onsuccess = () => resolve('Finished syncing followup');
            };
        });
    }

    getRootQuestionsWithAnswers(profileId: number, categoryId: number, groupName: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.openDatabase().then((db) => {
                let rootQuestions: IQuestion[] = [];
                const store = db.transaction(this.questionStoreName, 'readwrite').objectStore(this.questionStoreName);
                const index = store.index('categoryId');
                const search = IDBKeyRange.only(categoryId);
                const searchReq = index.openCursor(search);
                searchReq.onerror = (e) => {
                    console.log('error getting root questions with answers', e);
                    reject(e);
                };
                searchReq.onsuccess = async (e: any) => {
                    const cursor = e.target.result;
                    if (cursor) { // iterate through questions to search for root questions
                        const question: IQuestionSync = cursor.value;
                        if (question && question.isRoot && question.profileId === profileId && question.groupName &&
                            question.groupName.toLowerCase().includes(groupName.toLowerCase())) {
                            rootQuestions.push({
                                questionMappingId: question.questionMappingId,
                                questionId: question.questionId,
                                questionText: question.question,
                                attrs: question.validateRules,
                                answerInputType: question.inputType,
                                mandatory: question.isRequired,
                                categoryId: question.categoryId,
                                displayOrder: question.displayOrder,
                                referenceId: question.referenceSk,
                                answers: [],
                                isReadOnly: false,
                                displayQuestionText: question.question,
                                hyperLinks: [],
                                groupName: question.groupName
                            });
                        }
                        cursor.continue();
                    } else {
                        // populate the answers for each question in the list
                        if (rootQuestions.length < 1) {
                            resolve({ category: '', questions: rootQuestions, involvementTypes: null });
                        }
                        rootQuestions = _.sortBy(rootQuestions, 'displayOrder');
                        this.getAnswersForQuestion(rootQuestions).then(() => {
                            resolve({ category: '', questions: rootQuestions, involvementTypes: null });
                        }).catch((err) => {
                            console.error('error getting answers for root question', err);
                            reject(err);
                        });
                    }
                };
            });
        });
    }

    getFollowupQuestionWithAnswers(questionMappingId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this.openDatabase().then((db) => {
                let followQuestion: IQuestion = null;
                const store = db.transaction(this.questionStoreName, 'readwrite').objectStore(this.questionStoreName);
                const searchReq = store.get(questionMappingId);

                searchReq.onerror = (e) => {
                    console.log('error getting followup questions with answers', e);
                    reject(e);
                };
                searchReq.onsuccess = async (e: any) => {
                    const question: IQuestionSync = e.target.result;
                    if (question) { // iterate through questions to search for root questions
                        followQuestion = {
                            questionMappingId: question.questionMappingId,
                            questionId: question.questionId,
                            questionText: question.question,
                            attrs: question.validateRules,
                            answerInputType: question.inputType,
                            mandatory: question.isRequired,
                            categoryId: question.categoryId,
                            displayOrder: question.displayOrder,
                            referenceId: question.referenceSk,
                            answers: [],
                            isReadOnly: false,
                            displayQuestionText: question.question,
                            hyperLinks: [],
                            groupName: question.groupName
                        };

                        // populate the answers for the question
                        this.getAnswersForQuestion([followQuestion]).then(() => {
                            resolve(followQuestion);
                        }).catch((err) => {
                            console.error('error getting answers for root question', err);
                            reject(err);
                        });
                    }
                };
            });
        });
    }

    // getAnswersForQuestion - takes a reference to the array with a list of questions and modifies each questions
    // answers attribute in order to add the answers directly to the question object
    getAnswersForQuestion(rootQuestions: IQuestion[]): Promise<IAnswer[]> {
        return new Promise((resolve, reject) => {
            this.openDatabase().then((db) => {
                const store = db.transaction(this.answerStoreName, 'readwrite').objectStore(this.answerStoreName);
                const index = store.index('questionMappingId');

                rootQuestions.map((rootQ) => {
                    const search = IDBKeyRange.only(rootQ.questionMappingId);
                    const searchReq = index.openCursor(search);

                    searchReq.onerror = (e) => {
                        console.log('error getting answers for a question', e);
                        reject(e);
                    };
                    searchReq.onsuccess = (e: any) => {
                        const cursor = e.target.result;
                        if (cursor) { // iterate through questions to search for root questions
                            const ans: IAnswerSync = cursor.value;
                            rootQ.answers.push({
                                questionMappingId: ans.questionMappingId,
                                answerId: ans.id, // AnswerID
                                answerText: ans.answer, // AnswerText
                                displayOrder: ans.displayOrder,
                                riskLevel: null,
                                safetyLevel: null,
                                attrs: {}
                            });
                            cursor.continue();
                        } else {
                            rootQ.answers = _.sortBy(rootQ.answers, 'displayOrder');
                            resolve(null);
                        }
                    };
                });
            });
        });
    }

    getFollowupQuestionsWithAnswers(profileId: number, categoryId: number, answerId: number, groupName: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this.openDatabase().then((db) => {
                const followups: IFollowupSync[] = []; // holds all the followup mappings
                const store = db.transaction(this.followupStoreName, 'readwrite').objectStore(this.followupStoreName);
                const index = store.index('answerMappingId'); // search by answers mapping id
                const search = IDBKeyRange.only(answerId);
                const searchReq = index.openCursor(search);

                searchReq.onerror = (e) => {
                    console.log('error getting followup questions with answers', e);
                    reject(e);
                };
                searchReq.onsuccess = async (e: any) => {
                    const cursor = e.target.result;
                    if (cursor) { // iterate through followps
                        const followup: IFollowupSync = cursor.value;
                        if (followup) followups.push(followup);
                        cursor.continue();
                    } else {
                        // a list of promises. each promisme returns the question for a single followup
                        if (followups.length < 1) resolve({ category: '', questions: [] });
                        const promises = followups.map((f) => {
                            return this.getFollowupQuestionWithAnswers(f.questionMappingId);
                        });
                        // when the list of promises resolves, it will return a list of all followup questions
                        Promise.all(promises).then(val => {
                            val = _.sortBy(val, 'displayOrder');
                            resolve({ // resolve this function promise when all promises resolve
                                category: '',
                                questions: val
                            });
                        }).catch(er => console.error('error gettings followup questions with answers', er));
                    }
                };
            });
        });
    }

    getAllCategories(profileId: number) {
        return new Promise((resolve, reject) => {
            this.openDatabase().then((db) => {
                const store = db.transaction(this.categoriesStoreName, 'readwrite').objectStore(this.categoriesStoreName);
                const searchReq = store.getAll();

                searchReq.onerror = (e) => {
                    console.log('error getting all categories', e);
                    reject(e);
                };
                searchReq.onsuccess = async (e: any) => {
                    const values = _.sortBy(e.target.result, 'Description');
                    resolve({ categories: values });
                };
            });
        });
    }

    getCategory(categoryId: number) {
        return new Promise((resolve, reject) => {
            this.openDatabase().then((db) => {
                const store = db.transaction(this.categoriesStoreName, 'readwrite').objectStore(this.categoriesStoreName);
                const searchReq = store.get(categoryId);

                searchReq.onerror = (e) => {
                    console.log('error getting category', e);
                    reject(e);
                };
                searchReq.onsuccess = async (e: any) => {
                    const cursor = e.target.result;
                    resolve(cursor);
                };
            });
        });
    }

    syncDetailsSettings(settings: any) {
        return new Promise((resolve, reject) => {
            this.openDatabase().then(async (db) => {
                const store = db.transaction(this.miscStoreName, 'readwrite').objectStore(this.miscStoreName);
                const req = store.openCursor('settings'); // try to get the detail settings from DB to check if it has been added
                req.onerror = (e: any) => {
                    if (e && e.target) {
                        console.error(`Error syncing detail settings: ${e.target.error}`, e.target);
                        reject(e);
                    }
                };
                req.onsuccess = (e: any) => {
                    const cursor: IDBCursor = e.target.result;
                    const q_req = (cursor) ? cursor.update({ key: 'settings', value: settings })
                        : store.add({ key: 'settings', value: settings });
                    q_req.onsuccess = () => resolve('Finished syncing detail settings');
                    q_req.onerror = (err) => {
                        console.log('error adding detail settings', err);
                        reject(err);
                    };
                };
            });
        });
    }

    getDetailsSettings() {
        return new Promise((resolve, reject) => {
            this.openDatabase().then((db) => {
                const store = db.transaction(this.miscStoreName, 'readwrite').objectStore(this.miscStoreName);
                const searchReq = store.get('settings');

                searchReq.onerror = (e) => {
                    console.log('error getting settings', e);
                    reject(e);
                };
                searchReq.onsuccess = async (e: any) => {
                    const cursor = e.target.result;
                    if (cursor && cursor.value) {
                        resolve(cursor.value);
                    } else {
                        reject('settings are empty');
                    }
                };
            });
        });
    }
    syncAirportCodes(aiportCodes: any) {
        return new Promise((resolve, reject) => {
            this.openDatabase().then(async (db) => {
                const store = db.transaction(this.miscStoreName, 'readwrite').objectStore(this.miscStoreName);
                const req = store.openCursor('airportCodes'); // try to get the airport codes from DB to check if it has been added
                req.onerror = (e: any) => {
                    if (e && e.target) {
                        console.error(`Error syncing airport codes: ${e.target.error}`, e.target);
                        reject(e);
                    }
                };
                req.onsuccess = (e: any) => {
                    const cursor: IDBCursor = e.target.result;
                    let q_req = null;
                    if (cursor) {
                        q_req = cursor.update({ key: 'airportCodes', value: aiportCodes });
                    } else {
                        q_req = store.add({ key: 'airportCodes', value: aiportCodes });
                    }
                    q_req.onsuccess = () => resolve('Finished syncing airport codes');
                    q_req.onerror = (err) => {
                        console.log('error adding airport codes', err);
                        reject(err);
                    };
                };
            });
        });
    }

    getAirportCodes() {
        return new Promise((resolve, reject) => {
            this.openDatabase().then((db) => {
                const store = db.transaction(this.miscStoreName, 'readwrite').objectStore(this.miscStoreName);
                const searchReq = store.get('airportCodes');

                searchReq.onerror = (e) => {
                    console.log('error getting airport codes', e);
                    reject(e);
                };
                searchReq.onsuccess = async (e: any) => {
                    const cursor = e.target.result;
                    if (cursor && cursor.value) {
                        resolve(cursor.value);
                    } else {
                        resolve(null);
                    }
                };
            });
        });
    }

    syncCrewSequence(crewSequence: any) {
        return new Promise((resolve, reject) => {
            this.openDatabase().then(async (db) => {
                const store = db.transaction(this.miscStoreName, 'readwrite').objectStore(this.miscStoreName);
                const objectKey = 'crewSequence';
                const req = store.openCursor(objectKey);

                req.onerror = (e: any) => {
                    if (e && e.target) {
                        console.error(`Error syncing crew sequence: ${e.target.error}`, e.target);
                        reject(e);
                    }
                };
                req.onsuccess = (e: any) => {
                    const cursor: IDBCursor = e.target.result;
                    const objectVal = { key: objectKey, value: crewSequence };

                    const q_req = (cursor) ? cursor.update(objectVal) : store.add(objectVal);
                    q_req.onsuccess = () => resolve('Finished syncing crew sequence');
                    q_req.onerror = (err) => {
                        console.log('error adding crew sequence', err);
                        reject(err);
                    };
                };
            });
        });
    }

    getCrewSequence() {
        return new Promise((resolve, reject) => {
            this.openDatabase().then((db) => {
                const store = db.transaction(this.miscStoreName, 'readwrite').objectStore(this.miscStoreName);
                const searchReq = store.get('crewSequence');

                searchReq.onerror = (e) => {
                    console.log('error getting crew sequence', e);
                    reject(e);
                };
                searchReq.onsuccess = async (e: any) => {
                    const cursor = e.target.result;
                    if (cursor && cursor.value) {
                        resolve(cursor.value);
                    } else {
                        resolve(null);
                    }
                };
            });
        });
    }

    updateLastSyncData(profileId: number) {
        return new Promise((resolve, reject) => {
            this.openDatabase().then(async (db) => {
                const store = db.transaction(this.miscStoreName, 'readwrite').objectStore(this.miscStoreName);
                const req = store.openCursor('lastSync');
                req.onerror = (e: any) => {
                    if (e && e.target) {
                        console.error(`Error saving last sync data: ${e.target.error}`, e.target);
                        reject(e);
                    }
                };
                req.onsuccess = (e: any) => {
                    const cursor: IDBCursor = e.target.result;
                    const val = {
                        key: 'lastSync',
                        profile: profileId,
                        lastSynced: new Date()
                    };
                    const q_req = (cursor) ? cursor.update(val) : store.add(val);
                    q_req.onsuccess = () => resolve('Finished saving last sync data');
                    q_req.onerror = (err) => {
                        console.log('error adding last sync data', err);
                        reject(err);
                    };
                };
            });
        });
    }

    getLastSyncData() {
        return new Promise((resolve, reject) => {
            this.openDatabase().then((db) => {
                const store = db.transaction(this.miscStoreName, 'readwrite').objectStore(this.miscStoreName);
                const req = store.openCursor('lastSync');
                req.onerror = (e: any) => {
                    if (e && e.target) {
                        console.error(`Error saving last sync data: ${e.target.error}`, e.target);
                        reject(e);
                    }
                };
                req.onsuccess = (e: any) => {
                    const cursor: any = e.target.result;
                    if (cursor && cursor.value) {
                        resolve(cursor.value);
                    } else {
                        reject('has not been synced');
                    }
                };
            });
        });
    }

    saveUser(usr) {
        return new Promise((resolve, reject) => {
            this.openDatabase().then(async (db) => {
                const store = db.transaction(this.miscStoreName, 'readwrite').objectStore(this.miscStoreName);
                const req = store.openCursor('user');
                req.onerror = (e: any) => {
                    if (e && e.target) {
                        console.error(`Error saving last sync data: ${e.target.error}`, e.target);
                        reject(e);
                    }
                };
                req.onsuccess = (e: any) => {
                    const cursor: IDBCursor = e.target.result;
                    usr = this.cryptService.set(JSON.stringify(usr));
                    const q_req = (cursor) ? cursor.update({ user: usr, key: 'user' }) : store.add({ user: usr, key: 'user' });
                    q_req.onsuccess = () => resolve('Finished saving last sync data');
                    q_req.onerror = (err) => {
                        console.log('error adding last sync data', err);
                        reject(err);
                    };
                };
            });
        });
    }

    loadUser() {
        return new Promise((resolve, reject) => {
            this.openDatabase().then((db) => {
                const store = db.transaction(this.miscStoreName, 'readwrite').objectStore(this.miscStoreName);
                const req = store.openCursor('user');
                req.onerror = (e: any) => {
                    if (e && e.target) {
                        console.error(`Error saving last sync data: ${e.target.error}`, e.target);
                        reject(e);
                    }
                };
                req.onsuccess = (e: any) => {
                    const cursor: any = e.target.result;
                    if (cursor && cursor.value && cursor.value.user) {
                        resolve(JSON.parse(this.cryptService.get(cursor.value.user)));
                    } else {
                        reject('Has not been synced');
                    }
                };
            });
        });
    }

    saveEmployee(usr) {
        return new Promise((resolve, reject) => {
            this.openDatabase().then(async (db) => {
                const store = db.transaction(this.miscStoreName, 'readwrite').objectStore(this.miscStoreName);
                const req = store.openCursor('employee');
                req.onerror = (e: any) => {
                    if (e && e.target) {
                        console.error(`Error saving employee: ${e.target.error}`, e.target);
                        reject(e);
                    }
                };
                req.onsuccess = (e: any) => {
                    const cursor: IDBCursor = e.target.result;
                    usr = this.cryptService.set(JSON.stringify(usr));
                    const q_req = (cursor) ? cursor.update({ employee: usr, key: 'employee' }) : store.add({ employee: usr, key: 'employee' });
                    q_req.onsuccess = () => resolve('Finished saving employee');
                    q_req.onerror = (err) => {
                        console.log('error adding employee', err);
                        reject(err);
                    };
                };
            });
        });
    }

    loadEmployee() {
        return new Promise((resolve, reject) => {
            this.openDatabase().then((db) => {
                const store = db.transaction(this.miscStoreName, 'readwrite').objectStore(this.miscStoreName);
                const req = store.openCursor('employee');
                req.onerror = (e: any) => {
                    if (e && e.target) {
                        console.error(`Error saving employee: ${e.target.error}`, e.target);
                        reject(e);
                    }
                };
                req.onsuccess = (e: any) => {
                    const cursor: any = e.target.result;
                    if (cursor && cursor.value && cursor.value.employee) {
                        resolve(JSON.parse(this.cryptService.get(cursor.value.employee.toString())));
                    } else {
                        reject('User has not been synced');
                    }
                };
            });
        });
    }
}
