import { switchMap } from 'rxjs/operators';
import { Injectable, EventEmitter } from '@angular/core';
import { forkJoin } from 'rxjs';
import { SSEHubEmployeesService } from '../SSEHubClient/employees.service';
import { SSEHubProfileService } from '../SSEHubClient/profile.service';
import { SSEHubReportHistoryService } from '../SSEHubClient/report-history.service';
import { IUser, User } from '@app/store/user/user.model';
import * as _ from 'lodash';
import { SyncDatabaseService } from '../report-services/sync-database.service';

@Injectable()
export class UserService {
    public errorUserLoaded$: EventEmitter<User>;

    constructor(
        private sseHubEmployeesService: SSEHubEmployeesService,
        private sseHubProfileService: SSEHubProfileService,
        private sseHubReportHistoryService: SSEHubReportHistoryService,
        private syncDB: SyncDatabaseService,
    ) {
        this.errorUserLoaded$ = new EventEmitter();
    }

    loadUser(id: string) {
        return this.sseHubEmployeesService.get(id).pipe(
            switchMap(u => {
                if (u) {
                    let fullU = null;
                    this.syncDB.saveEmployee(u);
                    u = User.new(u);
                    fullU = this.loadPrivileges(u);
                    return fullU;
                }
            }));
    }

    async getEmployee(id: string) {
        const result = await this.sseHubEmployeesService.get(id).toPromise().catch((err) => {
            return null;
        });
        let _user = null;
        if (result && result.profileId >= 0 && result.firstName) {
            this.syncDB.saveEmployee(result);
            const user = User.new(result);
            _user = await this.loadPrivileges(user);
        }
        return _user;
    }

    async loadPrivileges(user: IUser) {
        const loadProfilePrivileges$ = this.sseHubProfileService.getProfilePrivileges(user.profileId);
        const loadUserPrivilage$ = this.sseHubEmployeesService.getUserPrivilegesByEmployeeId(user.id);
        const loadProxyPrivilage$ = this.sseHubEmployeesService.isProxyUser(user.id);
        const loadHistoryManagementAccess$ = this.sseHubReportHistoryService.getEmployeeReportManagementAccess(user.id);
        // Wait untill both calls are complete before updating the subject(currentUser.next)
        const results = await forkJoin([loadProfilePrivileges$, loadUserPrivilage$, loadProxyPrivilage$, loadHistoryManagementAccess$]).toPromise();

        if (results) {
            // Assign Profile Privileges
            if (results[0]) {
                user.privileges = [...user.privileges, ...results[0]];
            }
            // Assign User Privileges
            if (results[1]) {
                user.privileges = [...user.privileges, ...results[1]];
            }
            // Assign Proxy Privilage
            if (results[1]) {
                user.isProxyUser = false;
                if (User.hasPrivilege(user, 'WEB_SUADMIN') || results[2]) {
                    user.isProxyUser = true;
                }
            }
            // Add HistoryManagementAccess Privilege
            if (results[3]) {
                user.privileges = [...user.privileges, { id: '', privilegeName: 'WEB_HISTORY_MANAGEMENT_ACCESS' }];
                // TODO: Move this into the tables or service
            }

        } else if (!results || !user || User.isActiveEmployee(user)) {
            this.errorUserLoaded$.emit(null);
        }

        return user;

    }
}
