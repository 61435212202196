import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { SummaryComponent } from '../summary.component';

@Pipe({ name: 'formatSummaryAnswer' })
export class FormatSummaryAnswerPipe implements PipeTransform {
    constructor(public datepipe: DatePipe) {
    }

    transform(question): string {
        let answer = question.userAnswer.answerText ? question.userAnswer.answerText : question.userAnswer;
        if (question.answerInputType) {
            const inputType = question.answerInputType.toUpperCase();
            if (inputType === 'DATE') {
                return this.datepipe.transform(answer, 'MM/dd/yyyy','UTC');
            }
            if (inputType === 'INVOLVEMENT' || inputType === 'QUESTIONGROUP') {
                answer = '';
            }
        }
        return answer;
    }
}

@Pipe({ name: 'shouldDisplayQuestionText' })
export class ShouldDisplayQuestionTextPipe implements PipeTransform {
    constructor(public summaryComponent: SummaryComponent) { }
    transform(question): boolean {
        const questionText = question.questionText ?? null;
        const answer = question.userAnswer ?? null;
        const inputType = question.answerInputType ? question.answerInputType.toUpperCase() : null;

        const answeredAndNotWeirdType = questionText && answer && inputType && inputType !== 'INVOLVEMENT' && inputType !== 'MULTI' && inputType !== 'QUESTIONGROUP';
        const answeredAndWeirdType = (inputType === 'INVOLVEMENT' || inputType === 'QUESTIONGROUP') &&
            this.summaryComponent.checkForAnswers(question.followupQuestions);

        return answeredAndNotWeirdType || answeredAndWeirdType;
    }
}

