import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, Component, inject, InjectionToken, Input, OnInit } from "@angular/core";
import { FormGroupDirective, ValidationErrors } from "@angular/forms";
import { KendoUiModule } from "app/shared/common-ux/kendo-ui.module";
import { BehaviorSubject, distinctUntilChanged, merge, Subscription } from "rxjs";

const defaultTagErrors: {
    [key: string]: any;
} = {
    required: () => 'This field is required',
    // requiredLength and actualLength come from the control validation error
    minlength: ({ requiredLength, actualLength }: any) => `Must be at least ${requiredLength} characters long`,
    maxlength: ({ requiredLength, actualLength }: any) => `Maximum ${requiredLength} characters allowed`,
    pattern: () => 'Invalid character or format'
};

export const TAG_ERRORS = new InjectionToken('FORM_ERRORS', {
    providedIn: 'root',
    factory: () => defaultTagErrors,
});

@Component({
    standalone: true,
    selector: 'form-control-error',
    imports: [AsyncPipe, KendoUiModule],
    templateUrl: './form-control-error.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    // styleUrl: '' // inherit style from parent component
})
export class FormControlErrorComponent implements OnInit {
    @Input() controlName!: string;

    // this allows for overriding default error messages
    // e.g. [customErrors]="{ required: 'This could be a custom required error'} as the parameter in the template
    @Input() customErrors?: ValidationErrors;

    // [overrideError]="Display this"
    // this is if there's an error message generated somewhere else that we want to display e.g. override anything from the control validation
    // this will be overwritten as soon as there is a value change in the input
    @Input() set overrideError(value: string) {
        this.setErrorText(value);
    }

    private subscription = new Subscription();
    errors = inject(TAG_ERRORS);
    private formGroupDirective = inject(FormGroupDirective);
    message$ = new BehaviorSubject<string>('');

    ngOnInit(): void {
        if (this.formGroupDirective) {
            // Access the corresponding form control
            const control = this.formGroupDirective.control.get(this.controlName);

            if (control) {
                this.subscription = merge(control.valueChanges, this.formGroupDirective.ngSubmit)
                    .pipe(distinctUntilChanged())
                    .subscribe(() => {
                        this.setErrorText('');  // clear out any previous errors (mainly to handle the overrideError)
                        if (this.overrideError) {
                            this.setErrorText(this.overrideError);
                            return;
                        }
                        const controlErrors = control.errors;

                        if (controlErrors) {
                            const firstErrorKey = Object.keys(controlErrors)[0];
                            const getError = this.errors[firstErrorKey];
                            // Get message from the configuration
                            const text = this.customErrors?.[firstErrorKey] || getError(controlErrors[firstErrorKey]);

                            // Set the error based on the configuration
                            this.setErrorText(text);
                        } else {
                            this.setErrorText('');
                        }
                    });
            }
        }
    }

    setErrorText(text: string) {
        this.message$.next(text);
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
