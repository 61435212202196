import { Component, EventEmitter, ViewChild, Input, Output, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { NgForm, ControlContainer, NgModel } from '@angular/forms';
import { IDynamicQuestion, IFlightSequence, FlightSequence, UpdateFlightSelection } from '../../new-report-store/wizard';
import { Store } from '@ngrx/store';
import { State } from 'app/store';
import { flightSequenceDictionary } from '../dynamic-form.component';
import { ConfirmFlightChangeComponent } from '@wizardmodals/confirm/flight-change/confirm-flight-change.component';
import { getFlightSelection, getQuestions } from 'app/new-report/new-report-store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
    selector: 'app-select',
    templateUrl: './select.component.html',
    styleUrls: ['../dynamic-form.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})

export class SelectComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input() question: any;
    @Input() groupName: string;

    @Output() answerEvent$ = new EventEmitter<any>();
    questionAttrs: object = {};
    @ViewChild('form') form: NgModel;
    fieldNameFlightAttribute;
    currentFlightDetails: IFlightSequence;
    @ViewChild(ConfirmFlightChangeComponent, { static: true }) flightChangeCom: ConfirmFlightChangeComponent;
    generalQuestions: Array<IDynamicQuestion> = [];
    destroy$: Subject<void> = new Subject<void>();

    constructor(private store: Store<State>) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    ngOnInit() {
        this.fieldNameFlightAttribute = this.questionAttrs && this.questionAttrs['fieldName'] ?
            flightSequenceDictionary[this.questionAttrs['fieldName'].trim().toUpperCase()] : null;

        if (this.fieldNameFlightAttribute) {
            this.currentFlightDetails = new FlightSequence();
            this.store.select(getFlightSelection).pipe(takeUntil(this.destroy$)).subscribe(flight => {
                if (flight) {
                    this.currentFlightDetails = flight;
                }
            });
            this.store.select(getQuestions('general')).pipe(takeUntil(this.destroy$)).subscribe(a => {
                this.generalQuestions = a;
            });
        }
    }
    ngAfterViewInit() {
        // Check if this select is in the flight section and that it only has one answer
        // If it does then we want to default it to expanded so the user doesnt have to click
        if (this.groupName.toLowerCase() === 'flight' && this.question.answers.length === 1) {
            this.question.userAnswer = this.question.answers[0];
            this.onChange();
        }

    }

    showReload() {
        const attrs = this.question.attrs === null ? {} : this.question.attrs;
        const failedApi = attrs['failedAPICall'];

        return failedApi === undefined ? false : failedApi;
    }
    onChange() {
        this.answerEvent$.emit({
            question: this.question,
            answer: this.question.userAnswer,
            myform: this.form
        });
        let isQuestionsVisited = false;
        if (this.generalQuestions && this.generalQuestions.length > 0) {
            this.generalQuestions.forEach(a => {
                if (a.hasOwnProperty('userAnswer')) {
                    isQuestionsVisited = true;
                    return;
                }
            });
        }
        if (this.fieldNameFlightAttribute) {
            if ((this.currentFlightDetails[this.fieldNameFlightAttribute] === null && this.currentFlightDetails['flightNumber'] === null)
                || this.currentFlightDetails[this.fieldNameFlightAttribute] === undefined) {
                // first time selected
                this.saveToStore(null);
            } else if (this.currentFlightDetails[this.fieldNameFlightAttribute] !== this.question.userAnswer.answerText && isQuestionsVisited) {
                this.flightChangeCom.openModal();
            }
        }

    }

    selectAnswer(c1: any, c2: any): boolean {
        return (c1 && c2) ? c1.answerText === c2.answerText : c1 === c2;
    }
    saveToStore(flight: IFlightSequence) {
        this.currentFlightDetails[this.fieldNameFlightAttribute] = this.question.userAnswer.answerText;
        this.store.dispatch(new UpdateFlightSelection({ ...this.currentFlightDetails }));
    }

    cancelUpdate() {
        this.question.userAnswer.answerText = this.currentFlightDetails[this.fieldNameFlightAttribute];

    }
}
