import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { SSEHubClient } from './SSEHubClient.service';
import { SyncDatabaseService } from '../report-services/sync-database.service';

@Injectable()
export class SSEHubEmployeesService {
    constructor(
        private http: HttpClient,
        private sseHubClient: SSEHubClient,
        private syncDB: SyncDatabaseService
    ) { }

    get(id): Observable<any> {
        const url = this.sseHubClient.serviceUrl() + '/api/employees/' + encodeURIComponent(id);
        const offlineReq = new Observable(observer => {
            this.syncDB.loadEmployee().then((val) => {
                observer.next(val);
                observer.complete();
            });
        });
        return (window.navigator.onLine) ? this.http.get(url) : offlineReq;
    }

    search(filter, order, limit): Observable<any> {
        return this.http.get(
            this.sseHubClient.serviceUrl() + '/api/employees?filter=' + encodeURIComponent(filter) + '&order=' + encodeURIComponent(order)
            + '&limit=' + encodeURIComponent(limit));
    }

    // New crewsequenes API using common services Apigee.
    getCrewSequencesByEmployeeId(employeeId: string): Observable<any> {
        const url = this.sseHubClient.serviceUrl() + '/api/crewSequence/' + encodeURIComponent(Number(employeeId));
        const offlineReq = new Observable(observer => {
            this.syncDB.getCrewSequence().then((val) => {
                observer.next(val);
                observer.complete();
            });
        });
        return (window.navigator.onLine) ? this.http.get(url) : offlineReq;
    }

    getCrewflightAndPassengerSequencesByEmployeeId(employeeId): Promise<any> {
        return this.http.get(this.sseHubClient.serviceUrl() + '/api/employees/' + encodeURIComponent(employeeId) +
            '/crewflightandpassengersequences').toPromise();
    }

    getInjuriesByEmployeeId(employeeId): Observable<any> {
        return this.http.get(
            this.sseHubClient.serviceUrl() + '/api/employees/' + encodeURIComponent(employeeId) + '/employeeInjuries');
    }

    isProxyUser(employeeId): Promise<any> {
        return this.http.get(
            this.sseHubClient.serviceUrl() + '/api/employees/IsProxyUser/' + encodeURIComponent(employeeId)).toPromise();
    }

    getUserPrivilegesByEmployeeId(employeeId): Observable<any> {
        return this.http.get(
            this.sseHubClient.serviceUrl() + '/api/employees/' + encodeURIComponent(employeeId) + '/privileges');

    }
}
