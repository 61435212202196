import { ISections } from './sections.model';
import * as SectionsAction from './sections.actions';
import { Category, Question, Answer } from '@shared/common-ux/models/QuestionAnswer';

export const initSections: ISections = {
    flight: {
        questions: null,
        isLoading: false,
        isReady: false,
        hasError: false,
    },
    questions: {
        questions: new Array<Question>(),
        isLoading: false,
        isReady: false,
        hasError: false,
    },
    notes: {
        questions: null,
        isLoading: false,
        isReady: false,
        hasError: false,
    },
    miscellaneous: {
        isLoading: false,
        isReady: false,
        hasError: false,
    },
    opened: {
        questions: {},
        answers: {},
    }
};

export function sectionsReducer(state = initSections, action: SectionsAction.SectionsActions): ISections {
    switch (action.type) {
        case SectionsAction.LOAD_FLIGHT_QUESTIONS:
            return {
                ...state,
                flight: {
                    ...state.flight,
                    isLoading: true
                }
            };
        case SectionsAction.POPULATE_FLIGHT_QUESTIONS:
            return {
                ...state,
                flight: {
                    ...state.flight,
                    questions: action.payload,
                    isLoading: false,
                    isReady: true
                }
            };
        case SectionsAction.LOAD_GENERAL_QUESTIONS:
            return {
                ...state,
                questions: {
                    ...state.questions,
                    isLoading: true
                }
            };
        case SectionsAction.POPULATE_GENERAL_QUESTIONS:
            return {
                ...state,
                questions: {
                    ...state.questions,
                    questions: action.payload,
                    isLoading: false,
                    isReady: true
                }
            };
        case SectionsAction.LOAD_NOTES_QUESTIONS:
            return {
                ...state,
                notes: {
                    ...state.notes,
                    isLoading: true
                }
            };
        case SectionsAction.POPULATE_NOTES_QUESTIONS:
            return {
                ...state,
                notes: {
                    ...state.notes,
                    questions: action.payload,
                    isLoading: false,
                    isReady: true
                }
            };
        case SectionsAction.SET_LOADING_STATE:
            return {
                ...state,
                [action.payload]: {
                    ...state[action.payload],
                    hasError: false,
                    isReady: false,
                    isLoading: true
                }
            };
        case SectionsAction.CATEGORY_SECTIONS_ERROR:
            if (!(action.section === 'flight'
                || action.section === 'questions' || action.section === 'miscellaneous')) {
                return state;
            }
            return {
                ...state,
                [action.section]: {
                    ...state[action.section],
                    hasError: true,
                    isReady: false,
                    isLoading: false
                }
            };
        case SectionsAction.SET_QUESTION_OPEN_STATE:
            return {
                ...state,
                opened: {
                    ...state.opened,
                    questions: {
                        ...state.opened.questions,
                        [action.id]: {
                            ...state.opened.questions[action.id],
                            opened: action.state,
                            group: action.group
                        }
                    }
                }
            };
        case SectionsAction.SET_ANSWER_OPEN_STATE:
            return {
                ...state,
                opened: {
                    ...state.opened,
                    answers: {
                        ...state.opened.answers,
                        [action.id]: {
                            ...state.opened.answers[action.id],
                            opened: action.state,
                            group: action.group
                        }
                    }
                }
            };
        case SectionsAction.RESET_VIEW_BY_SECTION:
            const opened = { ...state.opened };
            const new_state = { ...initSections.opened };
            // return the answer/question/involvement that aren't part of the group
            Object.keys(opened.answers).map((key, index) => {
                if (opened.answers[key].group !== action.group) {
                    new_state.answers[key] = opened.answers[key];
                }
            });

            Object.keys(opened.questions).map((key, index) => {
                if (opened.questions[key].group !== action.group) {
                    new_state.questions[key] = opened.questions[key];
                }
            });

            return {
                ...state,
                opened: new_state
            };
        default:
            return state;
    }
}

export const sectionsSelectors = {
    getFlightSection: (state: ISections) => state.flight,
    getNotesSection: (state: ISections) => state.notes,
    getFlightQuestions: (state: ISections) => state.flight.questions,
    getGeneralQuestions: (state: ISections) => state.questions.questions,
    getNotesQuestions: (state: ISections) => state.notes.questions,
    getQuestionsFlags: (state: ISections) => {
        return {
            isLoading: state.questions.isLoading,
            hasError: state.questions.hasError,
            isReady: state.questions.isReady
        };
    },
    getQuestionsOpenedById: (state: ISections) => state.opened.questions,
    getAnswersOpenedById: (state: ISections) => state.opened.answers,
};
