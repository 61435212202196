import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { getUser } from '@app/store/user/user.reducer';
import { IUser, User, InitializeUser } from '@app/store/user';
import { State } from '@app/store';

import { Observable, of } from 'rxjs';
import { tap, filter, take, map, switchMap } from 'rxjs/operators';

@Injectable()
export class CategoryManagementGuard implements CanActivate {
    constructor(
        private store: Store<State>,
        private router: Router
    ) {
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.getFromStore().pipe(
            switchMap(u => this.setPrivileges(u).toPromise())
            ).toPromise();
    }


    public setPrivileges(user: IUser) {
        if (user) {
            // get user privileges
            const suAdmin = User.hasPrivilege(user, 'WEB_SUADMIN');
            const categoryMgnt = User.hasPrivilegeSubStr(user, 'WEB_ADMIN_CATEGORYMGNT');
            if (suAdmin || categoryMgnt) {
                // user has authorization
                return of(true);
            } else {
                // No authorized profile, therefore not authorized
                this.router.navigate(['/home']);
                return of(false);
            }
        }
        return of(false);
    }

    public getFromStore() {
        return this.store.select(getUser).pipe(
            tap(u => {}),
            filter(user => user),
            take(1)
            );
    }
}
