<modal modalClass="modal-md" #submitFailureModal>
    <modal-header>
        <i class="icon-warning"></i>
        <h4 class="iconText">Unable to submit report</h4>
    </modal-header>
    <modal-content>
        <p class="fontRed" *ngIf="submitReportErrorCounter === 1">Submission failed. Please retry.</p>
        <p class="fontRed" *ngIf="submitReportErrorCounter > 1">Submission failed again. If you have added a large
            attachment, please remove the attachment. Try again, and contact the
            helpdesk if this problem persists.</p>
        <p class="fontRed" *ngIf="submissionFailureMessage.length > 0">{{submissionFailureMessage}}</p>
    </modal-content>
    <modal-footer>
        <button class="btn-return" (click)="submitFailureModal.close()">Return to report</button>
    </modal-footer>
</modal>
<modal modalClass="modal-md" #offlineFailureModal>
    <modal-header>
        <i class="icon-warning"></i>
        <h4 class="iconText">Unable to submit report</h4>
    </modal-header>
    <modal-content>
        <p>Internet connection was lost. Please reconnect to submit report.</p>
    </modal-content>
    <modal-footer>
        <button class="btn-primary" (click)="offlineFailureModal.close()">Return to report</button>
    </modal-footer>
</modal>

<app-aa-spinner [loading]="isSubmitting"></app-aa-spinner>
<div *ngIf="reportSubmitted && !isSubmitting">
    <h1>Submission</h1>
    <span *ngIf="offlineSubmission">
        <h3> Report will submit when internet connection is established. </h3>
    </span>
    <span *ngIf="!offlineSubmission">
        <h3 class="subHeader noTopMargin">Report successfully submitted</h3>
        <h4 *ngIf="reportReceipt.reportId" class="categoryTitle"> Report ID: {{ reportReceipt.reportId }}</h4>
        <h4 *ngIf="reportReceipt.title" class="categoryTitle"> Category: {{ reportReceipt.title }}</h4>
        <h4 *ngIf="reportReceipt.dateTimeSubmitted" class="categoryTitle"> Submitted on:
            {{ reportReceipt.dateTimeSubmitted | date: 'yyyy-MM-dd HH:mm' }}</h4>

        <div class="summary-links" *ngIf="showLinks">
            <h4><a class="printLink" (click)="printReportContent()">Print Injury Summary</a></h4>
            <h4><a href="https://hr.jetnet.aa.com/workcomp/docs/laa_info_letter_fa.pdf" target="_blank">View Information
                    Package</a></h4>
        </div>
    </span>

    <!-- Confirmation Text -->
    <div class="confirmationText" *ngIf="confirmationText" appSafeHtml htmlString="{{confirmationText}}"></div>

    <div class="wizardNavButtons">
        <div class="left">
            <button class="btn-return" (click)="returnToStart()">New Report</button>
        </div>
    </div>

</div>

<div *ngIf="!reportSubmitted && !isSubmitting">

    <h4 *ngIf="(reportInfo$ | async).categoryDescription" class="categoryTitle"> Category:
        {{ (reportInfo$ | async).categoryDescription }}</h4>

    <h1>Summary</h1>

    <div id="ReportContent">
        <div *ngIf="displaySection('flight')" #focusFlight>
            <i [ngClass]="{'icon-warning': displayIncompleteIcon('flight'),
            'icon-circle-check': displayCompleteIcon('flight')}"></i>
            <h3 class="subHeader noTopMargin iconText">Flight</h3>
            <div class="indent">
                <app-summary-question-display [questions]="allQuestions | filterQuestionsByGroupname: 'flight'">
                </app-summary-question-display>
            </div>
        </div>

        <div *ngIf="displaySection('questions')" #focusQuestions>
            <i [ngClass]="{'icon-warning': displayIncompleteIcon('questions'),
                    'icon-circle-check': displayCompleteIcon('questions')}"></i>
            <h3 class="subHeader iconText" [ngClass]="{'noTopMargin': noTopMargin('questions')}">Questions
            </h3>
            <div class="indent">
                <app-summary-question-display [questions]="allQuestions | filterQuestionsByGroupname: 'general'">
                </app-summary-question-display>
            </div>
        </div>

        <div *ngIf="displaySection('notes')" #focusNotes>
            <i [ngClass]="{'icon-warning': displayIncompleteIcon('notes'),
                            'icon-circle-check': displayCompleteIcon('notes')}"></i>
            <h3 class="subHeader iconText" [ngClass]="{'noTopMargin': noTopMargin('notes')}">
                Notes</h3>
            <div class="indent">
                <app-summary-question-display [questions]="allQuestions | filterQuestionsByGroupname: 'notes'">
                </app-summary-question-display>

            </div>
        </div>

    </div>

    <div *ngIf="attachments.length > 0">
        <h3 class="subHeader" [ngClass]="{'noTopMargin': noTopMargin('attachments')}">
            Attachments</h3>
        <app-attachment-view></app-attachment-view>
    </div>

    <div class="wizardNavButtons btn-drop-container">
        <div class="left">
            <button class="btn-prev" (click)="previous()">Previous</button>
            <!--<button class="btn-save" (click)="saveReport()" *ngIf="newReportService.isPwa">Save</button>-->
        </div>
        <div class="right">
            <button class="btn-cancel" (click)="confirmCancel.displayModal()">Cancel</button>
            <button class="btn-next" (click)="submit()">Submit</button>
            <button class="btn-drop-cancel" (click)="confirmCancel.displayModal()">Cancel</button>
        </div>
    </div>
</div>
<app-confirm-navigate (navResult)="onNavigate($event)"></app-confirm-navigate>
<app-confirm-cancel (cancel)="onReportCancel($event)"></app-confirm-cancel>