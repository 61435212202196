import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'capitalize' })
export class CapitalizePipe implements PipeTransform {
    transform(value: string): string {
        if (!value || value.length === 0) { // not a valid string
            return value;
        }
        return value.toUpperCase();
    }
}

@Pipe({ name: 'capitalizeFirstLetter' })
export class CapitalizeFirstLetterPipe implements PipeTransform {
    transform(text: string): string {
        if (!text || text.length === 0) { // not a valid string
            return text;
        }
        text = text.toLowerCase();
        return text.charAt(0).toUpperCase() + text.slice(1);
    }
}

@Pipe({ name: 'capitalizeInputType' })
export class CapitalizeInputTypePipe implements PipeTransform {
    transform(text: string): string {
        if (!text || text.length === 0) { // not a valid string
            return text;
        }
        text = text.toLowerCase();
        if (text === 'questiongroup') {
            return 'QuestionGroup';
        }
        return text.charAt(0).toUpperCase() + text.slice(1);
    }
}

