import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';

@Component({
    selector: 'app-confirmation-popup',
    templateUrl: './confirmation-popup.component.html',
    styleUrls: ['./confirmation-popup.component.scss']
})
export class ConfirmationPopupComponent {
    @ViewChild('confirmModal', { static: true }) public modal;
    @Output() callAction: EventEmitter<any> = new EventEmitter();
    @Input() title: string;
    @Input() message: string;
    constructor() { }


    open() {
        this.modal.open();
    }
    ok() {
        this.modal.close();
        this.callAction.emit();
    }
    cancel() {
        this.modal.close();
    }
}
