import { Component, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'app/store';
import { Subject } from 'rxjs';
import { ExpandCollapseService } from 'app/shared/common-ux/components/component-services/expand-collapse.service';

@Component({
    selector: 'app-question-list',
    templateUrl: './question-list.component.html',
    styleUrls: [
        '../question-summary.component.scss',
        '../../summary.component.scss',
    ],
})
export class QuestionListComponent {
    @Input() questionsAndAnswers: any;

    constructor() { }

    isEmailsDataType(attrs: object) {
        if (attrs) {
            if (attrs &&
                attrs['dataType']?.toLowerCase().includes('emails')) return true;
        }
        return false;
    }
}
