import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import * as TagActions from './tag.actions';
import { NotificationService } from 'app/shared/error-handler-notify/services';
import { DynamicQuestionsService } from 'app/shared/SSEHubClient';
import { IDictionary, ITagDetails, ITagErrors, tagNameDictionary } from './tag.model';
import { TagService } from 'app/shared/SSEHubClient/tag.service';

@Injectable()
export class TagEffects {

    loadTagsForQuestionMapping$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(
            TagActions.LOAD_TAGS_FOR_QUESTION_MAPPING,
            TagActions.ADD_TAG_TO_QUESTION_MAPPING_SUCCESS,
            TagActions.REMOVE_TAG_FROM_QUESTION_MAPPING_SUCCESS
        ),
        switchMap((action: { payload: { questionMappingId: string, includeSystemRequired: boolean } }) => {
            const { includeSystemRequired } = action.payload;
            return this.tagService.getMappedTags(action.payload.questionMappingId, includeSystemRequired).pipe(
                map(tags => new TagActions.LoadTagsForQuestionMappingSuccess({ tags })),
                catchError(errorObject => {
                    console.log(errorObject);
                    this.notificationService.showError('Error loading tags for question');
                    return of(new TagActions.LoadTagsForQuestionMappingFailure(errorObject));
                })
            );
        })
    ));

    addTagToQuestionMapping$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(TagActions.ADD_TAG_TO_QUESTION_MAPPING),
        switchMap((action: { payload: { questionMappingId: string, tagVal: string[], includeSystemRequired: boolean } }) => {
            return this.dynamicQuestionService.addTagMapping(action.payload.questionMappingId, action.payload.tagVal).pipe(
                tap(() => {
                    this.notificationService.showSuccess('You successfully applied a new tag(s) to the question.')
                }),
                map(() => new TagActions.AddTagToQuestionMappingSuccess({ 
                    questionMappingId: action.payload.questionMappingId,
                    tagVal: action.payload.tagVal,
                    includeSystemRequired: action.payload.includeSystemRequired
                })),
                catchError(errorObject => {
                    console.log(errorObject);
                    return of(new TagActions.AddTagToQuestionMappingFailure(errorObject));
                })
            );
        })
    ));

    removeTagFromQuestionMapping$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(TagActions.REMOVE_TAG_FROM_QUESTION_MAPPING),
        switchMap((action: { payload: { questionMappingId: string, tagVal: string, includeSystemRequired: boolean } }) => {
            return this.dynamicQuestionService.removeTagMapping(action.payload.questionMappingId, action.payload.tagVal).pipe(
                map(() => {
                    this.notificationService.showSuccess('You have succesfully removed a tag from the question.');
                    return new TagActions.RemoveTagFromQuestionMappingSuccess({
                        questionMappingId: action.payload.questionMappingId,
                        includeSystemRequired: action.payload.includeSystemRequired
                    });
                }),
                catchError(errorObject => {
                    console.log(errorObject);
                    this.notificationService.showError('Error removing tag from question.');
                    return of(new TagActions.RemoveTagFromQuestionMappingFailure(errorObject));
                })
            );
        })
    ));

    loadAllTags$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(
            TagActions.LOAD_ALL_TAGS,
            TagActions.CREATE_TAG_SUCCESS,
            TagActions.UPDATE_TAG_SUCCESS),
        switchMap((action: { payload: { includeSystemRequired?: boolean, includeInactive?: boolean }}) => {
            const { includeSystemRequired, includeInactive } = action.payload;
            return this.tagService.getAllTags(includeSystemRequired, includeInactive).pipe(
                map(tags => new TagActions.LoadAllTagsSuccess({ tags: tags as ITagDetails[] })),
                catchError(errorObject => {
                    console.log(errorObject);
                    this.notificationService.showError('Error loading all tags');
                    return of(new TagActions.LoadAllTagsFailure(errorObject));
                })
            );
        })
    ));

    createTag$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(TagActions.CREATE_TAG),
        switchMap((action: { payload: { tag: ITagDetails } }) => {
            return this.tagService.createTag(action.payload.tag).pipe(
                map(tag => {
                    this.notificationService.showSuccess('You have succesfully created a new tag');
                    return new TagActions.CreateTagSuccess({ tag: action.payload.tag as ITagDetails })
                }),
                catchError(errorResponseObject => {
                    console.log(errorResponseObject);
                    let errorArray = [];
                    if (errorResponseObject?.error?.message) {
                        errorArray.push(errorResponseObject.error.message);
                    }
                    const errorsObject = errorResponseObject?.error?.errors as ITagErrors;

                    for (const key of Object.keys(errorsObject)) {
                        if (errorsObject[key]) {
                            const errors = errorsObject[key];
                            // replace the object property name returned from the API with
                            // one that will make sense to the user
                            errors.forEach((error, i, errors) => {
                                errors[i] = this.replaceStrings(error, tagNameDictionary);
                            })
                            errorArray.push(...errors);
                        }
                    }
                    // display all error messages in the toast message.
                    const errors = errorArray ? errorArray.join('\n') : 'Error creating tag';
                    this.notificationService.showError(errors);
                    return of(new TagActions.CreateTagFailure(errorResponseObject.error.errors));
                })
            );
        })
    ));

    updateTag$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(TagActions.UPDATE_TAG),
        switchMap((action: { payload: { tag: ITagDetails } }) => {
            return this.tagService.updateTag(action.payload.tag).pipe(
                tap(() => this.notificationService.showSuccess('Your change(s) have been saved.')),
                map(tag => new TagActions.UpdateTagSuccess({ tag: tag as ITagDetails })),
                catchError(errorObject => {
                    console.log(errorObject);
                    this.notificationService.showError('Error Updating the Tag');
                    return of(new TagActions.UpdateTagFailure(errorObject.error.errors));
                })
            );
        })
    ));

    deactivateTag$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(TagActions.DEACTIVATE_TAG),
        switchMap((action: { payload: { tagVal: string } }) => {
            return this.tagService.deactivateTag(action.payload.tagVal).pipe(
                map(() => new TagActions.DeactivateTagSuccess({ tagVal: action.payload.tagVal })),
                catchError(errorObject => {
                    console.log(errorObject);
                    this.notificationService.showError('Error deactivating tag');
                    return of(new TagActions.DeactivateTagFailure(errorObject));
                })
            );
        })
    ));
    constructor(
        private actions$: Actions,
        private dynamicQuestionService: DynamicQuestionsService,
        private notificationService: NotificationService,
        private tagService: TagService
    ) { }



    replaceStrings(text: string, nameDictionary: IDictionary<string>): string {
        for (let [key, value] of Object.entries(nameDictionary)) {
            text = text.replace(key, value);
        }
        return text;

    }
}
