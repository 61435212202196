import { Component, ViewChild, EventEmitter, Output, Input, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { IFlightSequence, FlightSequence, IDynamicQuestion } from 'app/new-report/new-report-store/wizard';
import { SSEHubFlightSequencesService } from 'app/shared/SSEHubClient';
import { NgModel, NgForm } from '@angular/forms';
import { FormComponentService } from 'app/shared/common-ux/components/component-services/form-component.service';
import { ReportAssemblyService } from 'app/new-report/new-report.service';
import { AirportCode } from 'app/shared/SSEHubClient/airport.service';
import { DatePipe } from '@angular/common';
import { ConfirmFlightChangeComponent } from '@wizardmodals/confirm/flight-change/confirm-flight-change.component';
import { getQuestions, getFlightSelection, State } from 'app/new-report/new-report-store';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Component({
    selector: 'app-search-flight',
    templateUrl: './flight-search.component.html',
    styleUrls: ['../modals.component.scss']
})

export class SearchFlightComponent implements OnDestroy {

    @ViewChild('searchModal', { static: true }) public modal: any;
    @ViewChild(ConfirmFlightChangeComponent) flightChangeCom: ConfirmFlightChangeComponent;
    @Output() onselected = new EventEmitter<IFlightSequence>();
    @Input() isFlight: boolean;
    @Input() flightNum: string;
    @ViewChild('form', { static: true }) form: NgForm;
    @ViewChild('flNum') flNum: NgModel;
    @ViewChild('dc', { static: true }) dc: NgModel;
    @ViewChild('flDate', { static: true }) flDate: NgModel;
    options: AirportCode[] = [];
    generalQuestions: Array<IDynamicQuestion> = [];
    destroy$: Subject<void> = new Subject<void>();

    @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

    isSearching = false;
    searchResult = '';
    flightNumber = {
        'number': null, 'attrs': { 'isRequired': true, 'isNaN': true, 'minValue': 1, 'maxValue': 9999 }
    };
    departureDate = {
        'date': null, 'attrs': { 'isRequired': true, 'maxDate': 0, 'minDate': 3 }
    };
    departCity = {
        'code': '', 'attrs': { 'isRequired': false, 'autoPopulateValuesKey': 'stationCodes', 'maxLength': 3 }
    };
    flights: Array<FlightSequence> = [];
    foundFlight: boolean;
    selectedFlightDetails: IFlightSequence;

    constructor(
        private cdr: ChangeDetectorRef,
        private store: Store<State>,
        private ssehubflightsearch: SSEHubFlightSequencesService,
        private formComponentService: FormComponentService,
        private newReportService: ReportAssemblyService,
        public datepipe: DatePipe
    ) {
        this.store.select(getQuestions('general')).pipe(takeUntil(this.destroy$)).subscribe(q => {
            this.generalQuestions = q;
        });
        this.store.select(getFlightSelection).pipe(takeUntil(this.destroy$)).subscribe(flightDetails => {
            this.selectedFlightDetails = flightDetails;
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    cancel() {
        if (this.flights !== undefined && this.flights !== null) {
            this.flights.forEach(f => f.isChecked = false);
        }
        this.modal.close();
    }

    closeModal(value) {
        this.autocomplete.closePanel();
        this.flights.forEach(f => f.isChecked = false);
        this.clear();
        this.flightChangeCom.modal.close();
    }

    replaceInput(val: any) {
        if (this.departCity.attrs.autoPopulateValuesKey && this.departCity.attrs.autoPopulateValuesKey.toUpperCase() === 'STATIONCODES') {
            val.target.value = val.target.value.toUpperCase().replace(/[^a-zA-Z\s]/g, '');
            this.options = this.newReportService.getAirportCodeOptions(val.target.value);
        }
        this.dc.control.setValue(val.target.value);
    }

    openSearchModal() {
        this.modal.open();
        for (const flight of this.flights) {
            if (flight !== undefined && flight.isChecked !== undefined) {
                flight.isChecked = false;
            }
        }
    }

    clearResult() {
        this.foundFlight = false;
        this.flights = [];
        this.searchResult = '';
    }

    clear() {
        this.flightNumber.number = null;
        this.departureDate.date = null;
        this.departCity.code = null;
        this.flights = [];
        this.foundFlight = false;
        this.isSearching = false;
    }

    search() {
        if (this.flightNumber.number && this.departureDate.date) {
            this.isSearching = true;
            this.flights = [];
            this.foundFlight = false;
            const searchdate = this.datepipe.transform(this.departureDate.date, 'yyyy-MM-dd', 'UTC');
            this.ssehubflightsearch.getByFlightLeg(this.flightNumber.number, searchdate, this.departCity.code).pipe(takeUntil(this.destroy$))
                .subscribe((flights) => {
                    for (const flight of flights) {
                        if (flight) {
                            this.flights.push(new FlightSequence(this.formatFlightNumber(flight.flightNumber),
                                flight.departureStation, flight.departureDate,
                                flight.arrivalStation, flight.interruptStation, flight.fleetDescription, flight.tailNumber));
                            this.foundFlight = true;
                        }
                    }
                    if (this.flights.length < 1) {
                        this.searchResult = 'No Flight Found';
                    }
                    this.isSearching = false;
                },
                    (error) => {
                        this.searchResult = 'No Flight Found';
                        this.isSearching = false;
                    }
                );
        }
    }

    formatFlightNumber(flightNumber: any): string {
        return flightNumber ? flightNumber.toString().replace(/^0+/, '') : null;
    }

    failedValidate() {
        this.flights = [];
        this.foundFlight = false;
        this.searchResult = ('Please correct or finish the form.');
    }

    searchFlight() {
        this.clearResult();
        this.flightNumber.number = this.formatFlightNumber(this.flightNumber.number);
        if (this.flightNumber.number && this.departureDate.date) {
            this.formComponentService.validateAllFormFields(this.form.control);
        } else {
            this.failedValidate();
        }
        if (this.form.valid) {
            this.search();
        } else {
            this.failedValidate();
        }
    }

    showConfirm(value) {
        // this.cdr.detectChanges();
        let isQuestionsVisited = false;
        if (this.generalQuestions && this.generalQuestions.length > 0) {
            this.generalQuestions.forEach(a => {
                if (a.hasOwnProperty('userAnswer')) {
                    isQuestionsVisited = true;
                    return;
                }
            });
        }

        if (this.isDifferentFlight(value) && isQuestionsVisited) {
            this.flightChangeCom.openModal();
            this.cdr.detectChanges();

        } else {
            this.fillOutFlightDetail(value);
        }
    }

    isDifferentFlight(value) {
        return (
            (this.selectedFlightDetails && value && this.selectedFlightDetails.flightNumber && value.flightNumber &&
                value.flightNumber !== this.selectedFlightDetails.flightNumber
            )
            || (this.selectedFlightDetails && value && this.selectedFlightDetails.departureStation && value.departureStation &&
                value.departureStation !== this.selectedFlightDetails.departureStation
            )
            || (this.selectedFlightDetails && value && this.selectedFlightDetails.flightDate && value.flightDate &&
                (new Date(this.selectedFlightDetails.flightDate)).toString() !== (new Date(value.flightDate)).toString())
            || (this.selectedFlightDetails && value && this.selectedFlightDetails.fleetDescription &&
                this.selectedFlightDetails.fleetDescription !== value.fleetDescription)
            || (this.selectedFlightDetails && value && this.selectedFlightDetails.tailNumber &&
                this.selectedFlightDetails.tailNumber !== value.tailNumber)
        );
    }

    fillOutFlightDetail(value) {
        if (value.isChecked) {
            this.onselected.emit(value as IFlightSequence);
            this.modal.close();
        } else {
            this.onselected.emit(null);
        }
        this.cdr.detectChanges();
    }
}
