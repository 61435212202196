import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ReportDetailInvolvement } from 'app/report-history/report-history-store/detail';
import { Subject } from 'rxjs';
import * as _ from 'lodash';

export const personDetailQuestionTextMetadata = {
    // person
    'lastName': { textToDisplay: 'Last name', group: 'person', order: 1 },
    'middleName': { textToDisplay: 'Middle name', group: 'person', order: 2 },
    'firstName': { textToDisplay: 'First name', group: 'person', order: 3 },
    'lastNameFirstName': { textToDisplay: 'Last, First', group: 'person', order: 4 },
    'ageYears': { textToDisplay: 'Age', group: 'person', order: 5 },
    'gender': { textToDisplay: 'Gender', group: 'person', order: 6 },
    'passport': { textToDisplay: 'Passport number', group: 'person', order: 7 },
    'passportCountry': { textToDisplay: 'Passport country', group: 'person', order: 8 },
    'passportPassportCountry': { textToDisplay: 'Passport, Country', group: 'person', order: 9 },
    'medicalLicense': { textToDisplay: 'Medical license', group: 'person', order: 10 },
    'driversLicense': { textToDisplay: 'Drivers license', group: 'person', order: 11 },
    'driversState': { textToDisplay: 'Drivers license state', group: 'person', order: 12 },
    'driversLicenseDriversState': { textToDisplay: 'Drivers license, State', group: 'person', order: 13 },

    // contact
    'address1': { textToDisplay: 'Address', group: 'contact', order: 1 },
    'address2': { textToDisplay: 'Address line 2', group: 'contact', order: 2 },
    'city': { textToDisplay: 'City', group: 'contact', order: 3 },
    'state': { textToDisplay: 'State', group: 'contact', order: 4 },
    'zip': { textToDisplay: 'Zip', group: 'contact', order: 5 },
    'cityState': { textToDisplay: 'City, State', group: 'contact', order: 6 },
    'cityZip': { textToDisplay: 'City, Zip', group: 'contact', order: 7 },
    'cityStateZip': { textToDisplay: 'City, State, Zip', group: 'contact', order: 8 },
    'stateZip': { textToDisplay: 'State, Zip', group: 'contact', order: 9 },
    'country': { textToDisplay: 'Country', group: 'contact', order: 10 },
    'phone': { textToDisplay: 'Best contact phone', group: 'contact', order: 11 },
    'email': { textToDisplay: 'Best contact email', group: 'contact', order: 12 },

    // employee
    'employeeId': { textToDisplay: 'Employee number', group: 'employee', order: 1 },
    'department': { textToDisplay: 'Department', group: 'employee', order: 2 },
    'jobTitle': { textToDisplay: 'Job title', group: 'employee', order: 3 },
    'costCenter': { textToDisplay: 'Cost center', group: 'employee', order: 4 },
    'baseStation': { textToDisplay: 'Base station', group: 'employee', order: 5 },
    'shiftStart': { textToDisplay: 'Normal tart of shift', group: 'employee', order: 6 },
    'shiftEnd': { textToDisplay: 'Normal end of shift', group: 'employee', order: 7 },
    'shiftStartShiftEnd': { textToDisplay: 'Shift start and end', group: 'employee', order: 8 },
    'daysOff': { textToDisplay: 'Scheduled days off', group: 'employee', order: 9 },

    // supervisor
    'supvFirstName': { textToDisplay: 'Supervisor\'s first name', group: 'supervisor', order: 1 },
    'supvLastName': { textToDisplay: 'Supervisor\'s last name', group: 'supervisor', order: 2 },
    'supvLastNameSupvFirstName': { textToDisplay: 'Supervisor\'s Last, First', group: 'supervisor', order: 3 },
    'supvEmployeeId': { textToDisplay: 'Supervisor\'s employee ID', group: 'supervisor', order: 4 },
    'supvPhone': { textToDisplay: 'Supervisor\'s phone', group: 'supervisor', order: 5 },

    // passenger
    'pnr': { textToDisplay: 'PNR', group: 'pax', order: 1 },
    'bagTag': { textToDisplay: 'Bag Tag', group: 'pax', order: 2 },
    'seat': { textToDisplay: 'Seat', group: 'pax', order: 3 },
    'aadvantageNumber': { textToDisplay: 'AAdvantage number', group: 'pax', order: 4 },

    // vendor
    'vendorCompany': { textToDisplay: 'Vendor company', group: 'vendor', order: 1 },
    'vendorEquipment': { textToDisplay: 'Vendor equipment', group: 'vendor', order: 2 },
    'vendorPhone': { textToDisplay: 'Vendor phone', group: 'vendor', order: 3 },
};

export const sectionMetadata = {
    person: { textToDisplay: 'Person details', order: 1 },
    contact: { textToDisplay: 'Contact info', order: 2 },
    employee: { textToDisplay: 'Employee details', order: 3 },
    supervisor: { textToDisplay: 'Supervisor', order: 4 },
    pax: { textToDisplay: 'Passenger info', order: 5 },
    vendor: { textToDisplay: 'Vendor details', order: 6 },
};

export class PersonDetailSections {
    sectionTitle: string;
    details: Array<PersonDetailQuestionAnswerObj>;
}

export class PersonDetailQuestionAnswerObj {
    question: string;
    answer: string;
}

export class DetailObj {
    key: string;
    question: string;
    answer: string | number;
    order: number;
}

@Component({
    selector: 'app-person-details',
    templateUrl: './person-details.component.html',
    styleUrls: [
        './person-details.component.scss',
        '../summary.component.scss',
    ],
})

export class PersonDetailComponent implements OnInit, OnDestroy {
    @Input() involvement: ReportDetailInvolvement;
    personDetailsArray = [];
    nameToDisplay = '';
    displayInvolvement = false;
    columnsClass = 'col-md-2 col-sm-12';
    destroy$: Subject<void> = new Subject<void>();

    constructor() { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        // this creates and object with each section as a property
        const sectionObj: any = _.reduce(this.involvement.personDetails, function (result, detail, key) {
            if (detail) {
                const obj: DetailObj = {
                    key: key,
                    question: personDetailQuestionTextMetadata[key].textToDisplay,
                    answer: detail,
                    order: personDetailQuestionTextMetadata[key].order
                };
                (result[personDetailQuestionTextMetadata[key].group] || (result[personDetailQuestionTextMetadata[key].group] = [])).push(obj);
            }
            return result;
        }, {});

        // this section combines details for the display e.g. last and first names on the same line
        this.combineDetails(sectionObj.contact, ['City', 'State', 'Zip']);
        this.combineDetails(sectionObj.person, ['LastName', 'FirstName']);
        this.combineDetails(sectionObj.person, ['Passport', 'PassportCountry']);
        this.combineDetails(sectionObj.person, ['DriversLicense', 'DriversState']);
        this.combineDetails(sectionObj.employee, ['ShiftStart', 'ShiftEnd']);
        this.combineDetails(sectionObj.supervisor, ['SupvLastName', 'SupvFirstName']);

        // this sorts the sections into the order we want to display them, sorts the question/answers in each sections,
        // and creates the array the template will use to display the information
        this.personDetailsArray = _.sortBy(Object.entries(sectionObj), ([key]) => {
            return sectionMetadata[key].order;
        }).map(([key, val]: [string, Array<PersonDetailQuestionAnswerObj>]) => {
            const ds = _.sortBy(val, 'order').map((details) => { return { question: details['question'], answer: details['answer'] }; });
            return { sectionTitle: sectionMetadata[key].textToDisplay, details: ds };
        });

        this.nameToDisplay = this.involvement.personDetails.lastName && this.involvement.personDetails.firstName ?
            this.involvement.personDetails.lastName + ', ' + this.involvement.personDetails.firstName :
            this.involvement.personDetails.lastName ?
                this.involvement.personDetails.lastName : this.involvement.personDetails.firstName ?
                    this.involvement.personDetails.firstName : '';
        this.nameToDisplay = this.nameToDisplay ? this.involvement.involvementType + ' - ' + this.nameToDisplay : this.involvement.involvementType;

        // this dynamically sizes the columns
        switch (this.personDetailsArray.length) {
            case 1:
                this.columnsClass = 'col-md-12 col-sm-12';
                break;
            case 2:
                this.columnsClass = 'col-md-6 col-sm-12';
                break;
            case 3:
                this.columnsClass = 'col-md-4 col-sm-12';
                break;
            case 4:
                this.columnsClass = 'col-md-3 col-sm-12';
                break;
            case 5:
            case 6:
            default:
                this.columnsClass = 'col-md-2 col-sm-12';
                break;
        }
    }


    // pass in the section array and an array of the detail keys you want to combine
    combineDetails(sectionArray: any[], valuesToCombine: string[]) {
        if (sectionArray && valuesToCombine) {
            const indicesToDelete = [];
            const toAdd = [];
            let detailKey = '';
            let answer = '';

            for (const value of valuesToCombine) {
                const valIndex = sectionArray.findIndex(x => x.key === value);
                if (valIndex >= 0) {
                    const val = sectionArray[valIndex];

                    if (val && val.answer && val.question) {
                        detailKey += val.key;
                        answer = answer === '' ? val.answer : answer + ', ' + val.answer;
                        indicesToDelete.push(valIndex);
                    }
                }
            }

            if (detailKey) {
                toAdd.push({
                    key: detailKey,
                    question: personDetailQuestionTextMetadata[detailKey].textToDisplay,
                    answer: answer,
                    order: personDetailQuestionTextMetadata[detailKey].order
                });
            }

            // must do in descencing order so doesn't mess up the indexing
            indicesToDelete.sort(function (a, b) { return b - a; });
            for (const index of indicesToDelete) {
                sectionArray.splice(index, 1);
            }
            for (const add of toAdd) {
                sectionArray.push(add);
            }
        }
    }

}
