import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-add-section-inline',
  templateUrl: './add-section-inline.component.html',
  styleUrls: ['./add-section-inline.component.scss']
})
export class AddSectionInlineComponent implements OnInit {
  @Output() onSave = new EventEmitter();
  @Output() onIsAddNew = new EventEmitter();
  @Input() title: string;
  @Input() btnTitle: string;
  textInvalid = false;
  isAddNew = false;
  text: string;
  constructor() { }

  ngOnInit() {
  }

  private cancel() {
    this.reset();
  }

  private save() {
    if (!this.text || this.text.trim() === '') {
      this.textInvalid = true;
      return;
    }
    this.onSave.emit(this.text);
    this.reset();
  }

  onAdd() {
    this.isAddNew = true;
    this.onIsAddNew.emit(this.isAddNew);
  }

  reset() {
    this.isAddNew = false;
    this.onIsAddNew.emit(this.isAddNew);
    this.textInvalid = false;
    this.text = '';
  }

}
