export const environment = {
  // Specifically used by PingAuthenticationModule in app.module.ts.
  authority: 'https://idp.aa.com',
  clientId: 'cers_prod',
  idle_threshold: 900,
  timeout_duration: 60,
  use_html5_routing: false,
  load_user_info: true,
  logLevel: 2,
  logoutURL: 'https://pfloginapp.cloud.aa.com/api/palogout?TargetResource=https://cers-dev.aa.com',
  // Outside of PingAuthenticationModule.
  apiUri: 'https://cersapi-dev.aa.com/SSEHub.CERSPortal/2.0',
  encryptKey: '16D71B81C42E4FA7AC16B2BDEA7E53E9',
  loginUrl: 'https://cers-dev.aa.com',
  production: false,
  appUrl: 'https://cers-dev.aa.com',
  ssoEndpoint: 'https://pfloginapp.cloud.aa.com/',
  bypassSSO: false,
  sw: true,
  serviceWorker: false,
  envName: 'dev',
  userOverride: null,
  cognosUrl: 'https://cognos.dev.aa.com/cers-interactive',
  scope: 'openid profile offline_access',
};
