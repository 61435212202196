import { Action } from '@ngrx/store';
import { Question } from 'app/shared/common-ux/models/QuestionAnswer';

// flight section actions
export const LOAD_FLIGHT_QUESTIONS = '[CategoryManagement sections] loadFlightQuestions';
export const POPULATE_FLIGHT_QUESTIONS = '[CategoryManagement sections] populateFlightQuestions';

// notes section actions
export const LOAD_NOTES_QUESTIONS = '[CategoryManagement sections] loadNotesQuestions';
export const POPULATE_NOTES_QUESTIONS = '[CategoryManagement sections] populateNotesQuestions';

// questions section actions
export const LOAD_GENERAL_QUESTIONS = '[CategoryManagement sections] loadGeneralQuestions';
export const POPULATE_GENERAL_QUESTIONS = '[CategoryManagement sections] populateGeneralQuestions';
export const POPULATE_REPORTED_BY_QUESTIONS = '[CategoryManagement sections] populateReportedByQuestions';
export const SET_ANSWER_OPEN_STATE = '[CategoryManagement sections] setAnswerOpenState';
export const SET_QUESTION_OPEN_STATE = '[CategoryManagement sections] setQuestionOpenState';
export const RESET_VIEW_BY_SECTION = '[CategoryManagement sections] resetViewBySection';

// state actions
export const SET_LOADING_STATE = '[CategoryManagement sections] setLoadingState';
export const CATEGORY_SECTIONS_ERROR = '[CategoryManagement sections] categorySectionsError';

// flight
export class LoadFlightQuestions implements Action {
    readonly type = LOAD_FLIGHT_QUESTIONS;
    constructor(public profileId: number, public categoryId: number, public group: string) { }
}
export class PopulateFlightQuestions implements Action {
    readonly type = POPULATE_FLIGHT_QUESTIONS;
    constructor(public payload: Array<Question>) { }
}

// notes
export class LoadNotesQuestions implements Action {
    readonly type = LOAD_NOTES_QUESTIONS;
    constructor(public profileId: number, public categoryId: number, public group: string) { }
}
export class PopulateNotesQuestions implements Action {
    readonly type = POPULATE_NOTES_QUESTIONS;
    constructor(public payload: Array<Question>) { }
}

// questions
export class LoadGeneralQuestions implements Action {
    readonly type = LOAD_GENERAL_QUESTIONS;
    constructor(public profileId: number, public categoryId: number) { }
}
export class PopulateGeneralQuestions implements Action {
    readonly type = POPULATE_GENERAL_QUESTIONS;
    constructor(public payload: Array<Question>) { }
}
// state
export class CategorySectionsError implements Action {
    readonly type = CATEGORY_SECTIONS_ERROR;
    // the payload specifies a section with a string
    constructor(public section: string, public notification?: string, public message?: string) { }
}
export class SetLoadingState implements Action {
    readonly type = SET_LOADING_STATE;
    constructor(public payload: string) { }
}
export class SetQuestionOpenState implements Action {
    readonly type = SET_QUESTION_OPEN_STATE;
    constructor(public id: number, public group: string, public state: boolean) { }
}
export class SetAnswerOpenState implements Action {
    readonly type = SET_ANSWER_OPEN_STATE;
    constructor(public id: number, public group: string, public state: boolean) { }
}
export class ResetViewBySection implements Action {
    // resets question, answer, or involvements by section
    readonly type = RESET_VIEW_BY_SECTION;
    constructor(public group: string) { }
}

export type SectionsActions
    = LoadFlightQuestions
    | PopulateFlightQuestions
    | LoadNotesQuestions
    | PopulateNotesQuestions
    | SetQuestionOpenState
    | SetAnswerOpenState
    | ResetViewBySection
    | LoadGeneralQuestions
    | PopulateGeneralQuestions
    | SetLoadingState
    | CategorySectionsError;
