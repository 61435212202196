import { Component, OnInit, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuService } from './menu.service';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { getUser } from '@app/store/user/user.reducer';
import { IUser, User } from '@app/store/user';
import { State } from '@app/store';
import { takeUntil } from 'rxjs/operators';
import { FeatureFlagService } from 'app/shared/feature-flag/feature-flag.service';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';

@Component({
    selector: 'app-aa-nav-bar',
    templateUrl: './nav-bar.component.html',
    providers: [],
    styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent implements OnInit, OnDestroy {
    currentActiveRoute = '/home';
    isReportManagementAccess = false;
    destroy$: Subject<void> = new Subject<void>();
    iodFlag = false;
    isGeneralMgr = false;

    constructor(
        private store: Store<State>,
        public router: Router,
        public menuService: MenuService,
        public featureFlag: FeatureFlagService,
        private authService: PingAuthenticationService
    ) {
        this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(user => this.loadUser(user));
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    loadUser(user: IUser) {
        if (!user) { return; }
        this.menuService.setPrivileges(user);
        this.isReportManagementAccess = User.hasPrivilege(user, 'WEB_HISTORY_MANAGEMENT_ACCESS');
        this.isGeneralMgr = user.profileId === 7;
    }

    public isRouteActive(route) {
        // This method is currently not used
        return this.router.url.startsWith(route);
    }

    public navigate(route) {
        this.currentActiveRoute = '/' + route;
    }

    updateRoute(route: string) {
        this.currentActiveRoute = route;
    }

    routeContains(route: string) {
        let contains = false;
        contains = this.currentActiveRoute.includes(route);
        return contains;
    }

    ngOnInit() {
        this.authService.OnSignInComplete.pipe(takeUntil(this.destroy$)).subscribe(_ => {
            this.featureFlag.hasFeatureFlag('FLAG_IOD_HISTORY').pipe(takeUntil(this.destroy$)).subscribe(f => this.iodFlag = f);
        });

        this.router.events
            .pipe(takeUntil(this.destroy$)).subscribe((event) => {
                if (event instanceof NavigationEnd) {
                    this.updateRoute(event.url);
                }
            });
    }

}
