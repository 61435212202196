import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import * as _ from 'lodash';

import { NotificationService } from '@shared/error-handler-notify/services/notification.service';
import { DynamicQuestionsService } from '@shared/SSEHubClient/dynamic-questions.service';
import { switchMap, map, withLatestFrom, catchError } from 'rxjs/operators';
import { Observable, merge, forkJoin, EMPTY as empty, of, pipe } from 'rxjs';


import * as templateActions from './template.actions';
import { ITemplateQuestions, ITemplateQuestion } from '.';


@Injectable()
export class TemplateEffects {

    
    loadTemplateNames$: Observable<Action> = createEffect(() => this.action$.pipe(
        ofType<templateActions.LoadTemplateNames>(templateActions.LOAD_TEMPLATE_NAMES),
        switchMap(action => {
            return this.dynamicQuestionsService.getTemplateNames().pipe(
                map(names => {
                    return new templateActions.PopulateTemplateNames(names);
                }),
                catchError(e => {
                    console.log(e);
                    this.notificationService.showError('Error loading template names.');
                    return of(new templateActions.TemplateNameError('Error loading template names.'));
                })
            );
        })
    ));

    
    loadSelectionsForTemplate$: Observable<Action> = createEffect(() => this.action$.pipe(
        ofType<templateActions.LoadSelectionsForTemplate>(templateActions.LOAD_SELECTIONS_FOR_TEMPLATE),
        switchMap(action => {
            return this.dynamicQuestionsService.loadFollowUpQuestions(action.payload.profileId, action.payload.categoryId,
                action.payload.group, action.payload.answer.answerId).pipe(
                    map(results => {
                        const existingTemplates = _(results.questions).map('templateName').uniq().value();
                        return new templateActions.PopulateSelectionsForTemplate({
                            selectedAnswer: action.payload.answer,
                            existingTemplates: existingTemplates,
                        });
                    }),
                    catchError(e => {
                        console.log(e);
                        this.notificationService.showError('Error loading template questions.');
                        return of(new templateActions.TemplateNameError('Error loading template questions.'));
                    })
                );
        })
    ));

    
    loadTemplateQuestions$: Observable<Action> = createEffect(() => this.action$.pipe(
        ofType<templateActions.LoadTemplateQuestions>(templateActions.LOAD_TEMPLATE_QUESTIONS),
        switchMap(action => {
            return this.dynamicQuestionsService.getTemplateQuestions(action.payload.profileId, action.payload.categoryId,
                action.payload.group, action.payload.answer.answerId).pipe(
                    map(templateQuestions => {
                        return new templateActions.PopulateTemplateQuestions(this.parseTemplateQuestions(templateQuestions));
                    }),
                    catchError(e => {
                        console.log(e);
                        this.notificationService.showError('Error loading template questions.');
                        return of(new templateActions.TemplateNameError('Error loading template questions.'));
                    })
                );
        })
    ));

    constructor(
        private action$: Actions,
        private notificationService: NotificationService,
        private dynamicQuestionsService: DynamicQuestionsService
    ) { }

    parseTemplateQuestions(templateQuestions): Array<ITemplateQuestions> {
        const tq: Array<ITemplateQuestions> = [];
        for (const template of templateQuestions) {
            if (template) {
                const t: ITemplateQuestions = {
                    templateName: template.templateName,
                    questions: []
                };
                for (const question of template.questions) {
                    if (question) {
                        const q: ITemplateQuestion = {
                            categoryId: question.categoryId,
                            profileId: question.profileId,
                            questionId: question.questionId,
                            templateQuestionMappingId: question.templateQuestionMappingId,
                            mappedQuestionMappingId: question.mappedQuestionMappingId,
                            questionText: question.question,
                            inputType: question.inputType,
                            displayOrder: question.displayOrder,
                            isRoot: question.isRoot,
                            groupName: question.groupName,
                            templateName: question.templateName,
                            isChecked: question.mappedQuestionMappingId > 0
                        };
                        t.questions.push(q);
                    }
                }
                t.questions.sort((a, b) => (a.displayOrder > b.displayOrder) ? 1 : -1);
                tq.push(t);
            }
        }
        return tq;
    }

}
