import { Action } from '@ngrx/store';
import { CategoryDetails } from './detail.model';
import { ICategory } from '../list';
import { CpAttrsVal } from 'app/shared/models/cp-attrs-val';

export const CATEGORY_DETAIL_ERROR = '[CategoryManagement detail] categoryDetailError';
// export const INITIALIZE_NEW_CATEGORY = '[CategoryManagement detail] initializeNewCategory';
export const LOAD_CATEGORY_DETAIL = '[CategoryManagement detail] loadCategoryDetail';
export const POPULATE_CATEGORY_DETAIL = '[CategoryManagement detail] populateCategoryDetail';
export const SELECT_CATEGORY = '[CategoryManagement detail] selectCategory';
export const SELECT_CATEGORY_BY_ID = '[CategoryManagement detail] selectCategoryById';
export const CLEAR_CATEGORY_DETAIL = '[CategoryManagement detail] clearCategoryDetail';
export const SET_CATEGORY_ATTRS = '[CategoryManagement detail] setCategoryAttrs';


export class SelectCategory implements Action {
    readonly type = SELECT_CATEGORY;
    constructor(public payload: ICategory) { }
}
export class SelectCategoryById implements Action {
    readonly type = SELECT_CATEGORY_BY_ID;
    constructor(public payload: number) { }
}

export class CategoryDetailError implements Action {
    readonly type = CATEGORY_DETAIL_ERROR;
    constructor(public payload: any) { }
}

export class LoadCategoryDetail implements Action {
    readonly type = LOAD_CATEGORY_DETAIL;
    constructor(public profileId: number, public categoryId: number) { }
}

export class PopulateCategoryDetail implements Action {
    readonly type = POPULATE_CATEGORY_DETAIL;
    constructor(public payload: CategoryDetails) { }
}

export class ClearCategoryDetail implements Action {
    readonly type = CLEAR_CATEGORY_DETAIL;
}

export class SetCategoryAttrs implements Action {
    readonly type = SET_CATEGORY_ATTRS;
    constructor(public payload: CpAttrsVal) { }
}

export type Actions
    = CategoryDetailError
    | ClearCategoryDetail
    | LoadCategoryDetail
    | PopulateCategoryDetail
    | SelectCategory
    | SelectCategoryById
    | SetCategoryAttrs
