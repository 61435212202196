import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NotificationService } from '../services/notification.service';
import { ToastEvent } from './toast-models';

@Component({
    selector: 'app-toaster',
    templateUrl: './toaster.component.html',
    styleUrls: ['./toaster.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToasterComponent implements OnInit {
    toastEvents: ToastEvent[] = [];

    constructor(private notificationService: NotificationService, private cdr: ChangeDetectorRef) { }

    ngOnInit() {
        this.notificationService.toastEvents.subscribe((toast) => {
            const currentToast: ToastEvent = {
                type: toast.type,
                title: toast.title,
                message: toast.message,
            };
            this.toastEvents.push(currentToast);
            this.cdr.detectChanges();
        });
    }

    dispose(index: number) {
        this.toastEvents.splice(index, 1);
        this.cdr.detectChanges();
    }
}
