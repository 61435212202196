export enum ToastType {
    Success = 'success',
    Error = 'error',
    Warning = 'warning',
    Info = 'info',
}

export class ToastEvent {
    type: ToastType;
    title?: string;
    message: string;
}
