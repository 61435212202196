import { Action } from '@ngrx/store';
import { Answer, Question } from 'app/shared/common-ux/models/QuestionAnswer';
import { ITemplateSelections, ITemplateQuestions } from './template.model';

// template actions
export const LOAD_TEMPLATE_NAMES = '[CategoryManagement template] loadTemplateNames';
export const POPULATE_TEMPLATE_NAMES = '[CategoryManagement template] populateTemplateNames';
export const LOAD_TEMPLATE_QUESTIONS = '[CategoryManagement template] loadTemplateQuestions';
export const POPULATE_TEMPLATE_QUESTIONS = '[CategoryManagement template] populateTemplateQuestions';
export const LOAD_SELECTIONS_FOR_TEMPLATE = '[CategoryManagement template] loadSelectionsForTemplate';
export const POPULATE_SELECTIONS_FOR_TEMPLATE = '[CategoryManagement template] populateSelectionsForTemplate';
export const TEMPLATE_NAME_ERROR = '[CategoryManagement template] templateNameError';


// templates
export class LoadTemplateNames implements Action {
    readonly type = LOAD_TEMPLATE_NAMES;
    constructor() { }
}
export class PopulateTemplateNames implements Action {
    readonly type = POPULATE_TEMPLATE_NAMES;
    constructor(public payload: Array<string>) { }
}
export class LoadTemplateQuestions implements Action {
    readonly type = LOAD_TEMPLATE_QUESTIONS;
    constructor(public payload: { profileId: number, categoryId: number, group: string, answer: Answer }) { }
}
export class PopulateTemplateQuestions implements Action {
    readonly type = POPULATE_TEMPLATE_QUESTIONS;
    constructor(public payload: Array<ITemplateQuestions>) { }
}
export class LoadSelectionsForTemplate implements Action {
    readonly type = LOAD_SELECTIONS_FOR_TEMPLATE;
    constructor(public payload: { profileId: number, categoryId: number, group: string, answer: Answer }) { }
}
export class PopulateSelectionsForTemplate implements Action {
    readonly type = POPULATE_SELECTIONS_FOR_TEMPLATE;
    constructor(public payload: ITemplateSelections) { }
}
export class TemplateNameError implements Action {
    readonly type = TEMPLATE_NAME_ERROR;
    constructor(public payload: string) { }
}


export type TemplateActions
    = LoadTemplateNames
    | PopulateTemplateNames
    | LoadTemplateQuestions
    | PopulateTemplateQuestions
    | LoadSelectionsForTemplate
    | PopulateSelectionsForTemplate
    | TemplateNameError;
