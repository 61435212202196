import { take, takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

// services
import { CategoryDetails } from '@shared/reportStructure/categoryDetails/reportSections';
import { CategoryDetailService } from './category-detail.service';

// store
import { IUser, User, getUser } from '@app/store/user';
import { State } from '@app/store';
import { getCategoryDetails, getSelectedProfileId } from '../category-management-store';
import * as Actions from '../category-management-store/detail/detail.actions';
import { SelectProfileById } from '../category-management-store/list/list.actions';
@Component({
    selector: 'app-category-details',
    templateUrl: './category-details.component.html',
    styleUrls: ['./category-details.component.scss']
})
export class CategorySummaryComponent implements OnInit, OnDestroy {
    destroy$: Subject<void> = new Subject<void>();
    category$: Observable<CategoryDetails>;
    categoryId: number;
    profileId: number;
    questionsNtFound: any;
    @ViewChild('myModal', { static: true }) modal;

    constructor(
        private store: Store<State>,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private categoryDetailService: CategoryDetailService,
    ) { }

    ngOnInit() {
        this.category$ = this.store.select(getCategoryDetails);

        this.activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
            this.profileId = params['profileId'];
            this.categoryId = params['categoryId'];
            this.categoryDetailService.setState(this.profileId, this.categoryId);
        });

        // sets selectedProfile in the store when user goes to categoryDetails directly
        this.store.select(getSelectedProfileId).pipe(take(1)).pipe(takeUntil(this.destroy$))
            .subscribe(profileId => {
                if (!profileId) {
                    this.store.dispatch(new SelectProfileById(this.profileId));
                }
            });


        this.store.select(getUser).pipe(takeUntil(this.destroy$))
            .subscribe(user => this.verifyAccess(user));
    }

    ngOnDestroy() {
        // clean up the service, state, and subscriptions
        this.categoryDetailService.clearState();
        this.store.dispatch(new Actions.ClearCategoryDetail());
        this.destroy$.next();
        this.destroy$.complete();
    }

    capitalizeFirstLetter(text: string) {
        text = text.toLowerCase();
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    gotoCategories() {
        this.router.navigate(['/admin/categorymanagement', { profileId: this.profileId }]);
    }


    private IsSectionEmpty(sections) {
        let sectionsNotDefined = false;
        if (sections) {
            sectionsNotDefined = (sections.flight && !sections.flight.sectionVisibility)
                && (sections.images && !sections.images.sectionVisibility)
                && (sections.notes && !sections.notes.sectionVisibility)
                && (sections.questions && !sections.questions.sectionVisibility)
                && (sections.recommendations && !sections.recommendations.sectionVisibility);
        }
        return sectionsNotDefined;
    }

    private verifyAccess(user: IUser) {
        if (user) {
            const access = User.hasPrivilegeArray(
                user,
                [
                    'WEB_SUADMIN',
                    'WEB_ADMIN_CATEGORYMGNT_VIEW_ALL',
                    'WEB_ADMIN_CATEGORYMGNT_VIEW_' + this.profileId,
                    'WEB_ADMIN_CATEGORYMGNT_EDIT_' + this.profileId
                ]);
            if (access) {
                this.verifyEditAccess(user);
                this.store.dispatch(new Actions.LoadCategoryDetail(this.profileId, this.categoryId));
            } else {
                this.gotoCategories();
            }
        }
    }

    private verifyEditAccess(user: IUser) {
        let canEdit = false;
        if (user && this.profileId) {
            canEdit = User.hasPrivilegeArray(
                user,
                [
                    'WEB_ADMIN_CATEGORYMGNT_EDIT_' + this.profileId,
                    'WEB_ADMIN_CATEGORYMGNT_EDIT_ALL',
                    'WEB_SUADMIN'
                ]);
        }
        this.categoryDetailService.setEditAccess(canEdit);
    }
    initializeCategory(event?) {
        // TODO
    }

    close() {
        this.modal.close();
        this.gotoCategories();
    }

    open() {
        this.modal.open();
    }
}
