import { Component, ViewChild, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@app/store';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil, map } from 'rxjs/operators';
import { Answer } from '@shared/common-ux/models/QuestionAnswer';
import { DynamicQuestionsService } from 'app/shared/SSEHubClient';
import { ITemplateNames, ITemplateQuestions, ITemplateQuestion } from '../../category-management-store/template/template.model';
import { LoadTemplateNames, LoadTemplateQuestions } from '../../category-management-store/template/template.actions';
import { getTemplateNames, getExistingTemplates, getSelectedAnswer, getTemplateQuestions } from '../../category-management-store/';
import { CategoryDetailService } from '../category-detail.service';
import { QuestionViewService } from '../question-view/question-view.service';
import { NotificationService } from 'app/shared/error-handler-notify/services';
import { FormComponentService } from 'app/shared/common-ux/components/component-services/form-component.service';
import { NgForm } from '@angular/forms';

interface ITemplateMetadata {
    profileId: number;
    categoryId: number;
    groupName: string;
    templateName: string;
    templateMappingsToExclude?: Array<ITemplateMapping>;
}

interface ITemplateMapping {
    answerMappingId: number;
    questionMappingId: number;
}

@Component({
    selector: 'app-template-add',
    templateUrl: './template-add.component.html',
    styleUrls: ['./template-add.component.scss']
})

export class TemplateAddComponent implements OnDestroy {
    @ViewChild('templateAddModal', { static: true }) public templateAddModal;
    @Input() groupName: string;
    @Input() profileId: string;
    @Input() categoryId: string;
    @ViewChild('form') form: NgForm;
    templateNames$: Observable<ITemplateNames>;
    templateQuestions$: Observable<ITemplateQuestions[]>;
    existingTemplates$: Observable<string[]>;
    questionsToDisplay$: Observable<ITemplateQuestion[]>;
    selectedTemplate: string = null;
    isLoading = false;
    destroy$: Subject<void> = new Subject<void>();
    selectedAnswer: Answer;

    constructor(
        private store: Store<State>,
        public dynamicQuestionsService: DynamicQuestionsService,
        public categoryDetailService: CategoryDetailService,
        public questionViewService: QuestionViewService,
        public notificationService: NotificationService,
        private formComponentService: FormComponentService,
    ) {
        this.templateNames$ = this.store.select(getTemplateNames);
        this.existingTemplates$ = this.store.select(getExistingTemplates);
        this.templateQuestions$ = this.store.select(getTemplateQuestions);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    open() {
        this.isLoading = true;

        this.store.select(getSelectedAnswer).pipe(takeUntil(this.destroy$)).subscribe(a => {
            if (!(a.answerId >= 0)) {
                return;
            }
            let answer = new Answer();
            if (a.answerId > 0) {
                answer = a;
            }
            if (a.answerId === 0) {
                answer = { answerId: 0 } as Answer;
            }
            this.store.dispatch(new LoadTemplateQuestions({
                profileId: parseInt(this.profileId, 10),
                categoryId: parseInt(this.categoryId, 10),
                group: this.groupName,
                answer: answer
            }));
        });

        this.isLoading = false;

        this.templateNames$.pipe(take(1)).pipe(takeUntil(this.destroy$)).subscribe(names => {
            if (!names.templates.length) {
                this.store.dispatch(new LoadTemplateNames());
            }
        });
        this.reset();
        this.templateAddModal.open();
    }

    filterQuestions($event) {
        this.questionsToDisplay$ = this.templateQuestions$.pipe(map(templates => templates.find(x => x.templateName === this.selectedTemplate)),
            map(q => q ? q.questions : null));
    }

    onClose(event?) {
        this.reset();
        this.templateAddModal.close();
    }

    reset() {
        if (this.form) {
            this.form.reset();
        }
        this.selectedTemplate = null;
        this.selectedAnswer = null;
        this.questionsToDisplay$ = null;
    }

    checkAll(questions: Array<ITemplateQuestion>, checkValue: boolean) {
        questions.forEach(question => question.isChecked = checkValue);
    }

    mapTemplate() {
        this.formComponentService.validateAllFormFields(this.form.control);
        if (this.form.valid) {
            this.isLoading = true;
            let selectedAnswer: Answer;
            this.categoryDetailService.state.selectedAnswer;
            this.store.select(getSelectedAnswer).pipe(takeUntil(this.destroy$)).subscribe(answer => selectedAnswer = answer);

            if (!selectedAnswer) {
                selectedAnswer = new Answer();
                selectedAnswer.answerId = 0;
            }
            const templateMappings: Array<ITemplateMapping> = [];
            this.questionsToDisplay$.pipe(takeUntil(this.destroy$)).subscribe(questions => {
                if (questions && questions.length > 0) {
                    questions.forEach(question => {
                        if (!question.isChecked) {
                            templateMappings.push({
                                answerMappingId: selectedAnswer.answerId,
                                questionMappingId: question.templateQuestionMappingId
                            });
                        }
                    });
                }
            });
            const templateMetadata: ITemplateMetadata = {
                profileId: this.categoryDetailService.state.profileId,
                categoryId: this.categoryDetailService.state.categoryId,
                templateName: this.selectedTemplate,
                groupName: this.groupName,
            };
            if (templateMappings.length > 0) {
                templateMetadata.templateMappingsToExclude = templateMappings;
            }

            this.dynamicQuestionsService.addTemplateQuestions(selectedAnswer.answerId, templateMetadata)
                .pipe(takeUntil(this.destroy$)).subscribe(
                    (result) => {
                        this.categoryDetailService.questionAddedorModified(this.groupName);
                    },
                    (error) => {
                        console.error('Failed to add template mapping.');
                        this.notificationService.showError('Error adding template questions.');
                        this.onClose();
                    },
                    () => {
                        this.isLoading = false;
                        this.onClose();
                    }
                );
        }
    }

}
