<div class="reports-tool-bar">
    <div class="clear-filter">
        <button class="clear-filter" (click)="clearAllFilter()">Clear filter</button>
    </div>
</div>

<div class="table-container">
    <mat-table #table matSort [dataSource]="dataSource" matSortActive="{{ (allReports$ | async).list.options.sort.id}}"
        matSortDirection="{{ (allReports$ | async).list.options.sort.start}}" matSortDisableClear>

        <ng-container matColumnDef="reportId">
            <mat-header-cell *matHeaderCellDef class="cell cell-small">
                <label class="header-row" mat-sort-header> ID </label>
                <div class="filter-div">
                    <input class="form-control" (keyup)="applyFilter()" [(ngModel)]="tableOptions.filter.reportId"
                        placeholder="Filter id">
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let tableData" class="cell cell-small">
                <label class="trowId">{{tableData.reportId}}</label>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="title">
            <mat-header-cell class="cell cell-large" *matHeaderCellDef>
                <label class="header-row" mat-sort-header> Title </label>
                <div class="filter-div">
                    <input class="form-control" [(ngModel)]="tableOptions.filter.title" (keyup)="applyFilter()"
                        placeholder="Filter title">
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let tableData" class="cell cell-large">
                <label class="trow">{{tableData.title}}</label>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="employeeId">
            <mat-header-cell *matHeaderCellDef class="cell cell-small">
                <label class="header-row" mat-sort-header> Emp Id </label>
                <div class="filter-div">
                    <input class="form-control" [(ngModel)]="tableOptions.filter.employeeId" (keyup)="applyFilter()"
                        placeholder="Emp #">
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let tableData" class="cell cell-small">
                <label class="trow"> {{tableData.employeeId}} </label>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="naturalName">
            <mat-header-cell *matHeaderCellDef class="cell cell-small">
                <label class="header-row" mat-sort-header> Emp name </label>
                <div class="filter-div">
                    <input class="form-control" [(ngModel)]="tableOptions.filter.naturalName" (keyup)="applyFilter()"
                        placeholder="emp name">
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let tableData" class="cell cell-small">
                <label class="trow"> {{tableData.naturalName}} </label>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="submitDate">
            <mat-header-cell *matHeaderCellDef class="cell cell-small">
                <label class="header-row" mat-sort-header> Sub. Date </label>
                <div class="filter-div">
                    <input class="form-control" [(ngModel)]="tableOptions.filter.submitDate" (keyup)="applyFilter()"
                        placeholder="mm/dd/yyyy">
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let tableData" class="cell cell-small">
                <label class="trow"> {{tableData.submitDate | date:'MM/dd/yyyy'}} </label>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="eventDate">
            <mat-header-cell *matHeaderCellDef class="cell cell-small">
                <label class="header-row" mat-sort-header> Event date </label>
                <div class="filter-div">
                    <input class="form-control" [(ngModel)]="tableOptions.filter.eventDate" (keyup)="applyFilter()"
                        placeholder="mm/dd/yyyy">
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let tableData" class="cell cell-small">
                <label class="trow">{{tableData.eventDate | date:'MM/dd/yyyy' : 'UTC'}} </label>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="flightNumber">
            <mat-header-cell *matHeaderCellDef class="cell cell-small">
                <label class="header-row" mat-sort-header>Flight num</label>
                <div class="filter-div">
                    <input class="form-control" [(ngModel)]="tableOptions.filter.flightNumber" (keyup)="applyFilter()"
                        placeholder="Filter flight">
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let tableData" class="cell cell-small">
                <label class="trow"> {{tableData.flightNumber}}</label>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="flightDate">
            <mat-header-cell *matHeaderCellDef class="cell cell-small">
                <label class="header-row" mat-sort-header>Flight date</label>
                <div class="filter-div">
                    <input class="form-control" [(ngModel)]="tableOptions.filter.flightDate" (keyup)="applyFilter()"
                        placeholder="mm/dd/yyyy">
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let tableData" class="cell cell-small">
                <label class="trow"> {{tableData.flightDate | date:'MM/dd/yyyy' : 'UTC'}} </label>
            </mat-cell>
        </ng-container>


        <ng-container matColumnDef="fleetType">
            <mat-header-cell *matHeaderCellDef class="cell cell-small">
                <label class="header-row" mat-sort-header>Fleet type</label>
                <div class="filter-div">
                    <input class="form-control" [(ngModel)]="tableOptions.filter.fleetType" (keyup)="applyFilter()"
                        placeholder="Fleet type">
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let tableData" class="cell cell-small">
                <label class="trow"> {{tableData.fleetType}} </label>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="base">
            <mat-header-cell *matHeaderCellDef class="cell cell-small">
                <label class="header-row" mat-sort-header> Base </label>
                <div class="filter-div">
                    <input class="form-control" [(ngModel)]="tableOptions.filter.base" (keyup)="applyFilter()"
                        placeholder="Filter base">
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let tableData" class="cell cell-small">
                <label class="trow"> {{tableData.base}} </label>
            </mat-cell>
        </ng-container>


        <ng-container matColumnDef="departureStation">
            <mat-header-cell *matHeaderCellDef class="cell cell-small">
                <label class="header-row" mat-sort-header> Departure </label>
                <div class="filter-div">
                    <input class="form-control" [(ngModel)]="tableOptions.filter.departureStation"
                        (keyup)="applyFilter()" placeholder="Filter departure">
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let tableData" class="cell cell-small">
                <label class="trow"> {{tableData.departureStation}} </label>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="arrivalStation">
            <mat-header-cell *matHeaderCellDef class="cell cell-small">
                <label class="header-row" mat-sort-header> Arrival </label>
                <div class="filter-div">
                    <input class="form-control" [(ngModel)]="tableOptions.filter.arrivalStation" (keyup)="applyFilter()"
                        placeholder="Filter arrival">
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let tableData" class="cell cell-small">
                <label class="trow"> {{tableData.arrivalStation}}</label>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="event">
            <mat-header-cell *matHeaderCellDef class="cell cell-small">
                <label class="header-row" mat-sort-header>Event </label>
                <div class="filter-div">
                    <input class="form-control" [(ngModel)]="tableOptions.filter.event" (keyup)="applyFilter()"
                        placeholder=" Filter event">
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let tableData" class="cell cell-small">
                <label class="trow"> {{tableData.event}} </label>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef class="cell cell-small">
                <label class="header-row" mat-sort-header> Status </label>
                <div class="filter-div">
                    <input class="form-control" [(ngModel)]="tableOptions.filter.status" (keyup)="applyFilter()"
                        placeholder="Filter status">
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let tableData" class="cell cell-small">
                <label class="trow"> {{tableData.status}} </label>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="DaysOpen">
            <mat-header-cell *matHeaderCellDef class="cell cell-small">
                <label class="header-row" mat-sort-header>Days open </label>
                <div class="filter-div">
                    <input class="form-control" [(ngModel)]="tableOptions.filter.DaysOpen" (keyup)="applyFilter()"
                        placeholder="Filter days">
                </div>
            </mat-header-cell>
            <mat-cell *matCellDef="let tableData" class="cell cell-small">
                <label class="trow"> {{tableData.daysOpen}} </label>
            </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns;"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="gotoReportSummary(row)"></mat-row>

    </mat-table>

    <mat-paginator #paginator [length]="dataSource.data.length" [pageSize]="10" [showFirstLastButtons]="true">
    </mat-paginator>
    <h3 *ngIf="(allReports$ | async).list.all.length === 0  && !(allReports$ | async).isLoading && !isParsingTable"
        class="text-center"> No results found </h3>
</div>
<app-aa-spinner [loading]="(allReports$ | async).isLoading || isParsingTable"></app-aa-spinner>