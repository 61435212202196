import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-aa-spinner-no-logo',
    templateUrl: './aa-spinner-no-logo.component.html'
})
export class AASpinnerNoLogoComponent {
    @Input() loading: boolean;
    constructor() {
    }
}
