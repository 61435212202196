import { Component, OnInit, ViewChild, OnDestroy, ElementRef, Renderer2 } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { NgForm } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { IUser } from '@app/store/user';
import {
    getAttachments,
    getAttachmentsSectionDetails,
    getAttachmentsSettings,
    getGeneralQuestionsIsLoading,
    getQuestions,
    State,
} from '@newreport/new-report-store/';
import {
    IDynamicQuestion,
    AddAttachment,
    RemoveAttachment,
    UpdateNotesPageStatus
} from '../../new-report-store/wizard';
import { ConfirmCancelComponent } from '@wizardmodals/confirm/cancel/confirm-cancel.component';
import { ConfirmNavigateComponent } from '@wizardmodals/confirm/navigate/confirm-navigate.component';
import { NotificationService } from '@shared/error-handler-notify/services/notification.service';
import { FormComponentService } from '@shared/common-ux/components/component-services/form-component.service';
import { Attachment } from 'app/report-history/report-history';
import { SSEHubSettingsService, ISetting } from 'app/shared/SSEHubClient';
import { WizardService, IReportInfo, FocusService } from '../wizard.service';
import { ReportAssemblyService } from '../../new-report.service';

import * as _ from 'lodash';
import { CanComponentDeactivate } from 'app/can-deactivate.guard';


@Component({
    selector: 'app-fill-out-report-wizard-notes',
    templateUrl: './notes.component.html',
    styleUrls: ['./steps.component.scss']
})

export class NotesComponent implements OnInit, OnDestroy, CanComponentDeactivate {
    groupName = 'Notes';
    @ViewChild('form', { static: true }) form: NgForm;
    @ViewChild(ConfirmCancelComponent, { static: true }) confirmCancel: ConfirmCancelComponent;
    @ViewChild(ConfirmNavigateComponent, { static: true }) confirmNavigate: ConfirmNavigateComponent;
    @ViewChild('focusAttachments') focusAttachments: ElementRef;
    notesQuestions: Array<IDynamicQuestion>;
    flightQuestions: Array<IDynamicQuestion>;
    generalQuestions: Array<IDynamicQuestion>;
    reportInfo$: Observable<IReportInfo>;
    isLoading = true;
    reportInfo: IReportInfo;
    attachmentVisibility = '';
    attachments: Attachment[];
    unallowedFileTypes: string[];
    maxAttachments: number;
    fileNameToAttach = '';
    displayInvalidAttachmentsBorder = false;
    errorMessageCounter = 0;
    navigateAwaySelection$: Subject<boolean> = new Subject<boolean>();
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<State>,
        private router: Router,
        private notificationService: NotificationService,
        private formComponentService: FormComponentService,
        private settingService: SSEHubSettingsService,
        private wizardService: WizardService,
        private focusService: FocusService,
        private renderer: Renderer2,
        public newReportService: ReportAssemblyService,
    ) {
        this.store.dispatch(new UpdateNotesPageStatus('inProgress'));
        this.loadAttachmentSettings();
    }

    onNavigate(isExitting) {
        return this.navigateAwaySelection$.next(isExitting);
    }

    canDeactivate(route: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.newReportService.isStateReportDirty(this.flightQuestions, this.generalQuestions, this.notesQuestions) && !nextState.url.startsWith('/newreport/wizard')) {
            this.confirmNavigate.displayModal();
            return this.navigateAwaySelection$;
        } else {
            return true;
        }
    }

    ngOnInit() {
        this.reportInfo$ = this.wizardService.getReportInfo();

        this.store.select(getAttachments).pipe(takeUntil(this.destroy$)).subscribe(attachments => this.attachments = attachments);
        this.store.select(getAttachmentsSectionDetails).pipe(takeUntil(this.destroy$)).subscribe(section => {
            if (section && section.sectionVisibility) {
                this.attachmentVisibility = section.sectionVisibility.toLocaleLowerCase();
            }
        });
        this.store.select(getGeneralQuestionsIsLoading).pipe(takeUntil(this.destroy$)).subscribe(il => this.isLoading = il);
        this.store.select(getQuestions('notes')).pipe(takeUntil(this.destroy$)).subscribe(notes => {
            if (notes) {
                this.notesQuestions = notes;
            }
        });
        this.store.select(getQuestions('flight')).pipe(takeUntil(this.destroy$)).subscribe(flightQs => this.flightQuestions = flightQs);
        this.store.select(getQuestions('general')).pipe(takeUntil(this.destroy$)).subscribe(generalQs => this.generalQuestions = generalQs);

        if (!this.notesQuestions || this.notesQuestions.length < 1) {
            this.wizardService.loadNotes();
        }
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        this.validatePage();
    }

    validatePage() {
        this.formComponentService.validateAllFormFields(this.form.control);
        const attachmentReqValid = (this.attachmentVisibility === 'required' && this.attachments.length > 0) || (this.attachmentVisibility !== 'required');

        if (this.form.valid && attachmentReqValid) {
            this.store.dispatch(new UpdateNotesPageStatus('complete'));
        } else {
            this.store.dispatch(new UpdateNotesPageStatus('incomplete'));
        }
    }

    isValidAttachments(): boolean {
        return (this.attachmentVisibility === 'required' && this.attachments && this.attachments.length < 1) ? false : true;
    }

    removeInvalidAttachmentsBorder() {
        this.displayInvalidAttachmentsBorder = false;
        this.focusAttachments.nativeElement.blur();
    }

    removeAttachment(attachment: Attachment) {
        this.store.dispatch(new RemoveAttachment(attachment));
    }

    next() {
        this.displayInvalidAttachmentsBorder = !this.isValidAttachments();
        this.validatePage();
        if (this.form.valid && this.isValidAttachments()) {
            this.router.navigate(['/newreport/wizard/summary']);
        } else {
            this.focusService.displayErrorMessage(++this.errorMessageCounter);
            const firstKey = this.focusService.getFirstInvalidKey(this.notesQuestions, this.form.form);
            if (!firstKey) {
                if (!this.isValidAttachments()) {
                    this.focusAttachments.nativeElement.scrollIntoView();
                }
            } else {
                this.focusService.setFocusKey(firstKey);
            }
        }
    }

    previous() {
        this.router.navigate(['/newreport/wizard/questions']);
    }

    onFileSelect($event: any): void {
        if (!($event && $event.target && $event.target.files && $event.target.files.length > 0)) {
            return; // return if no file was selected
        }
        const file: File = $event.target.files[0];
        $event.target.value = '';
        this.fileNameToAttach = file.name;
        const fileExtension = this.fileNameToAttach.split('.'); // can have multiple extensions
        const fileTypes = fileExtension.slice(1, fileExtension.length); // remove filename

        if (file.size > 0) {
            if (this.isAttachmentTypeValid(fileTypes)) {
                // check if file already added
                if (_.find(this.attachments, { 'fileName': this.fileNameToAttach })) {
                    this.notificationService.showError('File has already been attached.');
                    return;
                }
                const fileReader: FileReader = new FileReader();
                fileReader.onload = this.convertAndAttachFile.bind(this);
                fileReader.readAsArrayBuffer(file);
            } else {
                this.notificationService.showError('Invalid attachment type.');
            }
        } else {
            this.notificationService.showError('Cannot attach an empty file.');
        }
    }

    convertAndAttachFile(readerEvt) {
        let binaryString = '';
        const bytes = new Uint8Array(readerEvt.target.result);
        const length = bytes.byteLength;
        for (let i = 0; i < length; i++) {
            binaryString = binaryString + String.fromCharCode(bytes[i]);
        }
        let user: IUser;
        this.wizardService.getUser().pipe(take(1)).pipe(takeUntil(this.destroy$)).subscribe(u => user = u);
        const attachment: Attachment = {
            employeeId: user.id,
            firstName: user.firstName,
            lastName: user.lastName,
            fileName: this.fileNameToAttach,
            imageString: btoa(binaryString),
            createdDate: '' + new Date,
            description: '',
            guid: null,
            reportId: ''
        };
        this.store.dispatch(new AddAttachment(attachment));
    }

    isAttachmentTypeValid(fileTypes: string[]) {
        let valid = true;
        fileTypes.map((ft) => {
            if (_.includes(this.unallowedFileTypes, ft.toLowerCase())) {
                valid = false;
            }
        });
        return valid;
    }

    loadAttachmentSettings() {
        // load default offline settings in case network is down
        this.store.select(getAttachmentsSettings).pipe(takeUntil(this.destroy$)).subscribe((settings) => {
            if (settings && settings.unallowedFileTypes && settings.maxAttachments) {
                this.unallowedFileTypes = settings.unallowedFileTypes;
                this.maxAttachments = settings.maxAttachments;
            } else {
                this.unallowedFileTypes = ['ade', 'adp', 'app', 'bas', 'bat', 'chm', 'class', 'cmd', 'com', 'cpl', 'crt', 'dll',
                    'exe', 'fxp', 'hlp', 'hta', 'ins', 'isp', 'jse', 'lnk', 'mda', 'mdb', 'mde', 'mdt',
                    'mdw', 'mdz', 'msc', 'msi', 'msp', 'mst', 'ops', 'pcd', 'pif', 'prf', 'prg', 'reg',
                    'scf', 'scr', 'sct', 'shb', 'shs', 'url', 'vb', 'vbe', 'vbs', 'wsc', 'wsf', 'wsh'];
                this.maxAttachments = 10;
            }
        });

    }

    saveReport() {
        this.newReportService.saveUnfinishedReport();
    }
}

