import { Injectable, OnDestroy } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '@app/store';
import { IUser, User, getUser } from '../store/user';
import { SSEHubReportHistoryService } from 'app/shared/SSEHubClient/report-history.service';
import { ErrorHandlerService } from '@shared/error-handler/error-handler.service';
import { of, Subject } from 'rxjs';
import { tap, filter, take, map, switchMap, takeUntil } from 'rxjs/operators';
import { InitializeUser as InitUser } from '@app/store/user/user.actions';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';

@Injectable()
export class ReportDetailAuthGuard implements CanActivate, OnDestroy {
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<State>,
        private sseHubReportHistoryService: SSEHubReportHistoryService,
        private errorHandlerService: ErrorHandlerService,
        private authService: PingAuthenticationService

    ) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        return this.getFromStore().pipe(
            switchMap(u => this.getUserAuthorization(u, route).toPromise())
        ).toPromise();

    }

    private getUserAuthorization(user: IUser, route: ActivatedRouteSnapshot) {
        if (user == null) { return of(false); }
        if (route == null) { return of(false); }

        const reportId = route.params.reportId;

        if (!User.hasPrivilege(user, 'WEB_SUADMIN')) {

            return this.sseHubReportHistoryService.getReportAuthorization(reportId, user.id).pipe(
                map((response) => {
                    const responseMap = new Map(Object.entries(response));
                    if (responseMap.get('reportAccess') === false) {
                        this.errorHandlerService.UnAuthorized();
                    }
                    return responseMap.get('reportAccess') || false;
                })
            );
        } else {
            return of(true);
        }
    }

    public getFromStore() {
        return this.store.select(getUser).pipe(
            tap(u => {}),
            filter(user => user),
            take(1)
        );
    }
}

@Injectable()
export class ReportSearchAuthGuard implements CanActivate, OnDestroy {
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<State>,
        private errorHandlerService: ErrorHandlerService,
        private authService: PingAuthenticationService
    ) {
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
        return this.getFromStore().pipe(
            switchMap(u => this.getUserAuthorization(u, route).toPromise())
        ).toPromise();

    }

    private getUserAuthorization(user: IUser, route: ActivatedRouteSnapshot) {
        if (user == null) { return of(false); }
        if (route == null) { return of(false); }

        const reportId = route.params.reportId;

        if (user.profileId === 7) {
            return of(true);
        } else {
            this.errorHandlerService.UnAuthorized();
            return of(false);
        }
    }

    public getFromStore() {
        return this.store.select(getUser).pipe(
            tap(u => {}),
            filter(user => user),
            take(1)
        );
    }
}
