<div class="row wizardStepTracker">
    <ul>
        <li routerLink="flight" routerLinkActive="active">
            <span>
                Flight
                    <i [ngClass]="(flightPageStatus$ | async) != 'complete' ? 'icon-warning' : 'icon-circle-check'"></i>
            </span>
        </li>
        <li routerLink="questions" routerLinkActive="active">
            <span>
                Questions
                    <i [ngClass]="(questionsPageStatus$ | async) != 'complete' ? 'icon-warning' : 'icon-circle-check'"></i>
            </span>
        </li>
        <li routerLink="notes" routerLinkActive="active" class="lock-width">
            <span>
                Notes & Attachments
                    <i [ngClass]="(notesPageStatus$ | async)  != 'complete' ? 'icon-warning' : 'icon-circle-check'"></i>
            </span>
        </li>
        <li routerLink="summary" routerLinkActive="active"><span>Summary</span></li>
    </ul>
</div>
<div class="row wizardStepContent">
    <router-outlet></router-outlet>
</div>
