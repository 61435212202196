<div class="hide-print">
    <div *ngIf="question.injuries && question.injuries.length > 0 && !(this.userId === cleanUserId(question.userAnswer))"
        [ngClass]="{'top-pad': parentComponent !== 'summary'}">
        <div *ngIf="parentComponent === 'summary'">
            <label class="font-blue">
                Past injuries
            </label>
        </div>
        <div *ngIf="displayInjuries && parentComponent !== 'summary'" (click)="toggleIcon()">
            <i class="icon-collapse"></i>
            <label class="leftMargin font-blue">
                Collapse list of past injuries
            </label>
        </div>
        <table *ngIf="displayInjuries || parentComponent === 'summary'" class="top-pad table table-hover table-striped">
            <thead>
                <tr>
                    <th></th>
                    <th>Date</th>
                    <th>Body Part</th>
                    <th>Nature</th>
                    <th>Cause</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let injury of question.injuries; let i = index" class="childForm">
                    <td>{{ i + 1 }}</td>
                    <td>{{ injury.injuryDate | date: 'MM/dd/yyyy' }}</td>
                    <td>{{ injury.bodyPart }}</td>
                    <td>{{ injury.nature }}</td>
                    <td>{{ injury.cause }}
                </tr>
            </tbody>
        </table>
        <div *ngIf="!displayInjuries && parentComponent !== 'summary'" (click)="toggleIcon()">
            <i class="icon-expand"></i>
            <label class="leftMargin font-blue">Display list of past injuries</label>
        </div>
    </div>
    <div *ngIf="questionAttrs['displayInjuries'] && question.injuries && question.injuries.length === 0"
        [ngClass]="{'top-pad': parentComponent !== 'summary'}">
        <label class="font-green">This employee has no past injuries</label>
    </div>
</div>
