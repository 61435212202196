<h2>Notifications</h2>
<div class="divider"></div>
<div class="section-summary add-question-link">
    <div (click)="openNotificationModal()">
        <i class='icon-add icon-small'></i>
        <a>Add Notification Rule</a>
    </div>
</div>
@if (isNotificationModalOpened) {
    <kendo-window (close)="closeNotificationModal()" > 
        this is a test window
    </kendo-window>
}
<br />
<br />