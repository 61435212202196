
import { switchMap, catchError, map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { createEffect, Actions } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { UserService } from '@shared/user/user.service';
import { IUser, User } from './user.model';
import * as userActions from './user.actions';
import { Observable, EMPTY as empty, of } from 'rxjs';
import { ErrorHandlerService } from '@shared/error-handler/error-handler.service';
import { ofType } from '@ngrx/effects';

@Injectable()
export class UserEffects {

    
    initialize$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<userActions.InitializeUser>(userActions.INITIALIZE),
        switchMap(action => {
            if (!action.payload) {
                return null;
            }

            return this.userService.loadUser(action.payload).pipe(
                map((u: IUser) => {
                    if (u && u.firstName && u.lastName) {
                        if (!User.isActiveEmployee(u)) {
                            this.errorHandlerService.InactiveEmployee(u.status);
                            return new userActions.Error();
                        }
                        return new userActions.PopulateUser(u);
                    } else {
                        this.errorHandlerService.Error();
                        return new userActions.Error();
                    }
                }),
                catchError(e => {
                    this.errorHandlerService.Error();
                    return of(new userActions.Error());
                })
            );
        })));

    constructor(
        private actions$: Actions,
        private userService: UserService,
        private errorHandlerService: ErrorHandlerService
    ) { }
}
