import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { DataTablesModule } from 'angular-datatables';
import { CommonUXModule } from '@shared/common-ux/common-ux.module';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';

import { ReportHistoryService } from './report-history.service';

import { SummaryComponent } from './summary/summary.component';
import { FlightSummaryComponent } from './summary/flight-summary/flight-summary.component';
import { QuestionSummaryComponent } from './summary/question-summary/question-summary.component';
import { QuestionListComponent } from './summary/question-summary/question-list/question-list.component';
import { PersonDetailComponent } from './summary/person-details/person-details.component';
import { DescriptionSummaryComponent } from './summary/description-summary/description-summary.component';
import { RecommendationSummaryComponent } from './summary/recommendation-summary/recommendation-summary.component';
import { ActivitySummaryComponent } from './summary/activity-summary/activity-summary.component';

import { OpenModalImageComponent } from './summary/open-modal-image/open-modal-image.component';
import { AttachmentSummaryComponent } from './summary/attachment-summary/attachment-summary.component';
import { AddSectionInlineComponent } from './summary/add-section-inline/add-section-inline.component';
import { ReplySummaryComponent } from './summary/reply-summary/reply-summary.component';
import { RequestResponseSummaryComponent } from './summary/request-response-summary/request-response-summary.component';
import { RequestResponseComponent } from './summary/request-response/request-response.component';
import { RespondToRequestComponent } from './summary/respond-to-request/respond-to-request.component';
import { RequestResponseInlineComponent } from './summary/request-response-summary/request-response-inline/request-response-inline.component';
import { RespondToRequestInlineComponent } from './summary/request-response-summary/respond-to-request-inline/respond-to-request-inline.component';
import { ReportDetailSectionComponent } from './summary/report-detail-section/report-detail-section.component';
import { ReportDetailAuthGuard, ReportSearchAuthGuard } from './report-history.guard';
import { SortByTimeStampPipe } from './summary/sortByTimeStamp.pipe';
import { SortByTimeReversePipe } from './summary/sortByTimeReverse.pipe';
import { HideFromReportDetailPipe } from './summary/question-summary/hideFromReportDetail.pipe';
import { ReportHistorySearchPipe } from './filter.pipe';
import { TableMyReportsComponent } from './table-my-reports/table-my-reports.component';
import { TableTodoReportsComponent } from './table-todo-reports/table-todo-reports.component';
import { TableAllReportsComponent } from './table-all-reports/table-all-reports.component';
import { ReportSearchComponent } from './search-reports/search-reports.component';
import { reportHistoryRoutes } from './report-history.routing';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        CommonUXModule,
        DataTablesModule,
        MatInputModule,
        MatPaginatorModule,
        MatSortModule,
        MatFormFieldModule,
        MatTableModule,
        RouterModule
    ],
    declarations: [
        SummaryComponent,
        FlightSummaryComponent,
        QuestionSummaryComponent,
        QuestionListComponent,
        PersonDetailComponent,
        DescriptionSummaryComponent,
        RecommendationSummaryComponent,
        ActivitySummaryComponent,
        OpenModalImageComponent,
        AttachmentSummaryComponent,
        AddSectionInlineComponent,
        ReplySummaryComponent,
        RequestResponseSummaryComponent,
        RequestResponseComponent,
        RespondToRequestComponent,
        RequestResponseInlineComponent,
        RespondToRequestInlineComponent,
        ReportDetailSectionComponent,
        SortByTimeStampPipe,
        SortByTimeReversePipe,
        ReportHistorySearchPipe,
        HideFromReportDetailPipe,
        TableMyReportsComponent,
        TableTodoReportsComponent,
        TableAllReportsComponent,
        ReportSearchComponent
    ],
    providers: [
        ReportHistoryService,
        ReportDetailAuthGuard,
        ReportSearchAuthGuard,
        SortByTimeStampPipe,
        SortByTimeReversePipe,
        ReportHistorySearchPipe,
        HideFromReportDetailPipe
    ],
    exports: [
        OpenModalImageComponent,
        TableMyReportsComponent,
        TableTodoReportsComponent,
        TableAllReportsComponent,
        ReportSearchComponent,
        MatSortModule,
        MatTableModule,
        MatPaginatorModule
    ]
})
export class ReportHistoryModule { }
