import { Injectable } from '@angular/core';
import { SSEHubClient } from './SSEHubClient.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class SSEHubFlightSequencesService {

    constructor(
        private http: HttpClient,
        private sseHubClient: SSEHubClient
    ) { }

    getByFlightLeg(flightNumber, departDate, departStation?): Observable<any> {
        const departureStation = departStation ? '?departureStation=' + encodeURIComponent(departStation) : '';
        return this.http.get(
            this.sseHubClient.serviceUrl() + '/api/flightsequences/' + encodeURIComponent(flightNumber) + '/' +
            encodeURIComponent(departDate) + departureStation);
    }

}

