import * as newReportActions from './wizard.actions';
import { IFlightSequence, IQuestion, PageStatusTypes } from './wizard.model';
import * as _ from 'lodash';
import { IError } from 'app/shared/common-ux/models/Error';
import { Stats } from 'fs';
import { Attachment } from 'app/report-history/report-history';

export interface NewReportWizardQuestionsModel {
    isLoading: boolean;
    error: IError;
    questions: IQuestion[];
    questionsRawCopy: IQuestion[];  // this raw copy is used to reset general questions to their initial state when necessary
    pageStatus: {
        general: PageStatusTypes,
        flight: PageStatusTypes,
        notes: PageStatusTypes // just for the notes questions. attachment status is calculated in the selector
    }
    incidentStation: string;
    crewSequences: { options: Array<IFlightSequence>, userAnswer: IFlightSequence };
    flightSelection: IFlightSequence;
    isFlight: boolean;
    isLoadingCrewSequence: boolean;
    attachments: Array<Attachment>;
    attachmentSettings: {
        unallowedFileTypes: string[],
        maxAttachments: number
    };
}

export const initialQuestionsState: NewReportWizardQuestionsModel = {
    isLoading: false,
    error: null,
    questions: [],
    questionsRawCopy: [],
    pageStatus: {
        general: null,
        flight: null,
        notes: null
    },
    incidentStation: null,
    crewSequences: { options: [], userAnswer: null },
    flightSelection: null,
    isFlight: false,
    isLoadingCrewSequence: false,
    attachments: [],
    attachmentSettings: {
        unallowedFileTypes: [],
        maxAttachments: 0
    }
};

export function questionsReducer(
    state = initialQuestionsState,
    action: newReportActions.Actions
) {
    switch (action.type) {
        case newReportActions.LOAD_CREW_SEQUENCES:
            return {
                ...state,
                isLoadingCrewSequence: true,
                error: null
            };
        case newReportActions.POPULATE_CREW_SEQUENCES:
            return {
                ...state,
                crewSequences: action.payload,
                isLoadingCrewSequence: false,
                error: null
            };
        case newReportActions.UPDATE_FLIGHT_SELECTION:
            return {
                ...state,
                flightSelection: { ...action.payload }
            };
        case newReportActions.CHANGE_FLIGHT:
            return {
                ...state,
                isFlight: action.payload.isFlight
            };

        case newReportActions.LOAD_ALL_QUESTIONS:
            return {
                ...state,
                isLoading: true,
                pageStatus: {
                    ...state.pageStatus, general: 'incomplete' as PageStatusTypes, flight: 'incomplete' as PageStatusTypes, notes: 'incomplete' as PageStatusTypes
                },
                error: null
            };
        case newReportActions.POPULATE_ALL_QUESTIONS_AND_PAGE_STATUS:
            return {
                ...state,
                isLoading: false,
                error: null,
                questions: action.payload.questions,
                questionsRawCopy: _.cloneDeep(action.payload.questions),
                pageStatus: { ...state.pageStatus, flight: action.payload.pageStatuses.flight, general: action.payload.pageStatuses.general, notes: action.payload.pageStatuses.notes }
            };
        case newReportActions.ERROR_QUESTIONS:
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        case newReportActions.UPDATE_FLIGHT_PAGE_STATUS:
            return {
                ...state,
                pageStatus: { ...state.pageStatus, flight: action.payload }
            };
        case newReportActions.UPDATE_GENERAL_QUESTIONS_PAGE_STATUS:
            return {
                ...state,
                pageStatus: { ...state.pageStatus, general: action.payload }
            };
        case newReportActions.UPDATE_NOTES_PAGE_STATUS:
            return {
                ...state,
                pageStatus: { ...state.pageStatus, notes: action.payload }
            };
        case newReportActions.UPDATE_INCIDENT_STATION:
            return {
                ...state,
                incidentStation: action.payload
            };
        case newReportActions.RESET_GENERAL_QUESTIONS: {
            const nonGeneralQuestions = _.cloneDeep(state.questions.filter(q => q.groupName.toUpperCase() != 'GENERAL'));
            const generalQuestions = _.cloneDeep(state.questionsRawCopy.filter(q => q.groupName.toUpperCase() == 'GENERAL'));
            return {
                ...state,
                questions: nonGeneralQuestions.concat(generalQuestions)
            };
        }
        case newReportActions.UPDATE_QUESTIONS: {
            return {
                ...state,
                questions: action.questions
            };
        }
        case newReportActions.ADD_ATTACHMENT:
            return {
                ...state,
                attachments: [...state.attachments, action.payload]
            };
        case newReportActions.REMOVE_ATTACHMENT:
            const i = _.indexOf(state.attachments, action.payload);
            return {
                ...state,
                attachments: (i >= 0) ? [...state.attachments.slice(0, i), ...state.attachments.slice(i + 1)] : state.attachments
            };
        case newReportActions.POPULATE_ATTACHMENT_SETTINGS:
            return {
                ...state,
                attachmentSettings: { ...action.payload }
            };
        case newReportActions.ERROR_NOTES:
            return {
                ...state,
                error: action.payload,
                isLoading: false
            };
        // case newReportActions.LOAD_WIZARD_NOTES:
        //     return { ...action.payload };
        case newReportActions.WIZARD_RESET:
            return initialQuestionsState;
        case newReportActions.WIZARD_UPDATE:
            return { ...state };
        case newReportActions.LOAD_WIZARD_QUESTIONS:
            return { ...action.payload };
        default:
            return state;
    }
}

export const getGeneralPageStatusSelector = (state: NewReportWizardQuestionsModel) => state.pageStatus.general;
export const getGeneralQuestionsErrorSelector = (state: NewReportWizardQuestionsModel) => state.error;
export const getGeneralQuestionsIsLoadingSelector = (state: NewReportWizardQuestionsModel) => state.isLoading || state.isLoadingCrewSequence;
export const getGeneralQuestionsIncidentStation = (state: NewReportWizardQuestionsModel) => state.incidentStation;

export const getFlightPageStatusSelector = (state: NewReportWizardQuestionsModel) => state.pageStatus.flight;
export const getIsFlightSelector = (state: NewReportWizardQuestionsModel) => state.isFlight;
export const getCrewSequencesSelector = (state: NewReportWizardQuestionsModel) => state.crewSequences;
export const getFlightSelectionSelector = (state: NewReportWizardQuestionsModel) => state.flightSelection;

export const getNotesPageStatusSelector = (state: NewReportWizardQuestionsModel) => state.pageStatus.notes;
export const getAttachmentsSelector = (state: NewReportWizardQuestionsModel) => state.attachments;
export const getAttachmentsSettings = (state: NewReportWizardQuestionsModel) => state.attachmentSettings;
