import { Component, EventEmitter, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State } from 'app/store';
import { IDynamicQuestion, IFlightSequence, UpdateFlightSelection, FlightSequence } from '../../new-report-store/wizard';
import { getFlightSelection, getQuestions } from '@newreport/new-report-store';
import { flightSequenceDictionary } from '../dynamic-form.component';
import { ConfirmFlightChangeComponent } from '@wizardmodals/confirm/flight-change/confirm-flight-change.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-number',
    templateUrl: './number.component.html',
    styleUrls: ['../dynamic-form.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})

export class NumberComponent implements OnInit, OnDestroy {
    question: IDynamicQuestion;
    answerEvent$: EventEmitter<any>;
    questionAttrs: object = {};
    @ViewChild('form', { static: true }) form: NgModel;
    @ViewChild(ConfirmFlightChangeComponent, { static: true }) flightChangeCom: ConfirmFlightChangeComponent;
    fieldNameFlightAttribute;
    currentFlightDetails: IFlightSequence;
    generalQuestions: Array<IDynamicQuestion> = [];
    displayInjuries = false;
    destroy$: Subject<void> = new Subject<void>();

    constructor(private store: Store<State>) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        this.fieldNameFlightAttribute = this.questionAttrs && this.questionAttrs['fieldName'] ?
            flightSequenceDictionary[this.questionAttrs['fieldName'].trim().toUpperCase()] : null;
        if (this.fieldNameFlightAttribute) {
            this.currentFlightDetails = new FlightSequence();
            this.store.select(getFlightSelection).pipe(takeUntil(this.destroy$)).subscribe(flight => {
                if (flight) {
                    this.currentFlightDetails = flight;
                }
            });
            this.store.select(getQuestions('general')).pipe(takeUntil(this.destroy$)).subscribe(q => {
                this.generalQuestions = q;
            });
        }
    }

    updateStoreIfFlightDetailQuestion($event) {
        if (this.fieldNameFlightAttribute) {
            this.currentFlightDetails[this.fieldNameFlightAttribute] = $event.currentTarget.value;
            this.store.dispatch(new UpdateFlightSelection({ ...this.currentFlightDetails }));
        }
    }

    removeLeadingZeros() {
        if (this.question.userAnswer) {
            this.question.userAnswer = this.question.userAnswer.toString().replace(/^0+/, '');
        }
    }

    showFlightChangeConfirm($event) {
        if (!this.currentFlightDetails) return;

        let isQuestionsVisited = false;
        if (this.generalQuestions && this.generalQuestions.length > 0) {
            this.generalQuestions.forEach(a => {
                if (a.hasOwnProperty('userAnswer')) {
                    isQuestionsVisited = true;
                    return;
                }
            });
        }
        if ((this.currentFlightDetails[this.fieldNameFlightAttribute] === null || this.currentFlightDetails[this.fieldNameFlightAttribute] === undefined)
            && $event && $event.currentTarget) {
            // first time entered
            this.updateStoreIfFlightDetailQuestion($event);
        } else if (this.fieldNameFlightAttribute
            && $event && $event.currentTarget && $event.currentTarget.value && this.currentFlightDetails[this.fieldNameFlightAttribute]
            && this.currentFlightDetails[this.fieldNameFlightAttribute].toString().trim() !== $event.currentTarget.value.toString().trim()
            && isQuestionsVisited) {
            this.flightChangeCom.openModal();
        }
    }

    saveToStore(flight: IFlightSequence) {
        this.currentFlightDetails[this.fieldNameFlightAttribute] = this.question.userAnswer;
        this.store.dispatch(new UpdateFlightSelection({ ...this.currentFlightDetails }));
    }

    cancelUpdate() {
        this.question.userAnswer = this.currentFlightDetails[this.fieldNameFlightAttribute];
    }

    toggleIcon(): void {
        this.displayInjuries = !this.displayInjuries;
    }
}
