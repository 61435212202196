// Modules
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DataTablesModule } from 'angular-datatables';
import { CommonUXModule } from '@shared/common-ux/common-ux.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// Components
import { AdminComponent } from '@admin/admin.component';
import { CategoryManagementComponent } from '@admin/category-management/category-management.component';
import { CategorySummaryComponent } from '@admin/category-management/category-details/category-details.component';
import { GeneralQuestionViewComponent } from '@admin/category-management/category-details/question-view/question-view.component';
import { FlightViewComponent } from '@admin/category-management/category-details/flight-view/flight-view.component';
import { QuestionAddComponent } from '@admin/category-management/category-details/question-add/question-add.component';
import { QuestionsListComponent } from '@admin/category-management/category-details/questions-list/questions-list.component';
import { TagListComponent } from '@admin/category-management/category-details/tags-list/tags-list.component';
import { QuestionEditComponent } from '@admin/category-management/category-details/question-edit/question-edit.component';
import { InvolvementEditComponent } from '@admin/category-management/category-details/involvement-edit/involvement-edit.component';
import { AnswerAddComponent } from '@admin/category-management/category-details/answer-add/answer-add.component';
import { AnswerEditComponent } from '@admin/category-management/category-details/answer-edit/answer-edit.component';
// import { AnswerMultiInputComponent } from '@admin/category-management/category-details/answer-multi-input/answer-multi-input.component';
import { TagManagementComponent } from '@admin/category-management/tag-management/tag-management.component';
import { QuestionTagManagementComponent } from '@admin/category-management/question-tag-management/question-tag-management.component';

import { SectionVisibilityComponent } from '@admin/category-management/category-details/section-visibility/section-visibility.component';
import { DisplayOrderEditComponent } from '@admin/category-management/category-details/display-order-edit/display-order-edit.component';
import { MiscellaneousViewComponent } from '@admin/category-management/category-details/miscellaneous-view/miscellaneous-view.component';

import { TemplateAddComponent } from './category-management/category-details/template-add/template-add.component';
import { DisplayTemplateNamePipe, FilterTemplateQuestionsPipe, DisplayTemplateSaveButtonPipe } from './category-management/category-details/template-add/template.pipe';

import { QuestionAnswerDynamicComponent } from './dynamic-questions/question-answer-dynamic.component';
import { MultiAnswerDisplayAdminPipe } from './dynamic-questions/multi-answer-display-admin.pipe';
import { ViewOnlyDescriptionPipe } from './category-management/category-details/question-edit/view-only-description.pipe';
import { TagsViewComponent } from './category-management/category-details/tags-view/tags-view.component';

// Store
import { reducers } from './category-management/category-management-store';

// Services
import { CategoryDetailService } from './category-management/category-details/category-detail.service';
import { DisplayOrderService } from './category-management/category-details/display-order-edit/display-order.service';
import { QuestionViewService } from '@admin/category-management/category-details/question-view/question-view.service';
import { AppAuthGuard } from '../app.guard';
import { CategoryManagementGuard } from './category-management/category-management.guard';
import { UserResolver } from '../shared/user-resolver/user-resolver.service';
import { CategoryListEffects } from './category-management/category-management-store/list';
import { CategoryDetailEffects } from './category-management/category-management-store/detail';
import { CategorySectionEffects } from './category-management/category-management-store/detail/sections.effects';
import { TemplateEffects } from './category-management/category-management-store/template/template.effects';
import { HighlightService } from './category-management/highlight/highligh.service';
import { NotesViewComponent } from './category-management/category-details/notes-view/notes-view.component';
import { TableSortableHeader } from 'app/shared/directives/sortable.directive';
import { TagManagementGuard } from './tag-management.guard';
import { TagService } from '../shared/SSEHubClient/tag.service';
import { TagEffects } from './category-management/category-management-store/tag/tag.effects';
import { CreateNewTagModalComponent } from './category-management/create-new-tag-modal/create-new-tag-modal.component';
import { EditTagModalComponent } from './category-management/edit-tag-modal/edit-tag-modal.component';
import { CanDeactivateGuard } from 'app/can-deactivate.guard';
import { NotificationViewComponent } from './category-management/category-details/notification-view/notification-view.component';

@NgModule({
    declarations: [
        AdminComponent,
        CategoryManagementComponent,
        CategorySummaryComponent,
        GeneralQuestionViewComponent,
        FlightViewComponent,
        NotesViewComponent,
        QuestionAddComponent,
        QuestionEditComponent,
        InvolvementEditComponent,
        AnswerEditComponent,
        AnswerAddComponent,
        // AnswerMultiInputComponent,
        SectionVisibilityComponent,
        DisplayOrderEditComponent,
        MiscellaneousViewComponent,
        QuestionsListComponent,
        TagListComponent,
        TemplateAddComponent,
        QuestionAnswerDynamicComponent,
        DisplayTemplateNamePipe,
        FilterTemplateQuestionsPipe,
        DisplayTemplateSaveButtonPipe,
        MultiAnswerDisplayAdminPipe,
        ViewOnlyDescriptionPipe,
        TagManagementComponent,
        QuestionTagManagementComponent,
        CreateNewTagModalComponent,
        EditTagModalComponent,
        TagsViewComponent,
        NotificationViewComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        DataTablesModule,
        NgbModule,
        DragDropModule,
        CommonUXModule,
        RouterModule.forChild([
            {
                path: 'admin',
                component: AdminComponent,
                children: [
                    {
                        path: 'categorymanagement',
                        component: CategoryManagementComponent,
                        canActivate: [AppAuthGuard, CategoryManagementGuard]
                    },
                    {
                        path: 'categorymanagement/profile/:profileId/category/:categoryId/summary',
                        component: CategorySummaryComponent,
                        resolve: { user: UserResolver },
                        canActivate: [AppAuthGuard]
                    },
                    {
                        path: 'categorymanagement/profile/:profileId/category/:categoryId/question/:questionMappingId/tags',
                        component: QuestionTagManagementComponent,
                        canActivate: [AppAuthGuard, CategoryManagementGuard],
                        canDeactivate: [CanDeactivateGuard]
                    },
                    {
                        path: 'categorymanagement/tagmanagement',
                        component: TagManagementComponent,
                        canActivate: [AppAuthGuard, TagManagementGuard],
                        canDeactivate: [CanDeactivateGuard]
                    },
                ]
            }
        ]),
        StoreModule.forFeature('categoryManagement', reducers),
        EffectsModule.forFeature([CategoryListEffects, CategoryDetailEffects, CategorySectionEffects, TemplateEffects, TagEffects]),
        EditorModule, 
        TableSortableHeader,
        ReactiveFormsModule,
    ],
    providers: [
        CategoryDetailService,
        DisplayOrderService,
        QuestionViewService,
        CategoryManagementGuard,
        FilterTemplateQuestionsPipe,
        HighlightService,
        TagManagementGuard,
        TagService,
        { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
    ],
    exports: [
        AdminComponent,
        CategoryManagementComponent,
        RouterModule
    ]
})

export class AdminModule { }
