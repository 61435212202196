import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NotificationRecords, NotificationProfile, NotificMessage, NotificVendor, NotificationMethod } from './notification-verification.models';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { State } from '@app/store';
import { getUser, IUser } from 'app/store/user';
import { SSEHubCERSPortalNotificationService } from 'app/shared/SSEHubClient/notification.service';
import { ConfirmationPopupComponent } from 'app/shared/common-ux/components/confirmation-popup/confirmation-popup.component';
import * as _ from 'lodash';
import { SSEHubNotificationService } from 'app/shared/SSEHubNotification/notification.service';
import { FormControl, Validators, AbstractControl } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';


@Component({
    selector: 'app-notification-verification',
    templateUrl: './notification-verification.component.html',
    styleUrls: ['./notification-verification.component.scss']
})

export class NotificationVerificationComponent implements OnInit, OnDestroy {
    public user: IUser;
    public showFactor = false;
    public notificationRecords: NotificationRecords = new NotificationRecords();
    public notificationMethods: NotificationMethod[] = new Array<NotificationMethod>();
    public messageTypes: Array<NotificMessage> = new Array<NotificMessage>();
    public vendors: Array<NotificVendor> = new Array<NotificVendor>();
    destroy$: Subject<void> = new Subject<void>();
    // user selections
    public selectedProfile: NotificationProfile;
    public selectedMethod: NotificationMethod = null;

    // flags
    isLoading = false;
    updateLoading = false;
    confirmAllDisabled = false;
    responseMessage: string;

    // modals
    @ViewChild('saveConfirmation', { static: true }) saveConfirmModal: ConfirmationPopupComponent;
    @ViewChild('deleteConfirmation', { static: true }) deleteConfirmModal: ConfirmationPopupComponent;
    @ViewChild('confirmAll', { static: true }) confirmAllModal: ConfirmationPopupComponent;

    constructor(
        private notificService: SSEHubCERSPortalNotificationService,
        private ONEService: SSEHubNotificationService,
        public datepipe: DatePipe,
        private store: Store<State>
    ) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    ngOnInit() {
        this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(user => {
            this.onUserLoaded(user);
            this.isLoading = true;
            this.updateLoading = true;
        });
        this.responseMessage = '';
    }

    onUserLoaded(user: IUser) {
        this.user = user;
        if (this.user && this.user.id) {
            this.getNotificationRecords();
        }
    }

    getNotificationRecords(selectedProfileSk?: any) {
        this.updateLoading = true;
        this.selectedMethod = null;
        this.showFactor = false;

        this.notificationRecords = new NotificationRecords();
        this.notificationRecords.employeeId = this.user.id;
        this.notificationRecords.employeeName = this.user.lastName + ', ' + this.user.firstName;

        this.notificService.getMessageTypes().pipe(takeUntil(this.destroy$)).subscribe(types => {
            this.messageTypes = [];
            types.map(type => this.messageTypes.push(new NotificMessage(type.typeDesc, type.notificationTypeSk)));
            this.messageTypes = _.sortBy(this.messageTypes, t => t.messageType);
        });

        this.notificService.getVendors().pipe(takeUntil(this.destroy$)).subscribe(carriers => {
            this.vendors = [];
            carriers.map(carr => this.vendors.push({
                vendorName: carr.vendorName,
                vendorSk: carr.notificationVendorSk,
                vendorExt: carr.addressExt
            }));
            this.vendors = _.sortBy(this.vendors, c => c.vendorName);
        });

        this.notificService.getAllNotificationMethods(this.notificationRecords.employeeId).pipe(takeUntil(this.destroy$)).subscribe((methods) => {
            if (methods.Profiles) {
                this.notificationRecords.profiles = _.sortBy(methods.profiles, p => p.profileName);
            }

            if (this.notificationRecords && this.notificationRecords.profiles && this.notificationRecords.profiles.length > 0) {
                // If there are some data, default the first record to be selected
                if (selectedProfileSk) {
                    this.notificationRecords.profiles.map(p => {
                        if (p.profileSk === selectedProfileSk) this.selectedProfile = p;
                    });
                } else {
                    this.selectedProfile = this.notificationRecords.profiles[0];
                }
                this.getProfileNotificationMethods();
                this.confirmAllDisabled = false;
            }
            this.isLoading = false;
            this.updateLoading = false;

        });
    }

    getProfileNotificationMethods() {
        if (this.selectedProfile && this.selectedProfile.notificationMethods) {
            this.notificationMethods = new Array<NotificationMethod>();
            this.selectedProfile.notificationMethods.map((m: any) => {
                this.notificationMethods.push(this.createNotificationMethod(m));
            });
            this.showFactor = false;
            this.selectedMethod = null;
            this.responseMessage = '';
        } else {
            this.responseMessage = 'No notification method is selected';
        }
    }

    createNotificationMethod(m: any) {
        const method: NotificationMethod = {
            notificationSk: m.NotificationSk,
            notificationValue: m.NotificationValue,
            messageTypeSk: m.MessageTypeSk,
            messageType: m.MessageType,
            vendorSk: m.VendorSk,
            vendorName: m.VendorName,
            vendorExt: m.Extension,
            lastVerifiedDate: (new Date(m.LastUpdate)).toDateString(),
            selected: false,
            validationRules: null,
            notificDescription: m.NotificationDescription,
            notificationFactors: this.sortFactors(m.NotificationFactors),
            isEmail: false
        };
        method.isEmail = this.isMethodEmail(method);
        method.validationRules = new FormControl(method.notificationValue, this.getValidators(method));
        method.validationRules.markAsTouched();

        return method;
    }

    emailValidator(control: AbstractControl) {
        // if it has full email, show error for only prefix
        if (control.value.includes('@')) return { prefix: true };
        // numeric email not allowed (i.e. phone number)
        if (control.value.match(/^\d+$/)) return { invalid: true };

        return null;
    }

    getValidators(method) {
        method.isEmail = this.isMethodEmail(method);
        return [Validators.required, method.isEmail ? this.emailValidator : Validators.pattern(/^\d{10}$/)];
    }

    isMethodEmail(method) {
        return (method.messageType.toUpperCase().includes('MAIL') || method.vendorName.toUpperCase().includes('MAIL'));
    }

    sortFactors(factors) {
        return _(factors).chain().sortBy((factor) => {
            return factor.hierarchyDescription;
        }).sortBy((factor) => {
            return factor.factorType;
        }).value();
    }

    ConfirmSave() {
        if (!this.selectedMethod) {
            this.responseMessage = 'No notification method is selected';
            return;
        }
        if (this.selectedMethod.validationRules && !this.selectedMethod.validationRules.valid) {
            this.responseMessage = 'There is an error on the notification method';
            return;
        }
        this.saveConfirmModal.open();
    }

    ConfirmDelete() {
        (this.selectedMethod) ? this.deleteConfirmModal.open() : this.responseMessage = 'No notification method is selected';
    }


    Save() {
        this.responseMessage = '';
        this.updateLoading = true;

        if (this.selectedMethod !== null || this.selectedMethod !== undefined) {
            // prepare a data type to pipeline to db through ssehub services
            const sel = { ...this.selectedMethod };
            this.selectedMethod = null;

            this.notificService.update(
                sel.notificationSk,
                sel.messageTypeSk,
                sel.notificationValue,
                sel.notificDescription,
                sel.vendorName).pipe(takeUntil(this.destroy$)).subscribe(result => {
                    this.updateLoading = false;
                    if (result) {
                        this.responseMessage = 'Saved successfully';
                        this.notificationMethods
                            .map(m => { if (m.notificationSk === sel.notificationSk) m.lastVerifiedDate = new Date().toDateString(); });
                        this.updateLocalMethod(sel);
                    } else {
                        this.responseMessage = 'Failed to save';
                    }
                });
        } else {
            // failing message label
            this.responseMessage = 'Please select one notification method to save';
        }
    }

    updateLocalMethod(sel: NotificationMethod) {
        let updateMet = null;
        this.selectedProfile.notificationMethods.map((m: any) => {
            if (m.NotificationSk === sel.notificationSk) {
                m.NotificationSk = sel.notificationSk;
                m.MessageTypeSk = sel.messageTypeSk;
                m.MessageType = sel.messageType;
                m.NotificationValue = sel.notificationValue;
                m.NotificationDescription = sel.notificDescription;
                m.VendorName = sel.vendorName;
                m.LastUpdate = new Date();
                m.VendorSk = sel.vendorSk;
                m.Extension = sel.vendorExt;
                updateMet = m;
            }
        });
        this.notificationMethods.map(m => {
            if (m.notificationSk === sel.notificationSk) {
                m = this.createNotificationMethod(updateMet);
            }
        });

    }
    ShowAttributes() {
        this.showFactor = true;
    }

    Delete() {
        this.responseMessage = '';
        if (this.selectedMethod) {
            const sel = this.selectedMethod;
            this.selectedMethod = null;
            this.updateLoading = true;
            this.ONEService.sendDeleteNotificationRequest(this.user.id, sel.notificationValue).pipe(takeUntil(this.destroy$)).subscribe((result) => {
                if (result) {
                    this.updateLoading = false;
                    this.responseMessage = 'Request to delete notification sent successfully';
                }
            });
        }
    }

    ConfirmAllPopup() {
        this.confirmAllModal.open();
    }

    ConfirmAll() {
        // reset variables
        this.responseMessage = '';
        this.updateLoading = true;
        this.notificationMethods.map(m => m.selected = false);
        const tempSelectedProfileSk = this.selectedProfile.profileSk;

        this.notificService.confirmAll(this.notificationRecords.employeeId).pipe(takeUntil(this.destroy$)).subscribe(result => {
            this.updateLoading = false;
            this.confirmAllDisabled = true;
            if (result) {
                this.notificationRecords = new NotificationRecords();
                this.notificationMethods = new Array<NotificationMethod>();
                this.isLoading = true;
                this.getNotificationRecords(tempSelectedProfileSk);
                this.responseMessage = 'All notification methods are verified';
            } else {
                this.responseMessage = 'Failed to confirm all notification methods';
            }
        });
    }

    selectionChanged(method: NotificationMethod) {
        // update method
        method.notificationValue = method.validationRules.value;
        this.notificationMethods.map(m => m.selected = (m.notificationSk === method.notificationSk));

        // update variables
        this.responseMessage = '';
        this.selectedMethod = method;

        // ngModal did not pipelind the updated messageType
        method.messageType = _.find(this.messageTypes, (mT) => {
            return (mT.messageTypeSk === method.messageTypeSk);
        }).messageType;

        _.find(this.vendors, (v) => {
            if (v.vendorName.includes(method.vendorName)) {
                method.vendorExt = v.vendorExt;
                method.vendorSk = v.vendorSk;
            }
        });

        // update validation rules
        method.validationRules.setValidators(this.getValidators(method));
        method.validationRules.setValue(method.notificationValue);
        method.validationRules.markAsTouched();

        this.showFactor = false;
    }
}

