<div class="addnew" *ngIf="!isAddNew" (click)="onAdd()">
    <i class="icon-small icon-add"></i>
    <a *ngIf="title" style="padding-left: 5px">{{title}}</a>
</div>
<div *ngIf="isAddNew" class="form-group addnew">
    <textarea aaAutosize [minRows]="5" [maxRows]="20" class="form-control" id="text" required [(ngModel)]="text"
        name="text"> </textarea>
    <div *ngIf="textInvalid" class="validation-fail">
        Required
    </div>
    <button type="button" class="button-cancel" (click)="cancel()">Cancel</button>
    <button type="submit" class="button-save" (click)="save()">{{btnTitle}}</button>
</div>