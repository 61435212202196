import { Component, OnInit, OnDestroy } from '@angular/core';
import { IUser, User } from '@app/store/user';
import { UnfinishedReportDatabaseService, IUnfinishedReportMetaData } from 'app/shared/report-services/unfinished-report-database.service';
import { ICategory } from 'app/admin/category-management/category-management-store/list';
import { Store } from '@ngrx/store';
import { State } from '@app/store';
import { ReportAssemblyService } from 'app/new-report/new-report.service';
import {
    LoadTodoHistory, getTodoHistory, IHistoryList, IReportHistoryOptions,
    IReportHistory, FilterTodoHistory, getTodoList, FilterTodoList, SelectedTodo, ResetTodo, LoadTodoList
} from 'app/report-history/report-history-store/list';

import { getUser } from '@app/store/user/user.reducer';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
    selector: 'app-to-do',
    templateUrl: './to-do.component.html',
    styleUrls: ['./to-do.component.scss']
})
export class ToDoComponent implements OnInit, OnDestroy {
    category: ICategory;
    categoryId: number;
    categoryDescription: string;
    profileId: number;
    unfinishedReportsList: IUnfinishedReportMetaData[];
    employeeId: string;
    selectedReport: string;
    private resetTodo: IReportHistory;
    destroy$: Subject<void> = new Subject<void>();
    constructor(
        private store: Store<State>,
        private unfinishedReportDBService: UnfinishedReportDatabaseService,
        public newReportService: ReportAssemblyService
    ) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        this.unfinishedReportsList = this.unfinishedReportDBService.unfinishedReportMetaDataList;
        this.unfinishedReportDBService.retrieveUnfinishedReportList().then((v) => this.unfinishedReportsList = v);
        this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(user => this.onUserLoaded(user));
        this.store.dispatch(new ResetTodo(this.resetTodo));
    }

    onUserLoaded(user: IUser) {
        if (user && user.profileId) {

            this.profileId = user.profileId;
            this.employeeId = user.id;
            // check if user has feature flag access
            if (this.profileId === 4) {
                this.getHistoryLists();
            } else {
                this.getTodo();
            }

        }
    }
    displayReports(selectedReport: string) {
        this.selectedReport = selectedReport;
    }
    saveUnfinishedReport() {
        this.unfinishedReportDBService.saveUnfinishedReport();
    }
    private getTodo() {
        this.store.dispatch(new LoadTodoList(this.employeeId));
    }
    private getHistoryLists() {
        this.store.select(getTodoHistory).pipe(takeUntil(this.destroy$)).subscribe(todoList => {
            if (todoList.isLoading === null) {
                this.store.dispatch(new LoadTodoHistory(this.employeeId));
            }
        });
    }
}
