import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import * as _ from 'lodash';
import { LoadProfileCategories, SetSelectedCategory } from './new-report-store/list/list.actions';
import { LoadSectionDetail } from './new-report-store/wizard';
import { getUser, IUser } from '@app/store/user';
import { getCategoriesList, State } from '@newreport/new-report-store';
import { ICategory, ICategoryList } from './new-report-store/list/list.model';
import { WizardReset } from './new-report-store/wizard';
import { getTodo, IReportHistory } from 'app/report-history/report-history-store/list';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-new-report',
    templateUrl: './new-report.component.html',
    styleUrls: ['./new-report.component.scss']
})
export class NewReportComponent implements OnInit, OnDestroy {
    profileId: number;
    categoryList$: Observable<ICategoryList>;
    profilecategoryList$: Observable<ICategoryList>;
    profileCategory: ICategoryList;
    inactiveCategories = 0;
    private isTodo: boolean;
    destroy$: Subject<void> = new Subject<void>();
    constructor(private store: Store<State>, private router: Router) {
        this.categoryList$ = this.store.select(getCategoriesList);
        this.profilecategoryList$ = this.categoryList$;
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        window.scrollTo(0, 0); // scroll to top every time this page loads
        this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(user => this.onUserLoaded(user));
        this.store.select(getTodo).pipe(takeUntil(this.destroy$)).subscribe(todo => this.getSelectedTodoReport(todo));
        this.store.dispatch(new WizardReset());
        if (this.profileId === 2 && this.isTodo) {
            this.categoryList$ = this.profilecategoryList$;
        } else {
            this.store.select(getCategoriesList).pipe(takeUntil(this.destroy$)).subscribe(list => this.getCategory(list));
        }
    }

    onUserLoaded(user: IUser) {
        if (user && user.profileId) {
            this.profileId = user.profileId;
            this.store.dispatch(new LoadProfileCategories(this.profileId));
        }
    }

    onCategoryClick(category: ICategory) {
        this.store.dispatch(new SetSelectedCategory(category));
        this.store.dispatch(new LoadSectionDetail(this.profileId, category.id));
        if (category.id === 11) { // 11 = 'IDONTKNOWANYTHING'
            this.router.navigate(['newreport/wizard/notes']);
        } else {
            this.router.navigate(['newreport/wizard/flight']);
        }
    }

    getSelectedTodoReport(report: IReportHistory) {
        if (report === undefined || report == null) {
            this.isTodo = false;
        } else {
            this.isTodo = true;
        }
    }

    getCategory(item: ICategoryList) {
        if (this.profileId === 2 && this.isTodo) {
        } else {
            const cat: any = item.list.filter(x => x.id !== 11); // x.categoryId.toUpperCase().trim() !== 'IDONTKNOWANYTHING'
            item.list = cat;
        }
    }
}
