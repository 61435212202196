import { Injectable, ElementRef } from '@angular/core';


@Injectable()
export class ExpandCollapseService {
    public expand(el: ElementRef) {
        el.nativeElement.className = 'panel-collapse collapse.show';
        el.nativeElement.setAttribute('aria-expanded', 'true');
        el.nativeElement.style.height = 'auto';
    }

    public collapse(el: ElementRef) {
        el.nativeElement.className = 'panel-collapse collapse';
        el.nativeElement.setAttribute('aria-expanded', 'false');
    }
}
