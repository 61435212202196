import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SSEHubClient } from './SSEHubClient.service';

@Injectable()
export class SSEHubCERSPortalNotificationService {

    constructor(
        private http: HttpClient,
        private sseHubClient: SSEHubClient,
    ) { }

    getAllNotificationMethods(employeeId): Observable<any> {
        return this.http.get(`${this.sseHubClient.serviceUrl()}/api/notification/${employeeId}/allNotificationMethods`);
    }

    getMessageTypes(): Observable<any> {
        return this.http.get(`${this.sseHubClient.serviceUrl()}/api/notification/notificationTypes`);
    }

    getVendors(): Observable<any> {
        return this.http.get(`${this.sseHubClient.serviceUrl()}/api/notification/notificationVendors`);
    }

    update(methodSk: number, typeSk: number, value: string, description: string, vendorName: string): Observable<any> {
        return this.http.put(this.sseHubClient.serviceUrl() + '/api/notification', {
            NotificationMethodRefSk: methodSk,
            NotificationTypeSk: typeSk,
            NotificationValue: value,
            NotificationDescription: description,
            NotificationVendorName: vendorName
        });
    }

    confirmAll(employeeId: string): Observable<any> {
        return this.http.put(`${this.sseHubClient.serviceUrl()}/api/notification/${employeeId}/VerifyAllNotificationMethods`, employeeId);
    }

    sendReportNotification(reportId: string, notificationRecipients: string, notificationContent: string, notificationSubject: string): Observable<any> {
        return this.http.post(`${this.sseHubClient.serviceUrl()}/api/sendReportNotification`, {
            Report_Id: reportId,
            Notification_Recipients: notificationRecipients,
            Notification_Content: notificationContent,
            Notification_Subject: notificationSubject
        });
    }
}
