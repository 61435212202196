import { Injectable, EventEmitter } from '@angular/core';

import { SSEHubCategoriesService } from '../../SSEHubClient/categories.service';
import { CategoryDetails, ReportSections, SectionInfo } from './reportSections';

import { NotificationService } from '@shared/error-handler-notify/services/notification.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class CategoryDetailsService {

    constructor(
        private categoriesService: SSEHubCategoriesService,
        public notificationService: NotificationService
    ) { }

    getDetails(profileId, categoryId): Observable<CategoryDetails> {
        return this.categoriesService.getCategoryDetails(profileId, categoryId).pipe(
            map((result: any) => {
                const details: CategoryDetails = new CategoryDetails();
                let sectionInfo: SectionInfo;
                if (result) {
                    details.id = result.id;
                    details.description = result.description;
                    details.sections = new ReportSections();
                    details.attrs = result.attrs;
                    result.categorySections.forEach(section => {
                        sectionInfo = new SectionInfo();
                        sectionInfo.id = section.id;
                        sectionInfo.name = section.sectionName;
                        sectionInfo.sectionVisibility = section.sectionVisibility;
                        sectionInfo.visibility = section.visibility;
                        sectionInfo.includeInEmail = section.includeInEmail;
                        switch (section.sectionName) {
                            case 'Flight':
                                details.sections.flight = sectionInfo;
                                break;
                            case 'Questions':
                                details.sections.questions = sectionInfo;
                                break;
                            case 'Notes':
                                details.sections.notes = sectionInfo;
                                break;
                            case 'Images':
                                details.sections.images = sectionInfo;
                                break;
                        }
                    });
                }
                return details;
            }),
            catchError(e => {
                this.notificationService.showError('An error has occured while retrieving catagory info.');
                console.error(e);
                return of(e);
            })
        );
    }
}

