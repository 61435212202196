import { Component, EventEmitter, OnInit, ViewChild, Input } from '@angular/core';
import { IDynamicQuestion, questionsReducer } from '../../new-report-store/wizard';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';

@Component({
    selector: 'app-textarea',
    templateUrl: './textarea.component.html',
    styleUrls: ['../dynamic-form.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})

export class TextareaComponent {
    @Input() question: IDynamicQuestion;
    answerEvent$: EventEmitter<any>;
    questionAttrs: object = {};
    @ViewChild('form', { static: true }) form: NgModel;

    autofillTextNA() {
        this.question.userAnswer = 'NA';
    }

    sanitizeAnswer() {
        if (this.question.userAnswer) {
            this.question.userAnswer = this.question.userAnswer.toString().trim();
        }
    }
}
