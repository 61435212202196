import { CpAttrsVal } from "app/shared/models/cp-attrs-val";

export class SectionInfo {
    id: number;
    name: string;
    visibility: number;
    sectionVisibility: string;
    includeInEmail?: boolean;
}
export class ReportSections {
    flight: SectionInfo;
    questions: SectionInfo;
    notes: SectionInfo;
    images: SectionInfo;
    constructor() {
        this.flight = new SectionInfo();
        this.questions = new SectionInfo();
        this.notes = new SectionInfo();
        this.images = new SectionInfo();
    }
}
export class CategoryDetails {
    id: number;
    description: string;
    sections: ReportSections;
    attrs: CpAttrsVal;

    constructor() {
        this.description = '';
        this.sections = new ReportSections();
    }
}



