import { DatePipe } from '@angular/common';
import { FormGroup, FormControl } from '@angular/forms';

export class NotificationRecords {
    public employeeId: string;
    public employeeName: string;
    public datePipe: DatePipe;
    public profiles: Array<NotificationProfile>;

    constructor() {
        this.profiles = new Array<NotificationProfile>();
    }
}
export class NotificMessage {
    public messageType: string;
    public messageTypeSk: number;
    constructor(messageType: string, messageTypeSk: number) {
        this.messageType = messageType;
        this.messageTypeSk = messageTypeSk;
    }
}
export interface NotificVendor {
    vendorName: string;
    vendorSk: number;
    vendorExt: string;
}
export interface INotificationFactor {
    factorDescription: string;
    hierarchyDescription: string;
    factorType: string;
    notificationMethodSk: number;
}

export class NotificationProfile {
    public profileName: string;
    public profileSk: number;
    public notificationMethods: NotificationMethod[];
}

export interface NotificationMethod {
    isEmail: any;
    notificationSk: number;
    notificationValue: string;
    messageTypeSk: number;
    messageType: string;
    vendorSk: number;
    vendorName: string;
    vendorExt: string;
    lastVerifiedDate: string;
    selected: boolean;
    validationRules: FormControl;
    notificDescription: string;
    notificationFactors: Array<INotificationFactor>;
}
