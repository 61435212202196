import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


import { SSEHubClient } from './SSEHubClient.service';
import { Observable } from 'rxjs';


@Injectable()
export class SSEHubSectionService {

    constructor(
        private http: HttpClient,
        private sseHubClient: SSEHubClient
    ) { }

    modifySectionVisibility(profileId, categoryId, sectionId, visibility): Observable<any> {
        return this.http.put(
            this.sseHubClient.serviceUrl() + '/api/profiles/' + encodeURIComponent(profileId) + '/categories/' + encodeURIComponent(categoryId)
            + '/sectionInfo/' + encodeURIComponent(sectionId) + '/visibility?visibility=' + encodeURIComponent(visibility) + '',
            this.sseHubClient.getServiceOptions());
    }

    modifyIncludeAttachmentInEmail(profileId, categoryId, includeAttachmentInEmail): Observable<any> {
        return this.http.put(
            this.sseHubClient.serviceUrl() + '/api/profiles/' + encodeURIComponent(profileId) +
            '/categories/' + encodeURIComponent(categoryId) + '/includeAttachmentInEmail?includeAttachmentInEmail=' + includeAttachmentInEmail + '',
            this.sseHubClient.getServiceOptions());
    }
}

