import { Component, OnInit, ViewChild, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { State } from '@app/store';
import {
    LoadTodoHistory, getTodoHistory, IHistoryList, IReportHistoryOptions, IReportHistory, FilterTodoHistory
} from 'app/report-history/report-history-store/list';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NotificationService } from '@shared/error-handler-notify/services/notification.service';
import * as _ from 'lodash';
import { ReportHistorySearchPipe } from '../filter.pipe';
import { DatePipe } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-table-todo-reports',
    templateUrl: './table-todo-reports.component.html',
    styleUrls: ['./table-todo-reports.component.scss'],
    providers: [ReportHistorySearchPipe, DatePipe]
})
export class TableTodoReportsComponent implements AfterViewInit, OnDestroy {
    @Input() employeeId;
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    displayedColumns = ['reportId', 'title', 'employeeId', 'naturalName', 'submitDate', 'flightNumber',
        'flightDate', 'fleetType', 'base', 'departureStation', 'arrivalStation', 'status', 'DaysOpen', 'responseRequested'];
    dataSource = new MatTableDataSource<any>();
    historyList: IReportHistory[] = [];
    tableOptions: IReportHistoryOptions = { filter: {}, sort: { id: 'submitDate', start: 'desc', disableClear: true }, pageIndex: 0 };
    todoReports$: Observable<any>;
    isParsingTable = true;
    destroy$: Subject<void> = new Subject<void>();
    constructor(
        private searchFilterPipe: ReportHistorySearchPipe,
        private notificationService: NotificationService,
        private router: Router,
        private store: Store<State>
    ) {
        this.todoReports$ = this.store.select(getTodoHistory);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngAfterViewInit() {
        // Timeout prevents concurrrency errors between component and UI
        setTimeout(() => {
            this.todoReports$.pipe(takeUntil(this.destroy$)).subscribe(result => this.loadTodoReportHistory(result));
            this.dataSource = new MatTableDataSource(this.historyList);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            this.filterTable();

            // Listen to and update pagination
            this.paginator.page.pipe(takeUntil(this.destroy$)).subscribe(p => {
                this.tableOptions.pageIndex = p.pageIndex;
                this.store.dispatch(new FilterTodoHistory(this.tableOptions));
            });

            // Listen to and update sort
            // tslint:disable-next-line: angular-rxjs-takeuntil-before-subscribe
            this.sort.sortChange.pipe(takeUntil(this.destroy$)).subscribe(s => {
                this.tableOptions.pageIndex = 0;
                if (this.tableOptions.sort.start !== s.direction) {
                    this.tableOptions.sort.id = s.active;
                    this.tableOptions.sort.start = s.direction === 'asc' ? 'asc' : 'desc';
                    this.store.dispatch(new FilterTodoHistory(this.tableOptions));
                }
            });

            this.isParsingTable = false;
        });

    }

    filterTable() {
        if (this.paginator) {
            this.paginator.pageIndex = this.tableOptions.pageIndex;
        }
        this.dataSource.data = this.searchFilterPipe.transform(this.historyList, this.tableOptions.filter);
    }

    applyFilter() {
        this.tableOptions.pageIndex = 0;
        this.store.dispatch(new FilterTodoHistory(this.tableOptions));
    }

    clearAllFilter() {
        this.tableOptions = { filter: {}, sort: { id: 'submitDate', start: 'desc', disableClear: true }, pageIndex: 0 };
        this.sort.sort(this.tableOptions.sort);
        this.applyFilter();
    }

    loadTodoReportHistory(state: IHistoryList): void {
        if (!state.isLoading && state.list.all.length) {
            this.historyList = state.list.all;
            this.tableOptions = state.list.options;
            this.filterTable();
        }
    }

    gotoReportSummary(reportHistory) {
        if (reportHistory != null) {
            const reportIdString = reportHistory.reportId;
            this.router.navigate(['reporthistory/' + reportIdString + '/summary']);
        }
    }
}
