export interface IReportHistory {
    reportId: string;
    reportSk: number;
    title: string;
    flightNumber: string;
    flightDate: Date | string;
    departureStation: string;
    arrivalStation: string;
    status: string;
    nasaSent?: true;
    submitDate: Date | string;
    closeOutDate?: Date | string;
    eventDate: Date | string;
    noseNumber: string;
    fleetType: string;
    affectedStation: string;
    base: string;
    emergencyDeclared: true;
    daysOpen: number | string;
    employeeId: string;
    lastName: string;
    firstName: string;
    naturalName: string;
    event?: string;
    responseRequested?: string;
    id: string;
    taskId: string;
    taskDesc: string;

}

export class ReportHistory {
    static new(obj: any) {
        return {
            'reportId': obj.reportId,
            'reportSk': obj.reportSk,
            'title': obj.title,
            'flightNumber': obj.flightNumber,
            'flightDate': !!obj.flightDate ? new Date(obj.flightDate) : '',
            'departureStation': obj.departureStation,
            'arrivalStation': obj.arrivalStation,
            'status': obj.status,
            'nasaSent?': null,
            'submitDate': !!obj.submitDate ? new Date(obj.submitDate) : '',
            'closeOutDate?': null,
            'eventDate': new Date(obj.eventDate) || '',
            'noseNumber': obj.noseNumber,
            'fleetType': obj.fleetType,
            'affectedStation': obj.affectedStation,
            'base': obj.base,
            'emergencyDeclared': obj.isEmergencyDeclared,
            'daysOpen': '' + obj.daysOpen,
            'employeeId': obj.employeeId,
            'lastName': obj.lastName,
            'firstName': obj.firstName,
            'naturalName': obj.lastName + ', ' + obj.firstName,
            'event': obj.event,
            'responseRequested': obj.responseRequested,
            'id': obj.id,
            'taskId': obj.taskID,
            'taskDesc': obj.taskDesc
        };
    }
}

export class IReportHistoryFilter {
    reportId: string;
    eventDate: string;
    submitDate: string;
    flightNumber: string;
    flightDate: string;
    departureStation: string;
    arrivalStation: string;
    affectedStation: string;
    title: string;
    base: string;
    status: string;
    daysOpen: string;
    id: string;
    taskId: string;
    taskDesc: string;

}

export interface IReportHistoryOptions {
    filter: any;
    sort: {
        id: string,
        start: 'asc' | 'desc',
        disableClear: boolean
    };
    pageIndex: number;
}

