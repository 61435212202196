import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IDynamicQuestion } from '../../new-report-store/wizard';
import { ControlContainer, NgForm, NgModel, NgModelGroup } from '@angular/forms';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import * as _ from 'lodash';

@Component({
    selector: 'app-time',
    templateUrl: './time.component.html',
    styleUrls: ['../dynamic-form.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class TimeComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('timeForm', { static: true }) timeForm: NgModelGroup;
    @ViewChild('hoursControl') hoursControl: NgModel;
    @ViewChild('minsControl') minsControl: NgModel;
    question: IDynamicQuestion;
    questionAttrs: object = {};
    hoursAttrs: any;
    minsAttrs: any;
    hours: string;
    mins: string;
    angleUp = faAngleUp;
    angleDown = faAngleDown;
    hours24 = Array(24).fill(0).map((e, i) => this.pad(i));
    minutes60 = Array(60).fill(0).map((e, i) => this.pad(i));
    destroy$: Subject<void> = new Subject<void>();


    constructor() { }

    ngOnInit() {
        // set the hour and minute validation rules
        this.hoursAttrs = _.cloneDeep(this.questionAttrs);
        this.hoursAttrs.hours24 = true;
        this.minsAttrs = _.cloneDeep(this.questionAttrs);
        this.minsAttrs.mins60 = true;

        // pre-populate any already existing answers
        if (this.question && this.question.userAnswer) {
            this.hours = this.pad(this.question.userAnswer.toString().match(/((0|[1-9])+)/)[1]);
            this.mins = this.pad(this.question.userAnswer.toString().match(/:([0-9]+)/)[1]);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    // there are two fields (hour and minute) and there are 3 ways to update each
    // 1. manually type a value
    // 2. use the up/down arrows
    // 3. use the scroll (mat-autocomplete)
    // change detection operates a little differently for each of them. Therefore, I am subscribing to the valueChanges and ensuring the
    // hours and minutes variables are populated with the new value before calling changeTime() which updates the question
    // object with the input time answer.
    ngAfterViewInit() {
        this.hoursControl.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((hours) => {
                this.hours = hours; // updates the variable when either arrow or the scroll is used so the changeTime() call has the new value
                this.changeTime();  // updates the UserAnswer
            });
        this.minsControl.valueChanges.pipe(takeUntil(this.destroy$))
            .subscribe((mins) => {
                this.mins = mins;
                this.changeTime();
            });
    }

    // this ensures we only store a complete and valid time as the answer
    // it updates the UserAnswer property of the question object
    changeTime() {
        if (this.hours && (/^\d+$/.test(this.hours))) {
            this.hours = this.pad(this.hours);
        } else {
            this.hours = '';
        }
        if (this.mins && (/^\d+$/.test(this.mins))) {
            this.mins = this.pad(this.mins);
        } else {
            this.mins = '';
        }
        if (this.hoursControl.valid && this.minsControl.valid && this.hours && this.mins) {    // check if there's a complete time
            this.question.userAnswer = `${this.hours}:${this.mins}`;
        } else {
            this.question.userAnswer = null;
        }
    }

    // these update the hour when the up/down arrows are used
    incrementHours(amount: number) {
        // Does the field contain only digit characters and is non empty?
        if (this.hoursControl.valid && this.hours && /^\d+$/.test(this.hours.toString())) {
            let temp_hours: number = Number.parseInt(this.hours, 10).valueOf();
            temp_hours += amount;
            temp_hours %= 24;
            // for some reason js modulo doesn't always return a positive
            while (temp_hours < 0) {
                temp_hours = 24 + temp_hours;
            }
            this.hours = this.pad(temp_hours).toString();
        } else {
            this.hours = '00';
        }
    }

    // these update the minutes when the up/down arrows are used
    incrementMins(amount: number) {
        // Does the field contain only digit characters and is non empty?
        if (this.minsControl.valid && this.mins && /^\d+$/.test(this.mins.toString())) {
            let temp_mins: number = Number.parseInt(this.mins, 10).valueOf();
            temp_mins += amount;
            if (temp_mins < 0) {
                temp_mins = temp_mins % 60;
                this.incrementHours(-1);
            }
            if (temp_mins > 59) {
                temp_mins = temp_mins % 60;
                this.incrementHours(1);
            }
            // for some reason js modulo doesn't always return a positive
            if (temp_mins < 0) {
                temp_mins = 60 + temp_mins;
            }

            this.mins = this.pad(temp_mins).toString();
        } else {
            this.mins = '00';
        }
    }

    // adds the leading zero, if necessary, for 24 hour time
    pad(i: number | string): string {
        const num = typeof i === 'string' ? Number(i) : i;

        // Validate that the conversion results in a number and is not NaN
        if (isNaN(num)) {
            throw new Error('Invalid input: Must be a number or numeric string.');
        }

        // Apply padding logic
        return (num !== null && num < 10 && num.toLocaleString().length < 2) ? `0${num}` : `${num}`;
    }
}
