<div class="addnew" *ngIf="!displayRequestResponseForm" (click)="onAdd()">
    <i class="icon-medium icon-add"></i>
    <a style="padding-left: 5px">Request response</a>
</div>
<div *ngIf="displayRequestResponseForm">
    <h3>Request a response</h3>
    <div class="row">
        <label class="">Responders : <a (click)="openSearch()">Add responders</a></label>
        <div class="responder-header" *ngIf="responders.length < 1"></div>
        <label *ngIf="!isValid" class="validation-fail">Need to add at least one responder to forward.</label>
    </div>
</div>

<div class="form-group" *ngIf="responders.length > 0">
    <div class="responder-tooltip" *ngFor="let responder of responders"
        (mouseover)="toggleTooltipHover($event, responder)" (mouseout)="toggleTooltipHover($event, responder)"
        (click)="toggleTooltipClick($event, responder)">
        <label class="responder">{{responder.lastName}}, {{responder.firstName}}
            <i class="icon-medium icon-delete" (click)="removeResponder(responder)"
                style="float: right; padding-left: 10px;"></i>
            <span *ngIf="tooltipHover === responder.employeeId || tooltipClick === responder.employeeId"
                class="tooltiptext">
                <table>
                    <tr>
                        <td><i>Id:</i> </td>
                        <td> <label> {{responder.employeeId}} </label> </td>
                    </tr>
                    <tr>
                        <td><i>Role:</i> </td>
                        <td> <label> {{responder.jobTitle}} </label> </td>
                    </tr>
                    <tr>
                        <td><i>Dep.:</i> </td>
                        <td> <label> {{responder.departmentDesc}} </label> </td>
                    </tr>
                    <tr>
                        <td><i>Station:</i> </td>
                        <td> <label> {{responder.stationCode}} </label> </td>
                    </tr>
                </table>
            </span>
        </label>
    </div>
</div>
<div *ngIf="displayRequestResponseForm" class="form-group">
    <textarea aaAutosize [minRows]="5" [maxRows]="20" class="form-control" id="text" required [(ngModel)]="text"
        name="text"> </textarea>
    <div *ngIf="textInvalid" class="validation-fail">
        Required
    </div>
</div>
<div *ngIf="displayRequestResponseForm">
    <button type="button" class="button-cancel" (click)="reset()">Cancel</button>
    <button type="submit" class="button-save" (click)="onSave()">Send</button>
</div>

<modal modalClass="modal-lg " #searchModal (onClose)="closeSearch($event)">
    <modal-header>
        <h3>Search Responders</h3>
    </modal-header>
    <modal-content (click)="closeTooltip()">

        <div class="modal-body-responsive">
            <div class="form-group" *ngIf="responders.length > 0">
                <label class="responder-font" for="inputResponder">Responder</label>
                <label class="helper-text">Click or hover on responder's name to see details</label>
                <div class="row">
                    <div class="responder-tooltip" *ngFor="let responder of responders"
                        (mouseover)="toggleTooltipHover($event, responder)"
                        (mouseout)="toggleTooltipHover($event, responder)"
                        (click)="toggleTooltipClick($event, responder)">
                        <label class="responder">{{responder.lastName}}, {{responder.firstName}}
                            <i class="icon-medium icon-delete" (click)="removeResponder(responder)"
                                style="float: right; padding-left: 10px;"></i>
                            <span *ngIf="tooltipHover === responder.employeeId || tooltipClick === responder.employeeId"
                                class="tooltiptext">
                                <table>
                                    <tr>
                                        <td><i>Id:</i> </td>
                                        <td> <label> {{responder.employeeId}} </label> </td>
                                    </tr>
                                    <tr>
                                        <td><i>Role:</i> </td>
                                        <td> <label> {{responder.jobTitle}} </label> </td>
                                    </tr>
                                    <tr>
                                        <td><i>Dep.:</i> </td>
                                        <td> <label> {{responder.departmentDesc}} </label> </td>
                                    </tr>
                                    <tr>
                                        <td><i>Station:</i> </td>
                                        <td> <label> {{responder.stationCode}} </label> </td>
                                    </tr>
                                </table>
                            </span>
                        </label>
                    </div>
                </div>
            </div>
            <form formSearch [formGroup]="searchForm">
                <div class="row">
                    <div class="col-md-2 col-sm-4  col-xs-6">
                        <label class="" for="corpaa">Employee ID</label>
                        <input type="text" class="form-control" formControlName="searchFormControl" id="corpaa"
                            [(ngModel)]="corpaaId">
                        <label *ngIf="searchFormControl.errors && searchFormControl.dirty"
                            class="validation-fail">EmployeeID
                            accepts only numeric values.</label>
                    </div>
                    <div class="col-md-2 col-sm-4  col-xs-6">
                        <label class="" for="firstName">First Name</label>
                        <input type="text" class="form-control" formControlName="firstNameFormControl" id="firstName"
                            [(ngModel)]="firstName">
                        <label *ngIf="firstNameFormControl.errors && firstNameFormControl.dirty"
                            class="validation-fail">First name
                            accepts only letters, spaces and dashes.</label>
                    </div>
                    <div class="col-md-2 col-sm-4  col-xs-6">
                        <label class="" for="lastName">Last Name</label>
                        <input type="search" class="form-control" formControlName="lastNameFormControl" id="lastName"
                            [(ngModel)]="lastName">
                        <label *ngIf="lastNameFormControl.errors && lastNameFormControl.dirty"
                            class="validation-fail">Last name
                            accepts only letters, spaces and dashes</label>
                    </div>
                    <div class="col-md-2 col-sm-4  col-xs-6">
                        <label class="" for="jobTitle">Job Title</label>
                        <input type="text" class="form-control" formControlName="jobTitleFormControl" id="jobTitle"
                            [(ngModel)]="jobTitle">
                        <label *ngIf="jobTitleFormControl.errors && jobTitleFormControl.dirty"
                            class="validation-fail">Job title
                            name accepts only letters, spaces, numbers, and dashes</label>
                    </div>
                    <div class="col-md-2 col-sm-4  col-xs-6">
                        <label class="" for="departmentSearch">Department</label>
                        <input type="text" class="form-control" formControlName="departmentSearchFormControl"
                            id="departmentSearch" [(ngModel)]="department">
                    </div>
                    <div class="col-md-2 col-sm-4  col-xs-6">
                        <label class="" for="stationSearch">Station Code</label>
                        <input type="text" class="form-control" formControlName="stationSearchFormControl"
                            id="stationSearch" [(ngModel)]="stationCode">
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12 text-right">
                        <button type="submit" class="button-save search-btn" (click)="searchEmployees()">Search</button>
                    </div>
                </div>
            </form>
            <label *ngIf="isExist" class="validation-fail">Responder already added</label>
            <label *ngIf="timeoutError" class="validation-fail">Search result is too long. Please refine your search.
            </label>
            <app-aa-spinner [loading]="isLoading"></app-aa-spinner>
            <div class="employee-table" *ngIf="!isLoading && showSearch" style="width: 100%;">
                <table style="width: 100%;" datatable [dtOptions]="dtOptions"
                    class="reportHistory-table table table-hover table-striped">
                    <thead>
                    </thead>
                    <tbody>
                        <tr *ngFor="let employee of employees ">
                            <td>{{employee.employeeId}}</td>
                            <td>{{employee.lastName}}, {{employee.firstName}}</td>
                            <td>{{employee.jobTitle}}</td>
                            <td>{{employee.departmentDesc}}</td>
                            <td>{{employee.stationCode}}</td>
                            <td (click)="addEmployee(employee)"><a>Add</a></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </modal-content>
    <modal-footer>
        <button type="button" class="button-cancel" (click)="searchModal.close()">Close</button>
    </modal-footer>
</modal>