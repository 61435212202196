import { Pipe, PipeTransform } from '@angular/core';
import { ITemplateQuestion, ITemplateQuestions } from '../../category-management-store/template/template.model';
import * as _ from 'lodash';

@Pipe({ name: 'displayTemplateName' })
export class DisplayTemplateNamePipe implements PipeTransform {
    transform(value: string): string {
        if (value.toLowerCase().includes('mandatory-general')) {
            value = value.concat(' (Core General Questions)');
        }
        if (value.toLowerCase().includes('flight')) {
            value = value.concat(' (Core Flight Questions)');
        }
        return value.replace(/template-/i, '');
    }
}

@Pipe({ name: 'filterTemplateQuestions' })
export class FilterTemplateQuestionsPipe implements PipeTransform {
    transform(questions: Array<ITemplateQuestions>, selectedTemplate: string): Array<ITemplateQuestion> {
        if (questions && questions.length > 0) {
            const template = questions.filter(x => x.templateName === selectedTemplate)[0];
            return template && template.questions ? template.questions : [];
        }
    }
}

@Pipe({ name: 'displayTemplateSaveButton', pure: false })
export class DisplayTemplateSaveButtonPipe implements PipeTransform {
    transform(questions: Array<ITemplateQuestion>, selectedTemplate: string, existingTemplates: Array<string>): boolean {
        if (questions && questions.length > 0) {
            for (const question of questions) {
                if ((question.mappedQuestionMappingId > 0 && !question.isChecked) ||
                    (question.mappedQuestionMappingId < 1 && question.isChecked)) {
                    return true;
                }
            }
        }
        return false;
    }
}
