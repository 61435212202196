import { Action } from '@ngrx/store';
import { IReportDetail, IRecipientList, IActivityLog, IStatusList, IHistoryAuthorizations, Narrative } from './detail.model';

export const GET_REPORT_DETAIL = '[ReportHistory detail] getReportDetail';
export const GET_REPORT_SEARCH_DETAIL = '[ReportHistory detail] getReportSearchDetail';
export const GET_REPORT_DETAIL_SECTION_LOADER = '[ReportHistory detail] getReportDetailSectionLoader';
export const POPULATE_REPORT_DETAIL = '[ReportHistory detail] populateReportDetail';

export const GET_REPORT_STATUS_LIST = '[ReportHistory detail] getReportStatusList';
export const GET_REPORT_STATUS_LIST_ERROR = '[ReportHistory detail] getReportStatusListError';
export const POPULATE_REPORT_STATUS_LIST = '[ReportHistory detail] populatetReportStatusList';
export const SET_REPORT_STATUS = '[ReportHistory detail] setReportStatus';

export const ADD_NARRATIVE = '[ReportHistory detail] reportNarrative';
export const ADD_REPLY = '[ReportHistory detail] reportReply';
export const ADD_ATTACHMENT = '[ReportHistory detail] reportAttachemt';

export const GET_RECIPIENT_LIST = '[ReportHistory detail] getRecipientList';
export const POPULATE_RECIPIENT_LIST = '[ReportHistory detail] populateRecipientList';

export const GET_ACTIVITY_LOG = '[ReportHistory detail] getActivityLog';
export const GET_ACTIVITY_LOG_ERROR = '[ReportHistory detail] getActivityLogError';
export const POPULATE_ACTIVITY_LOG = '[ReportHistory detail] populateActivityLog';

export const SEND_RESPOND_TO_REQUEST = '[ReportHistory detail] sendRespondToReq';
export const SEND_REQUEST_RESPONSE = '[ReportHistory detail] sendRequestResponse';

export const SECTION_DETAIL_ERROR = '[ReportHistory detail] reportSectionError';
export const REPORT_DETAIL_ERROR = '[ReportHistory detail] reportDetailError';

export const GET_ADMIN_DISCUSSION = '[ReportHistory detail] getAdminDiscussion';
export const GET_ADMIN_DISCUSSION_ERROR = '[ReportHistory detail] getAdminDiscussionError';
export const POPULATE_ADMIN_DISCUSSION = '[ReportHistory detail] populateAdminDiscussion';

export const GET_REPORT_AUTH = '[ReportHistory detail] getReportAuth';
export const POPULATE_REPORT_AUTH = '[ReportHistory detail] populateReportAuth';

export const REPORT_DETAIL_RESET = '[ReportHistory detail] reportDetailReset';


export class RespondToRequest implements Action {
    readonly type = SEND_RESPOND_TO_REQUEST;
    constructor(public reportId: number, public respondObject: object) { }
}

export class RequestResponse implements Action {
    readonly type = SEND_REQUEST_RESPONSE;
    constructor(public reportId: number, public forwardObj: object) { }
}

export class GetAdminDiscussion implements Action {
    readonly type = GET_ADMIN_DISCUSSION;
    constructor(public reportId: number) { }
}

export class GetAdminDiscussionError implements Action {
    readonly type = GET_ADMIN_DISCUSSION_ERROR;
    constructor(public payload: string) { }
}

export class PopulateAdminDiscussion implements Action {
    readonly type = POPULATE_ADMIN_DISCUSSION;
    constructor(public payload: any) { }
}

export class GetReportAuthorization implements Action {
    readonly type = GET_REPORT_AUTH;
    constructor(public reportId: number, public loggedinUser: string) { }
}

export class PopulateReportAuthorization implements Action {
    readonly type = POPULATE_REPORT_AUTH;
    constructor(public payload: IHistoryAuthorizations) { }
}

export class SetReportStatus implements Action {
    readonly type = SET_REPORT_STATUS;
    constructor(public reportId: number, public reportStatus: number, public responderId: string) { }
}

export class GetReportDetail implements Action {
    readonly type = GET_REPORT_DETAIL;
    constructor(public reportId: number) { }
}

export class GetReportSearchDetail implements Action {
    readonly type = GET_REPORT_SEARCH_DETAIL;
    constructor(public employeeId: string, public reportIdOrEtn: string) { }
}

export class GetReportDetailSectionLoader implements Action {
    readonly type = GET_REPORT_DETAIL_SECTION_LOADER;
    constructor(public reportId: number) { }
}

export class PopulateReportDetails implements Action {
    readonly type = POPULATE_REPORT_DETAIL;
    constructor(public payload: IReportDetail) { }
}

export class GetReportStatusList implements Action {
    readonly type = GET_REPORT_STATUS_LIST;
    constructor() { }
}

export class GetReportStatusListError implements Action {
    readonly type = GET_REPORT_STATUS_LIST_ERROR;
    constructor(public payload: string) { }
}

export class PopulateReportStatusList implements Action {
    readonly type = POPULATE_REPORT_STATUS_LIST;
    constructor(public payload: IStatusList) { }
}

export class AddNarrative implements Action {
    readonly type = ADD_NARRATIVE;
    constructor(public narrativeObject: Narrative) { }
}

export class AddReply implements Action {
    readonly type = ADD_REPLY;
    constructor(public reportId: number, public replyObject: object) { }
}

export class AddAttachment implements Action {
    readonly type = ADD_ATTACHMENT;
    constructor(public reportId: number, public attachmentObject: object) { }
}

export class GetRecipientList implements Action {
    readonly type = GET_RECIPIENT_LIST;
    constructor(public reportId: number) { }
}

export class PopulateRecipientList implements Action {
    readonly type = POPULATE_RECIPIENT_LIST;
    constructor(public payload: IRecipientList) { }
}

export class GetActivityLog implements Action {
    readonly type = GET_ACTIVITY_LOG;
    constructor(public reportId: number) { }
}

export class GetActivityLogError implements Action {
    readonly type = GET_ACTIVITY_LOG_ERROR;
    constructor(public payload: string) { }
}

export class PopulateActivityLog implements Action {
    readonly type = POPULATE_ACTIVITY_LOG;
    constructor(public payload: IActivityLog[]) { }
}

export class ReportDetailError implements Action {
    readonly type = REPORT_DETAIL_ERROR;
    constructor(public payload: string, public notification?: string, public message?: string) { }
}

export class SectionDetailError implements Action {
    readonly type = SECTION_DETAIL_ERROR;
    constructor(public payload: any) { }
}

export class ReportDetailReset implements Action {
    readonly type = REPORT_DETAIL_RESET;
    constructor() { }
}


export type Actions
    = GetReportDetail
    | GetReportSearchDetail
    | GetReportDetailSectionLoader
    | PopulateReportDetails
    | GetReportAuthorization
    | PopulateReportAuthorization
    | RequestResponse
    | GetAdminDiscussion
    | GetAdminDiscussionError
    | PopulateAdminDiscussion
    | SetReportStatus
    | RespondToRequest
    | GetReportStatusList
    | GetReportStatusListError
    | PopulateReportStatusList
    | ReportDetailError
    | AddNarrative
    | AddReply
    | AddAttachment
    | GetRecipientList
    | PopulateRecipientList
    | GetActivityLog
    | GetActivityLogError
    | PopulateActivityLog
    | SectionDetailError
    | ReportDetailReset;
