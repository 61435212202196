import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';


import { SSEHubNotification } from './SSEHubNotification.service';
import { SSEHubNotificationService } from './notification.service';

@NgModule({
    declarations: [
    ],
    imports: [
        HttpClientModule,
    ],
    providers: [
        SSEHubNotification,
        SSEHubNotificationService
    ],
    exports: [
    ]
})

export class SSEHubNotificationModule {

}
