import { Injectable } from '@angular/core';

@Injectable()
export class QuestionMetaDataConstants {

    // these are the optional validation rules available for each respective question type

    public static dateMetaData = [
        { value: 'minDate', description: 'Min days', placeholder: 'Days before current date(e.g. 10)' },
        { value: 'maxDate', description: 'Max days', placeholder: 'Days after current date(e.g. 10)' },
    ];
    public static textMetaData = [
        { value: 'minLength', description: 'Min length', placeholder: 'Enter minimum length (e.g. 1)' },
        { value: 'maxLength', description: 'Max length', placeholder: 'Enter maximum length (e.g. 10)' },
        { value: 'uppercase', description: 'Uppercase', placeholder: 'Uppercase characters' },
        { value: 'naOption', description: 'NA option', placeholder: 'Display NA button' },
        { value: 'removeLeadingZeros', description: 'Remove leading zeros', placeholder: 'Remove leading zeros' }
    ];
    public static numericMetaData = [
        { value: 'minValue', description: 'Min value', placeholder: 'Enter a number(e.g. 10)' },
        { value: 'maxValue', description: 'Max value', placeholder: 'Enter a number(e.g. 10)' },
    ];
    public static involvementMetaData = [
        { value: 'maxInvolvements', description: 'Max involvements', placeholder: 'Enter a number(e.g. 10)' },
    ];
    public static questionGroupMetaData = [
        { value: 'maxQuestionGroups', description: 'Max allowed', placeholder: 'Enter a number(e.g. 10)' },
    ];
}

export class DataTypeConstants {
    // these are the data type dropdown options
    public static numericDataType = [
        { value: null, description: '' },
        { value: 'employeeId', description: 'Employee ID' },
    ];

    public static textboxDataType = [
        { value: null, description: '' },                   // regular textbox
        {
            value: 'stationCodes', description: 'Station Code',
            attrs: {
                'autoPopulateValuesKey': 'stationCodes',
                'disallowSpecialChars': true,
                'displayPropertyKey': 'DisplayLabel',
                'type': 'autopopulatetext',
                'valuePropertyKey': 'AirportId'
            }
        },   // station code textbox
        { value: 'additionalAAEmails', description: 'Additional Emails (AA Only)' },
        { value: 'additionalEmails', description: 'Additional Emails' }
    ];

    public static dropdownDataType = [
        { value: null, description: '' },
        { value: 'injuryBodyPart', description: 'Injured body part' },
        { value: 'injuryCause', description: 'Injury cause' },
        { value: 'injuryNature', description: 'Nature of injury' },
    ];

    public static dateDataType = [
        { value: null, description: '' },
        { value: 'eventDate', description: 'Event Date' },
    ];
}

export class DataTypeMetaDataConstants {
    // these are the optional rules for each dataType
    public static employeeId = [
        { value: 'disallowOwnEmployeeId', description: 'Disallow user\'s employee id' },
        { value: 'displayInjuries', description: 'Display employee injuries' },
    ];

    // these rules always need to be on when station data type is selected
    public static stationCode = [
        // for future use if we need some optional rules added later
        // this also prevents the textbox validation from showing up twice in admin
        { value: 'stationCodeValidations', description: 'Station code validations' },
    ];
    public static additionalEmailsMetaData = [
        { value: 'maxEmailLength', description: 'Max Email length', placeholder: 'Enter maximum length (e.g. 10)' }
    ];

    // this is used for filtering and all the above dataType optional rules should be appended here with spread notation.
    public static allDataTypeRules = [
        ...DataTypeMetaDataConstants.employeeId,
        ...DataTypeMetaDataConstants.stationCode,
        ...DataTypeMetaDataConstants.additionalEmailsMetaData
    ];
}
