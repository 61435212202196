import { Component, OnInit, ViewChild, ElementRef, OnDestroy, SecurityContext } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';
import { ResetSelectedCategory } from '../../new-report-store/list';
import {
    getSelectedCategory,
    getNotesPageStatus, getAttachments, getFlightPageStatus, getGeneralPageStatus, getWizardSessionLog, State, getAllQuestions
} from '@newreport/new-report-store';
import { IDynamicQuestion, WizardReset } from '../../new-report-store/wizard';
import { IUser } from '@app/store/user';
import { ReportAssemblyService } from '../../new-report.service';
import { IReportQuestionAnswer, IReportSubmitConfirmation, IReportImage } from '@models';
import { SSEHubNewReportService } from '@shared/SSEHubClient/new-report.service';
import { NotificationService } from 'app/shared/error-handler-notify/services/notification.service';
import { WizardService, IReportInfo } from '../wizard.service';
import { ConfirmCancelComponent } from '@wizardmodals/confirm/cancel/confirm-cancel.component';
import * as _ from 'lodash';
import { SubmittedReportService } from 'app/shared/report-services/submitted-report.service';
import { ICategory } from 'app/admin/category-management/category-management-store/list';
import { ResetTodo, IReportHistory, Reset } from 'app/report-history/report-history-store/list';
import { CanComponentDeactivate } from 'app/can-deactivate.guard';
import { ConfirmNavigateComponent } from '@wizardmodals/confirm/navigate/confirm-navigate.component';
import { environment as env } from 'environments/environment';
import { SSEHubCERSPortalNotificationService } from 'app/shared/SSEHubClient/notification.service';
import { SSEHubLogService } from 'app/shared/SSEHubClient/log.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
    selector: 'app-fill-out-report-wizard-summary',
    templateUrl: './summary.component.html',
    styleUrls: ['./steps.component.scss']
})

export class SummaryComponent implements OnInit, CanComponentDeactivate, OnDestroy {
    submissionFailureMessage = '';
    //generalQuestions: Array<IDynamicQuestion> = [];
    flightQuestions: Array<IDynamicQuestion> = [];
    allQuestions: Array<IDynamicQuestion> = [];
    notesQuestions: Array<IDynamicQuestion> = [];
    attachments: IReportImage[] = [];
    @ViewChild(ConfirmCancelComponent) confirmCancel: ConfirmCancelComponent;
    @ViewChild(ConfirmNavigateComponent) confirmNavigate: ConfirmNavigateComponent;
    @ViewChild('focusFlight') focusFlight: ElementRef;
    @ViewChild('focusQuestions') focusQuestions: ElementRef;
    @ViewChild('focusNotes') focusNotes: ElementRef;
    @ViewChild('submitFailureModal', { static: true }) public submitFailureModal;
    @ViewChild('offlineFailureModal', { static: true }) public offlineFailureModal;
    reportInfo$: Observable<IReportInfo>;
    reportQuestionsAnswers: Array<IReportQuestionAnswer> = [];
    reportReceipt: IReportSubmitConfirmation;
    reportSubmitted = false;
    isSubmitting = false;
    flightPageCompleted: boolean;
    questionsPageCompleted: boolean;
    notesPageCompleted: boolean;
    submitReportErrorCounter = 0;
    categoryDescription: string;
    offlineSubmission = false;
    cancellingReport = false;
    private resetTodo: IReportHistory;
    hasAnswers = {
        flight: false,
        generalQuestions: false,
        notes: false,
        attachmentsUploaded: false
    };
    navigateAwaySelection$: Subject<boolean> = new Subject<boolean>();
    printContent: {
        head: string,
        body: string
    };
    // variable to show or hide 'Print' and 'Information package'
    showLinks = false;
    destroy$: Subject<void> = new Subject<void>();
    confirmationText: any = null;
    reportIdRegex = '_!_REPORT_ID_REPLACE_!_';

    constructor(
        private store: Store<State>,
        private router: Router,
        private submitReport: ReportAssemblyService,
        private sseHubNewReportService: SSEHubNewReportService,
        private notificationService: NotificationService,
        private wizardService: WizardService,
        private reportDBService: SubmittedReportService,
        public newReportService: ReportAssemblyService,
        private ssehubNotificationService: SSEHubCERSPortalNotificationService,
        private logService: SSEHubLogService,
        private sanitizer: DomSanitizer
    ) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onNavigate(isExitting) {
        return this.navigateAwaySelection$.next(isExitting);
    }

    canDeactivate(route: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (this.newReportService.isStateReportDirty(this.flightQuestions, this.allQuestions, this.notesQuestions)
            && !this.reportSubmitted && !this.cancellingReport
            && !nextState.url.startsWith('/newreport/wizard')) {
            this.confirmNavigate.displayModal();
            return this.navigateAwaySelection$;
        }

        if (this.reportSubmitted &&
            (nextState.url.startsWith('/newreport/wizard/flight') || nextState.url.startsWith('/newreport/wizard/questions') || nextState.url.startsWith('/newreport/wizard/notes'))) {
            this.notificationService.showInfo('Click the New Report button to fill out a new report.');
            return false;
        }

        return true;
    }

    ngOnInit() {
        this.cancellingReport = false;
        this.reportInfo$ = this.wizardService.getReportInfo();

        this.store.select(getSelectedCategory).pipe(takeUntil(this.destroy$)).subscribe((cat: ICategory) => {
            if (cat && cat.description) {
                this.categoryDescription = cat.description;
            }
            if (cat?.attrs?.categoryConfirmationText) {
                this.confirmationText = cat.attrs.categoryConfirmationText;
            }
        });

        this.store.select(getFlightPageStatus).pipe(takeUntil(this.destroy$))
            .subscribe(status => this.flightPageCompleted = status === 'incomplete' ? false : true);
        this.store.select(getGeneralPageStatus).pipe(takeUntil(this.destroy$))
            .subscribe(status => this.questionsPageCompleted = status === 'incomplete' ? false : true);
        this.store.select(getNotesPageStatus).pipe(takeUntil(this.destroy$))
            .subscribe(status => this.notesPageCompleted = status === 'incomplete' ? false : true);

        // subscribe to all the section question
        // also determine if there are answered questions in each section.
        // this.store.select(getQuestions('flight')).pipe(takeUntil(this.destroy$)).subscribe(questions => {
        //     this.flightQuestions = questions;
        //     this.hasAnswers.flight = this.checkForAnswers(this.flightQuestions);
        // });

        this.store.select(getAllQuestions).pipe(
            map((questions: any) => {
                if (questions && questions.length > 0) {
                    this.allQuestions = questions

                    this.reportQuestionsAnswers.push(...questions);
                    this.hasAnswers.generalQuestions = this.checkForAnswers(this.allQuestions.filter(q => q.groupName.toUpperCase() == 'GENERAL'));
                    this.hasAnswers.flight = this.checkForAnswers(this.allQuestions.filter(q => q.groupName.toUpperCase() == 'FLIGHT'));
                    this.hasAnswers.notes = this.checkForAnswers(this.allQuestions.filter(q => q.groupName.toUpperCase() == 'NOTES'));


                    this.formatMultiInput(this.allQuestions);
                }
            }), take(1)).pipe(takeUntil(this.destroy$)).subscribe();

        this.store.select(getAttachments).pipe(takeUntil(this.destroy$)).subscribe(attachments => {
            this.attachments = new Array<IReportImage>();
            attachments.forEach((a) => {
                this.attachments.push({ 'image': a.imageString, 'fileName': a.fileName, 'description': a.description ? a.description : '' });
            });
            this.hasAnswers.attachmentsUploaded = this.attachments && this.attachments.length > 0 ? true : false;

        });
    }

    // this searches the input questions array to determine if any of the questions were answered
    // this is used when we're trying to determine whether or not to display the section
    checkForAnswers(questions): boolean {
        let answered = false;
        if (questions) {
            for (const question of questions) {
                if (question) {
                    const inputType = question.answerInputType ? question.answerInputType.toUpperCase() : null;
                    // if (inputType === 'MULTI') {
                    //     if (this.newReportService.extractMultiInputAnswers(question).length > 0) {
                    //         answered = true;
                    //         break;
                    //     }
                    // } else
                    if (question.userAnswer && inputType && inputType !== 'INVOLVEMENT' && inputType !== 'QUESTIONGROUP') {
                        answered = true;
                        break;  // breaks out of for loop and returns true
                    } else if (question.isChecked) {
                        answered = true;
                        break; // breaks out of for loop and returns true
                    }
                    if (question.followupQuestions) {
                        answered = this.checkForAnswers(question.followupQuestions);
                    }
                    if (inputType === 'CHECKBOX') {
                        answered = this.checkForAnswers(question.answers);
                    }
                    if (answered) {
                        break;
                    }
                }
            }
        }
        return answered;
    }

    formatMultiInput(questions) {
        if (questions) {
            for (const question of questions) {
                if (question) {
                    // if (question.answerInputType && question.answerInputType.toUpperCase() === 'MULTI' &&
                    //     question.attrs &&
                    //     question.attrs['dataType'] &&
                    //     question.attrs['dataType'].toUpperCase() === 'AMSADDRESS') {
                    //     question.userAnswer = this.newReportService.extractMultiInputAnswers(question);
                    // }
                    if (question.followupQuestions) {
                        this.formatMultiInput(question.followupQuestions);
                    }
                    if (question.answerInputType && question.answerInputType.toUpperCase() === 'CHECKBOX') {
                        this.formatMultiInput(question.answers);
                    }
                }
            }
        }
    }

    // this returns whether or not we need to display the section header (i.e. Flight, Questions, Notes) on the Summary page.
    // Returns true if ((there are questions answered) || (the section is incomplete i.e.there are required questions that are unanswered))
    displaySection(page: string): boolean {
        return (this.displayCompleteIcon(page) || this.displayIncompleteIcon(page));
    }

    // returns true if required questions have not been answered.
    displayIncompleteIcon(page: string) {
        const flightQuestions = this.allQuestions.filter(q => q.groupName.toUpperCase() == 'FLIGHT');
        const generalQuestions = this.allQuestions.filter(q => q.groupName.toUpperCase() == 'GENERAL');
        const notesQuestions = this.allQuestions.filter(q => q.groupName.toUpperCase() == 'GENERAL');
        switch (page) {
            case 'flight':
                return (flightQuestions && flightQuestions.length > 0) && !this.flightPageCompleted;
            case 'questions':
                return (generalQuestions && generalQuestions.length > 0) && !this.questionsPageCompleted;
            case 'notes':   // notes and attachments
                return ((notesQuestions && notesQuestions.length > 0) || this.attachments.length > 0) && !this.notesPageCompleted;
        }
    }

    // returns true if the page has at least one question that has been answered and all required questions have been answered
    displayCompleteIcon(page: string) {
        const flightQuestions = this.allQuestions.filter(q => q.groupName.toUpperCase() == 'FLIGHT');
        const generalQuestions = this.allQuestions.filter(q => q.groupName.toUpperCase() == 'GENERAL');
        const notesQuestions = this.allQuestions.filter(q => q.groupName.toUpperCase() == 'GENERAL');

        switch (page) {
            case 'flight':
                return flightQuestions && flightQuestions.length > 0 && this.flightPageCompleted && this.hasAnswers.flight;
            case 'questions':
                return (generalQuestions && generalQuestions.length > 0) && this.questionsPageCompleted && this.hasAnswers.generalQuestions;
            case 'notes':   // notes and attachments
                return ((notesQuestions && notesQuestions.length > 0) || this.attachments.length > 0) && this.notesPageCompleted &&
                    (this.hasAnswers.notes || this.hasAnswers.attachmentsUploaded);
        }
    }

    // this determines if the section being displayed before this section has any answered questions
    // this is needed for styling i.e. the spacing between the sections
    noTopMargin(page: string) {
        const pageOrderDictionary = {
            0: 'flight',
            1: 'questions',
            2: 'notes',
            3: 'attachments',
            flight: 0,
            questions: 1,
            notes: 2,
            attachments: 3
        };
        const answerMappingVariableDictionary = {
            flight: this.hasAnswers.flight,
            questions: this.hasAnswers.generalQuestions,
            notes: this.hasAnswers.notes,
            attachments: this.hasAnswers.attachmentsUploaded || this.hasAnswers.notes
        };
        const displaySectionVariableDictionary = {
            flight: this.displaySection('flight'),
            questions: this.displaySection('questions'),
            notes: this.displaySection('notes'),
            attachments: this.displaySection('notes')
        };
        for (let i = pageOrderDictionary[page] - 1; i >= 0 && displaySectionVariableDictionary[pageOrderDictionary[i]]; i--) {
            return !answerMappingVariableDictionary[pageOrderDictionary[i]];
        }
        return true;
    }

    // returns true if all required questions are answered
    // this is used to determine if we can submit the report
    allSectionsComplete(): boolean {
        let reportComplete = false;
        if (this.flightPageCompleted && this.questionsPageCompleted && this.notesPageCompleted) {
            reportComplete = true;
        }
        if (!reportComplete) {
            let errorText = '';
            let invalidSectionCounter = 0;
            if (!this.flightPageCompleted) {
                errorText += ' Flight';
                invalidSectionCounter++;
            }
            if (!this.questionsPageCompleted) {
                if (errorText) {
                    errorText += ',';
                }
                errorText += ' Questions';
                invalidSectionCounter++;
            }
            if (!this.notesPageCompleted) {
                if (errorText) {
                    errorText += ',';
                }
                errorText += ' Notes & Attachments';
                invalidSectionCounter++;
            }
            if (invalidSectionCounter > 1) {
                errorText = 'The following sections are incomplete: ' + errorText;
            } else {
                errorText = 'The' + errorText + ' section is incomplete.';

            }
            this.notificationService.showError(errorText);
        }
        return reportComplete;
    }

    submit() {
        if (this.allSectionsComplete()) {
            let info: IReportInfo;
            this.reportInfo$.pipe(take(1), takeUntil(this.destroy$)).subscribe(i => info = i);
            let user: IUser;
            this.wizardService.getUser().pipe(take(1), takeUntil(this.destroy$)).subscribe(u => user = u);
            // controls the spinner
            this.isSubmitting = true;

            try {
                // initialize assembly services properties
                this.submitReport.categoryId = info.categoryId;
                this.submitReport.title = info.categoryDescription;
                this.submitReport.categoryId = info.categoryId;

                this.submitReport.questions = this.reportQuestionsAnswers;
                // start the long process of converting -- Questions
                this.submitReport.ConvertQuestionsJson();

                this.submitReport.notes = this.notesQuestions;
                // start the long process of converting -- Notes & Recommendations

                this.submitReport.attachments = this.attachments;
                // start the long process of converting -- Attachment
                this.submitReport.ConvertAttachmentJson();

                this.submitReport.user = user;

                // ADDITIONAL EMAIL ATTRS
                // Check general question if they contain 'additionalAAEmails' or 'additionalEmails' attrs.
                // These attrs are for questions that contain a list of emails to forward report to. We must wait for report Id to forward email
                let emailRecipientList = this.submitReport.checkForAdditionalEmailsQuestions(this.submitReport.questions);

                const etemplate = this.getEmailReport();
                let emailTemplate = etemplate.emailTemplate;
                if (emailRecipientList === '') {
                    emailRecipientList = etemplate.paxEmailAddress ? etemplate.paxEmailAddress : '';
                } else {
                    if (etemplate.paxEmailAddress && etemplate.paxEmailAddress !== '') {
                        emailRecipientList = emailRecipientList + ';' + etemplate.paxEmailAddress;
                    }
                }

                // Flight, Questions, Notes/Recommendations, Attachment should get populated
                const report = this.submitReport.GetAssembledReport();

                this.createSummaryPrintContent();

                if (window.navigator.onLine) {
                    this.sseHubNewReportService.submitReport(report).pipe(takeUntil(this.destroy$)).subscribe(
                        (response) => {
                            this.isSubmitting = false;
                            if (response) {
                                this.reportReceipt = response;
                                this.reportSubmitted = true;

                                // Add report ID to CRO email
                                emailTemplate = emailTemplate.replace(this.reportIdRegex, this.reportReceipt.reportId.toString());

                                // check for session logs
                                this.checkUserSessionLog(this.reportReceipt.reportId.toString());

                                // clear this report from the new report store
                                this.store.dispatch(new WizardReset());

                                // clear the report history store so if the user navigates to report history the list will refresh to show this report
                                this.store.dispatch(new Reset());

                                // send out additional emails (to forward report) after successful submission
                                if (emailRecipientList) {
                                    this.ssehubNotificationService.sendReportNotification(
                                        this.reportReceipt.reportId.toString(), // report id
                                        emailRecipientList, // additional emails list
                                        emailTemplate, // summary page
                                        `${this.reportReceipt.title}  Report Submitted ${this.reportReceipt.reportId}`).pipe(takeUntil(this.destroy$))
                                        .subscribe(res => {
                                            if (res.responseMap.isEmailSent) {
                                                console.log('sent email successfully');
                                            } else {
                                                console.log('email not sent because the issue of ' + res.responseMap.email_Sent);
                                            }
                                        }); // subject line
                                }
                            }
                        }, (error) => {
                            this.isSubmitting = false;
                            console.log(error);
                            this.submitReportErrorCounter++;
                            this.submitFailureModal.open();
                        });
                } else if (window.matchMedia('(display-mode: standalone)').matches) {
                    // user is offline and we are in PWA mode, then save to database
                    this.reportDBService.saveSubmittedReport(report, this.categoryDescription).then((response) => {
                        this.reportSubmitted = true;
                        this.isSubmitting = false;
                        this.offlineSubmission = true;
                        // clear this report from the new report store
                        this.store.dispatch(new WizardReset());
                    }).catch(e => {
                        this.isSubmitting = false;
                        console.log(e);
                        this.submitReportErrorCounter++;
                        this.submitFailureModal.open();
                    });
                } else {
                    this.offlineFailureModal.open();
                    this.reportSubmitted = false;
                    this.isSubmitting = false;
                    console.log('cannot submit offline. reconnect to internet');
                }

                // this is what shows/hides the links needed for IOD
                if (this.submitReport.categoryId === 550) {
                    this.showLinks = true;
                }
            } catch (error) {
                this.isSubmitting = false;
                console.log(error.toString());
                this.submitReportErrorCounter = 1;
                this.submissionFailureMessage = error.toString();
                this.submitFailureModal.open();
            }
        } else {
            if (!this.flightPageCompleted) {
                this.focusFlight.nativeElement.scrollIntoView();
            } else {
                if (!this.questionsPageCompleted) {
                    this.focusQuestions.nativeElement.scrollIntoView();
                } else {
                    if (!this.notesPageCompleted) {
                        this.focusNotes.nativeElement.scrollIntoView();
                    }
                }
            }
        }

    }

    saveReport() {
        this.newReportService.saveUnfinishedReport();
    }

    previous() {
        this.router.navigate(['/newreport/wizard/notes']);
    }

    returnToStart() {
        this.store.dispatch(new ResetSelectedCategory());
        this.store.dispatch(new ResetTodo(this.resetTodo));
        this.router.navigate(['newreport']);
    }

    getEmailReport(): { emailTemplate: string, paxEmailAddress: string } {
        if (this.submitReport.title === 'Complaint Resolution Official Statement') {
            return this.getCROEmailReport();
        } else {
            return { emailTemplate: this.getDefaultEmailReport(), paxEmailAddress: '' };
        }
    }

    getDefaultEmailReport(): string {
        const styles = `h3 { font-size: 24px; line-height: 30px; color: #627A88; font-weight: 500; }
                        h1 { font-weight: 500; color: #0078d2; }
                        .childForm { margin-left: 25px; }
                        .image { max-width: 50px; vertical-align: middle; }
                        .title { font-size: 32px; color: #36495A; }
                        .questionItem { margin: 15px 0; }
                        .questionHeader { color: #36495A; font-weight: 600; margin: 0px; }
                        .questionValue { color: #36495A; display: block; margin: 0px; }
                        .subHeader { color: #0078d2; }`;
        const head = `<head><style>${styles}</style></head>`;
        const logo = `<div><img class="image"
                        src="${env.appUrl}/assets/icons/icon-email.png"><span class="title">CERS Report</span></div>`;
        const body = `<body>${logo}${document.getElementById('ReportContent').innerHTML}</body>`;
        let html = `<html>${head}${body}</html>`;
        // We are removing Angular meta code below (_ngcontent, _nghost, ng-star-inserted, and ng-reflect).
        // This is necessary as it makes, especifically, the IOD form too big to process.
        html = html.replace(/<!--[\s\S]*?-->|_ngcontent-[a-z0-9\-]*=""|_nghost-[a-z0-9\-]*=""|ng-star-inserted|ng-reflect-[a-z0-9\-]*="[a-z0-9\-]*"/g, '');
        html = html.replace(/ng-reflect-[a-z0-9\-]*="\[.*?\]"/g, '');
        return html;
    }

    private getCROId(paxFollowup: any[], text: string): number {
        const paxAttrIdIndex = paxFollowup.findIndex(q => q.questionText === text);
        return paxAttrIdIndex === -1 ? -1 : paxFollowup[paxAttrIdIndex].questionMappingID;
    }

    private getCROValue(pax: any[], qId: number): string {
        const paxAttrIndex = pax.findIndex(q => q.questionId === qId);
        const attrValue = paxAttrIndex === -1 ? '' : pax[paxAttrIndex].userAnswer;
        return attrValue ? attrValue : '';
    }

    private getCROTableShortRow(title: string, value: string): string {
        return '<tr><td style="border: 1px solid black; background: white; padding: 2px;"><b>' + title
            + '</b></td><td style="border: 1px solid black; background: white; padding: 2px;">'
            + (value === '' ? 'Not Available' : value) + '</td></tr>';
    }

    private getCROTableLongRow(title: string, value: string): string {
        return '<tr><td colspan="2" style="border: 1px solid black; background: white; padding: 2px;"><b>' + title
            + '</b></td></tr><tr><td colspan="2" style="border: 1px solid black; background: white; padding: 2px;">'
            + (value === '' ? 'Not Available' : value) + '</td></tr>';
    }

    getCROEmailReport(): { emailTemplate: string, paxEmailAddress } {
        const flight = this.submitReport.getFlightObject();
        const qs = this.submitReport.getQuestionJSON();
        const styles = 'h3 { font-size: 24px; line-height: 30px; color: #627A88; font-weight: 500; } h1 { font-weight: 500; color: #0078d2; } .title { font-size: 32px; color: #36495A; }'
            + ' .image { max-width: 50px; vertical-align: middle; } .container600 { width: 600px; } @media all and (max-width: 599px) { .container600 { width: 100%; } }';
        const title = '<title>Complaint Resolution Official Statement</title>';
        const head = `<head><meta charset = "utf-8" />${title}<style>${styles}</style></head>`;
        const logo = `<div><img class="image" src="${env.appUrl}/assets/icons/icon-email.png"><span class="title">CERS Report</span></div>`;
        const DNRMessage = '<p style="color: red;">Please do NOT reply to this notification. This email is only for notification purposes.</p>';
        const tableHeader = '<center><table><tbody><tr><td align="center"><p><h1>AA-AMERICAN AIRLINES</h1></p>'
            + '<p><h2>Complaint Resolution Official Statement</h2></p>'
            + '<p><h3>Report of complaint alleging violation of 14 CFR Part 382</h3></p></td></tr></tbody></table></center>';
        const flightNum = flight.flightNumber ? this.getCROTableShortRow('Flight Number', flight.flightNumber.toString()) : '';
        const departure = flight.departureStation ? this.getCROTableShortRow('Departure Station', flight.departureStation) : '';
        const arrival = flight.arrivalStation ? this.getCROTableShortRow('Planned Destination', flight.arrivalStation) : '';
        const today = new Date();
        const reportDate = this.getCROTableShortRow('Report Date', `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`);
        const flightInfo = `${reportDate}${flightNum}${departure}${arrival}`;
        const paxqIdIndex = this.submitReport.questions.findIndex((q: any) => q.questionText === 'Claimant');
        let paxName = '';
        let paxEmail = '';
        let paxAddress = '';
        let paxPhone = '';
        let pnr = '';
        let facts = '';
        let resolution = '';
        let violation = '';
        let section = '';
        let action = '';
        let satisfaction = '';
        let provided = '';
        if (paxqIdIndex !== -1) {
            const paxqId = this.submitReport.questions[paxqIdIndex].questionMappingId;
            const paxFollowup = this.submitReport.questions[paxqIdIndex]['followupQuestions'];
            const paxIndex = paxqId === -1 ? -1 : qs.findIndex(q => q.questionId === paxqId);
            const pax = paxIndex === -1 ? [] : qs[paxIndex].followupQuestions;
            const paxFNId = this.getCROId(paxFollowup, 'First Name');
            const paxLNId = this.getCROId(paxFollowup, 'Last Name');
            const paxAddr1Id = this.getCROId(paxFollowup, 'Address1');
            const paxAddr2Id = this.getCROId(paxFollowup, 'Address2');
            const paxCityId = this.getCROId(paxFollowup, 'City');
            const paxStateId = this.getCROId(paxFollowup, 'State');
            const paxCountryId = this.getCROId(paxFollowup, 'Country');
            const paxZipId = this.getCROId(paxFollowup, 'Zip');
            const paxPhoneId = this.getCROId(paxFollowup, 'Best contact phone');
            const paxPNRId = this.getCROId(paxFollowup, 'PNR');
            const factsId = this.getCROId(paxFollowup, 'Statement of Facts');
            const resolutionId = this.getCROId(paxFollowup, 'Resolution');
            const violationId = this.getCROId(paxFollowup, 'Did a violation occur?');
            const sectionId = this.getCROId(paxFollowup, 'Applicable Sections of Part 382');
            const actionId = this.getCROId(paxFollowup, 'Corrective Action Taken');
            const satisfactionId = this.getCROId(paxFollowup, 'Was the matter resolved to customer\'s satisfaction?');
            const providedId = this.getCROId(paxFollowup, 'How will the CRO report be provided to the customer?');
            const providedIdIndex = paxFollowup.findIndex(q => q.questionText === 'How will the CRO report be provided to the customer?');
            let emailIdIndex = -1;
            if (providedIdIndex !== -1 && paxFollowup[providedIdIndex].followupQuestions && paxFollowup[providedIdIndex].followupQuestions.findIndex) {
                emailIdIndex = paxFollowup[providedIdIndex].followupQuestions.findIndex(q => q.questionText === 'Specify customer\'s email address');
            }
            const paxEmailId = emailIdIndex === -1 ? -1 : paxFollowup[providedIdIndex].followupQuestions[emailIdIndex].questionMappingId;
            paxName = this.getCROValue(pax, paxFNId);
            paxName = paxName === 'NA' ? '' : paxName;
            let paxLN = this.getCROValue(pax, paxLNId);
            paxLN = paxLN === 'NA' ? '' : paxLN;
            if (paxName === '') {
                paxName = paxLN;
            } else {
                paxName = paxName + (paxLN === '' ? '' : ' ' + paxLN);
            }
            paxAddress = this.getCROValue(pax, paxAddr1Id);
            const paxAddr2 = this.getCROValue(pax, paxAddr2Id);
            paxAddress = paxAddr2 !== '' ? paxAddress + ', ' + paxAddr2 : paxAddress;
            const paxCity = this.getCROValue(pax, paxCityId);
            paxAddress = paxCity !== '' ? paxAddress + ', ' + paxCity : paxAddress;
            const paxState = this.getCROValue(pax, paxStateId);
            paxAddress = paxState !== '' ? paxAddress + ', ' + paxState : paxAddress;
            const paxZip = this.getCROValue(pax, paxZipId);
            paxAddress = paxZip !== '' ? paxAddress + ', ' + paxZip : paxAddress;
            const paxCountry = this.getCROValue(pax, paxCountryId);
            paxAddress = paxCountry !== '' ? paxAddress + ', ' + paxCountry : paxAddress;
            paxPhone = this.getCROValue(pax, paxPhoneId);
            paxEmail = this.getCROValue(pax, paxEmailId);
            pnr = this.getCROValue(pax, paxPNRId);
            facts = this.getCROValue(pax, factsId);
            resolution = this.getCROValue(pax, resolutionId);
            violation = this.getCROValue(pax, violationId);
            section = this.getCROValue(pax, sectionId);
            action = this.getCROValue(pax, actionId);
            satisfaction = this.getCROValue(pax, satisfactionId);
            provided = this.getCROValue(pax, providedId);
        }
        paxName = this.getCROTableShortRow('Passenger Name', paxName);
        const paxEmailAddress = paxEmail;
        paxEmail = this.getCROTableShortRow('Passenger Email', paxEmail);
        paxAddress = this.getCROTableShortRow('Passenger Address', paxAddress);
        paxPhone = this.getCROTableShortRow('Passenger Phone', paxPhone);
        pnr = this.getCROTableShortRow('PNR', pnr);
        const paxInfo = `${paxName}${paxEmail}${paxAddress}${paxPhone}${pnr}`;
        facts = this.getCROTableLongRow('Statement of Facts', facts);
        section = this.getCROTableLongRow('Applicable Sections of Part 382', section);
        const incidentInfo = `${facts}${section}`;
        violation = this.getCROTableShortRow('Did a violation occur?', violation);
        resolution = this.getCROTableLongRow('Resolution', resolution);
        action = this.getCROTableLongRow('Corrective Action Taken', action);
        const incidentResolution = `${violation}${resolution}${action}`;
        satisfaction = this.getCROTableShortRow('Was the matter resolved to the customer\'s satisfaction?', satisfaction);
        provided = this.getCROTableShortRow('Did the customer receive a copy of the CRO report?', provided);
        const reportId = this.getCROTableShortRow('Report ID (For Internal Use Only)', this.reportIdRegex);
        const postReport = `${satisfaction}${provided}${reportId}`;
        let reporterName = this.submitReport.user.firstName ? this.submitReport.user.firstName : '';
        if (reporterName === '') {
            reporterName = this.submitReport.user.lastName ? this.submitReport.user.lastName : '';
        } else {
            reporterName = this.submitReport.user.lastName ? (reporterName + ' ' + this.submitReport.user.lastName) : reporterName;
        }
        const CROName = this.getCROTableShortRow('CRO Name', reporterName);
        const CROSignature = '<tr><td colspan="2" style="border: 1px solid black; background: white; padding: 2px;"><b>CRO Signature</b></td></tr>';
        const commitment = '<p>We at AA-American Airlines are committed to providing each customer with exceptional customer service. If you have any additional concerns or questions, please contact the Consumer Relations Disability Hotline (1-800-892-3624).</p>';
        const noteToPax = '<p>Note to Passenger: You have the right to pursue this matter with the Department of Transportation.</p>';
        const CROInfo = `${CROName}${CROSignature}`;
        const tableBody = `<tbody>${flightInfo}${paxInfo}${incidentInfo}${incidentResolution}${postReport}${CROInfo}</tbody>`;
        const report = `${tableHeader}<br><table cellpadding="0" cellspacing="0">${tableBody}</table>`;
        const body = `<body><center><table class="container600" style="background: white; color: black; font-family: Arial,sans-serif;"><tr><td style="text-align: left;">${logo}<br>${report}${commitment}${noteToPax}${DNRMessage}</td></tr></table></center></body>`;
        const html = `<html>${head}${body}</html>`;
        return { emailTemplate: html, paxEmailAddress: paxEmailAddress };
    }

    createSummaryPrintContent() {
        let body = '';

        body = body + document.getElementById('ReportContent').innerHTML;
        // these two lines expand all sections so they are visible for print
        body = body.replace(/panel-collapse collapse(?!\s+in)/g, 'panel-collapse collapse in ');
        body = body.replace(/style="height: 0px;">/g, 'style="height: auto;">');
        // this hides any element with the hide-print class
        body = body.replace(/hide-print/g, 'hidden-group');
        const head = document.head.innerHTML + '<style type="text/css">i,a { display: none !important; }</style>';

        this.printContent = {
            head: head,
            body: body
        };
    }

    createCompletePrintContent(): string {
        // add this for Injury on Duty
        const iod = (this.categoryDescription === 'Injury on Duty') ? '<h4>Print 2 copies of this report. Employee signs both copies and keeps one copy.</h4><h4>***IMPORTANT*** Please also print a copy of the Injury-On-Duty Information Package. This document can also be found on the Workers compensation home page (under Jetnet Policies & Procedures tab).</h4>' : '';

        const category = '<h4 *ngIf="reportReceipt.title" class="categoryTitle"> Category: ' + this.reportReceipt.title + '</h4>';
        const reportId = '<h4 *ngIf="reportReceipt.reportId" class="categoryTitle" style="margin-bottom: 20px"> Report ID: ' + this.reportReceipt.reportId
            + '</h4>';
        return '<html><head>' + this.printContent.head + '</head><body onload=/"setTimeout(function(){ window.print(); }, 500)/">' + iod +
            category + reportId + this.printContent.body + '</body></html>';
    }

    printReportContent() {
        const popupWin = window.open('', '_blank');
        popupWin.document.open();
        popupWin.document.write(this.createCompletePrintContent());
        popupWin.document.close();
    }

    onReportCancel($event) {
        this.cancellingReport = $event;
    }

    checkUserSessionLog(reportId: string) {
        this.store.select(getWizardSessionLog).pipe(take(1)).toPromise().then((log) => {
            this.logService.logError({ source: 'cers web ' + reportId, url: 'cers.aa.com', message: JSON.stringify(log) });
        });
    }
}
