import * as newReportActions from './list.actions';
import { ICategory } from './list.model';

export interface CategoriesState {
    selected: ICategory;
    categories: {
        isLoading: boolean,
        hasError: boolean,
        list: ICategory[]
    };
}


export const initialState: CategoriesState = {
    categories: {
        isLoading: false,
        hasError: false,
        list: []
    },
    selected: null
};

export function reducer(state = initialState, action: newReportActions.Actions) {
    switch (action.type) {
        case newReportActions.LOAD_PROFILE_CATEGORIES:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    isLoading: true
                }
            };
        case newReportActions.LOAD_CATEGORIES_STATE:
            return {
                ...action.payload
            };
        case newReportActions.POPULATE_PROFILE_CATEGORIES:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    isLoading: false,
                    list: action.payload,
                }
            };
        case newReportActions.SET_SELECTED_CATEGORY:
            return {
                ...state,
                selected: action.payload
            };
        case newReportActions.RESET_SELECTED_CATEGORY:
            return {
                ...state,
                selected: null
            };
        case newReportActions.ERROR:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    hasError: true,
                    isLoading: false
                }
            };
        default:
            return state;
    }
}

export const getCategoriesListSelector = (state: CategoriesState) => {
    let categories = state.categories
    for (let category of categories.list) {
        category['IconExpand'] = false;
    }
    return categories;
};
export const getSelectedCategorySelector = (state: CategoriesState) => state.selected;
