<div class="table-container">
    <mat-table #table matSort [dataSource]="dataSource" >

        <ng-container matColumnDef="unfinishedReportName">
            <mat-header-cell class="cell cell-large" *matHeaderCellDef>
                <label class="header-row" mat-sort-header> Title </label>
            </mat-header-cell>
            <mat-cell *matCellDef="let tableData" class="cell cell-large" (click)="retrieveUnfinishedReport(tableData.unfinishedDate)" >
                <label class="trow">{{tableData.unfinishedReportName}}</label>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="unfinishedDate">
            <mat-header-cell *matHeaderCellDef class="cell cell-small">
                <label class="header-row" mat-sort-header> Saved date </label>
            </mat-header-cell>
            <mat-cell *matCellDef="let tableData" class="cell cell-small"  (click)="retrieveUnfinishedReport(tableData.unfinishedDate)" >
                <label class="trow"> {{tableData.unfinishedDate | date:'MM/dd/yyyy'}} </label>
            </mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions" >
                <mat-header-cell *matHeaderCellDef class="cell cell-small">
                    <label class="header-row" mat-sort-header></label>
                </mat-header-cell>
                <mat-cell *matCellDef="let tableData" class="cell cell-small">
                    <i (click)="deleteUnfinishedReport(tableData)" class="icon-medium icon-delete icon-center"></i>
                </mat-cell>
            </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" ></mat-row>
    </mat-table>


    <h3 *ngIf="!dataSource.data.length && !isParsingTable" class="text-center"> No results found </h3>
</div>
<app-aa-spinner [loading]="isParsingTable">
</app-aa-spinner>
<app-confirmation-popup #confirmDelete [title]="'Delete Unfinished Report'"
    [message]="'Are you sure you want to delete the selected report?'" (callAction)="deleteConfirmed()">
</app-confirmation-popup>
