import * as TemplateAction from './template.actions';
import { Question } from '@shared/common-ux/models/QuestionAnswer';
import { ITemplate, ITemplateQuestions } from './template.model';

export const initTemplate: ITemplate = {
    templateNames: {
        templates: new Array<string>(),
        isLoading: false,
        error: false
    },
    templateSelections: {
        selectedAnswer: null,
        existingTemplates: new Array<string>(),
    },
    templateQuestions: {
        questions: new Array<ITemplateQuestions>(),
        isLoading: false,
        error: false
    }
};

export function templateReducer(state = initTemplate, action: TemplateAction.TemplateActions): ITemplate {
    switch (action.type) {
        case TemplateAction.LOAD_TEMPLATE_NAMES:
            return {
                ...state,
                templateNames: {
                    ...state.templateNames,
                    isLoading: true
                }
            };
        case TemplateAction.POPULATE_TEMPLATE_NAMES:
            return {
                ...state,
                templateNames: {
                    ...state.templateNames,
                    templates: action.payload,
                    isLoading: false
                }
            };
        case TemplateAction.LOAD_TEMPLATE_QUESTIONS:
            return {
                ...state,
                templateQuestions: {
                    ...state.templateQuestions,
                    isLoading: true
                }
            };
        case TemplateAction.POPULATE_TEMPLATE_QUESTIONS:
            return {
                ...state,
                templateQuestions: {
                    ...state.templateQuestions,
                    questions: action.payload,
                    isLoading: false
                }
            };
        case TemplateAction.POPULATE_SELECTIONS_FOR_TEMPLATE:
            return {
                ...state,
                templateSelections: {
                    ...state.templateSelections,
                    selectedAnswer: action.payload.selectedAnswer,
                    existingTemplates: action.payload.existingTemplates
                }
            };
        case TemplateAction.TEMPLATE_NAME_ERROR:
            return {
                ...state,
                templateNames: {
                    ...state.templateNames,
                    error: action.payload,
                    isLoading: false
                }
            };
        default:
            return state;
    }
}

export const getTemplateNames = (state: ITemplate) => state.templateNames;
export const getExistingTemplates = (state: ITemplate) => state.templateSelections.existingTemplates;
export const getTemplateQuestions = (state: ITemplate) => state.templateQuestions.questions;
export const getSelectedAnswer = (state: ITemplate) => state.templateSelections.selectedAnswer;
