import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, } from '@angular/common/http';
import { SSEHubClient } from './SSEHubClient.service';


@Injectable()
export class DynamicQuestionsService {
    constructor(
        private http: HttpClient,
        private sseHubClient: SSEHubClient,
    ) {

    }
    getQuestions(): Observable<any> {
        return this.http.get(this.sseHubClient.serviceUrl() + '/api/Questions');
    }

    getAnswers(questionMappingId): Observable<any> {
        return this.http.get(this.sseHubClient.serviceUrl() + '/api/Answers/' + encodeURIComponent(questionMappingId));
    }

    getTemplateNames(): Observable<any> {
        return this.http.get(this.sseHubClient.serviceUrl() + '/api/templateNames/');
    }

    getTemplateQuestions(profileId, categoryId, groupName, answerId): Observable<any> {
        return this.http.get(this.sseHubClient.serviceUrl() + '/api/profiles/' + profileId + '/categories/'
            + categoryId + '/group/' + groupName + '/answers/' + answerId + '/templateQuestions');
    }

    loadRootQuestions(profileId, categoryId, groupName): Observable<any> {
        //  address example "/api/profiles/7/categories/550/rootQuestionWithAnswers?groupName=Flight"
        return this.http.get(this.sseHubClient.serviceUrl() + '/api/profiles/' + profileId + '/categories/' + categoryId
            + '/rootQuestionsWithAnswers?groupName=' + encodeURIComponent(groupName));
    }

    loadFollowUpQuestions(profileId, categoryId, groupName, answerId): Observable<any> {
        return this.http.get(this.sseHubClient.serviceUrl() + '/api/profiles/' + profileId + '/categories/'
            + categoryId + '/answers/' + answerId + '/followupQuestionsWithAnswers?groupName=' + encodeURIComponent(groupName));
    }

    createNewQuestion(question): Observable<any> {
        return this.http.post(this.sseHubClient.serviceUrl() + '/api/Questions', question);
    }

    editQuestion(question): Observable<any> {
        return this.http.put(this.sseHubClient.serviceUrl() + '/api/Questions', question);
    }

    getQuestionsMetaData(questionMappingId): Observable<any> {
        return this.http.get(this.sseHubClient.serviceUrl() + '/api/QuestionMapping/' + questionMappingId + '/questionMetadata');
    }

    getAnswerMetadata(answerId): Observable<any> {
        return this.http.get(this.sseHubClient.serviceUrl() + '/api/Answer?answerId=' + answerId);
    }

    updateQuestionsMetaData(questionMappingId, questionMetaData): Observable<any> {
        return this.http.put(this.sseHubClient.serviceUrl() + '/api/QuestionMapping/' + questionMappingId + '/questionMetadata', questionMetaData);
    }

    updateDisplayOrder(displayOrderMetadata): Observable<any> {
        return this.http.put(this.sseHubClient.serviceUrl() + '/api/Questions/DisplayOrder', displayOrderMetadata);
    }

    addQuestion(profileId, categoryId, questionMetadata): Observable<any> {
        return this.http.post(this.sseHubClient.serviceUrl() + '/api/profiles/' + profileId
            + '/categories/' + categoryId + '/questionMapping', questionMetadata);
    }

    addTemplateQuestions(answerMappingId, templateMetadata): Observable<any> {
        return this.http.post(this.sseHubClient.serviceUrl() + '/api/answerMappings/' + answerMappingId
            + '/followupMappings', templateMetadata);
    }

    addAnswer(answer): Observable<any> {
        return this.http.post(this.sseHubClient.serviceUrl() + '/api/Answers', answer);
    }

    updateAnswer(answer): Observable<any> {
        return this.http.put(this.sseHubClient.serviceUrl() + '/api/Answer/Answermapping', answer);
    }

    addTagMapping(questionMappingId, tagVal): Observable<any> {
        const encodedTagVals = tagVal.map(tag => encodeURIComponent(tag));
        const url = `${this.sseHubClient.serviceUrl()}/api/tags/mapTag?QuestionMappingId=${encodeURIComponent(questionMappingId)}`;
        return this.http.put(url, JSON.stringify(encodedTagVals), {headers: { 'Content-Type': 'application/json' }});  
    }

    removeTagMapping(questionMappingId: string, tagVal: string): Observable<any> {
        const url = `${this.sseHubClient.serviceUrl()}/api/tags/removeTagMapping?QuestionMappingId=${encodeURIComponent(questionMappingId)}&tag=${encodeURIComponent(tagVal)}`;
        return this.http.post(url, {});
    }

}
