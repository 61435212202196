import { Component, ViewChild } from '@angular/core';

@Component({
  selector: 'app-notification-view',
  templateUrl: './notification-view.component.html',
  styleUrl: './notification-view.component.scss'
})
export class NotificationViewComponent {
  isNotificationModalOpened : boolean = false;

  constructor() { }

  openNotificationModal() {
    this.isNotificationModalOpened = true;
  }
  closeNotificationModal() {
    this.isNotificationModalOpened = false;
  }
}