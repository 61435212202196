import { IUser } from './user.model';
import * as userActions from './user.actions';

export interface UserStateModel {
    user: IUser;
    proxyBackup: IUser;
    proxyMode: boolean;
}

export const initialState: UserStateModel = {
    user: null,
    proxyBackup: null,
    proxyMode: null
};

export function reducer(state = initialState, action: userActions.Actions) {
    switch (action.type) {
        case userActions.POPULATE:
            return {
                ...state,
                user: action.payload,
                proxyBackup: null,
                proxyMode: false
            };

        case userActions.PROXY_ADD:
            return {
                ...state,
                proxyBackup: state.user,
                user: action.payload,
                proxyMode: true
            };
        case userActions.PROXY_REMOVE:
            return {
                ...state,
                user: state.proxyBackup,
                proxyBackup: null,
                proxyMode: false
            };
        default:
            return state;
    }
}

// SELECTORS
export const getUser = state => state.auth.user;
export const getProxyMode = state => state.auth.proxyMode;
export const getProxyBackup = state => state.auth.proxyBackup;
