<div class="dynamicInputDiv" [ngModelGroup]="question.questionMappingId">
    <div class="errorBox">
        <label *ngIf="!(question.hyperLinks && question.hyperLinks.length >0)">
            <i *ngIf="questionAttrs['isRequired']" class="icon-cers-required"></i>
            {{ question.questionText | sentenceCasePipe }}
        </label>
        <label *ngIf="question.hyperLinks && question.hyperLinks.length >0">
            <app-question-with-hyperlinks [hyperlinks]="question.hyperLinks"
                [questionText]="question.displayQuestionText"></app-question-with-hyperlinks>
        </label>
        <!-- formerly class form-control-->
        <div class="form-check-padding">
            <div class="form-check" *ngFor="let answer of question.answers">
                <input type="radio" id="{{question.questionMappingId}}-{{answer.answerId}}" [value]="answer"
                    class="form-check-input" [(ngModel)]="question.userAnswer" name="{{question.questionText}}"
                    (change)="onChange(answer)" [appDynamicValidation]="questionAttrs" #form="ngModel"
                    [appFocus]="{questionId: question.questionId, questionMappingId: question.questionMappingId}">
                <label class="form-check-label"
                    for="{{question.questionMappingId}}-{{answer.answerId}}">{{answer.answerText}}</label>
                <br />
                <div *ngFor="let error of form.errors | keyvalue">
                    <label class="errorText" *ngIf="error.key !== 'isRequired'"> {{error.value}} </label>
                </div>
                <div *ngIf="showReload()">
                    <a id="recall" (click)="onChange(answer)">Click here to reload follow-up questions</a>
                </div>
            </div>
        </div>
    </div>