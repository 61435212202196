import { NgModule, Optional, SkipSelf, ModuleWithProviders, ErrorHandler } from '@angular/core';
import { APP_INITIALIZER } from '@angular/core';

import { UserService } from '@shared/user/user.service';
import { ErrorHandlerService } from '@shared/error-handler/error-handler.service';
import { SSEHubClientModule } from '@shared/SSEHubClient/SSEHubClient.module';
import { SSEHubNotificationModule } from '@shared/SSEHubNotification/SSEHubNotification.module';
import { UserResolver } from './shared/user-resolver/user-resolver.service';
import { AutosizeDirective } from '@shared/common-ux/components/aa-textarea-autosize/aa-textarea-autosize.directive';
import { GlobalErrorHandler } from './shared/error-handler-notify/services';

@NgModule({
  imports: [
    SSEHubClientModule,
    SSEHubNotificationModule
  ],
  declarations: [],
  exports: [],
  providers: []
})
export class CoreModule {

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        UserService,
        UserResolver,
        ErrorHandlerService,
        AutosizeDirective,
        { provide: ErrorHandler, useClass: GlobalErrorHandler }
      ]
    };
  }
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
