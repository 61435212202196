<ol class="breadcrumb">
    <li><a routerLink="/home"><i class="icon-home"></i> Home</a></li>
    <li>Admin</li>
    <li>Category management</li>
</ol>
<h1>Category management</h1>

<div class="profile-list-container" style="padding-top: 1em;">
    <div>
        <label>Select a profile to view categories:</label>
    </div>
    <div>
        <select name="profileSelect" id="profileSelect" class="form-control dropdown" (change)="selectionChanged()"
            [(ngModel)]="selectedProfile">
            <option *ngFor="let profile of (profileList$ | async)" id="{{profile.profileDescription}}"
                [ngValue]="profile">
                {{profile.profileDescription}}
            </option>
        </select>
    </div>
</div>

<div>
    <app-aa-spinner [loading]="(categories$ | async).isByProfileLoading"></app-aa-spinner>
    <div class="admin-buttons">
        <div class="tag-management" *ngIf="hasAdminAccess">
            <i class="icon-small icon-add"></i>
            <a routerLink="/admin/categorymanagement/tagmanagement">Tag management</a>
        </div>

        <div *ngIf="!(categories$ | async).isByProfileLoading" (click)="loadAllCategories()" class="add-category">
            <app-open-modal-button *ngIf="hasEditAccess" [title]="'Add category'" [modal]="addCategoryForProfile"
                [profileId]=(selectedProfile$) [iconClass]="'icon-small icon-add'"></app-open-modal-button>
        </div>
    </div>

    <table *ngIf="!(categories$ | async).isByProfileLoading" class="category-table table table-hover table-striped">
        <thead>
            <tr>
                <th>Category Description</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let category of (categoriesByProfile$ | async)">
                <td (click)="gotoCategoryDetail(category)">{{category.description}}</td>
                <td (click)="setCategorySelection(category)">
                    <app-open-modal-button *ngIf="hasEditAccess" [title]="''" [modal]="confirmCategoryRemove"
                        [profileId]=(selectedProfile$) [iconClass]="'icon-small icon-delete'"></app-open-modal-button>
                </td>
                <td (click)="gotoCategoryDetail(category)"><a class="category-hyperlink">View/Edit</a></td>
                <td (click)="updateCategoryActive(category)"><a class="category-hyperlink"> {{ category.categoryActive ?
                        "Deactivate" : "Activate"
                        }} </a>
                </td>
                <td (click)="setCategorySelectionForCopyCategory(category)">
                    <app-open-modal-button *ngIf="hasEditAccess" [title]="'Copy'" class="category-hyperlink"
                        [modal]="copyCategoryForProfile" [profileId]=(selectedProfile$)></app-open-modal-button>
                </td>
            </tr>
        </tbody>
    </table>

    <div *ngIf="(categoriesByProfile$ | async).length == 0 && !(categories$ | async).isByProfileLoading">
        No categories to show for the selected profile.
    </div>

    <app-confirmation-popup #confirmCategoryRemove [title]="'Remove Category'"
        [message]="'Are you sure you want to remove the following category?'" (callAction)="onCategoryDelete()">
    </app-confirmation-popup>

    <modal #addCategoryForProfile>
        <modal-header>
            <h4>Add Category</h4>
        </modal-header>
        <modal-content>
            <app-aa-spinner [loading]="(categories$ | async).isAllLoading"></app-aa-spinner>
            <div class="row">
                <label for="table-complete-search" class="col-sm-auto col-form-label">Search Category:</label>
                <div class="col-sm-auto">
                    <input id="table-complete-search" type="text" class="form-control" name="searchText"
                        [(ngModel)]="searchText" (keyup)="refreshCategories()" />
                </div>
            </div>
            <div *ngIf="categoriesAll">
                <table class="table table-hover table-striped">
                    <thead>
                        <tr>
                            <th>Category Description</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let category of categoriesAll">
                            <td>{{category.description}}</td>
                            <td (click)="addCategories(category)"><a>Add</a></td>
                        </tr>
                    </tbody>
                </table>
                <div class="d-flex justify-content-between p-2">
                    <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize"
                        [maxSize]="3" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"
                        (pageChange)="refreshCategories()">
                    </ngb-pagination>
                    <div class="page-items">
                        <label for="pageSize">Items per page: </label>
                        <select class="form-select" style="width: auto" name="pageSize" [(ngModel)]="pageSize"
                            (ngModelChange)="refreshCategories()">
                            <option [ngValue]="10">10</option>
                            <option [ngValue]="25">25</option>
                            <option [ngValue]="50">50</option>
                            <option [ngValue]="100">100</option>
                        </select>
                    </div>
                </div>
            </div>
        </modal-content>
        <modal-footer>
            <button type="button" class="btn btn-cancel" (click)="addModalClose()">Close</button>
            <button type="button" class="btn btn-primary create" (click)="createCategoryModal()">Create
                category</button>
        </modal-footer>
    </modal>

    <modal #copyCategoryForProfile>
        <modal-header>
            <h4>Copy Category</h4>
        </modal-header>
        <modal-content>
            <app-aa-spinner [loading]="isCopying"></app-aa-spinner>
            <div class="profile-list-container" style="padding-top: 1em;">
                <div>
                    <label>Select a target profile to copy to</label>
                </div>
                <div>
                    <select name="profileSelectCopyCategory" id="profileSelectCopyCategory"
                        class="form-control dropdown" [(ngModel)]="selectedProfileCopyCategory">
                        <option *ngFor="let profile of (profileList$ | async)" [ngValue]="profile">
                            {{profile.profileDescription}}
                        </option>
                    </select>
                </div>
            </div>
        </modal-content>
        <modal-footer>
            <button type="button" class="btn btn-cancel" (click)="copyCategoryForProfile.close()">Close</button>
            <button type="button" class="btn btn-primary leftMargin" (click)="onCopyCategory()">Copy</button>
            <label>
                <p>{{responseMessage}}</p>
            </label>
        </modal-footer>
    </modal>

    <modal #createCategoryForProfile>
        <modal-header>
            <h4>Create Category</h4>
        </modal-header>
        <modal-content>
            <div class="create-category-container">
                <div *ngIf="createCategoryStatus.loading" class="loader-center">
                    <!-- for some reason calling the aa-spinner component will not work here. -->
                    <div style="padding-bottom: 10px; padding-left: 10px;"><img src='assets/spinner.gif'></div>
                </div>
                <div *ngIf="createCategoryStatus.success">
                    <h4><i class="icon-circle-check"></i>New category was created successfully</h4>
                </div>
                <div class="form-container" *ngIf="!createCategoryStatus.loading && !createCategoryStatus.success">
                    <span>Enter category name:</span>
                    <input type="text" class="form-control" [(ngModel)]="createCategoryDescription">
                </div>
                <div class="errorText" *ngIf="createCategoryStatus.error.length > 0">
                    <span>{{createCategoryStatus.error}}</span>
                </div>
            </div>
        </modal-content>
        <modal-footer>
            <button type="button" class="btn btn-secondary" (click)="closeCreateModal()">Close</button>
            <button type="button" class="btn btn-primary" (click)="createNewCategory()"
                *ngIf="!createCategoryStatus.success">Save</button>
        </modal-footer>
    </modal>
</div>