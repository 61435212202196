import { Pipe, PipeTransform } from '@angular/core';

// this pipe is used to determine if either the hideFromReportDetail or the hideFollowupFromReportDetail
// attributes exist in the json attributes string

@Pipe({ name: 'hideFromReportDetailPipe' })
export class HideFromReportDetailPipe implements PipeTransform {
    transform(rules: any): boolean {
        if (rules) {
            const parsedRules = rules || {};
            return !(parsedRules.hasOwnProperty('hideFromReportDetail') || parsedRules.hasOwnProperty('hideFollowupFromReportDetail')
                || (parsedRules.dataType && parsedRules.dataType.toLowerCase().includes('emails')));
        }
        return true;
    }
}
