import { Action } from '@ngrx/store';
import { IQuestion, IFlightSequence, PageStatusTypes, NotesSectionModel, SessionLog } from './wizard.model';
import { CategoryDetails } from 'app/shared/reportStructure/categoryDetails/reportSections';
import { IError } from 'app/shared/common-ux/models/Error';
import { Attachment } from 'app/report-history/report-history';
import { NewReportWizardQuestionsModel, NewReportWizardSectionDetailModel } from '.';

// WIZARD
export const LOAD_SECTION_DETAIL = '[NewReportWizard] loadSectionDetail';
export const POPULATE_SECTION_DETAIL = '[NewReportWizard] populateSectionDetail';
export const WIZARD_RESET = '[NewReportWizard] wizardReset';
export const WIZARD_UPDATE = '[NewReportWizard] wizardUpdate';
export const WIZARD_LOAD = '[NewReportWizard] wizardLoad';
export const ERROR_SECTION = '[NewReportWizard] errorSection';
export const LOAD_WIZARD_QUESTIONS = '[NewReportWizard] loadWizardQuestions';
export const LOAD_WIZARD_SECTION = '[NewReportWizard] loadWizardSection';
export const WIZARD_SESSION_LOG = '[NewReportWizard] wizardSessionLog';
export const LOAD_ALL_QUESTIONS_AND_ANSWERS = '[NewReportWizard] loadAllQuestionsAndAnswers';

// FLIGHT QUESTIONS
export const ERROR_FLIGHT = '[NewReportWizardFlight] errorFlight';
export const LOAD_CREW_SEQUENCES = '[NewReportWizardFlight] loadCrewSequences';
export const POPULATE_CREW_SEQUENCES = '[NewReportWizardFlight] populateCrewSequences';
export const CHANGE_FLIGHT = '[NewReportWizardFlight] changeFlightSelection';
export const UPDATE_FLIGHT_PAGE_STATUS = '[NewReportWizardFlight] updateFlightPageStatus';
export const UPDATE_FLIGHT_SELECTION = '[NewReportWizardFlight] updateFlightSelection';

// GENERAL QUESTIONS
export const RESET_GENERAL_QUESTIONS = '[NewReportWizardQuestions] resetGeneralQuestions';
export const UPDATE_QUESTIONS_BY_PARENT_ANSWER = '[NewReportWizardQuestions] updateQuestionsByParentAnswer';
export const UPDATE_QUESTIONS = '[NewReportWizardQuestions] updateQuestions';
export const LOAD_ALL_QUESTIONS = '[NewReportWizardQuestions] loadAllQuestions';
export const POPULATE_ALL_QUESTIONS_AND_PAGE_STATUS = '[NewReportWizardQuestions] populateGeneralQuestionsAndPageStatus';
export const ERROR_QUESTIONS = '[NewReportWizardQuestions] errorQuestions';
export const UPDATE_GENERAL_QUESTIONS_PAGE_STATUS = '[NewReportWizardQuestions] updateGeneralQuestionsPageStatus';
export const UPDATE_INCIDENT_STATION = '[NewReportWizardQuestions] updateIncidentStation';

export const ADD_ATTACHMENT = '[NewReportWizardNotes] addAttachment';
export const REMOVE_ATTACHMENT = '[NewReportWizardNotes] removeAttachment';
export const LOAD_ATTACHMENT_SETTINGS = '[NewReportWizardNotes] loadAttachmentSettings';
export const POPULATE_ATTACHMENT_SETTINGS = '[NewReportWizardNotes] populateAttachmentSettings';
export const ERROR_NOTES = '[NewReportWizardNotes] errorNotes';
export const UPDATE_NOTES_PAGE_STATUS = '[NewReportWizardQuestions] updateNotesPageStatus';


export class LoadCrewSequences implements Action {
    readonly type = LOAD_CREW_SEQUENCES;
    constructor() { }
}
export class PopulateCrewSequences implements Action {
    readonly type = POPULATE_CREW_SEQUENCES;
    constructor(public payload?: { options: Array<IFlightSequence>, userAnswer: IFlightSequence }) { }
}
export class ChangeFlight implements Action {
    readonly type = CHANGE_FLIGHT;
    constructor(public payload: { isFlight: boolean }) { }
}
export class UpdateFlightSelection implements Action {
    readonly type = UPDATE_FLIGHT_SELECTION;
    constructor(public payload: IFlightSequence) { }
}
export class LoadSectionDetail implements Action {
    readonly type = LOAD_SECTION_DETAIL;
    constructor(public profileId: number, public categoryId: number) { }
}
export class PopulateSectionDetail implements Action {
    readonly type = POPULATE_SECTION_DETAIL;
    constructor(public payload: CategoryDetails) { }
}
export class ErrorSection implements Action {
    readonly type = ERROR_SECTION;
    constructor(public payload?: IError) { }
}
export class WizardReset implements Action {
    readonly type = WIZARD_RESET;
    constructor() { }
}
export class ResetGeneralQuestions implements Action {
    readonly type = RESET_GENERAL_QUESTIONS;
    constructor() { }
}
export class UpdateQuestionsByParentAnswer implements Action {
    readonly type = UPDATE_QUESTIONS_BY_PARENT_ANSWER;
    constructor(public payload: { groupName: string, parentAnswer: any, questions: IQuestion[] }) { }
}
export class UpdateQuestions implements Action {
    readonly type = UPDATE_QUESTIONS;
    constructor(public questions: IQuestion[]) { }
}
export class WizardUpdate implements Action {
    readonly type = WIZARD_UPDATE;
    constructor() { }
}
export class WizardLoad implements Action {
    readonly type = WIZARD_LOAD;
    constructor() { }
}
export class WizardSessionLog implements Action {
    readonly type = WIZARD_SESSION_LOG;
    constructor(public payload: SessionLog) { }
}
export class LoadWizardQuestions implements Action {
    readonly type = LOAD_WIZARD_QUESTIONS;
    constructor(public payload: NewReportWizardQuestionsModel) { }
}
export class LoadWizardSection implements Action {
    readonly type = LOAD_WIZARD_SECTION;
    constructor(public payload: NewReportWizardSectionDetailModel) { }
}
export class LoadAllQuestionsAndAnswers implements Action {
    readonly type = LOAD_ALL_QUESTIONS_AND_ANSWERS;
    constructor(public profileId: number, public categoryId: number) { }
}
export class ErrorFlight implements Action {
    readonly type = ERROR_FLIGHT;
    constructor(public payload?: IError) { }
} export class UpdateFlightPageStatus implements Action {
    readonly type = UPDATE_FLIGHT_PAGE_STATUS;
    constructor(public payload: PageStatusTypes) { }
}
export class LoadAllQuestions implements Action {
    readonly type = LOAD_ALL_QUESTIONS;
    constructor(public profileId: number, public categoryId: number) { }
}
export class PopulateAllQuestionsAndPageStatus implements Action {
    readonly type = POPULATE_ALL_QUESTIONS_AND_PAGE_STATUS;
    constructor(public payload: { questions: Array<IQuestion>, pageStatuses: { flight: PageStatusTypes, general: PageStatusTypes, notes: PageStatusTypes } }) { }
}
export class ErrorQuestions implements Action {
    readonly type = ERROR_QUESTIONS;
    constructor(public payload?: IError) { }
}
export class UpdateGeneralQuestionsPageStatus implements Action {
    readonly type = UPDATE_GENERAL_QUESTIONS_PAGE_STATUS;
    constructor(public payload: PageStatusTypes) { }
}
export class UpdateIncidentStation implements Action {
    readonly type = UPDATE_INCIDENT_STATION;
    constructor(public payload: string) { }
}
export class AddAttachment implements Action {
    readonly type = ADD_ATTACHMENT;
    constructor(public payload: Attachment) { }
}
export class RemoveAttachment implements Action {
    readonly type = REMOVE_ATTACHMENT;
    constructor(public payload: Attachment) { }
}
export class LoadAttachmentSettings implements Action {
    readonly type = LOAD_ATTACHMENT_SETTINGS;
    constructor() { }
}
export class PopulateAttachmentSettings implements Action {
    readonly type = POPULATE_ATTACHMENT_SETTINGS;
    constructor(public payload: { unallowedFileTypes: string[], maxAttachments: number }) { }
}
export class ErrorNotes implements Action {
    readonly type = ERROR_NOTES;
    constructor(public payload?: IError) { }
}
export class UpdateNotesPageStatus implements Action {
    readonly type = UPDATE_NOTES_PAGE_STATUS;
    constructor(public payload: PageStatusTypes) { }
}

export type Actions =
    | LoadSectionDetail
    | PopulateSectionDetail
    | ErrorSection
    | LoadWizardQuestions
    | LoadWizardSection
    | WizardReset
    | WizardUpdate
    | WizardLoad
    | WizardSessionLog
    | UpdateFlightPageStatus
    | UpdateFlightSelection
    | LoadCrewSequences
    | PopulateCrewSequences
    | ChangeFlight
    | ResetGeneralQuestions
    | UpdateQuestionsByParentAnswer
    | UpdateQuestions
    | LoadAllQuestions
    | PopulateAllQuestionsAndPageStatus
    | UpdateGeneralQuestionsPageStatus
    | UpdateIncidentStation
    | ErrorQuestions
    | UpdateNotesPageStatus
    | AddAttachment
    | RemoveAttachment
    | LoadAttachmentSettings
    | PopulateAttachmentSettings
    | ErrorNotes;
