<div class="container">

    <h1> CERS Notifications Consent </h1>
    <div class="description">
        <h4> Welcome CERS User, <br> To confirm that you wish to receive CERS notification on your registered mobile<span class="warning">*</span>, please verify the selected phone number(s) and click ‘Save’ to provide your consent.
        </h4>
    </div>

    <h4 class="description" *ngIf="!phones.length">
        No available phone number(s)
    </h4>

    <form *ngIf="phones.length" [formGroup]="form">
        <label class="phone-checkbox" formArrayName="phones" *ngFor="let order of form.controls.phones.controls; let i = index">
      <h4><input type="checkbox" [formControlName]="i"> {{phones[i].phoneNumber | phone}} </h4>
    </label>

        <button class="button-save" type="button" (click)="submit()" [disabled]="isDisabled">Save</button>
        <div class="user-msg">
            <img *ngIf="isLoadingConsent" src='assets/spinner.gif'>
            <h5 class="success" *ngIf="consentSuccess"><b>Your selection(s) is saved.</b></h5>
            <h5 class="warning" *ngIf="errorMsg"><b>{{errorMsg}}</b></h5>
        </div>

    </form>

    <h5><span class="warning">*</span>Text message frequency varies</h5>
    <h5><span class="warning">*</span>Standard message and data rates may apply</h5>
    <div class="description">
        <h4><b>Sponsor and Program Description:</b></h4>
        <h4>CERS notifications are sent to primarily American Airlines Group employees and contractors. CERS notifications may involve events involving employees, aircraft, flights, facilities, or other operational issues.
        </h4>
        <a href="{{termsAndConditionsUrl}}" target="_blank">
            <h4 class="termsUrl">Disclaimer, Terms and Conditions
                <i class="icon-newpage" aria-hidden="true"></i>
            </h4>
        </a>
    </div>

</div>
