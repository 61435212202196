import { Component, Input, OnInit } from '@angular/core';
import { IDynamicQuestion } from 'app/new-report/new-report-store/wizard';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { SummaryComponent } from '../summary.component';

@Component({
    providers: [SummaryComponent],
    selector: 'app-summary-question-display',
    templateUrl: './summary-question-display.component.html',
    styleUrls: ['../steps.component.scss']
})

export class SummaryQuestionDisplayComponent implements OnInit {
    @Input() questions: Array<IDynamicQuestion>;
    dataType = '';

    constructor(
        public datepipe: DatePipe,
        private summaryComponent: SummaryComponent) { }

    ngOnInit() {
        this.checkDataType(this.questions);
    }

    isCheckboxAnswered(question): boolean {
        if (this.isCheckbox(question)) {
            if (question.answers) {
                for (const answer of question.answers) {
                    if (answer.isChecked) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    isCheckbox(question): boolean {
        return question.answerInputType && question.answerInputType.toUpperCase() === 'CHECKBOX' ? true : false;
    }

    hasAnswers(questions): boolean {
        return this.summaryComponent.checkForAnswers(questions);
    }

    // excludes checkbox
    formatAnswer(question): string {
        let answer = question.userAnswer.answerText ? question.userAnswer.answerText : question.userAnswer;
        if (question.answerInputType) {
            if (question.answerInputType.toUpperCase() === 'DATE') {
                return this.datepipe.transform(answer, 'MM/dd/yyyy', 'UTC');
            }
            if (question.answerInputType.toUpperCase() === 'INVOLVEMENT') {
                answer = '';
            }
        }
        return answer;
    }

    parseEmailStringToList(emails: string) {
        if (emails && emails.split) {
            return emails.split(';');
        } else return [];
    }

    checkDataType(questions) {
        questions.forEach((question: { answerInputType: string; attrs: object; dataType: any; }) => {
            if (question && question.answerInputType && question.answerInputType.toUpperCase() === 'TEXTBOX') {
                let attrs: object = {};
                if (question.attrs) attrs = question.attrs;
                if (attrs['dataType']) question.dataType = attrs['dataType'];
            }
        });
    }
}
