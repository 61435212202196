import { Component, AfterViewInit, ViewChild, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { trigger, style, animate, transition, group } from '@angular/animations';

import { Store } from '@ngrx/store';
import { getUser } from '@app/store/user/user.reducer';
import { State } from '@app/store';

import { MenuService } from '../nav-bar/menu.service';
import { MatSidenav } from '@angular/material/sidenav';

import { UserService } from '@shared/user/user.service';
import { IUser, User, ProxyUser, ProxyRemove, getProxyMode } from '@app/store/user';
import * as ReportHistoryStore from 'app/report-history/report-history-store/list';

import { environment } from '@app/../environments/environment';

import { NotificationService } from '@shared/error-handler-notify/services';
import { takeUntil } from 'rxjs/operators';
import { FeatureFlagService } from 'app/shared/feature-flag/feature-flag.service';
import { ErrorHandlerService } from 'app/shared/error-handler/error-handler.service';
import { PingAuthenticationService } from '@techops-ui/ping-authentication';

@Component({
    selector: 'app-aa-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    animations: [
        trigger('fadeAnim', [
            transition(':enter', [
                style({ opacity: 0 }),
                animate('250ms ease-out', style({ opacity: 1 }))
            ]),
            transition(':leave', [
                style({ opacity: 1 }),
                animate('250ms ease-out', style({ opacity: 0 }))
            ])
        ]),
        trigger('sidenavAnim', [
            transition(':enter', [
                style({ marginLeft: '-260px' }),
                animate('250ms ease-out', style({ marginLeft: '0' }))
            ]),
            transition(':leave', [
                style({ marginLeft: '0px' }),
                animate('250ms ease-out', style({ marginLeft: '-260px' }))
            ]),
        ])
    ]
})
export class SidenavComponent implements OnInit, AfterViewInit, OnDestroy {
    currentActiveRoute = '/home';
    user: IUser;
    isResponsive = false;
    proxyUserId = '';
    proxyMode$: Observable<any>;
    isProxyUser = false;
    isProxyMenuItem = true;
    isProxyMenuItemInput = false;
    @ViewChild('nav') nav: MatSidenav;
    isReportManagementAccess = false;
    isGeneralMgr = false;
    isPwa = false;
    destroy$: Subject<void> = new Subject<void>();
    iodFlag = false;
    reportHistoryActive = false;

    constructor(
        private store: Store<State>,
        public router: Router,
        public menuService: MenuService,
        public userService: UserService,
        public notificationService: NotificationService,
        public featureFlag: FeatureFlagService,
        public errorService: ErrorHandlerService,
        private authService: PingAuthenticationService
    ) {
        this.proxyMode$ = this.store.select(getProxyMode);
    }

    ngOnInit(): void {
        this.authService.OnSignInComplete.pipe(takeUntil(this.destroy$)).subscribe(_ => {
            this.featureFlag.hasFeatureFlag('FLAG_IOD_HISTORY').pipe(takeUntil(this.destroy$)).subscribe(f => this.iodFlag = f);
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngAfterViewInit() {
        this.isPwa = (window.matchMedia('(display-mode: standalone)').matches || (environment.envName && environment.envName.includes('dev')));
        this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(user => this.loadUser(user));
        this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.updateRoute(event.url);
                if (event.url.toLowerCase().includes('reporthistory')) this.reportHistoryActive = true;
                this.close();
            }
        });
        this.menuService.responsive.pipe(takeUntil(this.destroy$)).subscribe((opened) => {
            this.isResponsive = opened;
            if (opened) {
                document.body.setAttribute('style', 'overflow: hidden;');
            } else {
                document.body.setAttribute('style', 'overflow: initial;');
            }
        });
    }
    close() {
        this.menuService.toggleResponsive(false);
        document.body.setAttribute('style', 'overflow: initial;');
    }

    loadUser(user: IUser) {
        if (user) {
            this.menuService.setPrivileges(user);
            this.user = user;
            if (this.user !== null) {
                this.isProxyUser = this.user.isProxyUser;
            }
            this.isReportManagementAccess = User.hasPrivilege(user, 'WEB_HISTORY_MANAGEMENT_ACCESS');
            this.isGeneralMgr = user.profileId === 7;
        }
    }

    public isRouteActive(route) {
        return this.router.url.startsWith(route);
    }

    public navigate(route) {
        this.currentActiveRoute = '/' + route;
    }


    toggleResponsive(opened: boolean) {
        this.menuService.toggleResponsive(opened);
    }

    updateRoute(route: string) {
        this.currentActiveRoute = route;
    }

    routeContains(route: string) {
        let contains = false;
        contains = this.currentActiveRoute.includes(route);
        return contains;
    }

    public proxyMenuItemHide() {
        this.isProxyMenuItem = false;
        this.isProxyMenuItemInput = true;
    }

    public proxyMenuItemShow() {
        this.isProxyMenuItem = true;
        this.isProxyMenuItemInput = false;
    }

    public loadProxyUser() {
        this.verifyProxyUser(this.proxyUserId);
    }

    private async verifyProxyUser(id: string) {
        const proxyUser = await this.userService.getEmployee(id);
        if (proxyUser && proxyUser.profileId >= 0) {
            if (!User.isActiveEmployee(proxyUser)) {
                this.notificationService.showWarning(this.errorService.getErrorTextProxy(proxyUser.id, proxyUser.status));
            }
            this.store.dispatch(new ProxyUser(proxyUser));
            this.store.dispatch(new ReportHistoryStore.Reset());
            // stays in notification if the user is there, otherwise navigates to home
            this.router.navigate([this.router.url.includes('/notifications') ? '/notifications' : '/home']);
        } else {
            this.notificationService.showWarning('Proxy user not found');
        }
    }

    public exitProxyMode() {
        this.store.dispatch(new ProxyRemove());
        this.store.dispatch(new ReportHistoryStore.Reset());
        this.router.navigate(['/home']);
        this.proxyUserId = '';
        this.proxyMenuItemShow();
    }
}
