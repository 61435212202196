import { Component, OnInit, ViewChild, EventEmitter, Output, OnDestroy } from '@angular/core';
import { SSEHubReportHistoryService } from '../../../../shared/SSEHubClient/report-history.service';
import { NotificationService } from 'app/shared/error-handler-notify/services/notification.service';
import { ReportHistoryRespond } from '../../../report-history';
import { ReportHistoryService } from 'app/report-history/report-history.service';
import { RespondToRequest, getReportDetailsReportId, GetAdminDiscussion, getAdminDiscussion } from 'app/report-history/report-history-store/detail';
import { of, Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from 'app/store';
import { getUser } from 'app/store/user';
import { takeUntil } from 'rxjs/operators';


@Component({
    selector: 'app-respond-to-request-inline',
    templateUrl: './respond-to-request-inline.component.html',
    styleUrls: ['./respond-to-request-inline.component.scss', '../../summary.component.scss']
})

export class RespondToRequestInlineComponent implements OnInit, OnDestroy {
    @Output() respondToRequestFormDisplaying = new EventEmitter();
    responderEmployeeId: number;
    reportId: number;
    respondObject: ReportHistoryRespond = new ReportHistoryRespond;
    displayRespondToRequestForm = false;
    destroy$: Subject<void> = new Subject<void>();
    // flags
    isLoading = false;
    isValid = true;
    isExist = false;
    isCommentValid = true;
    disableSend = false;
    timeoutError = false;

    respondToRequest$: Observable<any>;

    constructor(
        private store: Store<State>,
        private sseHubReportHistoryService: SSEHubReportHistoryService,
        private reportHistoryService: ReportHistoryService,
        private notificationService: NotificationService
    ) {
        this.respondToRequest$ = this.store.select(getAdminDiscussion);
    }
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(user => this.responderEmployeeId = user.id);
        this.store.select(getReportDetailsReportId).pipe(takeUntil(this.destroy$)).subscribe(result => this.reportId = result);
    }

    updateDisplayRespondToRequestForm(value: boolean) {
        this.displayRespondToRequestForm = value;
        this.respondToRequestFormDisplaying.emit(this.displayRespondToRequestForm);
    }

    onSave(text) {
        if (!text.trim()) {
            this.isCommentValid = false;
            return;
        }

        this.disableSend = true;
        this.respondObject.message = text;
        this.respondObject.responderEmployeeId = this.responderEmployeeId;

        this.store.dispatch(new RespondToRequest(this.reportId, this.respondObject));
    }
}
