<div class="dynamicInputDiv" [ngModelGroup]="question.questionMappingId">
    <div class="involvement-top-margin">
        <i *ngIf="question.uniqueIdentifier && !questionAttrs['isRequired']" (click)="remove($event)"
            class="icon-small icon-delete"></i>
        <i *ngIf="questionAttrs['isRequired']" class="icon-cers-required"></i>
        <label class="leftMargin involvementFont">
            {{ question.questionText }}
        </label>
    </div>
</div>

<modal modalClass="modal-md" #confirmDelete>
    <modal-header>
        <h4>Confirm {{ question.questionText }} removal</h4>
    </modal-header>
    <modal-content>
        <p>{{ modalText }}</p>
    </modal-content>
    <modal-footer>
        <button class="btn-cancel" (click)="cancel()">Cancel</button>
        <button class="btn-primary leftMargin remove" type="button" (click)="confirm()">Confirm</button>
    </modal-footer>
</modal>