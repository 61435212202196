<modal #searchModal (onClose) = closeModal($event)>

    <modal-header>
        <h4> Search for a flight</h4>
    </modal-header>

    <modal-content>
        <form #form="ngForm">
            <div>
                <i *ngIf="flightNumber.attrs.isRequired" class="icon-cers-required"></i>
                <label [ngClass]="{'icon-cers-not-required': !flightNumber.attrs.isRequired}">Flight Number</label>
                <input type="number" class="form-control" id="flightNumber" [(ngModel)]="flightNumber.number"
                    name="flightNumber" [appDynamicValidation]="flightNumber.attrs" #flNu="ngModel">
                <div *ngFor="let error of flNu.errors | keyvalue" class="validation-fail">
                    <label class="errorText" *ngIf="error.key !== 'isRequired'"> {{error.value}} </label>
                </div>
            </div>

            <div>
                <i *ngIf="departureDate.attrs.isRequired" class="icon-cers-required"></i>
                <label [ngClass]="{'icon-cers-not-required': !departureDate.attrs.isRequired}">Departure Date</label>
                <input matInput [matDatepicker]="picker" placeholder="mm/dd/yyyy" class="form-control date"
                    [(ngModel)]="departureDate.date" name="departureDate" (click)="picker.open()"
                    [appDynamicValidation]="departureDate.attrs" #flDate="ngModel" (focus)="picker.open()">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <div *ngFor="let error of flDate.errors | keyvalue | dateValidationPipe" class="validation-fail">
                    <label class="errorText" *ngIf="error.key !== 'isRequired'"> {{error.value}} </label>
                </div>
            </div>

            <div [ngModelGroup]="departCity">
                <i *ngIf="departCity.attrs.isRequired" class="icon-cers-required"></i>
                <label [ngClass]="{'icon-cers-not-required': !departCity.attrs.isRequired}">Departure Station</label>
                <input type="text" class="form-control" id="departCity" name="departCity" (input)="replaceInput($event)"
                    [(ngModel)]="departCity.code" [appDynamicValidation]="departCity.attrs" [matAutocomplete]="auto"
                    #dc="ngModel">
                <mat-autocomplete #auto="matAutocomplete">
                    <mat-option *ngFor="let option of options" [value]="option.airportId">
                        {{option.airportId + ' - ' + option.airportDesc}}
                    </mat-option>
                </mat-autocomplete>
                <div *ngFor="let error of dc.errors | keyvalue" class="validation-fail">
                    <label class="errorText" *ngIf="error.key !== 'isRequired'"> {{error.value}} </label>
                </div>
            </div>

            <app-aa-spinner [loading]="isSearching"></app-aa-spinner>

            <div *ngIf="foundFlight">
                <div *ngFor="let flight of flights; let i = index">
                    <input type="checkbox" name="{{i}}" [(ngModel)]="flight.isChecked"
                        (ngModelChange)="showConfirm(flight)" [ngModelOptions]="{standalone: true}"
                        class="checkbox checkboxList flight">
                    <label>{{flight.flightNumber}} {{flight.flightDate | date:'shortDate' : 'UTC' }}
                        {{flight.departureStation}}-{{flight.interruptStation ? flight.interruptStation :
                        flight.arrivalStation}}
                        {{flight.fleetDescription}} {{flight.tailNumber}}
                    </label>
                    <app-confirm-flight-change [flightDetail]="flight" (confirmUpdate)="fillOutFlightDetail(flight)"
                        (cancelUpdate)="closeModal($event)"></app-confirm-flight-change>
                </div>
            </div>
            <div *ngIf="!foundFlight" class="validation-fail">
                {{ searchResult }}
            </div>
        </form>
    </modal-content>

    <modal-footer>
        <button type="submit" class="btn-primary" (click)="searchFlight()">Search</button>
        <button class="btn-cancel leftMargin" (click)="clear()">Clear</button>
        <button class="btn-cancel leftMargin" (click)="cancel()">Cancel</button>
    </modal-footer>
</modal>