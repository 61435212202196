<h2>Miscellaneous</h2>
<div class="divider"></div>
<div>
    <h4 class="subHeader">{{imagesSectionInfo.name}}</h4>
    <app-section-visibility [profileId]=profileId [categoryId]=categoryId [sectionInfo]="imagesSectionInfo"
        [sectionId]="10"></app-section-visibility>
    <div class="section-summary without-padding">
        <label class="subHeader">Include in email notification : </label>
        <input type="checkbox" [(ngModel)]="includeAttachmentInEmail" class="checkbox"
            (click)="onClickIncludeAttachmentInEmail()" />
        <div *ngIf="isEditingIncludeAttachmentInEmail" class="edit-section-visibility">
            <button type="button" class="button-cancel" (click)="onEditIncludeAttachmentInEmail()">Cancel</button>
            <button type="button" class="button-save" (click)="onIncludeAttachmentInEmailSave()"> Save </button>
        </div>
        <div *ngIf="!isEditingIncludeAttachmentInEmail && canEdit" (click)="onEditIncludeAttachmentInEmail()"
            class="edit-section-icon">
            <i class="icon-medium icon-edit"></i>
        </div>
    </div>

    <ng-container *ngFor="let field of categoryAttributes; let i = index;">
        <br>
        <h4 class="subHeader">{{field.header}}</h4>
        <div *ngIf="!field.editing">
            <div class="confirmView" appSafeHtml htmlString="{{field.savedValue}}"></div>
            <div *ngIf="canEdit" class="edit-section-icon">
                <button class="primary" (click)="field.editing = true">
                    Edit <i class="icon-medium icon-edit"></i>
                </button>
            </div>
        </div>
        <div *ngIf="field.editing">
            <editor class="confirmEditing" [init]="{
                    base_url: '/tinymce', suffix: '.min',
                    plugins: 'link',
                    menubar: false, toolbar:'bold italic underline backcolor forecolor link fontsizeselect',
                    fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt'
                }" [(ngModel)]="field.changedValue"></editor>
            <div *ngIf='field.saveLoading; else notLoading'>
                <app-aa-spinner-no-logo [loading]="field.saveLoading"></app-aa-spinner-no-logo>
            </div>
            <ng-template #notLoading>
                <button class="button-cancel" (click)="field.editing = false">Cancel</button>
                <button class="button-save" (click)="saveAttribute(field)">Save</button>
            </ng-template>
        </div>
    </ng-container>
</div>
<br /><br />