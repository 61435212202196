import { NgModule, Attribute } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

// icons
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
    faAngleDown, faAngleUp, faArrowLeft, faArrowRight, faEdit, faCommentAlt, faCalendarAlt, faTrash, faCheck, faTimes, faSpinner,
    faStar, faArrowCircleUp, faToggleOff, faToggleOn, faUsers, faQuestionCircle, faBullseye, faArrowUp, faArrowDown, fas, faPlus, faCopy
} from '@fortawesome/free-solid-svg-icons';


import { AASpinnerComponent } from './components/aa-spinner/aa-spinner.component';
import { AASpinnerNoLogoComponent } from './components/aa-spinner-no-logo/aa-spinner-no-logo.component';
import { OverflowMenuComponent } from './components/overflow-menu/overflow-menu.component';
import { ConfirmationPopupComponent } from './components/confirmation-popup/confirmation-popup.component';
import { EditInlineTextComponent } from './components/edit-inline-text/edit-inline-text.component';
import { TitleBarComponent } from './components/title-bar/title-bar.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { MenuService } from './components/nav-bar/menu.service';
import { OpenModalButtonComponent } from './components/open-modal-button/open-modal-button.component';
import { ReportHistoryService } from '../../report-history/report-history.service';
import { AutosizeDirective } from './components/aa-textarea-autosize/aa-textarea-autosize.directive';
import { ExpandCollapseService } from './components/component-services/expand-collapse.service';
import { FormComponentService } from './components/component-services/form-component.service';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { AddAttachmentComponent } from './components/add-attachment/add-attachment.component';
import { TimeoutComponent } from './components/timeout/timeout.component';
import { FeatureFlagDirective } from '../feature-flag/feature-flag.directive';
import { SSEHubClientModule } from '../SSEHubClient/SSEHubClient.module';
import { CERSModalComponent } from './components/modal/modal.component';
import { CERSModalHeaderComponent } from './components/modal/modal.component';
import { CERSModalContentComponent } from './components/modal/modal.component';
import { CERSModalFooterComponent } from './components/modal/modal.component';
import { AMSAddressValidator } from '../directives/ams-address.directive';
import { NumberDirective } from '../directives/numbers-only.directive';
import { JsonToObjectPipe } from '../pipes/json-to-object.pipe';
import { AttributeValuePipe } from '../pipes/attribute-value.pipe';
import { CapitalizePipe, CapitalizeFirstLetterPipe, CapitalizeInputTypePipe } from '../pipes/capitalize.pipe';
import { AlphabetizePipe } from '../pipes/alphabetize.pipe';
import { QuestionWithHyperlinksComponent } from './components/question-with-hyperlinks/question-with-hyperlinks.component';
import { EmailsInputComponent } from './components/emails-input/emails-input.component';
import { ObjectPropertiesPipe } from '../pipes/object-properties.pipe';
import { SafeHtmlDirective } from '../directives/safe-html.directive';
import { KendoUiModule } from './kendo-ui.module';
import { FormControlErrorComponent } from './components/form-control-error/form-control-error.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        DataTablesModule,
        RouterModule,
        BrowserAnimationsModule,
        SSEHubClientModule,
        FontAwesomeModule,
        KendoUiModule,
        FormControlErrorComponent
    ],
    declarations: [
        AASpinnerComponent,
        QuestionWithHyperlinksComponent,
        AASpinnerNoLogoComponent,
        OverflowMenuComponent,
        ConfirmationPopupComponent,
        EditInlineTextComponent,
        TitleBarComponent,
        NavBarComponent,
        OpenModalButtonComponent,
        AutosizeDirective,
        SidenavComponent,
        AddAttachmentComponent,
        TimeoutComponent,
        FeatureFlagDirective,
        CERSModalComponent,
        CERSModalHeaderComponent,
        CERSModalContentComponent,
        CERSModalFooterComponent,
        JsonToObjectPipe,
        AttributeValuePipe,
        CapitalizePipe,
        CapitalizeFirstLetterPipe,
        CapitalizeInputTypePipe,
        AMSAddressValidator,
        NumberDirective,
        EmailsInputComponent,
        AlphabetizePipe,
        ObjectPropertiesPipe,
        SafeHtmlDirective
    ],
    exports: [
        KendoUiModule,
        AASpinnerComponent,
        AASpinnerNoLogoComponent,
        QuestionWithHyperlinksComponent,
        AASpinnerNoLogoComponent,
        OverflowMenuComponent,
        ConfirmationPopupComponent,
        EditInlineTextComponent,
        TitleBarComponent,
        NavBarComponent,
        OpenModalButtonComponent,
        AutosizeDirective,
        SidenavComponent,
        AddAttachmentComponent,
        TimeoutComponent,
        FeatureFlagDirective,
        CERSModalComponent,
        CERSModalHeaderComponent,
        CERSModalContentComponent,
        CERSModalFooterComponent,
        JsonToObjectPipe,
        AttributeValuePipe,
        CapitalizePipe,
        CapitalizeFirstLetterPipe,
        CapitalizeInputTypePipe,
        AMSAddressValidator,
        NumberDirective,
        EmailsInputComponent,
        AlphabetizePipe,
        FontAwesomeModule,
        ObjectPropertiesPipe,
        SafeHtmlDirective,
        FormControlErrorComponent
    ],
    providers: [
        MenuService,
        ReportHistoryService,
        ExpandCollapseService,
        FormComponentService,
        JsonToObjectPipe,
        AttributeValuePipe,
        AASpinnerComponent
    ]
})
export class CommonUXModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fas);
        library.addIcons(
            faTrash, faCheck, faTimes, faAngleDown, faAngleUp, faArrowCircleUp, faArrowDown, faArrowLeft, faArrowRight, faArrowUp,
            faStar, faSpinner, faToggleOn, faToggleOff, faEdit, faCommentAlt, faCalendarAlt, faUsers,
            faQuestionCircle, faBullseye, faPlus, faCopy);
    }
}
