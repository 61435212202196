import { Component, OnInit, OnDestroy } from '@angular/core';
import { SSEHubReportHistoryService } from '../shared/SSEHubClient/report-history.service';
import { Store } from '@ngrx/store';
import { State } from '@app/store';
import { getUser, IUser } from 'app/store/user';
import { ActivatedRoute, Params } from '@angular/router';
import { Attachment } from 'app/report-history/report-history';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Guid } from 'app/shared/utilities/guid';
import { AttachmentSummary } from 'app/shared/common-ux/models/AttachmentSummary';

@Component({
    selector: 'app-view-attachment',
    templateUrl: './view-attachment.component.html',
    styleUrls: ['./view-attachment.component.scss']
})

export class ViewAttachmentComponent implements OnInit, OnDestroy {
    public user: IUser;
    public attachment: Attachment;
    // Blobstorage attachment.
    reportId: number;
    attachmentGuid: Guid;
    employeeId: number;
    sanitizedImageData: SafeUrl;
    destroy$: Subject<void> = new Subject<void>();
    imageHtml: any;

    // flags
    isLoading = false;
    responseMessage: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private reportHistoryService: SSEHubReportHistoryService,
        private sanitizer: DomSanitizer,
        private store: Store<State>,
        private attachmentSummaryService: AttachmentSummary
    ) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(user => {
            this.isLoading = true;
        });

        this.attachment = new Attachment();
        this.attachment.imageString = '';

        this.isLoading = true;
        this.responseMessage = '';

        this.activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
            this.reportId = params['reportId'];
            this.attachmentGuid = params['attachmentGuid'];
        });

        this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(user => this.employeeId = user.id);
        this.getAttachment(this.reportId, this.attachmentGuid,);
    }

    getAttachment(reportId: any, attachmentGuid: any) {
        this.imageHtml = null;
        this.reportHistoryService.getAttachment(reportId, attachmentGuid, this.employeeId).pipe(takeUntil(this.destroy$)).subscribe(
            (attachment) => {
                this.attachment = attachment;
                const attachmentType = this.attachmentSummaryService.getAttachmentType(attachment);
                if (attachmentType === 'viewable_file_type') {
                    const elem = document.getElementById("image");
                    elem.innerHTML = this.attachmentSummaryService.getHTML(attachment);
                } else {
                    this.imageHtml = '';
                    this.attachmentSummaryService.saveAttachment(attachment);
                }
                this.isLoading = false;
                this.responseMessage = '';
            },
            (error) => {
                this.isLoading = false;
                this.responseMessage = 'Failed to retrieve attachment.';
            }
        );
    }
}

