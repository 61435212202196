import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SSEHubClient } from './SSEHubClient.service';
import { Observable } from 'rxjs';


@Injectable()
export class SSEHubPassengersService {

    constructor(
        private http: HttpClient,
        private sseHubClient: SSEHubClient
    ) { }

    getList(flightNumber, departDate, departStation): Observable<any> {
        return this.http.get(this.sseHubClient.serviceUrl() + '/api/passengerInfo/' + encodeURIComponent(flightNumber) + '/'
            + encodeURIComponent(departDate) + '/' + encodeURIComponent(departStation));
    }
}

