export interface IDictionary<T> {
    [key: string]: T;
}

// 'name returned from API': 'user friendly name'
export const tagNameDictionary: IDictionary<string> = {
    'TagVal': 'tag name',
    'TagDescription': 'description',
    'TagDisplayTxt': 'display text',
    'SystemRequired': 'system required',
    'PiiInd': 'PII indicator'
}

export interface ITagDetails {
    tagVal: string;
    tagDescription: string;
    tagDisplayTxt: string;
    systemRequired: boolean;
    piiInd: boolean;
    inactiveDate?: Date | null;
    effectiveDate?: Date;
}

// these are arrays of the individual errors for either the tagVal, the tagDescription, or the tagDisplayText
// e.g. { tagVal: ['Tag already exists'], tagDescription: ['Contains invalid character', 'Length too long'], tagDisplayTxt: [] }
export interface ITagErrors {
    tagVal: string[];
    tagDescription: string[];
    tagDisplayTxt: string[];
}

export interface IQuestionMappingTagState {
    selectedQuestionMappingId: string;
    selectedQuestionMappingText: string;
    selectedCategory: string;
    questionMappingTags: ITagDetails[];
    isLoading: boolean;
    error: any;
}

export interface ITagState {
    alltags: ITagDetails[];
    isLoading: boolean;
    error: any;
}

export interface IAddTagState {
    isLoading: boolean;
    success: boolean;
    error: any;
}

export interface ICreateTagState {
    tagName: string;
    isLoading: boolean;
    success: boolean;
    error: ITagErrors;
}

export interface IUpdateTagState {
    isLoading: boolean;
    success: boolean;
    error: ITagErrors;
}

export interface ITagManagementState {
    questionMappingTagState: IQuestionMappingTagState;
    tagState: ITagState;
    addTagState: IAddTagState;
    createTagState: ICreateTagState;
    updateTagState: IUpdateTagState;
}
