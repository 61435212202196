import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import * as detailActions from './detail.actions';
import { CategoryDetailsService } from '@shared/reportStructure/categoryDetails/categoryDetails.service';
import { NotificationService } from '@shared/error-handler-notify/services/notification.service';
import { SSEHubCategoriesService } from '@shared/SSEHubClient/categories.service';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Observable, EMPTY as empty, of } from 'rxjs';

@Injectable()
export class CategoryDetailEffects {

    
    loadCategoryDetail$: Observable<Action> = createEffect(() => this.action$.pipe(
        ofType<detailActions.LoadCategoryDetail>(detailActions.LOAD_CATEGORY_DETAIL),
        switchMap(action => {
            return this.categoryDetailsService.getDetails(action.profileId, action.categoryId).pipe(
                map(detail => {
                    if (detail) {
                        const newDetail: any = detail;
                        return new detailActions.PopulateCategoryDetail(newDetail);
                    } else {
                        this.notificationService.showError('Error loading category details');
                        return new detailActions.CategoryDetailError('Error loading category details');
                    }
                }),
                catchError(error => {
                    console.log(error);
                    this.notificationService.showError('Error loading category details');
                    return of(new detailActions.CategoryDetailError('Error loading category details'));
                })
            );
        })
    ));

    constructor(
        private action$: Actions,
        private categoryDetailsService: CategoryDetailsService,
        private notificationService: NotificationService,
    ) { }

}
