import { Injectable } from '@angular/core';
@Injectable()
export class DatabaseService {
    constructor() {}

    openSubmittedReports(databaseName: string): Promise<IDBDatabase> {
        return new Promise((resolve, reject) => {
            if (!window.indexedDB) {
                reject('IndexedDB not supported');
            }
            const req = window.indexedDB.open(databaseName, 1);
            req.onsuccess = (evt) => {
                resolve(req.result);
            };
            req.onerror = (evt: any) => {
                reject(`openDb:", ${evt.target.errorCode}`);
            };

            req.onupgradeneeded = function (event: any) {
                let db: IDBDatabase;
                if (event && event.target) {
                    db = event.target.result;
                }
                const objectStore = db.createObjectStore('reports', { autoIncrement: true });
                objectStore.transaction.oncomplete = (transactionEvent) => {
                    db.transaction('reports', 'readwrite').objectStore('reports');
                    resolve(db);
                };
                objectStore.transaction.onerror = (transactionEvent: Event) => reject(`open db transaction error, ${transactionEvent}`);
            };
        });
    }

    getAllReports(database: string, store: string): Promise<any[]> {
        return new Promise((resolve, reject) => {
            this.openSubmittedReports(database).then((db) => {
                if (db) {
                    const objectStore = db.transaction(store, 'readonly').objectStore(store); // open the database reports store
                    const req = objectStore.openCursor();
                    const list: any = [];

                    req.onerror = (errEvent: any) => console.error('Error retrieving all reports: ' + errEvent.target.errorCode);
                    req.onsuccess = (cursorEvent: any) => { // callback for the cursors that iterate through database
                        const cursor: IDBCursorWithValue = (cursorEvent && cursorEvent.target) ? cursorEvent.target.result : null;
                        if (cursor) {
                            list.push(cursor.value);
                            cursor.continue(); // grab the next item
                        } else {
                            resolve(list); // cursor is done iterating through database
                        }
                    };
                }
            }).catch(e => {
                console.error('Error retrieving all reports in database', e);
            });
        });
    }

    getReport(database: string, store: string, key: any) {
        return new Promise((resolve, reject) => {
            this.openSubmittedReports(database).then((db) => {
                const objectStore = db.transaction(store, 'readonly').objectStore(store); // open the database reports store
                const req = objectStore.get(key);
                req.onerror = (errEvent: any) => console.error('Error retrieving a report: ' + errEvent.target.errorCode);
                req.onsuccess = (getEvent: any) => { // callback for getting the report
                    if (getEvent && getEvent.target) {
                        const dbData: any = getEvent.target.result;
                        resolve(dbData);
                    } else {
                        reject('Unable to read report');
                    }
                };
            });
        }).catch(e => {
            console.error('Error retrieving a report in database', e);
        });
    }

    deleteReport(database: string, store: string, key: any) {
        return new Promise((resolve, reject) => {
            this.openSubmittedReports(database).then((db) => {
                const objectStore = db.transaction(store, 'readwrite').objectStore(store); // open the database reports store
                const req = objectStore.delete(key);
                req.onerror = (errEvent: any) => console.error('Error retrieving a report: ' + errEvent.target.errorCode);
                req.onsuccess = (getEvent) => { // callback for getting the report
                    const dbData: any = getEvent.target;
                    resolve(dbData);
                };
            });
        }).catch(e => {
            console.error('Error deleting a report in databse', e);
        });
    }

    addReport(database: string, store: string, report: any, key: any) {
        return new Promise((resolve, reject) => {
            this.openSubmittedReports(database).then((db) => {
                const objectStore = db.transaction(store, 'readwrite').objectStore(store); // open the database reports store
                const req = objectStore.add(report, key);
                req.onerror = (errEvent: any) => console.error('Error retrieving a report: ' + errEvent.target.errorCode);
                req.onsuccess = (getEvent) => { // callback for getting the report
                    const dbData: any = getEvent.target;
                    resolve(dbData);
                };
            });
        }).catch(e => {
            console.error('Error adding a report in databse', e);
        });
    }
}
