import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';

import { SSEHubClient } from './SSEHubClient.service';
import { SyncDatabaseService } from '../report-services/sync-database.service';

@Injectable()
export class SSEHubCategoriesService {
    jsonResult: any = {};
    constructor(
        private http: HttpClient,
        private sseHubClient: SSEHubClient,
        private syncDB: SyncDatabaseService
    ) { }

    getAllCategories() {
        return this.http.get(this.sseHubClient.serviceUrl() + '/api/categories/all');
    }

    getByProfileId(profileId): Observable<any> {
        const url = this.sseHubClient.serviceUrl() + '/api/profiles/' + encodeURIComponent(profileId) + '/categories';
        const offlineReq = new Observable(observer => {
            this.syncDB.getAllCategories(profileId).then((val) => {
                observer.next(val);
                observer.complete();
            });
        });
        return (window.navigator.onLine) ? this.http.get(url) : offlineReq;
    }

    getByProfileIdForCategoryCopy(profileId): Observable<any> {
        const url = this.sseHubClient.serviceUrl() + '/api/profiles/' + encodeURIComponent(profileId) + '/categories';
        return this.http.get(url);
    }

    deleteCategory(profileId, categoryId) {
        return this.http.delete(this.sseHubClient.serviceUrl() + '/api/profiles/' + encodeURIComponent(profileId)
            + '/categories/' + categoryId);
    }

    initializeCategory(profileId, categoryId) {
        return this.http.post(this.sseHubClient.serviceUrl() + '/api/profiles/' + encodeURIComponent(profileId) + '/categories/' +
            encodeURIComponent(categoryId) + '/categorySections', null);
    }

    getCategoryDetails(profileId, categoryId) {
        const url = `${this.sseHubClient.serviceUrl()}/api/profiles/${profileId}/categories/${categoryId}`;
        const offlineReq = new Observable(observer => {
            this.syncDB.getCategory(categoryId).then((val) => {
                observer.next(val);
                observer.complete();
            });
        });
        return (window.navigator.onLine) ? this.http.get(url) : offlineReq;
    }

    addCategory(categoryId, profileId) {
        return this.http.post(this.sseHubClient.serviceUrl() + '/api/profiles/' + encodeURIComponent(profileId) + '/categories/' +
            encodeURIComponent(categoryId) + '', null);
    }

    createCategory(profileId: number, description: string): Observable<any> {
        return this.http.post(`${this.sseHubClient.serviceUrl()}/api/profiles/${profileId}/categoryDescription/${description}`, null);
    }

    copyCategory(sourceProfileId, categoryId, targetProfileId): Observable<any> {
        return this.http.post(this.sseHubClient.serviceUrl() + '/api/sourceProfile/' + encodeURIComponent(sourceProfileId) + '/category/' +
            encodeURIComponent(categoryId) + '/targetProfile/' + encodeURIComponent(targetProfileId), null);
    }

    updateCategoryActive(profileId, categoryId, status): Observable<any> {
        return this.http.put(
            this.sseHubClient.serviceUrl() + '/api/profiles/' + encodeURIComponent(profileId) +
            '/categories/' + encodeURIComponent(categoryId) + '/categoryActive?categoryActive=' + status + '',
            this.sseHubClient.getServiceOptions());
    }

    deleteSection(profileId, categoryId, groupName): Observable<any> {
        return this.http.delete(this.sseHubClient.serviceUrl() + '/api/profiles/' + encodeURIComponent(profileId) + '/categories/' +
            encodeURIComponent(categoryId) + '/group/' + groupName);
    }

    deleteTemplateQuestions(profileId, categoryId, groupName): Observable<any> {
        return this.http.delete(this.sseHubClient.serviceUrl() + '/api/profiles/' + encodeURIComponent(profileId) + '/categories/' +
            encodeURIComponent(categoryId) + '/questionTemplate/' + groupName);
    }

    deleteQuestionsByMappingId(mappingId): Observable<any> {
        return this.http.delete(this.sseHubClient.serviceUrl() + '/api/QuestionMapping/' + encodeURIComponent(mappingId));
    }

    deleteAnswerById(Id): Observable<any> {
        return this.http.delete(this.sseHubClient.serviceUrl() + '/api/Answer/' + encodeURIComponent(Id));
    }
}
