<h4 *ngIf="(reportInfo$ | async).categoryDescription" class="categoryTitle">
    Category: {{ (reportInfo$ | async).categoryDescription }}</h4>
<h1>Flight information</h1>
<p><i class="icon-cers-required"></i><i class="fontRed">Required</i></p>
<div>
    <form #form="ngForm">
        <app-aa-spinner [loading]="isLoading" [page]="'flight'"></app-aa-spinner>

        <div *ngIf="isFlight">
            <div class="button-image-div">
                <button id="FlightSearch" class="button-image" title="Search for a flight" (click)="openSearchModal()"
                    text="Find flights"></button>
            </div>
            <label class="labelname" (click)="openSearchModal()">Find flights</label>
        </div>

        <div *ngIf="crewSeqs && crewSeqs.options && crewSeqs.options.length > 0 && isFlight">
            <label>My crew sequence</label>
            <select name="crewSeqSelect" id="crewSeqSelect" class="form-control dropdown marginBottom"
                (change)="confirmFlightChange(crewSeqs.userAnswer)" [(ngModel)]="crewSeqs.userAnswer">
                <option *ngFor="let seq of crewSeqs.options" [ngValue]="seq">
                    {{seq.flightNumber | flightNumberDisplayPipe }} {{seq.flightDate | date:'shortDate' : 'UTC'}}
                    {{seq.departureStation}} {{seq.arrivalStation}} {{seq.fleetDescription}} {{seq.tailNumber}}
                </option>
            </select>
        </div>

        <app-dynamic-form *ngIf="!isLoading && questions && questions.length > 0"
            [profileId]="(reportInfo$ | async).profileId" [categoryId]="(reportInfo$ | async).categoryId"
            [groupName]="groupName" [questions]="questions" (answerEvent$)="showOrHideCrewSequence($event)">
        </app-dynamic-form>
    </form>

    <div class="wizardNavButtons" [ngClass]="{'btn-drop-container': newReportService.isPwa}">
        <div class="left">
            <!--<button class="btn-prev" (click)="saveReport()" *ngIf="newReportService.isPwa">Save</button>-->
        </div>
        <div class="right">
            <button class="btn-cancel" (click)="confirmCancel.displayModal()">Cancel</button>
            <button class="btn-next" (click)="next()">Next</button>
            <button class="btn-drop-cancel" (click)="confirmCancel.displayModal()">Cancel</button>
        </div>
    </div>
    <app-confirm-navigate (navResult)="onNavigate($event)"></app-confirm-navigate>
    <app-confirm-cancel></app-confirm-cancel>
    <app-search-flight [isFlight]="isFlight" [flightNum]="flightNumber" (onselected)="autoFillOutFlightDetail($event)">
    </app-search-flight>
    <app-confirm-flight-change [flightDetail]="candidateFlight" (confirmUpdate)="autoFillOutFlightDetail($event)"
        (cancelUpdate)="cancelUpdate()">
    </app-confirm-flight-change>