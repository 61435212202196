import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LoggerService } from './logger.service';
import { NotificationService } from './notification.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) { }

    handleError(error) {
        // the Angular class ErrorHandler displays errors in console by default
        // we are handling errors in our LoggingService

        const loggingService = this.injector.get(LoggerService);
        const notificationService = this.injector.get(NotificationService);
        const message = error.message ? error.message : error.toString();

        // loggingService.error(error);

        // notificationService.showError(message);
        // throw error;
    }
}
