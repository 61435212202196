import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './home/home.component';
import { PingAuthGuard } from '@techops-ui/ping-authentication';

import { ReportHistoryComponent } from './report-history/report-history.component';
import { ToDoComponent } from './to-do/to-do.component';
import { SummaryComponent } from './report-history/summary/summary.component';
import { HelpComponent } from './help/help.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationVerificationComponent } from './notification-verification/notification-verification.component';
import { ViewAttachmentComponent } from './view-attachment/view-attachment.component';
import { AppAuthGuard } from './app.guard';
import { AdminRouteGuard } from './admin/admin.route-guard';
import { ReportDetailAuthGuard, ReportSearchAuthGuard } from './report-history/report-history.guard';
// disabling offline-sync for the time being
// import { OfflineSyncComponent } from './offline-sync/offline-sync.component';
import { ReportSearchComponent } from './report-history/search-reports/search-reports.component';
import { ReportHistoryRerouteComponent } from './report-history/report-history-reroute/report-history-reroute.component';
import { ReportHistoryRerouteAuthGuard } from './report-history/report-history-reroute/report-history-reroute.guard';

export const appRoutes: Routes = [
    { path: 'admin', loadChildren: () => import('app/admin/admin.module').then(routes => routes.AdminModule), canLoad: [AdminRouteGuard] },
    { path: 'reporthistory', component: ReportHistoryComponent, canActivate: [AppAuthGuard] },
    { path: 'reporthistory/reportsearch', component: ReportSearchComponent, canActivate: [AppAuthGuard, ReportSearchAuthGuard] },
    { path: 'reporthistory/reportsearch/:employeeId/:reportIdOrEtn', component: SummaryComponent, canActivate: [AppAuthGuard, ReportSearchAuthGuard] },
    { path: 'reporthistory/:option', component: ReportHistoryComponent, canActivate: [AppAuthGuard] },
    { path: 'reporthistory/:reportId/summary', component: SummaryComponent, canActivate: [AppAuthGuard, ReportDetailAuthGuard] },
    { path: 'reporthistory/reroute/:option', component: ReportHistoryRerouteComponent, canActivate: [AppAuthGuard, PingAuthGuard, ReportHistoryRerouteAuthGuard] },
    // This differentiates between superuser/manager and normal user.
    { path: 'myreports', component: ReportHistoryComponent, canActivate: [AppAuthGuard] },
    { path: 'myreports/:option', component: ReportHistoryComponent, canActivate: [AppAuthGuard] },
    { path: 'myreports/:reportId/summary', component: SummaryComponent, canActivate: [AppAuthGuard, ReportDetailAuthGuard] },
    { path: 'todo', component: ToDoComponent, canActivate: [AppAuthGuard] },
    { path: 'newreport', loadChildren: () => import('app/new-report/new-report.module').then(routes => routes.NewReportModule) },
    { path: 'home', component: HomeComponent, canActivate: [AppAuthGuard, PingAuthGuard] },
    // disabling offline-sync for the time being
    // { path: 'offlinesync', component: OfflineSyncComponent, canActivate: [AppAuthGuard] },
    { path: 'help', component: HelpComponent, canActivate: [AppAuthGuard] },
    { path: 'notifications', component: NotificationsComponent, canActivate: [AppAuthGuard] },
    { path: 'notificationverification', component: NotificationVerificationComponent, canActivate: [AppAuthGuard] },
    { path: 'viewattachment/:reportId/:attachmentGuid', component: ViewAttachmentComponent, canActivate: [AppAuthGuard] },
    {
        path: '',
        redirectTo: '/home',
        pathMatch: 'full'
    }
];

// if you need it, add enableTracing: true to the forRoot below
@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes, { useHash: true })
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
