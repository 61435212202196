<div class="dynamicInputDiv" [ngModelGroup]="question.questionMappingId">
    <label>
        <i *ngIf="questionAttrs['isRequired']" class="icon-cers-required"></i>
        {{ question.questionText | sentenceCasePipe }}
    </label>
    <div *ngIf="!question.isInvolvement">
        <select class="form-control dropdown" (change)="onChange()" [(ngModel)]="question.userAnswer" name="{{question.questionMappingId}}" [appDynamicValidation]="questionAttrs" [appFocus]="{questionId: question.questionId, questionMappingId: question.questionMappingId}"
            [compareWith]="selectAnswer" [disabled]="question.isReadOnly" #form="ngModel">
            <option *ngFor="let answer of question.answers" [ngValue]="answer">
                {{ answer.answerText }}
            </option>
        </select>
        <div *ngFor="let error of form.errors | keyvalue">
            <label class="errorText" *ngIf="error.key !== 'isRequired'"> {{error.value}} </label>
        </div>
        <div *ngIf="showReload()">
            <a id="recall" (click)="onChange()">Click here to reload follow-up questions</a>
        </div>
    </div>
    <app-confirm-flight-change [flightDetail]="currentFlightDetails" (confirmUpdate)="saveToStore($event)" (cancelUpdate)="cancelUpdate()"></app-confirm-flight-change>
</div>