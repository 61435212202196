import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import * as _ from 'lodash';
import { environment } from '@app/../environments/environment';

@Injectable({
    providedIn: 'root'
})

export class EncrDecrService {
    key = null;
    error = null;
    decryptStr = null;
    constructor() {
        this.getKeys();
    }

    public getKeys() {
        // get keys from config
        this.key = environment.encryptKey;
    }
    // The set method is use for encrypt the value
    set(value: string) {

        if (this.key) {
            const key = CryptoJS.enc.Utf8.parse(this.key);
            const iv = CryptoJS.enc.Utf8.parse(this.key);
            const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key, {
                keySize: 128 / 8,
                iv: iv,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });

            return encrypted.toString();
        } else {
            this.error = 'Encryption Key is null';
            throw new Error('Encryption Key is null');
        }
    }

    // The get method is use for decrypt the value
    get(value: string) {
        let decryptedString = '';
        if (value) {
            if (this.key) {
                const key = CryptoJS.enc.Utf8.parse(this.key);
                const iv = CryptoJS.enc.Utf8.parse(this.key);
                const decrypted = CryptoJS.AES.decrypt(value, key, {
                    keySize: 128 / 8,
                    iv: iv,
                    mode: CryptoJS.mode.CBC,
                    padding: CryptoJS.pad.Pkcs7
                });
                decryptedString = decrypted.toString(CryptoJS.enc.Utf8);
            } else {
                this.error = 'Encryption Key is null';
                throw new Error('Encryption Key is null');
            }
        }
        return decryptedString;
    }
}
