import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';
import { DynamicQuestionsService } from '@shared/SSEHubClient/dynamic-questions.service';
import { Question } from '@shared/common-ux/models/QuestionAnswer';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { QuestionConstants, MultiInputType } from './question.constant';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { CategoryDetailService } from '../category-detail.service';
import { QuestionViewService } from '../question-view/question-view.service';
import { orderBy } from 'lodash';
import { NotificationService } from 'app/shared/error-handler-notify/services/notification.service';
import { FeatureFlagService } from 'app/shared/feature-flag/feature-flag.service';
import { takeUntil } from 'rxjs/operators';
import { SSEHubInvolvementsService } from 'app/shared/SSEHubClient';
import { InvolvementType } from '@models';

@Component({
    selector: 'app-question-add',
    templateUrl: './question-add.component.html',
    styleUrls: ['./question-add.component.scss']
})
export class QuestionAddComponent implements OnInit, OnDestroy {
    @Input() generalQuestions: any;
    @ViewChild('createNewQuestion', { static: true }) public createNewQuestionModal;
    @ViewChild('createNewQuestionForm', { static: true }) createNewQuestionForm;
    @ViewChild(DataTableDirective)
    dtElement: DataTableDirective;
    questions: any;
    newQuestions: Question = new Question();
    answerInputType: any;
    isLoading = true;
    hasError = false;
    isReady = false;
    categoryId: number;
    profileId: Number;
    questionId: any;
    addQuestMetaData: any;
    dtOptions: any;
    submitted = false;
    textEmpty = false;
    parentAnswerId = -1;
    parentData: any;
    @Input() group: string;
    addQuest: any;
    dtTrigger: Subject<any> = new Subject();
    groupName: string;
    selectedInvolvement: string;
    selectedMultiInput: string;
    allInvolvements: InvolvementType[];
    allMultiInputs: MultiInputType[];
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private activatedRoute: ActivatedRoute,
        public dynamicQuestionsService: DynamicQuestionsService,
        private categoryDetailService: CategoryDetailService,
        private questionViewService: QuestionViewService,
        private involvementService: SSEHubInvolvementsService,
        private notificationService: NotificationService,
        public featureFlag: FeatureFlagService
    ) {
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    ngOnInit() {
        this.startLoadingState();

        this.answerInputType = this.categoryDetailService.state.canAdminFillOutReport ?
            QuestionConstants.answerInputType : _.filter(QuestionConstants.answerInputType, inputType => {
                inputType.toUpperCase() !== 'INVOLVEMENT';
            });

        this.newQuestions.answerInputType = this.answerInputType[0];

        this.selectedInvolvement = null;

        this.activatedRoute.params.pipe(takeUntil(this.destroy$)).subscribe((params: Params) => {
            this.profileId = params['profileId'];
            this.categoryId = params['categoryId'];
        });

        this.allMultiInputs = QuestionConstants.multiInputTypes;

        this.involvementService.getAllInvolvementTypes()
            .then((result: InvolvementType[]) => {
                if (result) {
                    result.map(inv => {
                        // check whether the first character is alphabetic and lowercase
                        if (inv.involvementTypeDescription && /[a-z]/i.test(inv.involvementTypeDescription.charAt(0))) {
                            inv.involvementTypeDescription = inv.involvementTypeDescription.charAt(0).toUpperCase()
                                + inv.involvementTypeDescription.slice(1);
                        }
                    });
                    this.allInvolvements = orderBy(result, ['InvolvementTypeDescription'], ['asc']);
                }
            }).catch((error) => {
                console.error(error);
            });
    }

    open() {
        this.createNewQuestionModal.open();
    }
    close() {
        this.createNewQuestionModal.close();
        this.onClose();
    }
    onOpen() {
        this.newQuestions.questionText = '';
        this.newQuestions.answerInputType = '';
    }
    onClose(event?) {
        this.resetForm();
        this.questionViewService.setSelectedAnswer(this.parentData);
        this.questionViewService.saveCreateNew(this.group);
    }

    onDismiss(event?) {
        this.resetForm();
        this.questionViewService.setSelectedAnswer(this.parentData);
        this.questionViewService.saveCreateNew(this.group);
    }

    saveNewQuestion(createNewQuestionForm: NgForm) {
        const form = this.createNewQuestionForm;
        this.submitted = true;
        this.textEmpty = false;
        if (this.newQuestions.questionText.trim() === '' && this.newQuestions.answerInputType !== 'Involvement') {
            this.textEmpty = true;
            return;
        }
        if (!form.valid) {
            return;
        }
        this.startLoadingState();
        let questionText = this.newQuestions.questionText;
        if (this.newQuestions.answerInputType === 'Involvement' && !this.newQuestions.questionText) {
            // if question text not specified and involvement, then default to involvement description
            this.allInvolvements.map(inv => {
                if (inv.id === parseInt(this.selectedInvolvement, 10)) {
                    questionText = inv.involvementTypeDescription;
                }
            });
        }

        // const attrs = (this.newQuestions.answerInputType.toLowerCase() === 'multi' && this.selectedMultiInput) ?
        //     { 'dataType': this.selectedMultiInput } : {};
        const attrs = {};

        this.dynamicQuestionsService.createNewQuestion({ question: questionText, inputType: this.newQuestions.answerInputType, attrs: attrs })
            .pipe(takeUntil(this.destroy$)).subscribe(
                (result) => {
                    if (result) {
                        const questionObj = this.createQuestionMetadataObject(result.primaryKey);
                        this.addNewQuestionToCategory(questionObj, this.questionViewService.selectedAnswer);
                        this.onReady();
                        this.submitted = false;
                        this.close();
                    } else {
                        this.notificationService.showError('Error creating a new question');
                        this.onError();
                    }
                },
                (error) => {
                    this.notificationService.showError('Error creating a new question');
                    this.onError();
                }
            );
    }

    public createQuestionMetadataObject(questionId: number) {
        const newQuestion = {
            questionId: questionId,
            questionText: '',
            isRequired: false,
        };
        return newQuestion;
    }

    public removeQuestionFromMasterList(question: any) {
        this.questions = _.remove(this.questions, function (c: any) {
            return c.questionId !== question.questionId;
        });
    }

    public addNewQuestionToCategory(question, parentData) {
        if (parentData && parentData.answerId) {
            this.parentData = parentData;
            this.parentAnswerId = parentData.answerId;
        } else {
            this.parentData = null;
            this.parentAnswerId = -1;
        }
        this.addQuestionToCategory(question);
    }

    public addQuestionToCategory(question: any) {
        this.addQuest = question;
        this.questionId = question.questionId;
        let isRoot = true;
        this.groupName = this.group;
        // ReferenceSK is used to map Involvement type to Questions. This is used only for Involvement questions
        if (this.group === 'INVOLVEMENTQUESTIONS') {
            const selectedCategory = this.categoryDetailService.state;
            if (selectedCategory && selectedCategory.selectedInvolvementType) {
                question.referenceSk = selectedCategory.selectedInvolvementType.id;
            }
        }

        // setting ReferenceSK to 6 to map Questionsto reportedBy. This is used only for Reported by questions
        if (this.group === 'ReportedBy') {
            question.referenceSk = '6';
            this.groupName = 'INVOLVEMENTQUESTIONS';
        }

        if (this.selectedInvolvement) {
            question.referenceSk = this.selectedInvolvement;
        }

        if (this.parentAnswerId > 0) {
            isRoot = false;
        }

        this.addQuestMetaData = {
            'categoryId': 0,
            'questionId': question.questionId,
            'question': '',
            'inputType': this.newQuestions.answerInputType,
            'isRequired': question.isRequired,
            'displayOrder': question.displayOrder,
            'isRoot': isRoot,
            'min': 0,
            'max': 0,
            'profileId': 0,
            'groupName': this.groupName,
            'referenceSk': question.referenceSk,
            'validateRules': Object.assign({}, this.newQuestions.answerInputType.toLowerCase() === 'number' ? { minValue: 0 } : {}),
            'answerId': this.parentAnswerId
        };
        this.startLoadingState();
        this.questionViewService.mapQuestionToCategory(
            this.profileId,
            this.categoryId,
            this.addQuestMetaData,
            this.onReady(),
            this.onError()
        );
    }

    private resetForm() {
        this.newQuestions.questionText = '';
        this.newQuestions.answerInputType = '';
        this.submitted = false;
        this.textEmpty = false;
        this.createNewQuestionForm.reset();
    }
    private startLoadingState() {
        this.isLoading = true;
        this.isReady = false;
        this.hasError = false;
    }

    private onError() {
        this.isLoading = false;
        this.isReady = false;
        this.hasError = true;
    }

    private onReady() {
        this.isLoading = false;
        this.isReady = true;
        this.hasError = false;
    }

}
