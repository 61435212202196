import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';


import { SSEHubClient } from './SSEHubClient.service';
import { Observable } from 'rxjs';
import { ITagDetails } from 'app/admin/category-management/category-management-store/tag';


@Injectable()
export class TagService {

    constructor(
        private http: HttpClient,
        private sseHubClient: SSEHubClient
    ) { }

    getAllTags(includeSystemRequired?: boolean, includeInactive?: boolean): Observable<any> {
        let params = new HttpParams();

        if (includeSystemRequired) {
            params = params.set('includeSystemRequired', includeSystemRequired.toString());
        }

        if (includeInactive) {
            params = params.set('includeInactive', includeInactive.toString());
        }

        return this.http.get(this.sseHubClient.serviceUrl() + '/api/tags/allTags', { params });
    }

    getMappedTags(questionMappingId: string, includeSystemRequired: boolean = false): Observable<any> {
        const params = new HttpParams()
            .set('includeSystemRequired', includeSystemRequired.toString());
        return this.http.get(this.sseHubClient.serviceUrl() + '/api/tags/questionMapping/' + questionMappingId + '/tags', { params });
    }

    getTagById(tagVal: string): Observable<any> {
        return this.http.get(this.sseHubClient.serviceUrl() + '/api/tags/' + encodeURIComponent(tagVal));
    }


    createTag(tag: ITagDetails) {
        const url = this.sseHubClient.serviceUrl() + '/api/tags/createTag';
        return this.http.put(url, tag);
    }

    updateTag(tag) {
        return this.http.put(this.sseHubClient.serviceUrl() + '/api/tags/updateTag', tag);
    }

    deactivateTag(tagVal: string) {
        return this.http.put(this.sseHubClient.serviceUrl() + '/api/tags/deactivate', tagVal);
    }
}

