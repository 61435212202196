import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SSEHubClient } from './SSEHubClient.service';
import { ServerErrorMessage } from '@models';

@Injectable()
export class SSEHubLogService {
    constructor(
        private http: HttpClient,
        private ssehubClient: SSEHubClient
    ) {

    }
    logError(errorMessage: ServerErrorMessage) {
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json; charset=utf-8',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, PATCH, POST, DELETE',
                'Access-Control-Allow-Headers': 'Content-Type',
                'Access-Control-Max-Age': '86400'
            })
        };

        return this.http.post(
            this.ssehubClient.serviceUrl() + '/api/error', JSON.stringify(errorMessage), httpOptions).toPromise();
    }
}

// allowedHeaders: [
//     'Content-Type',
//     'Authorization',
//     'Origin',
//     'x-access-token',
//     'XSRF-TOKEN'
// ],

