import { NgZone, Directive, ElementRef, AfterContentInit, Renderer2, Input, OnDestroy } from '@angular/core';
import { FocusService, IFocusKeys } from '../wizard/wizard.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive({
    selector: 'appFocus, [appFocus]'
})

export class FocusDirective implements AfterContentInit, OnDestroy {
    @Input('appFocus') elementKeys: IFocusKeys;
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private el: ElementRef,
        private zone: NgZone,
        private renderer: Renderer2,
        private focusService: FocusService
    ) { }

    ngAfterContentInit() {
        this.focusService.getFocusKey().pipe(takeUntil(this.destroy$)).subscribe(keysFromService => {
            if (keysFromService) {
                if (this.elementKeys.questionId === keysFromService.questionId &&
                    this.elementKeys.questionMappingId === keysFromService.questionMappingId && !this.elementKeys.skip) {
                    this.zone.runOutsideAngular(() => setTimeout(() => {
                        this.renderer.selectRootElement(this.el.nativeElement, true).focus();
                    }, 0));
                }
            }
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
