import {
    Directive, Input, OnInit, OnChanges, ElementRef
} from '@angular/core';

@Directive({
    selector: '[appSafeHtml]',
})
export class SafeHtmlDirective implements OnInit, OnChanges {
    @Input() htmlString: string;

    constructor(private elementRef: ElementRef) { }

    ngOnChanges() {
        this.elementRef.nativeElement.innerHTML = this.htmlString;
    }

    ngOnInit() {
        this.elementRef.nativeElement.innerHTML = this.htmlString;
    }
}
