export class Question {
    category: string;
    userAnswer: string;
    questionId: number;
    questionText: string;
    displayQuestionText: string;
    isRequired: boolean;
    answerInputType: string;
    followupQuestions: Question[];
    hyperLinks: string[];
    displayOrder: number;
    min: number;
    max: number;
    attrs?: any = {};
    isValid: boolean;
    manualValidation?: () => void;
    setEditability?: (editable: boolean) => void;
    public answers: Answer[];
    referenceId: number;
    additionalField: string;
    parentId: number;
    template: string;
    groupName: string;
}

export class Answer {
    questionIdfk: number;
    answerId: number;
    answerText: string;
    displayOrder: number;
    isChecked: boolean;
    followupQuestions: Question[];
    answerInputType?: string;
    attrs?: object;
}

export class QuestionAnswer {
    answerId: number;
    questionMappingId: number;
    answer: string;
    displayorder: number;
    attrs?: object;
    constructor() {
        this.answerId = 0;
        this.questionMappingId = 0;
        this.answer = '';
        this.displayorder = 0;
    }
}

export class Category {
    id: number;
    description: string;
    categoryId: string;
    displayOrder: number;
    categorySections: CategorySection[];
    questions: Question[];
}

export class CategorySection {
    id: number;
    categoryId: number;
    sectionName: string;
    visibility: number;
    sectionVisibility: string;
}

export abstract class RecursiveQAComponent {
    callAction: (event: string, object: any) => void;
}
