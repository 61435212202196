import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from 'app/shared/error-handler-notify/services';
import { Store } from '@ngrx/store';
import { State } from 'app/store';
import * as Actions from '../category-management-store/tag/tag.actions';
import { ITagDetails, ITagErrors } from '../category-management-store/tag';
import { takeUntil } from 'rxjs/operators';
import { getUpdateTagError, getUpdateTagSuccess } from '../category-management-store';
import { Subject } from 'rxjs';
import { availableTagDescription } from '../question-tag-management/question-tag-management.component';
import { CanComponentDeactivate } from 'app/can-deactivate.guard';

@Component({
    selector: 'app-edit-tag-modal',
    templateUrl: './edit-tag-modal.component.html',
    styleUrl: './edit-tag-modal.component.scss'
})

export class EditTagModalComponent implements OnInit, OnDestroy, CanComponentDeactivate {
    @Input() showSaveAndApplyButton: boolean = true;
    @Output() actionAddTagMapping: EventEmitter<any> = new EventEmitter();
    @Output() onClose: EventEmitter<any> = new EventEmitter();
    @Input() tagData: availableTagDescription; // tag data to edit

    destroy$: Subject<void> = new Subject<void>();

    opened: boolean;    // indicates if the main window is open or closed
    confirmExitDialogOpened: boolean;    // indicates if the confirm exit popup us open or closed
    editTagFormGroup!: FormGroup;   // new editTag form group

    // max length values for the fields
    displayMaxLength = 100;
    descriptionMaxLength = 200;

    // character count for the fields
    displayCharCount: number;
    descriptionCharCount: number;
    
    // error text to display below the field
    descriptionError = '';
    displayError = '';

    // Promise resolver for canDeactivate
    canDeactivateResolver: ((value: boolean) => void) | null = null;

    constructor(
        public notificationService: NotificationService,
        private store: Store<State>,
    ) { }

    ngOnInit(): void {
        this.open();
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private open(): void {
        this.resetErrorHints();
        this.initializeCharCounts();
        this.editTagFormGroup = new FormGroup({
            name: new FormControl(
                { value: this.tagData.tagVal, disabled: true }
            ),
            display: new FormControl(
                this.tagData.tagDisplayTxt, 
                {
                    nonNullable: true, 
                    validators: [
                        Validators.required,
                        Validators.maxLength(this.displayMaxLength),
                        Validators.minLength(1),
                    ],
                }
            ),
            description: new FormControl(
                this.tagData.tagDescription, 
                {
                    nonNullable: true, 
                    validators: [
                        Validators.required,
                        Validators.maxLength(this.descriptionMaxLength),
                        Validators.minLength(1),
                    ],
                }
            ),
            systemRequired: new FormControl(
                this.tagData.systemRequired, 
                {
                    nonNullable: true, 
                    validators: [Validators.required],
                }
            ),
            pii: new FormControl(
                this.tagData.piiInd, 
                {
                    nonNullable: true, 
                    validators: [Validators.required],
                }
            ),
        });
        this.opened = true; // opens the window
    }

    private initializeCharCounts() {
        this.displayCharCount = this.tagData.tagDisplayTxt.length;
        this.descriptionCharCount = this.tagData.tagDescription.length;
    }
    
    private resetErrorHints() {
        this.displayError = '';
        this.descriptionError = '';
    }

    private close(): void {
        this.onClose.emit();
    }

    // updates for the char counts
    public onDisplayValueChange(ev: string): void {
        this.displayCharCount = ev.length;
    }

    public onDescriptionValueChange(ev: string): void {
        this.descriptionCharCount = ev.length;
    }

    // save, save & apply, and discard actions
    public onSaveAndApply() {
        this.onSave(true);
    }
    
    public onSave(mapQuestion?: boolean) {
        this.resetErrorHints();

        if (this.editTagFormGroup.invalid) {
            this.editTagFormGroup.markAllAsTouched();
            this.notificationService.showError("Please complete all required fields.");
            return;
        }

        const editedTag: ITagDetails = {
            tagVal: this.editTagFormGroup.get('name')?.value,
            tagDescription: this.editTagFormGroup.get('description')?.value,
            tagDisplayTxt: this.editTagFormGroup.get('display')?.value,
            systemRequired: this.editTagFormGroup.get('systemRequired')?.value,
            piiInd: this.editTagFormGroup.get('pii')?.value,
        }

        this.store.dispatch(new Actions.UpdateTag({ tag: editedTag }));

        this.store.select(getUpdateTagSuccess).pipe(takeUntil(this.destroy$)).subscribe(success => {
            if (success) {
                if (mapQuestion) {
                    this.actionAddTagMapping.emit(editedTag.tagVal);
                }
                this.close();
            }
        });

        this.store.select(getUpdateTagError).pipe(takeUntil(this.destroy$)).subscribe(errors => {
            const errorsObject = errors as ITagErrors;
            for (const key of Object.keys(errorsObject)) {
                if (errors[key]) {
                    if (key == 'TagDescription' ) {
                        this.descriptionError = errors[key][0];
                    }
                    if (key == 'TagDisplayTxt') { 
                        this.displayError = errors[key][0];
                    }
                }
            }
        });
    }

    private hasUnsavedChanges(): boolean {
        // Check if any fields have been changed from their default values
        // If so, then display a popup asking if the user really wants to navigate away and lose their changes
        const defaultValues: Record<string, any> = {
            name: this.tagData.tagVal,
            display: this.tagData.tagDisplayTxt,
            description: this.tagData.tagDescription,
            pii: this.tagData.piiInd,
            systemRequired: this.tagData.systemRequired
        };

        const hasChanges = Object.keys(this.editTagFormGroup.controls).some(key => {
            const currentValue = this.editTagFormGroup.get(key)?.value; // If not found, equals undefined
            const defaultValue = defaultValues[key] ?? null; // Fallback to null if key doesn't exist

            // The field has been changed (i.e., no longer equals default value)
            return currentValue !== defaultValue;
        });

        return hasChanges;
    }

    public onDiscard() {
        if (this.hasUnsavedChanges()) {
            this.confirmExitDialogOpened = true; // Open the confirmation dialog
            this.opened = false; // Close the current dialog or form
            return; // Exit the method to prevent further execution
        }
        this.close(); // If no changes, simply close the form/dialog
    }

    canDeactivate(): Promise<boolean> | boolean {
        if (this.hasUnsavedChanges ()) {
            this.confirmExitDialogOpened = true; // Open the confirmation dialog
            this.opened = false; // Close the current dialog or form

            // Return a Promise that resolves based on the user's choice
            return new Promise<boolean>((resolve) => {
                this.canDeactivateResolver = resolve;
            });
        }
        return true; // Allow deactivation if there are no changes
    }

    // yes or no exit the main popup. this comes from the confirm exit popup
    public onConfirmExit(exit: boolean) {
        if (exit) { // We are exiting out of the process
            this.confirmExitDialogOpened = false; // Close the dialog
            this.close();
            this.resolveCanDeactivate(true); // Allow navigation
            return;
        }
        // We want to return to the create tag popup
        this.confirmExitDialogOpened = false;
        this.opened = true;
        this.resolveCanDeactivate(false); // Prevent navigation
    }

    // Resolve the pending canDeactivate promise and clean up the resolver.
    private resolveCanDeactivate(allowNavigation: boolean) {
        if (this.canDeactivateResolver) {
            this.canDeactivateResolver(allowNavigation);
            this.canDeactivateResolver = null; // Clean up
        }
    }
}
