import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getUser, State } from 'app/store';
import { getCategoryDetails, getSelectedQuestionMappingText, getAllTags, getQuestionMappingTags, getSelectedCategory, getAddTagToQuestionMappingError } from '../category-management-store';
import * as Actions from '../category-management-store/tag/tag.actions';
import { LoadCategoryDetail } from '../category-management-store/detail';
import { DynamicQuestionsService } from 'app/shared/SSEHubClient/dynamic-questions.service';
import { CompositeFilterDescriptor, filterBy, FilterDescriptor } from '@progress/kendo-data-query';
import { LoggerService } from 'app/shared/error-handler-notify/services';
import { ITagDetails } from '../category-management-store/tag';
import { FilterService, GridComponent, RowClassArgs } from '@progress/kendo-angular-grid';
import { IUser, User } from 'app/store/user';
import { CanComponentDeactivate } from 'app/can-deactivate.guard';
import { CreateNewTagModalComponent } from '../create-new-tag-modal/create-new-tag-modal.component';
import { EditTagModalComponent } from '../edit-tag-modal/edit-tag-modal.component';

export interface availableTagDescription extends ITagDetails {
    isMapped: boolean;
    //isActiveTag: boolean; // Flag to show if the tag is active or not. This is not used in the UI currently as "Active" column is not shown. If it needed in future then uncomment this property and appropriate code in component and spec file.
}
@Component({
    selector: 'app-question-tag-management',
    templateUrl: './question-tag-management.component.html',
    styleUrl: './question-tag-management.component.scss'
})

export class QuestionTagManagementComponent implements OnInit, OnDestroy, CanComponentDeactivate {
    destroy$: Subject<void> = new Subject<void>();
    category$: Observable<string>;
    categoryId: number;
    profileId: number;
    questionMappingId: string;
    mappedTagVals: string[] = [];
    availableTags: availableTagDescription[] = []; // Original data
    filteredTags: availableTagDescription[] = []; // Filtered data for grid
    selectedTags: string[] = [];
    questionText$: Observable<string>;
    isITUser: boolean = false;
    tagToRemove: any;
    questionMappingErrorMessage: string
    searchTerm: string = '';
    statusOptions = [{ text: 'Active', value: true }, { text: 'Inactive', value: false }];
    booleanOptions = [{ text: 'Yes', value: true }, { text: 'No', value: false }];
    defaultOption = { text: 'Select', value: null };
    isCreateNewTagModalOpen = false;
    confirmRemoveTagDialogOpened = false;
    isEditTagModalOpen = false;
    selectedEditTag: availableTagDescription;
    addTagUserErrorModalOpened = false; // Flag to open the error modal
    confirmExitDialogOpened: boolean; // Indicates if the confirm exit dialog is open
    @ViewChild('grid') grid: GridComponent;
    @ViewChild(CreateNewTagModalComponent) createNewTagModal: CreateNewTagModalComponent;
    @ViewChild(EditTagModalComponent) editTagModal: EditTagModalComponent;

    // Promise resolver for canDeactivate
    canDeactivateResolver: ((value: boolean) => void) | null = null;

    constructor(
        private store: Store<State>,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private dynamicQuestionsService: DynamicQuestionsService,
        private logger: LoggerService,
    ) { }

    // Only IT Admins should be able to see System tags.
    public setPrivileges(user: IUser) {
        if (user) {
            this.isITUser = User.hasPrivilege(user, 'WEB_SUADMIN');
        }
    }

    ngOnInit() {
        this.store.select(getUser).pipe(
            take(1)
        ).subscribe(user => {
            this.setPrivileges(user);
        });

        this.activatedRoute.paramMap.pipe(
            switchMap(params => {
                this.profileId = +params.get('profileId');
                this.categoryId = +params.get('categoryId');
                this.questionMappingId = params.get('questionMappingId');

                this.category$ = this.getCategoryDetails(this.profileId, this.categoryId);
                this.questionText$ = this.getQuestionMetadata(this.questionMappingId);

                return this.prepareTagCollections();
            }),
            takeUntil(this.destroy$)
        ).subscribe(tags => {
            // Below block of code is commented out as isActiveTag is not used in the UI. This can be used in future if needed, appropiate changes (comment out code) need to be made in the UI.
            //this.availableTags = tags.filter(tag => {
            //    const activeTag = this.checkTagActiveStatus(tag.effectiveDate, tag.inactiveDate);
            //    // If isITUser then show all tags (active and inactive), else show only active non-system tags.
            //    return this.isITUser || (activeTag && !tag.systemRequired);
            //}).map(tag => ({
            //    ...tag,
            //    isActiveTag: this.checkTagActiveStatus(tag.effectiveDate, tag.inactiveDate)
            //}))
            this.availableTags = tags;
            this.searchTags(false);
        });

        this.store.select(getAddTagToQuestionMappingError).pipe(
            takeUntil(this.destroy$)
        ).subscribe(errorMessage => {
            if (errorMessage && errorMessage !== "") {
                this.questionMappingErrorMessage = errorMessage;
                this.addTagUserErrorModalOpened = true;
            }
        });
    }

    // Fetch the category details from the store's getSelectedCategory selector if not available in the store
    // then dispatch an action to load the category details and select from getCategoryDetails selector
    getCategoryDetails(profileId: number, categoryId: number): Observable<string> {
        return this.store.select(getSelectedCategory).pipe(
            switchMap(category => {
                if (category) {
                    return of(category);
                } else {
                    this.store.dispatch(new LoadCategoryDetail(profileId, categoryId));
                    return this.store.select(getCategoryDetails).pipe(map(category => category.description));
                }
            })
        );
    }


    prepareTagCollections() {
        // Checking privilege.
        const suAdmin = this.isITUser;
        if (suAdmin) {
            // Dispatch actions to load all tags and mapped tags
            // Fetch all tags and mapped tags for IT users.
            this.store.dispatch(new Actions.LoadAllTags({ includeSystemRequired: null, includeInactive: null }));
            this.store.dispatch(new Actions.LoadTagsForQuestionMapping({ questionMappingId: this.questionMappingId, includeSystemRequired: true }));
        } else {
            // Dispatch actions to load all tags and mapped tags
            // Fetch only non-system tags for non-IT users.
            this.store.dispatch(new Actions.LoadAllTags({ includeSystemRequired: false, includeInactive: null }));
            this.store.dispatch(new Actions.LoadTagsForQuestionMapping({ questionMappingId: this.questionMappingId, includeSystemRequired: false }));
        }
        // Select the data from the store
        return combineLatest({
            allTags: this.store.select(getAllTags),
            mappedTags: this.store.select(getQuestionMappingTags)
        }).pipe(
            debounceTime(0),
            map(({ allTags, mappedTags }) => {
                if (allTags.length > 0) {
                    // Filter tags based on privileges.
                    return this.getAvailableTagsToMap(allTags, mappedTags);
                }
            })
        );
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        this.store.dispatch(new Actions.ResetAddTagToQuestionMapping());
    }

    getQuestionMetadata(questionMappingId: string): Observable<string> {
        return this.store.select(getSelectedQuestionMappingText).pipe(
            switchMap(questionText => {
                if (questionText) {
                    // questionText is available in the store
                    return of(questionText);
                } else {
                    // questionText is not available in the store, make a network call
                    return this.dynamicQuestionsService.getQuestionsMetaData(questionMappingId).pipe(
                        map(data => {
                            return data.question;
                        }),
                        catchError(error => {
                            this.logger.error('Error fetching question metadata:', error);
                            return of(null);
                        })
                    );
                }
            })
        );
    }

    getAvailableTagsToMap(allTags, mappedTags): availableTagDescription[] {
        const mappedTagVals = new Set(mappedTags.map(tag => tag.tagVal));
        //store the mapped tag values for showing the mapped tags in the multiselect
        this.mappedTagVals = Array.from(mappedTagVals) as string[];
        this.selectedTags = Array.from(mappedTagVals) as string[];
        return allTags.map(tag => ({ ...tag, isMapped: mappedTagVals.has(tag.tagVal) }))
    }
    
    canDeactivate() {
        // Prepare a filtered list of selected tags:
        // - `selectedTags` contains only the tags selected by the user that are not already mapped.
        const selectedTags = this.selectedTags.filter((tagVal) => !this.mappedTagVals.includes(tagVal));
    
        // Handle deactivation scenarios:
        // 1. If the edit tag modal is open, delegate deactivation logic to it.
        if (this.isEditTagModalOpen) {
            return this.editTagModal.canDeactivate();
        }
        // 2. If the create new tag modal is open, delegate deactivation logic to it.
        else if (this.isCreateNewTagModalOpen) {
            return this.createNewTagModal.canDeactivate();
        }
        // 3. If there are unsaved selected tags, open a confirmation dialog and wait for user input.
        else if (selectedTags.length > 0) {
            this.confirmExitDialogOpened = true;
            return new Promise<boolean>((resolve) => {
                this.canDeactivateResolver = resolve; // Resolve the promise based on the user's choice.
            });
        }
        // 4. If none of the above conditions are met, allow deactivation by default.
        else {
            return true;
        }
    };
    
    // yes or no exit the main popup. this comes from the confirm exit popup
    public onConfirmExit(exit: boolean) {
        if (exit) { // We are exiting out of the process
            this.confirmExitDialogOpened = false; // Close the dialog
            this.resolveCanDeactivate(true); // Allow navigation
            return;
        }
        // We want to return to the create tag popup
        this.confirmExitDialogOpened = false;
        this.resolveCanDeactivate(false); // Prevent navigation
    }

    // Resolve the pending canDeactivate promise and clean up the resolver.
    private resolveCanDeactivate(allowNavigation: boolean) {
        if (this.canDeactivateResolver) {
            this.canDeactivateResolver(allowNavigation);
            this.canDeactivateResolver = null; // Clean up
        }
    }
    
    gotoActiveCategory() {
        this.router.navigate(['/admin/categorymanagement/profile/' + this.profileId + '/category/' + this.categoryId + '/summary']);
    }

    searchTags(resetGrid: boolean): void {
        if (!this.searchTerm) {
            this.filteredTags = [...this.availableTags];
        } else {
            const searchFilters: FilterDescriptor[] = [{
                field: "tagVal",
                operator: "contains",
                value: this.searchTerm
            },
            {
                field: "tagDisplayTxt",
                operator: "contains",
                value: this.searchTerm
            },
            {
                field: "tagDescription",
                operator: "contains",
                value: this.searchTerm
            }];

            let compositeFilter: CompositeFilterDescriptor = { logic: "or", filters: searchFilters };
            this.filteredTags = filterBy(this.availableTags, compositeFilter);
        }
        if (resetGrid) {
            this.grid.pageChange.emit({ skip: 0, take: this.grid.pageSize });
        }
    }

    getCurrentFilterValue(
        filter: CompositeFilterDescriptor,
        fieldName: string
    ): any {
        const currentFilter = (filter.filters as FilterDescriptor[]).find(
            (f) => f.field === fieldName
        ) as FilterDescriptor;

        return currentFilter ? currentFilter.value : null;
    }

    onFilterChange(
        value: boolean,
        filterService: FilterService,
        fieldName: string
    ): void {
        filterService.filter({
            filters: [{ field: fieldName, operator: 'eq', value: value }],
            logic: 'or',
        });
    }
    
    // Below block of code is commented out as isActiveTag is not used in the UI. This can be used in future if needed, appropiate changes (comment out code) need to be made in the UI.
    // Check if the tag is active based on effective and inactive dates.
    // private checkTagActiveStatus(effectiveDate: Date, inactiveDate?: Date): boolean {
    //     const currentDate = new Date();
    //     if (!inactiveDate) {
    //         return true;
    //     }
    //     return effectiveDate <= currentDate && inactiveDate >= currentDate;
    // }

    public toggleCreateNewTagDialog(open: boolean): void {
        this.isCreateNewTagModalOpen = open;
    }

    removeTag(tag: any): void {
        this.tagToRemove = tag;
        this.confirmRemoveTagDialogOpened = true;
    }

    public onRemoveTagConfirm(remove: boolean): void {
        this.confirmRemoveTagDialogOpened = false;

        if (!remove) {
            return;
        }
        if (this.tagToRemove && this.questionMappingId) {
            const actionToRemoveQuestionTag = new Actions.RemoveTagFromQuestionMapping({
                questionMappingId: this.questionMappingId,
                tagVal: this.tagToRemove,
                includeSystemRequired: this.isITUser
            });
            this.store.dispatch(actionToRemoveQuestionTag);
        } else {
            this.logger.error('Tag or questionMappingId is not set');
        }
    }

    public onApply() {
        //selectedtags is the  array of selectedTags minus the mappedtagvals
        const selectedTags = this.selectedTags.filter((tagVal) => !this.mappedTagVals.includes(tagVal));
        this.applyTagMappings(selectedTags);
    }

    public applyTagMappings(tags: string[]): void {
        this.store.dispatch(new Actions.AddTagToQuestionMapping({
            questionMappingId: this.questionMappingId,
            tagVal: tags,
            includeSystemRequired: this.isITUser
        }));
    }

    public onEdit(dataItem: any): void {
        if (!this.isEditTagModalOpen) {
            this.isEditTagModalOpen = true;
            this.selectedEditTag = dataItem;
        }
    }

    public handleEditModalClose() {
        this.isEditTagModalOpen = false;
    }

    public closeAddTagErrorModal() {
        this.addTagUserErrorModalOpened = false;
        this.store.dispatch(new Actions.ResetAddTagToQuestionMapping());
    }

    public myRowSelectionKey(context): string {
        return context.dataItem.tagVal;
    };

    public rowClass = (args: RowClassArgs) => {
        if (args.dataItem.isMapped) {
            return { 'k-selected': false };
        }
    };
    
    itemDisabled(itemArgs: { dataItem: any; index: number }) {
        return itemArgs.dataItem.value === null;
    }
}
