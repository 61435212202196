import { Component, EventEmitter, ViewChild, Input, AfterViewInit, Output, OnInit } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { IDynamicQuestion, IAnswer } from '../../new-report-store/wizard';


@Component({
    selector: 'app-radio',
    templateUrl: './radio.component.html',
    styleUrls: ['../dynamic-form.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})

export class RadioComponent implements AfterViewInit {

    @Input() question: IDynamicQuestion;
    @Input() groupName: string;

    @Output() answerEvent$: EventEmitter<any> = new EventEmitter<any>();
    questionAttrs: object = {};
    @ViewChild('form') form: NgModel;
    ngAfterViewInit() {
        // check if radio button has answer
        if (this.question && this.question.userAnswer) {
            const answ: any = this.question.userAnswer;
            const el: any = document.getElementById(`${this.question.questionMappingId}-${answ.answerId}`);
            setTimeout(() => { // set radio element to check manually
                if (el) {
                    el.checked = true;
                }
            }, 200);
        }
        // Check if this radio button is in the flight section and that it only has one answer
        // If it does then we want to default it to expanded so the user doesnt have to click
        if (this.groupName.toLowerCase() === 'flight' && this.question.answers.length === 1) {
            this.question.userAnswer = this.question.answers[0];
            this.onChange(this.question.answers[0]);
        }
    }

    showReload() {
        const attrs = this.question.attrs === null ? {} : this.question.attrs;
        const failedApi = attrs['failedAPICall'];

        return failedApi === undefined ? false : failedApi;
    }
    onChange(answer) {
        this.answerEvent$.emit({
            question: this.question,
            answer: answer,
            myform: this.form
        });
    }
}
