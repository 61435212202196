<modal #empSearchModal (onClose)="onClose($event)">

    <modal-header>
        <h4>Employee search</h4>
    </modal-header>

    <modal-content>
        <form [formGroup]="empForm" (ngSubmit)="empSearch()">
            <div class="lineContainer flight-search-section">
                <div class="dynamicInputDiv inline20 left">
                    <label>Emp. ID</label>
                    <input type="text" class="form-control" maxlength="8" pattern="[0-9]*" id="id" formControlName="id">
                </div>

                <div class="dynamicInputDiv inline30">
                    <label>First name</label>
                    <input type="text" class="form-control" id="firstName" formControlName="firstName">
                </div>

                <div class="dynamicInputDiv inline50 right">
                    <label>Last name</label>
                    <input type="text" class="form-control" id="lastName" formControlName="lastName">
                </div>
            </div>
            <div class="lineContainer flight-search-section second-row">
                <div class="dynamicInputDiv inline20 left">
                    <label>Station</label>
                    <input type="text" class="form-control" maxlength="3" pattern="[a-zA-Z]{3}" id="station"
                        formControlName="station" (input)="replaceInput($event)" [matAutocomplete]="auto">
                    <mat-autocomplete #station #auto="matAutocomplete" panelWidth="400px">
                        <mat-option *ngFor="let option of options" [value]="option.AirportId">
                            {{ option.airportId + ' - ' + option.airportDesc }}
                        </mat-option>
                    </mat-autocomplete>
                    <div class="validation-fail"
                        *ngIf="empForm.controls['station'].invalid && empForm.controls['station'].errors.pattern">
                        Must be 3 letters
                    </div>
                </div>
                <div class="dynamicInputDiv inline30">
                    <label>Job title</label>
                    <input type="text" class="form-control" id="jobTitle" formControlName="jobTitle">
                </div>

                <div class="dynamicInputDiv inline50 right">
                    <label>Department</label>
                    <input type="text" class="form-control" id="department" formControlName="department">
                </div>
            </div>
            <div>
                <p class="fontRed" *ngIf="atLeastOneFieldRequired">
                    At least one search field is required.</p>
                <p class="fontRed"
                    *ngIf="empForm.controls['id'].errors?.minlength || empForm.controls['id'].errors?.pattern">
                    Employee ID must be 6 numbers
                </p>
            </div>
            <div class="search-buttons-div footer-right">
                <button type="submit" [disabled]="atLeastOneFieldRequired || empForm.invalid"
                    class="btn-primary search-buttons">Search</button>
                <button type="reset" class="btn-cancel leftMargin search-buttons" (click)="reset()">Clear</button>
            </div>
        </form>
        <app-aa-spinner [loading]="isSearching"></app-aa-spinner>
        <div *ngIf="!isSearching">
            <div *ngIf="empList && empList.length > 0">
                <hr>
                <div *ngIf="empList.length >= resultLimit">
                    <label class="fontRed margin-bottom-5">Result limit of {{resultLimit}} exceeded. Refine search
                        criteria.</label>
                </div>
                <input type="text" class="form-control marginBottom" (keyup)="onQuickFilterChanged()"
                    [(ngModel)]="quickSearchValue" placeholder="Filter..." />
                <ag-grid-angular #agGrid id="empResultsGrid" [ngStyle]="style" class="ag-theme-balham marginBottom"
                    [rowData]="empList" [gridOptions]="gridOptions" (rowSelected)="onRowSelected($event)"
                    (gridReady)="onGridReady($event)">
                </ag-grid-angular>
            </div>
            <div *ngIf="(empList && empList.length <= 0) || searchError">
                <hr>
                <label class="fontRed">No employees match the search criteria. Verify search details.</label>
            </div>
        </div>
    </modal-content>

    <modal-footer *ngIf="selectedEmp">
        <label class="footer-left">Selection:
            {{ this.selectedEmp | formatEmployeeNameDisplay }}</label>
        <button type="submit" class="btn-primary" (click)="populatePersonData()">Select</button>

    </modal-footer>
</modal>
