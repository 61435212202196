<modal #templateAddModal id="templateAddModal" (onClose)="onClose($event)" (onDismiss)="onClose($event)">
    <modal-header>
        <h4>Template Questions</h4>
    </modal-header>
    <modal-content>
        <app-aa-spinner [loading]="(templateNames$ | async).isLoading || isLoading">
        </app-aa-spinner>

        <ng-container *ngIf="!(templateNames$ | async).isLoading && !isLoading">
            <form #form="ngForm">
                <select required name="templateSelect" id="templateSelect" class="form-control dropdown"
                    [(ngModel)]="selectedTemplate" (change)="filterQuestions($event)">
                    <option [ngValue]="null">Select a template</option>
                    <option *ngFor="let template of ((templateNames$ | async).templates | alphabetize: 'asc') "
                        [ngValue]="template">
                        {{ template | displayTemplateName }}
                        <p *ngIf="(existingTemplates$ | async).includes(template)"> - Existing</p>
                    </option>
                </select>
            </form>
            <p *ngIf="selectedTemplate && (existingTemplates$ | async).includes(selectedTemplate)" class="errorText">
                The {{ selectedTemplate | displayTemplateName }} template has already been added. Saving changes
                here will delete the currently mapped {{ selectedTemplate | displayTemplateName }} questions and
                map new template questions based on your selections below.
            </p>
            <app-aa-spinner [loading]="(templateNames$ | async) && selectedTemplate && !(questionsToDisplay$ | async)"></app-aa-spinner>
            <div *ngIf="questionsToDisplay$ | async as questions"
                [ngClass]="{'topMargin': !(selectedTemplate && (existingTemplates$ | async).includes(selectedTemplate))}">
                <ng-container *ngIf="questions.length > 0">
                    <div *ngIf="questions.length > 1" class="bottomMargin">
                        <button class="btn-cancel" (click)="checkAll(questions, true)">Check all</button>
                        <button class="btn-cancel leftMargin" (click)="checkAll(questions, false)">Uncheck all</button>
                    </div>
                    <table class="category-table table table-hover table-striped">
                        <tbody>
                            <tr>
                                <th>
                                    <h4>Add</h4>
                                </th>
                                <th>
                                    <h4>Question</h4>
                                </th>
                                <th class="no-wrap">
                                    <h4>Input Type</h4>
                                </th>
                            </tr>
                            <tr *ngFor="let question of questions">
                                <ng-container *ngIf='question.isRoot'>
                                    <td>
                                        <input type="checkbox" [name]="question.questionId" [value]="question"
                                            class="checkbox" [(ngModel)]="question.isChecked" />
                                    </td>
                                    <td>
                                        {{ question.questionText }}
                                    </td>
                                    <td class="no-wrap">
                                        {{ question.inputType | capitalizeFirstLetter }}
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </ng-container>
            </div>
        </ng-container>
    </modal-content>

    <modal-footer>
        <button class="btn btn-cancel" (click)="templateAddModal.close()">Close</button>
        <button
            *ngIf="questionsToDisplay$ | async | displayTemplateSaveButton: selectedTemplate : (existingTemplates$ | async)"
            class="btn btn-primary leftMargin" type="button" (click)="mapTemplate()">Save</button>
    </modal-footer>
</modal>
