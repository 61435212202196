<ol class="breadcrumb">
    <li>
        <a routerLink="/home">
            <i class="icon-home"></i> Home</a>
    </li>
    <li class="active">Help</li>
</ol>
<div class="help-main-text">
    <h4>
        Welcome to CERS! We strive to provide our fellow employees or contractors with the most convenient and accessible reporting
        platforms. Please reference our
        <a href="assets/static-resources/CERSReportHelp2.pdf" target="_blank">user guide </a> for an in depth explanation of features and accessibility.
    </h4>
</div>
<div class="help-section">
    <h2>Navigation</h2>
    <div class="help-row">
        <h4>Home</h4>
        <span class="description">
            Brief introduction to the system and main navigation. At the top right, your name is confirmed with the dropdown that provides access
            to 'Logout' or other privileges if any.
        </span>
    </div>
    <div class="help-row">
        <h4>Fill out report</h4>
        <span class="description">Provides you with the ability to fill out report.</span>
    </div>
    <div class="help-row">
        <h4>Report history</h4>
        <span class="description">
            Listing of all your reports submitted into the CERS system, each report id is a hyperlink to your ‘Report detail’.
        </span>
    </div>
    <div class="help-row">
        <h4>To Do:</h4>
        <span class="description">
            List of all reports/activities that require your attention.
        </span>
    </div>

</div>
<div class="help-section">
    <div class="help-row">
        <h2>User guide</h2>
        <span class="description">
            <a href="assets/static-resources/CERSReportHelp2.pdf" target="_blank">Click here </a>to access the help document including troubleshooting.
        </span>
    </div>
</div>
<div class="help-section">
    <h2>Frequently asked questions</h2>
    <div class="help-row">
        <h4>How do I access CERS to submit a report?</h4>
        <span class="description">CERS is available on desktop and mobile devices using <a href="https://cers.aa.com" target="_blank">https://cers.aa.com</a>.</span>
    </div>
    <div class="help-row">
        <h4>Can I open a report using any browser?</h4>
        <span class="description">No, currently the browsers supported are Microsoft Edge, Chrome, Island, and Safari.</span>
    </div>
    <!--
    <div class="help-row">
        <h4>How do I get the CERS mobile app?</h4>
        <span class="description">Follow these instructions to install CERS as a mobile app on iOS(Apple devices only): </span>
        <ol type="a">
            <li>Ensure your device is connected to Wi-Fi or Mobile network.</li>
            <li>Log on to <a href="https://newcers.aa.com" target="_blank">https://newcers.aa.com</a> on the mobile phone. </li>
            <li>Click on the Action icon on the browser.</li>
            <img src="assets/static-resources/actions_icon2.png" alt="actions icon" style="width:50px;height:60px">
            <li>Click on the "Add to Home Screen".</li>
            <img src="assets/static-resources/home_screen_icon3.png" alt="home screen icon" style="width:70px;height:85px;margin-left:-10px">
            <li>Upon completion, you will find the CERS icon on your device.</li>
            <img src="assets/static-resources/cers_icon.png" alt="cers icon" style="width:55px;height:70px">
        </ol>
    </div>
    <div class="help-row">
        <h4>What is available when using CERS as a mobile app?</h4>
        <span class="description">
            <ol type="a">
                <li>'Sync my data' updates your crew sequence and all other flight data.</li>
                <li>Pending Submissions - Those saved while you were offline, will only be visible until you connect to a Wi-Fi
                or cell service on the mobile device.</li>
                <li>Unfinished Reports - You can save a report when filling out a report and pick up where you left off under the
                To do using unfinished reports on the mobile device.</li>
            </ol>
        </span>
    </div>
    -->
    <div class="help-row">
        <h4>I don't see my report in the Report history tab!</h4>
        <span class="description">Submitted reports will appear in your history when they are fully processed, this normally takes about two minutes.
        If you do not see your report within two hours of submission please contact the help desk for assistance 918-833-3333.
        Don’t worry, your report was not lost, it may just need a nudge to finish processing.</span>
    </div>
    <div class="help-row">
        <h4>Report detail under Report history.</h4>
        <span class="description">Provides you with visibility to your submitted report, report status, activity log, and recipient list for that report.</span>
    </div>
</div>
