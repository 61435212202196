import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import * as newReportActions from './list.actions';
import { map, catchError, mergeMap } from 'rxjs/operators';
import { Observable, EMPTY as empty, of } from 'rxjs';
import { ErrorHandlerService } from '@shared/error-handler/error-handler.service';
import { SSEHubCategoriesService } from '@shared/SSEHubClient/categories.service';

@Injectable()
export class NewReportListEffects {


    loadProfileCategories$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<newReportActions.LoadProfileCategories>(newReportActions.LOAD_PROFILE_CATEGORIES),
        mergeMap(action => {
            if (!action.profileId) {
                return null;
            }
            return this.sseHubCategoriesService.getByProfileId(action.profileId).pipe(
                map((result: any) => {
                    if (result) {
                        return new newReportActions.PopulateProfileCategories(result);
                    } else {
                        this.errorHandlerService.Error();
                        return new newReportActions.Error();
                    }
                }),
                catchError(e => {
                    this.errorHandlerService.Error();
                    return of(new newReportActions.Error());
                })
            );
        })));

    constructor(
        private actions$: Actions,
        private sseHubCategoriesService: SSEHubCategoriesService,
        private errorHandlerService: ErrorHandlerService,
    ) { }

}

