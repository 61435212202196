import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { getUser } from '@app/store/user/user.reducer';
import { IUser, User } from '@app/store/user';
import { State } from '../../../../store';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-overflow-menu',
    templateUrl: './overflow-menu.component.html',
    styleUrls: ['./overflow-menu.component.scss']
})
export class OverflowMenuComponent implements OnInit, OnDestroy {
    @Input() profileId: number;
    @Input() context: string;
    @Input() isDisabled: boolean;
    @Output() callAction: EventEmitter<any> = new EventEmitter();
    canEdit = false;
    canAdminFillOutReport = false;
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<State>,
    ) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(user => this.setEditPermissions(user));
    }

    editMetaData() {
        this.callAction.emit('editMetaData');
    }

    addQuestion() {
        this.callAction.emit('addQuestion');
    }

    addTemplate() {
        this.callAction.emit('addTemplate');
    }

    removeQuestion() {
        if (this.context === 'followup-question-dropdown' ||
            this.context === 'group-question-dropdown' ||
            this.context === 'followup-question' ||
            this.context === 'group-question') {
            this.callAction.emit('removeQuestion');
        } else {

            if (!this.isDisabled) {
                this.callAction.emit('removeQuestion');
            }
        }

    }

    addAnswer() {
        if (this.context === 'flight-question-dropdown') {
            if (!this.isDisabled) {
                this.callAction.emit('answerAdd');
            }
        } else {
            this.callAction.emit('answerAdd');
        }
    }

    editAnswer() {
        this.callAction.emit('editAnswer');
    }

    editGroup() {
        this.callAction.emit('editGroup');
    }

    removeAnswer() {
        if (!this.isDisabled) {
            this.callAction.emit('removeAnswer');
        }
    }

    addGroupType() {
        this.callAction.emit('addGroup');
    }

    removeGroupType() {
        this.callAction.emit('removeGroup');
    }

    addGroupDetail() {
        this.callAction.emit('addGroupDetails');
    }

    removeGroupDetail() {
        this.callAction.emit('removeGroupDetails');
    }

    resetSection() {
        this.callAction.emit('resetSection');
    }

    resetGeneralQuestion() {
        this.callAction.emit('resetGeneralQuestions');
    }

    sortQuestion() {
        this.callAction.emit('sortQuestion');
    }

    sortAnswer() {
        this.callAction.emit('sortAnswer');
    }

    editGroupType() {
        this.callAction.emit('editGroupType');
    }

    manageTags() {
        this.callAction.emit('manageTags');
    }
    private setEditPermissions(user: IUser) {
        if (user) {
            this.canEdit = User.hasPrivilegeArray(user, [
                'WEB_ADMIN_CATEGORYMGNT_EDIT_' + this.profileId,
                'WEB_ADMIN_CATEGORYMGNT_EDIT_ALL',
                'WEB_SUADMIN'
            ]);
            this.canAdminFillOutReport = User.hasPrivilege(user, 'FLAG_WEB_ADMIN_FILLOUTREPORT');
        }
    }
}
