// import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonModule, DatePipe, registerLocaleData } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { CommonUXModule } from '@shared/common-ux/common-ux.module';
import { DeviceDetectorService } from 'ngx-device-detector';
import { AgGridModule } from 'ag-grid-angular';

import { wizardRoutes } from './new-report.routing';

// Dynamic Component
import { AutotextComponent } from './dynamic-form-component/autotext/autotext.component';
import { CheckboxComponent } from './dynamic-form-component/checkbox/checkbox.component';
import { DateComponent } from './dynamic-form-component/date/date.component';
import { RadioComponent } from './dynamic-form-component/radio/radio.component';
import { SelectComponent } from './dynamic-form-component/select/select.component';
import { TextComponent } from './dynamic-form-component/textbox/textbox.component';
import { GroupComponent } from './dynamic-form-component/group/group.component';
import { MultiGroupComponent } from './dynamic-form-component/group/multi-group.component';
import { OptionalGroupComponent } from './dynamic-form-component/group/optional-group.component';
// import { MultiInputComponent } from './dynamic-form-component/multi-input/multi-input.component';
// import { MultiInputAMSComponent } from './dynamic-form-component/multi-input/ams/ams.component';
import { NumberComponent } from './dynamic-form-component/number/number.component';
import { DisplayInjuriesComponent } from './dynamic-form-component/display-injuries/display-injuries.component';
import { TextareaComponent } from './dynamic-form-component/textarea/textarea.component';
import { TimeComponent } from './dynamic-form-component/time/time.component';
import { QuestionSearchesComponent } from './dynamic-form-component/question-searches/question-searches.component';
import { DynamicQuestionDirective } from './dynamic-form-component/dynamic-question.directive';
import { DynamicFormComponent } from './dynamic-form-component/dynamic-form.component';
import { DateValidationPipe } from './dynamic-form-component/pipes/date-validation.pipe';
import { FormatSummaryAnswerPipe, ShouldDisplayQuestionTextPipe } from './wizard/steps/dynamic-summary/format-summary-answer.pipe';
import { DisplayPaxSearchPipe, DisplayCrewSearchPipe, DisplayEmployeeSearchPipe } from './dynamic-form-component/pipes/display-person-search.pipe';
import { IndefiniteArticlePipe } from './dynamic-form-component/pipes/indefinite-article.pipe';
import { FilterQuestionsByGroupnamePipe } from 'app/shared/pipes/filter-questions-by-groupname.pipe';
import { FlightNumberDisplayPipe } from './dynamic-form-component/pipes/flight-number-display.pipe';
import { MultiAnswerOptionalTextPipe } from './dynamic-form-component/pipes/multi-answer-optional-text.pipe';
import { SentenceCasePipe } from './dynamic-form-component/pipes/sentence-case.pipe';
import { FocusDirective } from './dynamic-form-component/focus.directive';

// Wizard Components
import { WizardComponent } from './wizard/wizard.component';
import { FlightComponent } from './wizard/steps/flight.component';
import { QuestionsComponent } from './wizard/steps/questions.component';
import { NotesComponent } from './wizard/steps/notes.component';
import { SummaryComponent } from './wizard/steps/summary.component';
import { SummaryQuestionDisplayComponent } from './wizard/steps/dynamic-summary/summary-question-display.component';
import { AttachmentViewComponent } from './wizard/steps/dynamic-summary/attachment-view.component';
import { SSEHubNewReportService } from '@shared/SSEHubClient/new-report.service';
import { DynamicValidationDirective, NoDuplicatesValidator, IsRequiredAMSAddressValidator } from './dynamic-form-component/dynamic-validation.directive';
import { ReportAssemblyService } from './new-report.service';
import { WizardService, FocusService, RandomNumberGenerator } from './wizard/wizard.service';

// Modal Components
import { CrewSearchComponent } from '@wizardmodals/crew-search/crew-search.component';
import { EmployeeSearchComponent } from '@wizardmodals/employee-search/employee-search.component';
import { PaxSearchComponent } from '@wizardmodals/pax-search/pax-search.component';
import { FlightSearchGenericComponent } from '@wizardmodals/flight-search-generic.component';
import { FormatPaxNameDisplay, FormatCrewNameDisplay, FormatEmployeeNameDisplay } from '@wizardmodals/format-name-display.pipe';
import { SearchFlightComponent } from '@wizardmodals/flight-search/flight-search.component';
import { ConfirmNavigateComponent } from '@wizardmodals/confirm/navigate/confirm-navigate.component';
import { ConfirmFlightChangeComponent } from '@wizardmodals/confirm/flight-change/confirm-flight-change.component';
import { ConfirmCancelComponent } from '@wizardmodals/confirm/cancel/confirm-cancel.component';

// NgRx
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NewReportEffects, reducer } from './new-report-store';

/** config angular i18n **/
import en from '@angular/common/locales/en';

registerLocaleData(en);

@NgModule({
    imports: [
        RouterModule.forChild(wizardRoutes),
        FormsModule,
        ReactiveFormsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatAutocompleteModule,
        NgbModule,
        CommonModule,
        CommonUXModule,
        AgGridModule,
        StoreModule.forFeature('newReport', reducer),
        EffectsModule.forFeature(NewReportEffects),
    ],
    declarations: [
        DynamicQuestionDirective,
        AutotextComponent,
        CheckboxComponent,
        DateComponent,
        RadioComponent,
        SelectComponent,
        TextComponent,
        GroupComponent,
        MultiGroupComponent,
        OptionalGroupComponent,
        // MultiInputComponent,
        // MultiInputAMSComponent,
        NumberComponent,
        DisplayInjuriesComponent,
        TextareaComponent,
        TimeComponent,
        QuestionSearchesComponent,
        DynamicFormComponent,
        DynamicValidationDirective,
        NoDuplicatesValidator,
        IsRequiredAMSAddressValidator,
        WizardComponent,
        FlightComponent,
        QuestionsComponent,
        NotesComponent,
        SummaryComponent,
        SummaryQuestionDisplayComponent,
        DateValidationPipe,
        IndefiniteArticlePipe,
        DisplayPaxSearchPipe,
        DisplayCrewSearchPipe,
        DisplayEmployeeSearchPipe,
        FlightNumberDisplayPipe,
        MultiAnswerOptionalTextPipe,
        FormatSummaryAnswerPipe,
        ShouldDisplayQuestionTextPipe,
        SentenceCasePipe,
        FilterQuestionsByGroupnamePipe,
        AttachmentViewComponent,
        ConfirmCancelComponent,
        ConfirmNavigateComponent,
        ConfirmFlightChangeComponent,
        SearchFlightComponent,
        FocusDirective,
        PaxSearchComponent,
        CrewSearchComponent,
        EmployeeSearchComponent,
        FlightSearchGenericComponent,
        FormatPaxNameDisplay,
        FormatCrewNameDisplay,
        FormatEmployeeNameDisplay,
    ],
    providers: [
        SSEHubNewReportService,
        DatePipe,
        ReportAssemblyService,
        WizardService,
        FocusService,
        DeviceDetectorService,
        RandomNumberGenerator,
        ShouldDisplayQuestionTextPipe
    ],
    exports: [
        DynamicFormComponent,
    ]
})
export class NewReportModule { }
