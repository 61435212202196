import { Component, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { WizardService } from '@newreport/wizard/wizard.service';
import { IFlightSequence } from '@newreport/new-report-store/wizard';

@Component({
    selector: 'app-confirm-flight-change',
    templateUrl: './confirm-flight-change.component.html',
    styleUrls: ['../../modals.component.scss']
})

export class ConfirmFlightChangeComponent {

    @ViewChild('confirmFlightChange', { static: true }) public modal;
    @Output() public cancelUpdate = new EventEmitter();
    @Output() public confirmUpdate = new EventEmitter<IFlightSequence>();
    @Input() public flightDetail: IFlightSequence;


    constructor(
        private wizardService: WizardService
    ) { }

    openModal() {
        this.modal.open();
    }

    // reset the general questions page
    confirm() {
        this.modal.close();
        this.wizardService.resetGeneralQuestions();
        this.confirmUpdate.emit(this.flightDetail);
    }

    // don't cancel anything, leave the user where they are
    cancel() {
        this.modal.close();
        this.cancelUpdate.emit();
    }
}
