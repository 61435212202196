import { Action } from '@ngrx/store';
import { ICategory, IProfile } from './list.model';

export const LOAD_CATEGORIES_BY_PROFILE = '[CategoryManagement List] loadCategoriesByProfile';
export const POPULATE_CATEGORIES_BY_PROFILE = '[CategoryManagement List] populateCategoriesByProfile';

export const LOAD_CATEGORIES = '[CategoryManagement List] loadCategories';
export const POPULATE_CATEGORIES = '[CategoryManagement List] populateCategories';

export const ADD_CATEGORY = '[CategoryManagement List] addCategory';
export const ADD_CATEGORY_SUCCESS = '[CategoryManagement List] addCategorySuccess';

export const DELETE_CATEGORY = '[CategoryManagement List] deleteCategory';
export const DELETE_CATEGORY_SUCCESS = '[CategoryManagement List] deleteCategorySuccess';

export const SELECT_PROFILE = '[CategoryManagement List] selectProfile';
export const SELECT_PROFILE_BY_ID = '[CategoryManagement List] selectProfileById';

export const LOAD_PROFILES = '[CategoryManagement List] loadProfiles';
export const POPULATE_PROFILES = '[CategoryManagement List] populateProfiles';

export const CATEGORIES_ERROR = '[CategoryManagement List] categoriesError';
export const PROFILES_ERROR = '[CategoryManagement List] profilesError';

export class LoadCategories implements Action {
    readonly type = LOAD_CATEGORIES;
}

export class PopulateCategories implements Action {
    readonly type = POPULATE_CATEGORIES;
    constructor(public payload: Array<ICategory>) { }
}

export class LoadCategoriesByProfile implements Action {
    readonly type = LOAD_CATEGORIES_BY_PROFILE;
    constructor(public payload: number) { }
}

export class PopulateCategoriesByProfile implements Action {
    readonly type = POPULATE_CATEGORIES_BY_PROFILE;
    constructor(public payload: Array<ICategory>) { }
}

export class AddCategory implements Action {
    readonly type = ADD_CATEGORY;
    constructor(public payload: { category: any, profileId: number }) { }
}

export class AddCategorySuccess implements Action {
    readonly type = ADD_CATEGORY_SUCCESS;
    constructor(public payload: ICategory) { }
}

export class DeleteCategory implements Action {
    readonly type = DELETE_CATEGORY;
    constructor(public payload: { profileId: number, categoryId: string }) { }
}

export class DeleteCategorySuccess implements Action {
    readonly type = DELETE_CATEGORY_SUCCESS;
    constructor(public payload: string) { }
}
export class SelectProfile implements Action {
    readonly type = SELECT_PROFILE;
    constructor(public payload: IProfile) { }
}
export class SelectProfileById implements Action {
    readonly type = SELECT_PROFILE_BY_ID;
    constructor(public payload: number) { }
}

export class LoadProfiles implements Action {
    readonly type = LOAD_PROFILES;
}

export class PopulateProfiles implements Action {
    readonly type = POPULATE_PROFILES;
    constructor(public payload: Array<IProfile>) { }
}

export class CategoriesError implements Action {
    readonly type = CATEGORIES_ERROR;
    constructor(public payload: string) { }
}

export class ProfilesError implements Action {
    readonly type = PROFILES_ERROR;
    constructor(public payload: string) { }
}

export type Actions
    = LoadCategories
    | LoadCategoriesByProfile
    | PopulateCategories
    | PopulateCategoriesByProfile
    | AddCategory
    | AddCategorySuccess
    | DeleteCategory
    | DeleteCategorySuccess
    | SelectProfile
    | SelectProfileById
    | LoadProfiles
    | PopulateProfiles
    | ProfilesError
    | CategoriesError;
