import { Component, OnInit, ViewChild, Input, AfterViewInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '@app/store';
import { getMyHistory, IReportHistoryOptions } from 'app/report-history/report-history-store/list';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort} from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import * as _ from 'lodash';
import { DatePipe } from '@angular/common';
import { Observable } from 'rxjs';
import { IUnfinishedReportMetaData, UnfinishedReportDatabaseService } from 'app/shared/report-services/unfinished-report-database.service';

@Component({
    selector: 'app-table-unfinished-reports',
    templateUrl: './table-unfinished-reports.component.html',
    styleUrls: ['./table-unfinished-reports.component.scss'],
    providers: [DatePipe]
})
export class TableUnfinishedReportsComponent implements OnInit {
    @Input() employeeId: string;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;
    @ViewChild('confirmDelete', { static: true }) public confirmDelete;
    displayedColumns = ['unfinishedReportName', 'unfinishedDate', 'actions'];
    dataSource = new MatTableDataSource<any>();
    unfinishedReportList: IUnfinishedReportMetaData[] = [];
    tableOptions: IReportHistoryOptions = { filter: {}, sort: { id: 'submitDate', start: 'desc', disableClear: true }, pageIndex: 0 };
    myReports$: Observable<any>;
    isParsingTable = true;
    private unfinishedReportToDelete: Date;

    constructor(
        private store: Store<State>,
        private unfinishedReportDatabaseService: UnfinishedReportDatabaseService
    ) {
    }

    ngOnInit() {
        this.unfinishedReportDatabaseService.retrieveUnfinishedReportList().then((result) => {
            if (!result) return;
            this.unfinishedReportList = result;
            this.dataSource = new MatTableDataSource(this.unfinishedReportList);
            this.isParsingTable = false;

        });
    }

    retrieveUnfinishedReport(dateKey: Date) {
        this.unfinishedReportDatabaseService.retrieveUnfinishedReport(dateKey);
    }

    deleteUnfinishedReport(item) {
        // set id (date) locally
        this.unfinishedReportToDelete = null;
        if (item && item.unfinishedDate) {
            this.unfinishedReportToDelete = item.unfinishedDate;
        }

        this.confirmDelete.open();
    }

    deleteConfirmed() {
        // delete from DB
        if (this.unfinishedReportToDelete) {
            this.unfinishedReportDatabaseService.deleteUnfinishedReport(this.unfinishedReportToDelete);

            // remove from UI
            const index = this.dataSource.data.findIndex(r => r.unfinishedDate === this.unfinishedReportToDelete);
            if (index > -1) {
                this.dataSource.data.splice(index, 1);
                this.dataSource = new MatTableDataSource(this.unfinishedReportList);
            }

            // clean up
            this.unfinishedReportToDelete = null;
        }
    }
}
