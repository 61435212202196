<div class="panel-group" id="accordion">
    <app-report-detail-section [section]="'recommendation'" [title]="'Recommendation'"
        [allExpandState]="allExpandState"></app-report-detail-section>
    <div #section id="recommendation" class="panel-collapse collapse">
        <div class="panel-body ">
            <app-aa-spinner [loading]="isLoading"></app-aa-spinner>
            <div *ngIf="!isLoading">
                <div *ngFor="let reportRecommendation of recommendation  | SortByTimeStampPipe : 'CreateDate'"
                    class="recomm">
                    <div class="description-timestamp">{{reportRecommendation.createDate |
                        date:'MM/dd/yyyy'}} at {{reportRecommendation.createDate |
                        date:'shortTime'}}</div>
                    <div class="description-text">{{reportRecommendation.narrativeText}}</div>

                </div>
                <label *ngIf="!recommendation.length > 0"> No Recommendation Provided</label>
                <app-add-section-inline *ngIf="hideAddButton" [title]="'Add recommendation'" [btnTitle]="'Save'"
                    (onSave)="onSave($event)"> </app-add-section-inline>
            </div>
        </div>
    </div>
