<div class="panel-group" id="accordion">
    <app-report-detail-section [section]="'description'" [title]="'Description'" [allExpandState]="allExpandState">
    </app-report-detail-section>
    <div #section id="description" class="panel-collapse collapse">
        <div class="panel-body ">
            <app-aa-spinner [loading]="isLoading"></app-aa-spinner>
            <div *ngIf="!isLoading">
                <div *ngFor="let reportDescription of description | SortByTimeStampPipe : 'CreateDate'" class="desc">
                    <label class="description-timestamp">{{reportDescription.createDate | date:'MM/dd/yyyy'}} at
                        {{reportDescription.createDate | date:'shortTime'}}</label>
                    <label class="description-text"> {{reportDescription.narrativeText}}</label>
                </div>
                <label *ngIf="!description.length > 0"> No Description Provided</label>
                <app-add-section-inline *ngIf="hideAddButton" [title]="'Add description'" [btnTitle]="'Save'"
                    (onSave)="onSave($event)"> </app-add-section-inline>
            </div>
        </div>
    </div>
</div>
