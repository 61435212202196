import { Component, ViewChild, EventEmitter, Output, OnInit, Input, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoadCrewSequences, IFlightSequence, FlightSequence } from 'app/new-report/new-report-store/wizard';
import { getCrewSequences, getFlightSelection, State } from '@newreport/new-report-store';
import { NgModel, NgForm } from '@angular/forms';
import { FormComponentService } from 'app/shared/common-ux/components/component-services/form-component.service';
import { ReportAssemblyService } from 'app/new-report/new-report.service';
import { AirportCode } from 'app/shared/SSEHubClient/airport.service';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';
import { NotificationService } from 'app/shared/error-handler-notify/services';
import { take, takeUntil } from 'rxjs/operators';
import { getUser } from 'app/store/user';
import { Subject } from 'rxjs';

export interface IFlightQuestions {
    flightNumber: {
        attrs: object
    };
    flightDate: {
        attrs: object
    };
    departureStation: {
        attrs: object
    };
}

@Component({
    selector: 'app-flight-search-generic',
    templateUrl: './flight-search-generic.component.html',
    styleUrls: ['./modals.component.scss']
})

export class FlightSearchGenericComponent implements OnInit, OnDestroy {
    @ViewChild('form', { static: true }) form: NgForm;
    @ViewChild('flt', { static: true }) flt: NgModel;
    @ViewChild('brd', { static: true }) brd: NgModel;
    @ViewChild('date', { static: true }) date: NgModel;
    @Output() flightDetails$: EventEmitter<IFlightSequence> = new EventEmitter();
    profileId: number;
    destroy$: Subject<void> = new Subject<void>();

    flightQuestions: IFlightQuestions = {
        flightNumber: {
            attrs: { 'isRequired': true, 'isNaN': true, 'minValue': 1, 'maxValue': 9999 }
        },
        // We need to change the minDate back to 3 on a later date ('minDate': 3).
        flightDate: {
            attrs: { 'isRequired': true, 'maxDate': 0, 'minDate': 30 }
        },
        departureStation: {
            attrs: { 'isRequired': true, 'autoPopulateValuesKey': 'stationcode', 'maxLength': 3 }
        }
    };

    flightResults: IFlightSequence = new FlightSequence();
    emptyCrewSeq: IFlightSequence = {
        aircraftTypeDescription: '',
        arrivalStation: '',
        departureStation: '',
        interruptStation: '',
        employeeId: '',
        fleetDescription: 'Click here to select',
        flightDate: '',
        flightNumber: '',
        sequencePosition: '',
        tailNumber: ''
    };

    options: AirportCode[] = [];
    crewSeqs: { options: Array<IFlightSequence>, userAnswer: any } = { options: [], userAnswer: {} };

    constructor(
        private store: Store<State>,
        private formComponentService: FormComponentService,
        private newReportService: ReportAssemblyService,
        public datepipe: DatePipe,
        public notificationService: NotificationService,
    ) {

    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    ngOnInit() {
        this.store.select(getUser).pipe(take(1)).pipe(takeUntil(this.destroy$)).subscribe(user => this.profileId = parseInt(user.profileId, 10));

        this.store.select(getCrewSequences).pipe(takeUntil(this.destroy$)).subscribe((cq) => {
            this.crewSeqs = cq;

            if (this.crewSeqs && this.crewSeqs.options && this.crewSeqs.options.length > 0 &&
                !this.crewSeqs.options.find(cr => cr['fleetDescription'] === this.emptyCrewSeq.fleetDescription)) {
                this.emptyCrewSeq.fleetDescription = 'Click here to select';
                this.crewSeqs.options = [this.emptyCrewSeq, ...this.crewSeqs.options];
                this.crewSeqs.userAnswer = this.emptyCrewSeq;
            }
        });

        if (!this.crewSeqs) {
            this.store.dispatch(new LoadCrewSequences());
        }

        this.store.select(getFlightSelection).pipe(take(1)).pipe(takeUntil(this.destroy$)).subscribe(details => {
            if (details && (details.flightNumber || details.flightDate || details.departureStation)) {
                this.flightResults = details;
            }
        });
    }

    replaceInput(val: any) {
        if (this.flightQuestions.departureStation.attrs &&
            this.flightQuestions.departureStation.attrs['autoPopulateValuesKey']?.toUpperCase() === 'STATIONCODE') {
            val.target.value = val.target.value.toUpperCase().replace(/[^a-zA-Z\s]/g, '');
            this.options = this.newReportService.getAirportCodeOptions(val.target.value);
        }
        this.brd.control.setValue(val.target.value);
    }

    updateCrewSeqSelection() {
        this.flightResults = { ...this.crewSeqs.userAnswer };
    }

    clearCrewSeqSelection() {
        this.crewSeqs.userAnswer = null;
    }

    clear() {
        this.crewSeqs.userAnswer = null;
        this.flightResults.flightNumber = null;
        this.flightResults.flightDate = null;
        this.flightResults.departureStation = null;
        this.flightDetails$.emit(this.flightResults);
    }

    searchFlight() {
        this.formComponentService.validateAllFormFields(this.form.control);
        if (this.form.valid) {
            this.flightDetails$.emit(this.flightResults);
        }
    }

    formatFlightNumber(flightNumber: any): string {
        return flightNumber ? flightNumber.toString().replace(/^0+/, '') : null;
    }

    toggleScrollLock(opened: boolean) {
        if (opened) {
            document.body.setAttribute('style', 'overflow: hidden;');
        } else {
            document.body.setAttribute('style', 'overflow: initial;');
        }
    }
}
