import { Component, EventEmitter, ViewChild, OnInit, Output, AfterViewInit, OnDestroy } from '@angular/core';
import { ControlContainer, NgForm, NgModel } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State } from 'app/store';
import { IDynamicQuestion, IFlightSequence, UpdateFlightSelection, FlightSequence, UpdateIncidentStation } from '../../new-report-store/wizard';
import { getFlightSelection, getQuestions } from '@newreport/new-report-store';
import { AirportCode } from 'app/shared/SSEHubClient/airport.service';
import { ReportAssemblyService } from 'app/new-report/new-report.service';
import { flightSequenceDictionary } from '../dynamic-form.component';
import { ConfirmFlightChangeComponent } from '@wizardmodals/confirm/flight-change/confirm-flight-change.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-textbox',
    templateUrl: './textbox.component.html',
    styleUrls: ['../dynamic-form.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})

export class TextComponent implements OnInit, AfterViewInit, OnDestroy {
    question: IDynamicQuestion;
    @Output() answerEvent$ = new EventEmitter<IFlightSequence>();
    questionAttrs: object = {};
    options: AirportCode[] = [];
    @ViewChild('form', { static: true }) form: NgModel;
    fieldNameFlightAttribute;
    currentFlightDetails: IFlightSequence;
    @ViewChild(ConfirmFlightChangeComponent, { static: true }) flightChangeCom: ConfirmFlightChangeComponent;
    generalQuestions: Array<IDynamicQuestion> = [];
    isEmailDataType = false;
    focusEmail: EventEmitter<boolean> = new EventEmitter();
    destroy$: Subject<void> = new Subject<void>();

    constructor(private newReportService: ReportAssemblyService,
        private store: Store<State>) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    ngOnInit() {
        this.fieldNameFlightAttribute = this.questionAttrs && this.questionAttrs['fieldName'] ?
            flightSequenceDictionary[this.questionAttrs['fieldName'].trim().toUpperCase()] : null;
        if (this.fieldNameFlightAttribute) {
            this.currentFlightDetails = new FlightSequence();
            this.store.select(getFlightSelection).pipe(takeUntil(this.destroy$)).subscribe(flight => {
                if (flight) {
                    this.currentFlightDetails = flight;
                }
            });
            this.store.select(getQuestions('general')).pipe(takeUntil(this.destroy$)).subscribe(q => {
                this.generalQuestions = q;
            });
        }
        // check if data type is email
        this.isEmailDataType = (this.questionAttrs &&
            (this.questionAttrs['dataType']?.toUpperCase() === 'ADDITIONALAAEMAILS' ||
                this.questionAttrs['dataType']?.toUpperCase() === 'ADDITIONALEMAILS'));

        if (this.question && this.question.userAnswer) {
            this.form.control.setValue(this.question.userAnswer);
        }
    }

    ngAfterViewInit() {
        if (this.isEmailDataType) {
            document.getElementById(`textbox-input-${this.question.questionMappingId}`).style.display = 'none';
        }
    }

    onChange(answer: any) {
        if (this.questionAttrs &&
            this.questionAttrs.hasOwnProperty('valuePropertyKey') &&
            this.questionAttrs['valuePropertyKey'].toLowerCase() === 'airportid') {
            this.store.dispatch(new UpdateIncidentStation(answer));
        }
    }

    // this triggers the station code on a textbox question
    replaceInput(val: any) {
        if (this.questionAttrs['uppercase']) {
            val.target.value = val.target.value.toUpperCase();
        }
        if ((this.questionAttrs &&
            this.questionAttrs['autoPopulateValuesKey']?.toUpperCase().includes('STATIONCODE')) ||
            this.questionAttrs['dataType']?.toUpperCase().includes('STATIONCODE')) {
            val.target.value = val.target.value.toUpperCase().replace(/[^a-zA-Z\s]/g, '');
            this.options = this.newReportService.getAirportCodeOptions(val.target.value);
        }
        this.form.control.setValue(val.target.value);
    }

    autofillTextNA() {
        this.question.userAnswer = 'NA';
    }

    sanitizeAnswer() {
        this.removeAllSpaces();
        this.removeLeadingZeros();
    }

    removeLeadingZeros() {
        // Previously we were always removing leading zeros but we were discovering too many exceptions to that rule
        // (e.g. cost_center, phone number fields, zip) so I'm letting the textbox field treat strings as strings
        // (i.e.allow the 0 character) and make removing them the exception. 'RemoveLeadingZeros' is now an admin option.
        if (this.questionAttrs['removeLeadingZeros']) {
            if (this.question.userAnswer) {
                this.question.userAnswer = this.question.userAnswer.toString().replace(/^0+/, '');
            }
        }
    }

    removeAllSpaces() {
        if (this.questionAttrs &&
            this.questionAttrs['personMapping'] &&
            this.questionAttrs['personMapping'].toUpperCase().includes('PHONE')) {
            this.question.userAnswer = this.question.userAnswer.toString().replace(/\s+/g, '');
        }
        if (this.question.userAnswer) {
            this.question.userAnswer = this.question.userAnswer.toString().trim();
        }
    }

    showFlightChangeConfirm($event) {
        if (!this.currentFlightDetails) return;

        let isQuestionsVisited = false;
        if (this.generalQuestions && this.generalQuestions.length > 0) {
            this.generalQuestions.forEach(a => {
                if (a.hasOwnProperty('userAnswer')) {
                    isQuestionsVisited = true;
                    return;
                }
            });
        }
        if ((this.currentFlightDetails[this.fieldNameFlightAttribute] === null && this.currentFlightDetails['flightNumber'] === null)
            || this.currentFlightDetails[this.fieldNameFlightAttribute] === undefined) {
            // first time date entered
            this.saveToStore($event.target.value);
        } else if (this.currentFlightDetails[this.fieldNameFlightAttribute] !== $event.target.value
            && isQuestionsVisited) {
            this.flightChangeCom.openModal();
        }
    }
    saveToStore(value) {
        this.currentFlightDetails[this.fieldNameFlightAttribute] = value;
        this.store.dispatch(new UpdateFlightSelection({ ...this.currentFlightDetails }));
    }

    cancelUpdate() {
        this.question.userAnswer = this.currentFlightDetails[this.fieldNameFlightAttribute];
    }

    // update the question answers and forms from Emails components
    updateEmails(event: string) {
        this.question.userAnswer = (!event.length) ? null : event;
        this.form.control.setValue(this.question.userAnswer);
    }

    // when a focus events is triggered, check if emails is used to pass the focus
    focusIn() {
        setTimeout(() => {
            if (this.isEmailDataType) this.focusEmail.next(true);
        }, 350);
    }
}
