import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType, concatLatestFrom } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as actions from './wizard.actions';
import { map, catchError, switchMap, withLatestFrom } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { NotificationService } from '@shared/error-handler-notify/services/notification.service';
import { SSEHubNewReportService } from '@shared/SSEHubClient/new-report.service';
import { PageStatusTypes, IDynamicQuestion, IFlightSequence, IQuestion } from './wizard.model';
import * as _ from 'lodash';
import { ExtractQuestionUrls } from 'app/shared/common-ux/models/ExtractQuestionUrls';
import { ISetting, SSEHubEmployeesService, SSEHubSettingsService } from 'app/shared/SSEHubClient';
import { UserStateModel, getUser } from 'app/store/user';
import { getAllQuestions } from '..';

@Injectable()
export class NewReportWizardQuestionsEffects {

    loadAllGeneralQuestionsAndAnswers$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<actions.LoadAllQuestions>(actions.LOAD_ALL_QUESTIONS),
        switchMap(action => {
            return this.sseHubNewReportService
                .getAllQuestionsAndAnswers(
                    action.profileId,
                    action.categoryId,
                    'all'
                )
                .pipe(
                    map(questions => {
                        if (questions && questions.length > 0) {
                            questions.forEach(element => {

                                const newexUtil = new ExtractQuestionUrls(element);
                                if (newexUtil && newexUtil !== undefined) {
                                    element.hyperLinks = newexUtil.hyperLinks;
                                    element.displayQuestionText = newexUtil.displayQuestionText;
                                }
                            });
                        }

                        questions = questions || [];

                        for (let i = 0; i < questions.length; i++) {
                            const question = questions[i];
                            question.answerInputType = question.answerInputType.toUpperCase();
                            question.isInvolvement = question.answerInputType === 'INVOLVEMENT';
                            const attr: object = question.attrs ?? {};
                            if (question.isInvolvement || question.answerInputType.toUpperCase() === 'QUESTIONGROUP') {
                                const allowMultiple = (attr['maxInvolvements'] && attr['maxInvolvements'] > 1) ||
                                    (attr['maxQuestionGroups'] && attr['maxQuestionGroups'] > 1) ||
                                    (!attr['maxQuestionGroups'] && !attr['maxInvolvements']);
                                if (allowMultiple) {
                                    if (attr['isRequired']) {
                                        const multiInvolvement: IDynamicQuestion = _.cloneDeep(question);
                                        delete attr['isRequired'];
                                        multiInvolvement.attrs = Object.keys(attr).length < 1 ? {} : attr;
                                        multiInvolvement.answerInputType = 'MULTI_GROUP';
                                        questions.splice(++i, 0, multiInvolvement);
                                    } else {
                                        question.answerInputType = 'OPTIONAL_GROUP';
                                    }
                                }
                            }
                        }

                        const flightQuestions = questions.filter(q => q.groupName == 'Flight');
                        const generalQuestions = questions.filter(q => q.groupName == 'General');
                        const notesQuestions = questions.filter(q => q.groupName == 'Notes');

                        const allPageStatuses = {
                            flight: flightQuestions.length > 0 ? 'complete' : null as PageStatusTypes,
                            general: generalQuestions.length > 0 ? 'complete' : null as PageStatusTypes,
                            notes: notesQuestions.length > 0 ? 'complete' : null as PageStatusTypes
                        }

                        allPageStatuses.flight = flightQuestions.length > 0 ? 'complete' : null;
                        for (const question of flightQuestions) {
                            const attr: object = question.attrs ?? {};
                            if (attr['isRequired']) {
                                allPageStatuses.flight = 'incomplete';
                            }
                        }

                        allPageStatuses.general = generalQuestions.length > 0 ? 'complete' : null;
                        for (const question of generalQuestions) {
                            const attr: object = question.attrs ?? {};
                            if (attr['isRequired']) {
                                allPageStatuses.general = 'incomplete';
                            }
                        }

                        allPageStatuses.notes = notesQuestions.length > 0 ? 'complete' : null;

                        for (const question of notesQuestions) {
                            const attr: object = question.attrs ?? {};
                            if (attr['isRequired']) {
                                allPageStatuses.notes = 'incomplete';
                            }
                        }

                        return new actions.PopulateAllQuestionsAndPageStatus({ questions: questions, pageStatuses: allPageStatuses });
                    }),
                    catchError(e => {
                        console.error(e);
                        const errorMessage = 'Error retrieving questions.';
                        this.notificationService.showError(errorMessage);
                        return of(new actions.ErrorQuestions({ hasError: true, errorMessage: errorMessage }));
                    })
                );
        })
    ));

    // only call the crew sequence API if the user object property isFlightCrew is true
    loadNewreportWizardFlightCrewSequences$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<actions.LoadCrewSequences>(actions.LOAD_CREW_SEQUENCES), withLatestFrom(this.userStore.select(getUser)),
        switchMap(([action, user]) => {
            if (user.isFlightCrew) {
                return this.employeeService.getCrewSequencesByEmployeeId(user.id)
                    .pipe(
                        map(sequences => {
                            const crewSeqs: { options: Array<IFlightSequence>, userAnswer: IFlightSequence }
                                = sequences ? { options: sequences, userAnswer: null } : { options: [], userAnswer: null };
                            for (const seq of crewSeqs.options) {
                                if (seq.flightNumber) {
                                    seq.flightNumber = seq.flightNumber.replace(/^0+/, '');
                                }
                            }
                            return new actions.PopulateCrewSequences(crewSeqs);
                        }),
                        catchError(e => {
                            console.error(e);
                            this.notificationService.showError(
                                'Error in retrieving crew sequences information'
                            );
                            return of(new actions.ErrorFlight({ hasError: true, errorMessage: 'Error in retrieving crew sequences information' }));
                        })
                    );
            }
            return of(new actions.PopulateCrewSequences({ options: [], userAnswer: null }))
        })
    ));

    getAttachmentSettings$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<actions.LoadAttachmentSettings>(actions.LOAD_ATTACHMENT_SETTINGS),
        switchMap(action => {
            return this.settingService.getAll().pipe(
                map((val: any) => {
                    if (val) {
                        let unallowedFileTypes: string[] = [];
                        let maxAttachments = 10; // default value

                        val.map((setting: ISetting) => {
                            if (setting && setting.value && setting.key === 'UNACCEPTABLE_ATTACHMENT_TYPE') {
                                // parse the string of file types into an array
                                unallowedFileTypes = setting.value.replace(/\"|\./g, '').split(',');
                            }
                            if (setting && setting.value && setting.key === 'PHOTO_MAX') {
                                maxAttachments = Number(setting.value);
                            }
                        });
                        return new actions.PopulateAttachmentSettings({ unallowedFileTypes, maxAttachments });
                    }
                }),
                catchError(e => {
                    console.error(e);
                    const errorMessage = 'Error initializing notes and/or attachments.';
                    this.notificationService.showError(errorMessage);
                    return of(new actions.ErrorNotes({ hasError: true, errorMessage: errorMessage }));
                })
            );
        }),
    ));

    updateQuestionsByParentAnswer: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<actions.UpdateQuestionsByParentAnswer>(actions.UPDATE_QUESTIONS_BY_PARENT_ANSWER),
        concatLatestFrom(action => this.userStore.select(getAllQuestions)),
        switchMap(([action, allQuestions]) => {
            const groupName = action.payload.groupName;
            const newQuestions = action.payload.questions;

            const ourQuestions = action.payload.groupName ? allQuestions.filter(q => q.groupName.toUpperCase() == groupName.toUpperCase()) : [];
            const otherQuestions = action.payload.groupName ? allQuestions.filter(q => q.groupName.toUpperCase() != groupName.toUpperCase()) : [];

            // this means it's a root question
            if (!action.payload.parentAnswer) {
                return of(new actions.UpdateQuestions(otherQuestions.concat(newQuestions)));
            }

            // not a root question which means we're modifying a list of followup questions
            // find the parent answer so we can update it
            const parentQMID = action.payload.parentAnswer.questionMappingId;
            const parentAnswerId = action.payload.parentAnswer.answerId;

            const questionUpdated = this.findAndUpdateQuestionByParentAnswer(parentQMID, parentAnswerId, ourQuestions, newQuestions);

            // todo: verify were updated or throw error
            return of(new actions.UpdateQuestions(otherQuestions.concat(ourQuestions)));
        }
        )
    ));

    constructor(
        private actions$: Actions,
        private notificationService: NotificationService,
        private sseHubNewReportService: SSEHubNewReportService,
        private employeeService: SSEHubEmployeesService,
        private settingService: SSEHubSettingsService,
        private userStore: Store<UserStateModel>,
    ) { }

    // primitives are passed by value, objects (e.g. questions) are passed by reference so updating questions here will update it in the effect
    findAndUpdateQuestionByParentAnswer(parentQMID: number, parentAnswerId: number, allQuestions: IQuestion[], newQuestions: IQuestion[]): boolean {
        for (const question of allQuestions) {
            if (question.questionMappingId == parentQMID) {
                for (const answer of question.answers) {
                    if (answer.answerId == parentAnswerId) {
                        answer.followupQuestions = newQuestions;
                        return true;
                    }
                    const found = this.findAndUpdateQuestionByParentAnswer(parentQMID, parentAnswerId, answer.followupQuestions, newQuestions);
                    if (found) {
                        return true;
                    }
                }
            }
        }
        return false;
    }
}
