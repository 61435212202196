import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { LoggerService } from 'app/shared/error-handler-notify/services';
import { IUser, User, getUser } from 'app/store/user';
import { State } from "@app/store";
import { catchError, combineLatest, debounceTime, forkJoin, map, of, Subject, take, takeUntil } from 'rxjs';
import * as Actions from '../../category-management-store/tag/tag.actions';
import { getQuestionMappingTags } from '../../category-management-store';
import { ITagDetails } from '../../category-management-store/tag';

@Component({
    selector: 'app-tags-view',
    templateUrl: './tags-view.component.html',
    styleUrls: ['./tags-view.component.scss']
})

export class TagsViewComponent implements OnInit, OnDestroy {
  @Input() questionMappingId: string = '';

    kendoPanelBarExpandMode: string = 'single';
    tagsView: any[] = [];
    includeSystemRequired: boolean;
    destroy$ = new Subject<void>();

    constructor(
        private store: Store<State>,
        public logger: LoggerService,
    ) { }

    // Only IT Admins should be able to see System tags.
    public setPrivileges(user: IUser) {
        if (user) {
            this.includeSystemRequired = User.hasPrivilege(user, 'WEB_SUADMIN');
        }
    }

    getMappedTags(): void {
        // Get the mapped tags for the question mapping id via the store.
        this.store.dispatch(new Actions.LoadTagsForQuestionMapping({ questionMappingId: this.questionMappingId, includeSystemRequired: this.includeSystemRequired }));

        // Select the data from the store.
        this.store.select(getQuestionMappingTags).pipe(
            takeUntil(this.destroy$),
            map(mappedTags => {
                if (mappedTags && mappedTags.length > 0) {
                    this.processMappedTags(mappedTags);
                } else {
                    this.tagsView = [{
                        title: 'No tags available.',
                        disabled: true
                    }];
                }
            }),
            catchError(error => {
                this.logger.error('Error fetching mapped tags.', error);
                return of([]);
            })
        ).subscribe();
    }

    private processMappedTags(mappedTags: ITagDetails[]): void {
        this.tagsView = mappedTags.map(tag => ({
            title: tag.tagVal,
            children: [
                {
                    title: tag.tagDescription || 'No description available.',
                    disabled: true
                }
            ]
        }));
    }

    ngOnInit(): void {
        this.store.select(getUser).pipe(take(1)).subscribe(user => {
            this.setPrivileges(user);
            this.getMappedTags();
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
