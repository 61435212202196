import { Injectable } from '@angular/core';
import { SSEHubClient } from './SSEHubClient.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';


@Injectable()
export class SSEHubAttributesService {

    constructor(
        private http: HttpClient,
        private sseHubClient: SSEHubClient
    ) { }

    update(attrObject): Observable<boolean> {
        return this.http.put<boolean>(this.sseHubClient.serviceUrl() + '/api/attributes/update', attrObject);
    }

    add(attrObject): Observable<number> {
        return this.http.post<number>(this.sseHubClient.serviceUrl() + '/api/attributes/insert', attrObject);
    }
}

