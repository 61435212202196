import { Injectable, EventEmitter, OnDestroy } from '@angular/core';
import { IAssembledReport, IReportSubmitConfirmation } from '@models';
import { SSEHubNewReportService } from '../SSEHubClient/new-report.service';
import { EncrDecrService } from './encrDecryption/encrDecr.service';
import { Store } from '@ngrx/store';
import { State } from 'app/store';
import { getUser } from 'app/store/user';
import { DatabaseService } from './database.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export const SubmittedReportStates = {
    pending: 'Pending upload',
    uploading: 'Uploading',
    uploaded: 'Uploaded',
    error: 'Error uploading report'
};
export interface ISubmittedReport {
    date: Date;
    category: string;
    state: string;
    hasError: boolean;
}

export interface ISubmittedReportData {
    date: Date;
    category: string;
    report: IAssembledReport;
    state: string;
}

@Injectable()
export class SubmittedReportService implements OnDestroy {
    employeeId: any;
    private dbName = 'submittedReports';
    private dbStore = 'reports';

    public newOfflineReportEvent: EventEmitter<ISubmittedReport[]> = new EventEmitter<ISubmittedReport[]>();
    private submittedReportsView: ISubmittedReport[] = []; // list used to update report list for the user
    private uploadQueue: ISubmittedReportData[] = []; // list used to submit reports
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private sseHubNewReportService: SSEHubNewReportService,
        private encrDecrService: EncrDecrService,
        private store: Store<State>,
        private db: DatabaseService
    ) {
        this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(v => this.employeeId = (v && v.id) ? v.id : '');
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getSubmittedReportList() {
        return this.submittedReportsView;
    }

    saveSubmittedReport(report: IAssembledReport, category): Promise<any> {
        return new Promise((resolve, reject) => {
            // check for support
            if (!window.indexedDB) {
                reject('This browser does not support offline database');
                return;
            }
            const offlineReport: any = {};
            offlineReport.report = this.encrDecrService.set(JSON.stringify(report));
            offlineReport.date = new Date();
            offlineReport.category = category;
            offlineReport.state = SubmittedReportStates.pending;

            this.db.addReport(this.dbName, this.dbStore, offlineReport, offlineReport.date).then(() => {
                this.submittedReportsView.push({ // add to local list to display in UI
                    category: offlineReport.category,
                    date: offlineReport.date,
                    state: offlineReport.state,
                    hasError: false
                });
                this.newOfflineReportEvent.emit(this.submittedReportsView);
                resolve(null);
            });
        });
    }

    uploadAllSubmittedReports() {
        if (!this.uploadQueue && this.uploadQueue.length < 1) {
            return;
        }
        this.uploadQueue.map((report) => {
            // make sure reports are being uploaded or have been uploaded
            if (report.state !== SubmittedReportStates.uploading && report.state !== SubmittedReportStates.uploaded) {
                this.updateLocalReportStatus(report, SubmittedReportStates.uploading);
                this.sseHubNewReportService.submitReport(report.report).pipe(takeUntil(this.destroy$)).subscribe((response: IReportSubmitConfirmation) => {
                    if (response) {
                        // delete form database
                        this.db.deleteReport(this.dbName, this.dbStore, report.date).then(() => {
                            report.state = SubmittedReportStates.uploaded;
                            this.updateLocalReportStatus(report, SubmittedReportStates.uploaded);
                        });
                    }
                }, (error) => {
                    report.state = SubmittedReportStates.uploaded;
                    console.log(error);
                    this.updateLocalReportStatus(report, SubmittedReportStates.error, true);
                });
            }
        });
    }

    uploadSubmittedReport(report: ISubmittedReport) {
        this.db.getReport(this.dbName, this.dbStore, report.date).then((dbData: any) => {
            const dbReport: IAssembledReport = JSON.parse(this.encrDecrService.get(dbData.report.toString()));
            this.updateLocalReportStatus(report, SubmittedReportStates.uploading, false);
            this.sseHubNewReportService.submitReport(dbReport).pipe(takeUntil(this.destroy$)).subscribe((response: IReportSubmitConfirmation) => {
                if (response) {
                    this.db.deleteReport(this.dbName, this.dbStore, report.date).then(() => {
                        report.state = SubmittedReportStates.uploaded;
                        this.updateLocalReportStatus(report, SubmittedReportStates.uploaded);
                    });
                }
            }, (error) => {
                report.state = SubmittedReportStates.uploaded;
                console.log(error);
                this.updateLocalReportStatus(report, SubmittedReportStates.error, true);
            });
        });
    }

    syncPendingSubmittedReports() {
        this.db.getAllReports(this.dbName, this.dbStore).then((reports: ISubmittedReportData[]) => {
            if (reports) {
                reports.forEach((submittedReport) => {
                    const report: IAssembledReport = JSON.parse(this.encrDecrService.get(submittedReport.report.toString()));
                    // queue report for upload
                    this.uploadQueue.push({ ...submittedReport, report: report });
                    if (report.employeeId === this.employeeId) {
                        // show report in the UI if the current user submitted this report
                        this.addToLocalReportsList(submittedReport.category, submittedReport.date);
                    }
                });
            }
            if (window.navigator.onLine) this.uploadAllSubmittedReports(); else this.uploadQueue = [];
        });
    }

    addToLocalReportsList(category, date) {
        // throw new report event to update UI with new information
        let isAdded = false;
        this.submittedReportsView.forEach((rpt) => {
            if (rpt.category === category && rpt.date && rpt.date.getTime() === date.getTime()) {
                isAdded = true;
            }
        });
        if (!isAdded) {
            this.submittedReportsView.push({ category: category, date: date, state: SubmittedReportStates.pending, hasError: false });
            this.newOfflineReportEvent.emit(this.submittedReportsView);
        }
    }

    updateLocalReportStatus(report: any, newStatus: string, hasError?: boolean) {
        // throw new report event to update UI with new information
        this.submittedReportsView.map((rpt) => {
            if (rpt.category === report.category && rpt.date && report.date && rpt.date.getTime() === report.date.getTime()) {
                rpt.state = newStatus;
                if (hasError !== null || hasError !== undefined) { rpt.hasError = hasError; }
                this.newOfflineReportEvent.emit(this.submittedReportsView);
            }
        });
        this.uploadQueue.map((rpt) => {
            if (rpt.category === report.category && rpt.date && report.date && rpt.date.getTime() === report.date.getTime()) {
                rpt.state = newStatus;
            }
        });
    }

    deleteFromLocalsubmittedReportsView(report: ISubmittedReport) {
        for (let i = 0; i < this.submittedReportsView.length; i++) {
            if (this.submittedReportsView[i].date === report.date) {
                this.submittedReportsView = [...this.submittedReportsView.slice(0, i), ...this.submittedReportsView.slice(i + 1)];
            }
        }
        this.newOfflineReportEvent.emit(this.submittedReportsView);
    }
}
