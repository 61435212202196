import { Component, ViewChild, EventEmitter, Output, OnInit, OnDestroy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Store } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { SSEHubPassengersService } from 'app/shared/SSEHubClient/passengers.service';
import { NotificationService } from 'app/shared/error-handler-notify/services';
import { IFlightSequence, UpdateFlightSelection } from '../../new-report-store/wizard';
import { getFlightSelection, State } from '@newreport/new-report-store';
import { UtilitiesService } from 'app/shared/utilities/utilities.service';
import { Subject } from 'rxjs';
import { IPassengerModel } from './models/pax.model';

@Component({
    selector: 'app-pax-search',
    templateUrl: './pax-search.component.html',
    styleUrls: ['../modals.component.scss']
})

export class PaxSearchComponent implements OnDestroy {

    @ViewChild('paxSearchModal', { static: true }) public modal: any;
    @Output() selectedPaxData = new EventEmitter<IPassengerModel>();
    isSearching = false;
    searchError = false;
    close = false;  // this is used to force the refresh of the child flight-search component.
    passengerList: IPassengerModel[];
    selectedPax: IPassengerModel;
    flightResults: IFlightSequence = {
        aircraftTypeDescription: null,
        arrivalStation: null,
        departureStation: null,
        interruptStation: null,
        employeeId: null,
        fleetDescription: null,
        flightDate: null,
        flightNumber: null,
        sequencePosition: null,
        tailNumber: null
    };
    displayFlightSearch = true; // should we display the flight search options or can we get the flight data from the flight page?
    destroy$: Subject<void> = new Subject<void>();

    // begin grid display parameters
    private gridApi;
    private gridColumnApi;
    quickSearchValue: string;
    columnDefs = [
        {
            headerName: 'Seat',
            field: 'seat',
            comparator: this.seatSort,
            sort: 'asc',
            width: 75,
            unSortIcon: true,
        },
        {
            headerName: 'Last name',
            field: 'lastName',
            unSortIcon: true,
        },
        {
            headerName: 'First name',
            field: 'firstName',
            unSortIcon: true,
        },
        {
            headerName: 'PNR',
            field: 'pnr',
            width: 80,
            unSortIcon: true,
        },
        {
            headerName: 'Group',
            field: 'grouping',
            width: 85,
            unSortIcon: true,
        },
    ];
    gridOptions = {
        defaultColDef: {
            resizable: true,
            sortable: true,
            unSortIcon: true,

        },
        columnDefs: this.columnDefs,
        rowSelection: 'single',
        suppressColumnVirtualisation: true,
    };
    style = {
        width: '100%',
        height: '314px',
    };
    // end grid display parameters

    constructor(
        private store: Store<State>,
        private passengersService: SSEHubPassengersService,
        public datepipe: DatePipe,
        public notificationService: NotificationService,
        public utilities: UtilitiesService
    ) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    // grid functions
    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.calculateSizing();
    }

    onQuickFilterChanged() {
        this.gridApi.setGridOption('quickFilterText', this.quickSearchValue);
    }

    calculateSizing() {
        const allColumnIds = [];
        this.gridColumnApi.getAllColumns().forEach(function (column) {
            if (!column.colDef.width) {
                allColumnIds.push(column.colId);
            }
        });
        this.gridColumnApi.autoSizeColumns(allColumnIds);
        if (this.gridColumnApi.columnController.scrollWidth > this.gridColumnApi.columnController.bodyWidth) {
            this.gridColumnApi.sizeColumnsToFit(this.gridColumnApi.columnController.scrollWidth - 1);
            // the size parameter fixes a weird horizontal scroll that was only appearing at the end of the display and covering the last row
        }
    }

    seatSort(seat1, seat2) {
        const seat1Number = seat1.match(/[0-9]+/);
        const seat1Letter = seat1.match(/[A-Z]/);
        const seat2Number = seat2.match(/[0-9]+/);
        const seat2Letter = seat2.match(/[A-Z]/);

        if (seat1Number! - seat2Number) {
            return seat1Number - seat2Number;
        } else {
            return seat1Letter - seat2Letter;
        }
    }
    // end grid functions

    open() {
        if (!window.navigator.onLine) {
            this.notificationService.showWarning('Passenger search is not available while offline.');
        } else {
            this.reset();
            this.populateFlightDetails();
            if (!this.displayFlightSearch) {
                this.paxSearch();
            }
            this.modal.open();
        }
    }

    populateFlightDetails() {
        this.displayFlightSearch = true;
        this.store.select(getFlightSelection).pipe(take(1)).pipe(takeUntil(this.destroy$)).subscribe(flight => {
            if (flight && !this.utilities.isNullOrEmpty(flight.flightNumber)
                && !this.utilities.isNullOrEmpty(flight.flightDate) && !this.utilities.isNullOrEmpty(flight.departureStation)) {
                this.flightResults = flight;
                this.displayFlightSearch = false;
            }
        });
    }

    reset() {
        this.close = false;
        this.searchError = false;
        this.isSearching = false;
        this.selectedPax = null;
        this.passengerList = null;
        this.quickSearchValue = null;
    }

    onClose($event) {
        this.reset();
        this.close = true;
    }

    onRowSelected($event) {
        if ($event.node.isSelected()) {
            this.selectedPax = $event.node.data;
        }
    }

    populatePersonData() {
        if (this.displayFlightSearch) {
            this.store.dispatch(
                new UpdateFlightSelection({ ...this.flightResults }));
        }
        this.selectedPaxData.emit(this.selectedPax);
        this.modal.close();
    }

    // this forces a DOM update
    updateIsSearching(value: boolean) {
        this.isSearching = value;
    }

    flightSearch(event) {
        this.reset();
        if (event.flightNumber && event.flightDate && event.departureStation) {
            this.flightResults = event;
            this.paxSearch();
        }
    }

    // passenger search
    paxSearch() {
        this.updateIsSearching(true);
        const searchdate = this.datepipe.transform(this.flightResults.flightDate, 'yyyy-MM-dd', 'UTC');
        this.passengersService.getList(this.flightResults.flightNumber, searchdate, this.flightResults.departureStation).pipe(takeUntil(this.destroy$))
            .subscribe(
                passengers => {
                    if (!passengers) {
                        this.searchError = true;
                    } else {
                        this.passengerList = passengers;
                    }
                },
                (error) => {
                    console.error('Passenger search failed.');
                    this.notificationService.showError('Passenger search failed.');
                    this.updateIsSearching(false);
                },
                () => {
                    this.updateIsSearching(false);
                });
    }
}
