import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { IDynamicQuestion } from '../../new-report-store/wizard';
import { Store } from '@ngrx/store';
import { getUser } from 'app/store/user';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { State } from '../../new-report-store';

@Component({
    selector: 'app-display-injuries',
    templateUrl: './display-injuries.component.html',
    styleUrls: ['../dynamic-form.component.scss'],
    viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})

export class DisplayInjuriesComponent implements OnInit, OnDestroy {
    @Input() question: IDynamicQuestion;
    @Input() questionAttrs: object = {};
    @Input() parentComponent: string;
    displayInjuries = false;
    userId: string;
    destroy$: Subject<void> = new Subject<void>();

    constructor(private store: Store<State>) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(user => {
            this.userId = this.cleanUserId(user.id);
        });
    }

    cleanUserId(id): string {
        if (id) {
            return id.replace(/^0+/, '');
        }
        return null;
    }

    toggleIcon(): void {
        this.displayInjuries = !this.displayInjuries;
    }
}
