import { Component, OnInit, Input, OnChanges, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { NotificationService } from '@shared/error-handler-notify/services/notification.service';
import { Narrative } from '../../report-history';
import { NarrativeRetrievalObj } from '../../report-history';
import { Store } from '@ngrx/store';
import { State } from '@app/store';
import { AddNarrative } from 'app/report-history/report-history-store/detail/detail.actions';
import { getReportDetails, getReportDetailsReportId, getReportDetailsDescription } from 'app/report-history/report-history-store/detail';
import { Observable, Subject } from 'rxjs';
import { ExpandCollapseService } from 'app/shared/common-ux/components/component-services/expand-collapse.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-description-summary',
    templateUrl: './description-summary.component.html',
    styleUrls: ['./description-summary.component.scss', '../summary.component.scss'],
})
export class DescriptionSummaryComponent implements OnInit, OnChanges, OnDestroy {
    description: NarrativeRetrievalObj[] = [];
    @Input() hideAddButton;
    @Input() allExpandState: Subject<any>;
    @ViewChild('section', { static: true }) sectionElem: ElementRef;
    reportId: number;
    iconStyle = 'icon-collapse icon-large';
    isLoading = true;
    newDescriptionObj: Narrative = new Narrative;
    sectionDescSummary$: Observable<any>;
    destroy$: Subject<void> = new Subject<void>();
    constructor(
        private store: Store<State>,
        private expandCollapseService: ExpandCollapseService,
        private notificationService: NotificationService) {
        // dont remove.
        // To show local loader for sub section put the select values into an observable instead of variable.
        this.sectionDescSummary$ = this.store.select(getReportDetailsDescription);
    }
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        // this.store.select(getReportDetailsDescription).subscribe(result => this.description = result);
        this.store.select(getReportDetailsReportId).pipe(takeUntil(this.destroy$)).subscribe(result => this.reportId = result);
        this.allExpandState.pipe(takeUntil(this.destroy$)).subscribe(event => {
            if (event) {
                this.expandCollapseService.expand(this.sectionElem);
            } else {
                this.expandCollapseService.collapse(this.sectionElem);
            }
        });

    }

    ngOnChanges() {
        this.sectionDescSummary$.pipe(takeUntil(this.destroy$)).subscribe(detail => this.loadDescription(detail));
    }

    loadDescription(detail) {
        if (detail) {
            this.isLoading = false;
            this.description = detail;
        } else {
            this.isLoading = true;
        }
    }

    onSave(newDescriptionText) {
        const note = new Narrative();
        note.reportId = this.reportId;
        note.narrativeText = newDescriptionText;
        note.narrativeTypeCd = 'AN';

        this.store.dispatch(new AddNarrative(note));
        this.isLoading = true;
    }

    private toggleIcon(): void {
        if (this.iconStyle === 'icon-expand icon-large') {
            this.iconStyle = 'icon-collapse icon-large';
        } else {
            this.iconStyle = 'icon-expand icon-large';
        }
    }
}
