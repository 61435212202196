import { Action } from '@ngrx/store';
import { IReportHistory, IReportHistoryOptions } from './history.model';

export const LOAD_MY_HISTORY = '[ReportHistory List] loadMyHistory';
export const POPULATE_MY_HISTORY = '[ReportHistory List] populateMyHistory';
export const FILTER_MY_HISTORY = '[ReportHistory List] filterMyHistory';
export const ERROR_MY_HISTORY = '[ReportHistory List] errorMyHistory';
export const LOAD_ALL_HISTORY = '[ReportHistory List] loadAllHistory';
export const POPULATE_ALL_HISTORY = '[ReportHistory List] populateAllHistory';
export const FILTER_ALL_HISTORY = '[ReportHistory List] filterALLHistory';
export const ERROR_ALL_HISTORY = '[ReportHistory List] errorALLHistory';
export const LOAD_TODO_HISTORY = '[ReportHistory List] loadTodoHistory';
export const POPULATE_TODO_HISTORY = '[ReportHistory List] populateTodoHistory';
export const FILTER_TODO_HISTORY = '[ReportHistory List] filterTodoHistory';
export const ERROR_TODO_HISTORY = '[ReportHistory List] errorTodoHistory';
export const SET_SELECTED_LIST = '[ReportHistory List] setSelectedList';
export const REMOVE_CLOSED_REPORT = '[ReportHistory List] removeClosedReport';
export const RESET = '[ReportHistory List] reset';
export const LOAD_TODO_LIST = '[Todo List] loadTodoList';
export const POPULATE_TODO_LIST = '[Todo List] populateTodoList';
export const ERROR_TODO_LIST = '[Todo List] errorTodoList';
export const FILTER_TODO_LIST = '[Todo List] filterTodoList';
export const SELECTED_TODO = '[Todo List] selectedTodo';
export const RESET_TODO = '[Todo List] resetTodo';
export class LoadMyHistory implements Action {
    readonly type = LOAD_MY_HISTORY;
    constructor(public payload: { employeeId: string, filterString: string }) { }
}

export class PopulateMyHistory implements Action {
    readonly type = POPULATE_MY_HISTORY;
    constructor(public payload: Array<IReportHistory>) { }
}

export class FilterMyHistory implements Action {
    readonly type = FILTER_MY_HISTORY;
    constructor(public payload: IReportHistoryOptions) { }
}

export class ErrorMyHistory implements Action {
    readonly type = ERROR_MY_HISTORY;
    constructor(public payload: string) { }
}

export class LoadAllHistory implements Action {
    readonly type = LOAD_ALL_HISTORY;
    constructor(public payload: string) { }
}

export class PopulateAllHistory implements Action {
    readonly type = POPULATE_ALL_HISTORY;
    constructor(public payload: Array<IReportHistory>) { }
}

export class FilterAllHistory implements Action {
    readonly type = FILTER_ALL_HISTORY;
    constructor(public payload: IReportHistoryOptions) { }
}

export class ErrorAllHistory implements Action {
    readonly type = ERROR_ALL_HISTORY;
    constructor(public payload: string) { }
}

export class LoadTodoHistory implements Action {
    readonly type = LOAD_TODO_HISTORY;
    constructor(public payload: string) { }
}
export class LoadTodoList implements Action {
    readonly type = LOAD_TODO_LIST;
    constructor(public payload: string) { }
}
export class PopulateTodoHistory implements Action {
    readonly type = POPULATE_TODO_HISTORY;
    constructor(public payload: Array<IReportHistory>) { }
}
export class PopulateTodoList implements Action {
    readonly type = POPULATE_TODO_LIST;
    constructor(public payload: Array<IReportHistory>) { }
}
export class FilterTodoHistory implements Action {
    readonly type = FILTER_TODO_HISTORY;
    constructor(public payload: IReportHistoryOptions) { }
}
export class FilterTodoList implements Action {
    readonly type = FILTER_TODO_LIST;
    constructor(public payload: IReportHistoryOptions) { }
}
export class SelectedTodo implements Action {
    readonly type = SELECTED_TODO;
    constructor(public payload: IReportHistory) { }
}
export class ResetTodo implements Action {
    readonly type = RESET_TODO;
    constructor(public payload: IReportHistory) { }
}
export class ErrorTodoHistory implements Action {
    readonly type = ERROR_TODO_HISTORY;
    constructor(public payload: string) { }
}
export class ErrorTodoList implements Action {
    readonly type = ERROR_TODO_LIST;
    constructor(public payload: string) { }
}
export class SetSelectedList implements Action {
    readonly type = SET_SELECTED_LIST;
    constructor(public payload: string) { }
}

export class RemoveClosedReport implements Action {
    readonly type = REMOVE_CLOSED_REPORT;
    constructor(public payload: string) { }
}

export class Reset implements Action {
    readonly type = RESET;
    constructor() { }
}

export type Actions
    = LoadMyHistory
    | PopulateMyHistory
    | FilterMyHistory
    | ErrorMyHistory
    | LoadAllHistory
    | PopulateAllHistory
    | FilterAllHistory
    | ErrorAllHistory
    | LoadTodoHistory
    | PopulateTodoHistory
    | FilterTodoHistory
    | ErrorTodoHistory
    | SetSelectedList
    | RemoveClosedReport
    | Reset
    | LoadTodoList
    | PopulateTodoList
    | FilterTodoList
    | ErrorTodoList
    | SelectedTodo
    | ResetTodo;
