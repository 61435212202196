import { Component, OnInit, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import * as _ from 'lodash';
import { Attachment } from 'app/report-history/report-history';
import { SSEHubSettingsService, ISetting } from 'app/shared/SSEHubClient';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-add-attachment',
  templateUrl: './add-attachment.component.html',
  styleUrls: ['./add-attachment.component.scss']
})
export class AddAttachmentComponent implements OnDestroy {
  @Output() onSave: EventEmitter<Attachment> = new EventEmitter();
  @Input() employeeId: string;
  @ViewChild('fileUpload') public fileUploadForm;
  @Input() attachments: Attachment[] = [];
  @Input() descriptionRequired?: boolean;

  isAddNew = false;
  fileName: string;
  base64TextString: string;
  isLoading = false;
  isDescriptionInValid = false;
  hasError = false;
  newAttachment: Attachment = new Attachment;
  isFileInValid = false;
  isFileEmpty = false;
  fileType: string[];
  unallowedFileTypes: string[] = [];
  destroy$: Subject<void> = new Subject<void>();
  constructor(private settingService: SSEHubSettingsService) {
    this.settingService.getAll().pipe(map((val: any) => {
      if (val && val.settings) {
        val.settings.map((setting: ISetting) => {
          if (setting && setting.value && setting.key === 'UNACCEPTABLE_ATTACHMENT_TYPE') {
            // parse the string of file types into an array
            this.unallowedFileTypes = setting.value.replace(/\"|\./g, '').split(',');
          }
        });
      }
    })).pipe(takeUntil(this.destroy$)).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onAdd() {
    this.isAddNew = true;
  }

  close() {
    this.reset();
  }

  reset() {
    this.isAddNew = false;
    this.isFileInValid = false;
    this.newAttachment = new Attachment;
    this.isLoading = false;
    this.isDescriptionInValid = false;
    this.isFileEmpty = false;
    this.fileName = null;

  }

  clearErrorMessages() {
    this.isFileInValid = false;
    this.isDescriptionInValid = false;
  }

  resetData() {
    this.fileName = null;
  }

  onChangeDescTxt() {
    if (!this.newAttachment.description) {
      this.isDescriptionInValid = true;
      return;
    } else {
      this.isDescriptionInValid = false;
    }
  }
  onAttachmentSave() {
    if (!this.fileName) {
      this.isFileInValid = true;
      return;
    }
    if (!this.newAttachment.description && this.descriptionRequired) {
      this.isDescriptionInValid = true;
      return;
    }
    this.newAttachment.employeeId = this.employeeId;
    this.newAttachment.fileName = this.fileName;
    this.newAttachment.imageString = this.base64TextString;
    this.newAttachment.createdDate = (new Date()).toISOString();

    this.onSave.emit(this.newAttachment);
    this.reset();
  }

  onFileSelect($event): void {
    this.readFile($event.target);
  }

  readFile(inputValue: any): void {
    const file: File = inputValue.files[0];
    if (!(file && file.name)) {
      this.resetData();
      return;
    }
    const fileExtension = file.name.split('.'); // can have multiple extensions
    this.fileType = fileExtension.slice(1, fileExtension.length); // remove filename

    const emptyFileFlag = this.isFileEmptyFunction(file);
    if (!emptyFileFlag) {
      let valid = true;
      this.fileType.map((ft) => {
        if (_.includes(this.unallowedFileTypes, ft.toLowerCase())) {
          valid = false;
        }
      });
      if (valid) {
        this.fileName = file.name;
        const fileReader: FileReader = new FileReader();
        fileReader.onload = this.convertFile.bind(this);
        fileReader.readAsArrayBuffer(file);
        this.clearErrorMessages();
      } else {
        this.fileUploadForm.reset();
        this.resetData();
        this.isFileInValid = true;
      }
    } else {
      this.fileUploadForm.reset();
      this.resetData();
      this.clearErrorMessages();
    }
  }

  isFileEmptyFunction(file) {
    if (file.size === 0) {
      this.isFileEmpty = true;
      return true;
    } else {
      this.isFileEmpty = false;
      return false;
    }
  }
  convertFile(readerEvt) {

    let binaryString = '';
    const bytes = new Uint8Array(readerEvt.target.result);
    const length = bytes.byteLength;
    for (let i = 0; i < length; i++) {
      binaryString = binaryString + String.fromCharCode(bytes[i]);
    }
    this.base64TextString = btoa(binaryString);
  }
}
