<modal #answerAddModal (onOpen)="onOpen($event)" (onClose)="onClose($event)">
    <modal-header>
        <h4>Add Answer</h4>
    </modal-header>
    <modal-content>
        <app-aa-spinner [loading]="isLoading"></app-aa-spinner>
        <form (ngSubmit)="onSubmit()" #answerForm="ngForm">
            <!-- Commenting out AMS address code -->
            <!-- <div *ngIf='!isLoading && isMultiInputAmsAddress'>
                <app-answer-multi-input *ngIf="!close" [addOrEdit]="'add'" [existingAnswers]="question.answers"
                    (answerText)="updateAnswer($event)" (newAttribute)="updateAttribute($event)">
                </app-answer-multi-input>
            </div> -->
            <!-- <div *ngIf="!isLoading && !isMultiInputAmsAddress" class="form-group"> -->
            <div *ngIf="!isLoading" class="form-group">
                <label for="AnswerText">Answer :</label>
                <input type="text" class="form-control" id="AnswerText" required [(ngModel)]="answer.answer"
                    name="AnswerText" #AnswerText="ngModel">
                <div *ngIf="AnswerText.invalid && (AnswerText.dirty || AnswerText.touched || isAnswerValid)"
                    class="validation-fail">
                    Answer is required
                </div>
            </div>
        </form>
    </modal-content>
    <modal-footer>
        <button type="button" class="btn btn-cancel" (click)="answerAddModal.close()">Close</button>
        <button type="submit" class="btn btn-primary" (click)="onSubmit()">Save</button>
    </modal-footer>
</modal>
