import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';
import { Injectable, OnDestroy } from '@angular/core';
import { Attachment } from 'app/report-history/report-history';
import { Store } from '@ngrx/store';
import { State } from '@app/store';
import { getUser } from 'app/store/user';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SSEHubReportHistoryService } from 'app/shared/SSEHubClient';
import { saveAs } from 'file-saver';

@Injectable({
    providedIn: 'root',
})

export class AttachmentSummary implements OnDestroy {
    imageType = ['png', 'jpg', 'gif', 'jpeg'];
    pdfType = ['pdf'];
    attachments = [];
    isLoading = false;
    iconStyle = 'icon-expand icon-large';
    employeeId: number;
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<State>,
        private sseHubReportHistoryService: SSEHubReportHistoryService,
    ) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getSubmitterName(reportAttachment: Attachment): string {
        let name = null;
        if (reportAttachment.lastName && reportAttachment.firstName) {
            name = `${reportAttachment.lastName}, ${reportAttachment.firstName}`;
        } else if (reportAttachment.lastName) {
            name = reportAttachment.lastName;
        } else if (reportAttachment.firstName) {
            name = reportAttachment.firstName;
        }
        return name;
    }

    public loadAttachments(detail) {
        if (detail) {
            this.isLoading = false;
            this.attachments = detail;
        } else {
            this.isLoading = true;
        }
    }

    public getAttachmentType(reportAttachment: Attachment) {
        const fileExtension = reportAttachment.fileName.split('.').pop().toLowerCase();
        // Viewable types = png, jpg, gif, pdf (pdf is size dependent)
        // pdf files > 1.5 MB = downloaded, < 1.5MB displayed in new browser tab
        // 2000000 base64 string length ~= 1.5MB
        if (_.includes(this.imageType, fileExtension) || _.includes(this.pdfType, fileExtension) && reportAttachment.imageString.length < 1999990) {
            return 'viewable_file_type';
        }
        return 'downloadable_file_type';
    }

    public toggleIcon(): void {
        if (this.iconStyle === 'icon-expand icon-large') {
            this.iconStyle = 'icon-collapse icon-large';
        } else {
            this.iconStyle = 'icon-expand icon-large';
        }
    }

    public viewAttachment(attachment: Attachment) {
        if (!attachment || !attachment.fileName || (!attachment.guid && !attachment.imageString)) {
            const newTab = window.open();
            console.error('Cannot process attachment link');
            if (newTab) {
                newTab.document.body.innerHTML = 'Could not find attachment.';
            }
        }

        if (attachment.guid && !attachment.imageString) {
            this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(user => this.employeeId = user.id);
            this.sseHubReportHistoryService.getAttachment(attachment.reportId, attachment.guid, this.employeeId).pipe(takeUntil(this.destroy$))
                .subscribe(serviceAttachment => {
                    this.processAttachment(serviceAttachment);
                });
        }

        if (attachment.imageString) {
            this.processAttachment(attachment);
        }
    }

    public processAttachment(attachment: Attachment) {
        const attachmentType = this.getAttachmentType(attachment);
        if (attachmentType === 'viewable_file_type') {
            this.openAttachment(attachment);
        } else {
            this.saveAttachment(attachment);
        }
    }

    public openAttachment(attachment: Attachment) {
        const newTab = window.open();
        if (!newTab) {  // could be null so handle that
            return;
        }
        newTab.document.body.innerHTML = this.getHTML(attachment);
    }

    public getHTML(attachment: Attachment) {
        if (!attachment || !attachment.fileName || !attachment.imageString) {
            console.error('Cannot process attachment link');
            return 'Could not find attachment.';
        }

        const fileExtension = attachment.fileName.split('.').pop();
        const base64image = attachment.imageString;
        const dataType = _.includes(this.imageType, fileExtension.toLowerCase()) ? 'image' : 'application';

        return `<object style="min-height:100vh;width:100%" data="data:${dataType}/${fileExtension};base64,${base64image}"/>`;
    }

    public saveAttachment(attachment: Attachment) {
        const fileExtension = attachment.fileName.split('.').pop();
        saveAs(`data:application/${fileExtension};base64,${attachment.imageString}`, attachment.fileName);
    }
}
