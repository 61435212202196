import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as _ from 'lodash';

@Pipe({
  name: 'ReportHistorySearchPipe'
})

@Injectable()
export class ReportHistorySearchPipe implements PipeTransform {
  constructor(private datepipe: DatePipe) { }
    transform(dataList: any[], filter: any): any[] {
      if (dataList && filter && dataList.length > 0) {
        const filterKeys = Object.keys(filter);
        if (filterKeys && filterKeys.length > 0) {
            filterKeys.forEach((value, index, list) => {
                dataList = dataList.filter(item => {
                    let match = false;

                    let columnValue = item[value];
                    const columnFilter = filter[value];

                    // convert dates
                    if (columnValue instanceof Date) {
                        columnValue = this.datepipe.transform(columnValue, 'MM/dd/yyyy', 'UTC');
                    }

                    if (columnValue && columnFilter) {
                        // toString() converts numbers and dates so we can do compares
                        match = columnValue.toString().toLowerCase().indexOf(columnFilter.toString().toLowerCase()) >= 0 ? true : false;
                    } else if (!columnFilter) {
                        match = true;
                    }

                    return match;
                });
            });
        }
      }

    return dataList;
  }
}
