import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dateValidationPipe' })
export class DateValidationPipe implements PipeTransform {
    transform(value: any): string {
        if (value) {
            // Check for material date picker malformed error
            for (const element of value) {
                if (element.key && element.key === 'matDatepickerParse') {
                    element.value = 'Invalid date format';
                }
            }
        }
        return value;
    }
}
