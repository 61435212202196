import { Guid } from 'app/shared/utilities/guid';

export class ReportHistory {
    reportId: string;
    reportSk: number;
    eventDate: Date;
    submitDate: Date;
    flightNumber: number;
    flightDate: Date;
    departureStation: string;
    arrivalStation: string;
    noseNumber: string;
    fleetType: string;
    affectedStation: string;
    title: string;
    emergencyDecalred: Boolean;
    riskClassification: string;
    responseRequested: string;
    status: string;
    daysUntilReply: number;
    base: string;
    route: string;
    // reportId: string;
    // title: string;
    employeeId: number;
    permCrewBase: string;
    seat: string;
    equipment: string;
    division: string;
    lastName: string;
    firstName: string;
    crewBase: string;
    reportStatus: string;
    // reportSk: string;
    eventSk: string;
}

export class ReportDetail {
    reportId: string;
    etn: number;
    reportSk: number;
    categoryId: number;
    createDate: Date;
    createdBy: number;
    crewBase: string;
    division: string;
    employeeId: string;
    equipment: string;
    eventSk: number;
    firstName: string;
    lastName: string;
    flight: FlightDetailInfo;
    involvements: Array<ReportDetailInvolvement>;
    notes: Array<Narrative>;
    permCrewBase: string;
    position: string;
    questionAnswers: Array<Question>;
    reportStatus: string;
    reportType: string;
    responseRequested: string;
    riskclassification: string;
    seat: string;
    suggestions: Array<Narrative>;
    title: string;
    updateDate: Date;
    updatedBy: number;
    reportStatusSk: number;
    reportStatusDesc: string;
    reportTypeDesc: string;
    replies: Array<ReportHistoryReplies>;
    recipients: Array<RecipientListInfo>;
}

export class AdminDiscussion {
    date: Date;
    submitter: DiscussionPerson;
    comment: string;
    responders?: Array<DiscussionPerson>; // a response won't have responders
    request: boolean;  // true means a request. false means a response.
}

export class DiscussionPerson {
    firstName: string;
    middleName?: string;
    lastName: string;
    employeeId: string;
}


export class FlightDetailInfo {
    flightId: string;
    flightDate: string;
    departureStation: string;
    arrivalStation: string;
    interruptStation: string;
    tailNumber: string;
    fleetDescription: string;
    flightPhaseDescription: string;
    arrivalTerminal: string;
    arrivalGate: string;
    departureTerminal: string;
    departureGate: string;
}

export class ReportDetailInvolvement {
    reportSk: number;
    involvementType: string;
    categoryId: number;
    personSk: number;
    questionSk: number;
    lastName: string;
    middleName: string;
    firstName: string;
    employeeId: string;
    seat: string;
    followupQuestions: Array<any>;
}

export class Question {
    reportSk: number;
    categoryId: number;
    categoryDesc: string;
    questionSk: number;
    question: string;
    answerSk: number;
    answer: string;
    personSk: number;
    lastName: string;
    middleName: string;
    firstName: string;
}

export class Narrative {
    reportId: number;
    narrativeText: string;
    narrativeTypeCd: string;
}

export class NarrativeRetrievalObj {
    reportId: number;
    narrativeText: string;
    narrativeTypeCd: string;
    createDate: string;
    updateDate: string;
}

export class Attachment {
    reportId: string;
    employeeId: string;
    fileName: string;
    description: string;
    imageString: string;     // The API returns ImageString
    guid: Guid;             // For report history retrieval
    createdDate: string;
    firstName: string;
    lastName: string;
}

export class ActivityLogInfo {
    activitySk: number;
    activityStatusDescription: string;
    createdDate: Date;
    taskDesc: string;
    eventSk: number;
    reportId: number;
    firstName: string;
    lastName: string;
    middleName: string;
    updatedDate: Date;
    additionalEmails: string;
}

export class ReportStatus {
    statusSk: number;
    statusDescription: string;
}

export class ReportHistoryReplies {
    reportSk: number;
    personRptHeaderSk: number;
    reportType: string;
    createDate: string;
    createdUserSk: number;
    personRptTextSk: number;
    rptText: string;
    firstName: string;
    middleName: string;
    lastName: string;
}

// TODO: change from forward to request response
export class ReportHistoryForward {
    recipientEmployeeIds: Array<string>;
    responderEmployeeId: number;
    message: string;
}

export class ReportHistoryRespond {
    responderEmployeeId: number;
    message: string;
}

export class ReportHistoryReply {
    responderEmployeeId: string;
    message: string;
    statusSk: number;
}

export class RecipientListInfo {
    reportSk: number;
    reportType: string;
    createDate: string;
    firstName: string;
    lastName: string;
    stationCode: string;
    jobTitle: string;
}
