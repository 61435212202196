import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import * as _ from 'lodash';

import * as categoryActions from './list.actions';
import { IProfile } from './list.model';
import { IUser, User, getUser } from '@app/store/user';
import { State } from '@app/store';

import { SSEHubCategoriesService } from '@shared/SSEHubClient/categories.service';
import { SSEHubProfileService } from '@shared/SSEHubClient/profile.service';
import { NotificationService } from '@shared/error-handler-notify/services/notification.service';

import { switchMap, map, withLatestFrom, catchError } from 'rxjs/operators';
import { Observable, merge, of } from 'rxjs';

@Injectable()
export class CategoryListEffects {

    
    loadProfiles$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<categoryActions.LoadProfiles>(categoryActions.LOAD_PROFILES),
        withLatestFrom(this.store$.select(getUser)), // selects the current value from store once
        switchMap(([action, user]) => {
            return this.seeHubProfileServices.getAll().pipe(
                map((payload: any) => {
                    if (payload && payload) {
                        const filtered = this.filterProfiles(user, payload);
                        return new categoryActions.PopulateProfiles(filtered);
                    } else {
                        this.notificationService.showError('Error loading profiles');
                        return new categoryActions.CategoriesError('Error loading profiles');
                    }
                }),
                catchError(e => {
                    console.log(e);
                    this.notificationService.showError('Error loading profiles');
                    return of(new categoryActions.CategoriesError('Error loading profiles'));
                })
            );
        })
    ));

    
    selectProfileById$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<categoryActions.SelectProfileById>(categoryActions.SELECT_PROFILE_BY_ID),
        withLatestFrom(this.store$.select(getUser)), // selects the current value from store once
        switchMap(([action, user]) => {
            return this.seeHubProfileServices.get(action.payload).pipe(
                map((profile: any) => {
                    return new categoryActions.SelectProfile(profile.Profiles[0]);
                })
            );
        })
    ));

    
    loadCategories$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<categoryActions.LoadCategories>(categoryActions.LOAD_CATEGORIES),
        switchMap((payload) => {
            return this.sseHubCategoriesService.getAllCategories().pipe(
                map((result: any) => {
                    if (result) {
                        return new categoryActions.PopulateCategories(result);
                    } else {
                        this.notificationService.showError('Error loading categories');
                        return new categoryActions.CategoriesError('Error loading categories');
                    }
                }),
                catchError(e => {
                    console.log(e);
                    this.notificationService.showError('Error loading categories');
                    return of(new categoryActions.CategoriesError('Error loading categories'));
                })
            );
        })
    ));

    
    loadCategoryByProfile$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<categoryActions.LoadCategoriesByProfile>(categoryActions.LOAD_CATEGORIES_BY_PROFILE),
        switchMap(action => {
            return this.sseHubCategoriesService.getByProfileId(action.payload).pipe(
                map((result: any) => {
                    if (result) {
                        return new categoryActions.PopulateCategoriesByProfile(result);
                    } else {
                        this.notificationService.showError('Error loading profile categories');
                        return new categoryActions.CategoriesError('Error loading profile categories');
                    }
                }),
                catchError(e => {
                    console.log(e);
                    this.notificationService.showError('Error loading profile categories');
                    return of(new categoryActions.CategoriesError('Error loading profile categories'));
                })
            );
        })
    ));

    
    addCategory$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<categoryActions.AddCategory>(categoryActions.ADD_CATEGORY),
        switchMap(action => {
            return this.sseHubCategoriesService.addCategory(action.payload.category.id, action.payload.profileId).pipe(
                map((result: any) => {
                    if (result.success) {
                        return new categoryActions.AddCategorySuccess(action.payload.category);
                    }
                    this.notificationService.showError(result.message);
                    return new categoryActions.CategoriesError(result.message);
                }),
                catchError(e => {
                    console.log(e);
                    this.notificationService.showError('Error adding category');
                    return of(new categoryActions.CategoriesError('Error loading profile categories'));
                })
            );
        })
    ));

    
    deleteCategory$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType<categoryActions.DeleteCategory>(categoryActions.DELETE_CATEGORY),
        switchMap((action) => {
            return this.sseHubCategoriesService.deleteCategory(action.payload.profileId, action.payload.categoryId).pipe(
                map((result: any) => {
                    if (result.success) {
                        return new categoryActions.DeleteCategorySuccess(action.payload.categoryId);
                    }
                    this.notificationService.showError(result.message);
                    return new categoryActions.CategoriesError(result.message);
                }),
                catchError(e => {
                    console.log(e);
                    this.notificationService.showError('Error deleting category');
                    return of(new categoryActions.CategoriesError('Error deleting category'));
                })
            );
        })
    ));

    // filterProfiles removes the profiles to which the user doesn't have access to
    filterProfiles(user: IUser, profiles: IProfile[]) {
        let filtered = [];
        const all: boolean =
            user &&
                User.hasPrivilegeArray(user,
                    [
                        'WEB_SUADMIN',
                        'WEB_ADMIN_CATEGORYMGNT_VIEW_ALL',
                        'WEB_ADMIN_CATEGORYMGNT_EDIT_ALL'
                    ]) ? true : false;
        if (!all && user) {
            const edit = User.getPrivilegesMatching(user, 'WEB_ADMIN_CATEGORYMGNT_EDIT_');
            const view = User.getPrivilegesMatching(user, 'WEB_ADMIN_CATEGORYMGNT_VIEW_');
            const both = edit.concat(view);

            _.each(both, (p, i) => {
                const parsed = parseInt(p.replace('WEB_ADMIN_CATEGORYMGNT_EDIT_', '').replace('WEB_ADMIN_CATEGORYMGNT_VIEW_', ''), 10);
                const authed = _.find(profiles, (prof) => { return prof.profileId === parsed; });
                if (authed) {
                    filtered.push(authed);
                }
            });

        } else {
            filtered = profiles;
        }

        return filtered;
    }

    constructor(
        private actions$: Actions,
        private store$: Store<State>,
        private sseHubCategoriesService: SSEHubCategoriesService,
        private seeHubProfileServices: SSEHubProfileService,
        private notificationService: NotificationService
    ) { }
}
