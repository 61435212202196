import * as wizard from './wizard/wizard.reducer';
import * as questions from './wizard/questions.reducer';
import * as wizardEffects from './wizard/wizard.effects';
import * as questionsEffects from './wizard/questions.effects';
import * as categories from './list';
import { ActionReducerMap, createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '@app/store';
import { NewReportWizardQuestionsModel, visibilityDictionary } from './wizard';

export interface NewReportState {
    categories: categories.CategoriesState;
    wizardQuestions: questions.NewReportWizardQuestionsModel;
    wizardSection: wizard.NewReportWizardSectionDetailModel;
}

export interface State extends fromRoot.State {
    newReport: categories.CategoriesState;
}

export const reducer: ActionReducerMap<NewReportState> = {
    categories: categories.reducer,
    wizardQuestions: questions.questionsReducer,
    wizardSection: wizard.sectionReducer
};

export const NewReportEffects = [
    categories.NewReportListEffects,
    questionsEffects.NewReportWizardQuestionsEffects,
    wizardEffects.NewReportWizardSectionEffects
];

/**
 *  SELECTORS
 *  every reducer has their own selectors, but they're are not aware of their parent nodes
 *  createSelector is used to connect them to the root reducer
 */

// createFeatureSelector selects a part of the root store and allows us to access the its branches
export const getNewReportState = createFeatureSelector<NewReportState>('newReport');
export const getNewReportCategoriesState = createSelector(getNewReportState, (state: NewReportState) => state.categories);
export const getNewReportQuestionsState = createSelector(getNewReportState, (state: NewReportState) => state.wizardQuestions);
export const getNewReportSectionState = createSelector(getNewReportState, (state: NewReportState) => state.wizardSection);

// categories selectors
export const getCategoriesList = createSelector(getNewReportCategoriesState, categories.getCategoriesListSelector);
export const getSelectedCategory = createSelector(getNewReportCategoriesState, categories.getSelectedCategorySelector);

// wizard section selectors
export const getFlightSectionDetails = createSelector(getNewReportSectionState, wizard.getFlightSectionDetailsSelector);
export const getQuestionsSectionDetails = createSelector(getNewReportSectionState, wizard.getQuestionsSectionDetailsSelector);
export const getNotesSectionDetails = createSelector(getNewReportSectionState, wizard.getNotesSectionDetailsSelector);
export const getAttachmentsSectionDetails = createSelector(getNewReportSectionState, wizard.getAttachmentsSectionDetailsSelector);
export const getWizardSessionLog = createSelector(getNewReportSectionState, wizard.getWizardSessionLog);

// wizard questions selectors
export const getQuestions = (groupName: string) => createSelector(getNewReportQuestionsState, (state: NewReportWizardQuestionsModel) => {
    return state.questions.filter(q => q.groupName.toUpperCase() == groupName.toUpperCase())
});

export const getAllQuestions = createSelector(getNewReportQuestionsState, (state: NewReportWizardQuestionsModel) => state.questions);

export const getGeneralPageStatus = createSelector(getNewReportQuestionsState, questions.getGeneralPageStatusSelector);
export const getGeneralQuestionsIsLoading = createSelector(getNewReportQuestionsState, questions.getGeneralQuestionsIsLoadingSelector);
export const getGeneralQuestionsIncidentStation = createSelector(getNewReportQuestionsState, questions.getGeneralQuestionsIncidentStation);
export const getGeneralQuestionsError = createSelector(getNewReportQuestionsState, questions.getGeneralQuestionsErrorSelector);

// wizard notes selectors
export const getAttachments = createSelector(getNewReportQuestionsState, questions.getAttachmentsSelector);
export const getAttachmentsSettings = createSelector(getNewReportQuestionsState, questions.getAttachmentsSettings);
export const getNotesPageStatus = createSelector(getNewReportQuestionsState, questions.getNotesPageStatusSelector);
export const getAttachmentsPageStatus = createSelector(
    getNewReportQuestionsState,
    getAttachmentsSectionDetails,
    (questionState, attachmentsState) => {
        // if attachments are required
        if (attachmentsState && attachmentsState.visibility && visibilityDictionary[attachmentsState.visibility] === 'required') {
            // verify there is an attachment
            return questionState.attachments && questionState.attachments.length > 0 ? 'complete' : 'incomplete';
        }
        return 'complete';
    }
);

// this is a combination of the notes questions status as well at the attachments status... so it's more complicated
export const getCombinedNotesAndAttachmentsPageStatus = createSelector(
    getNotesPageStatus,
    getAttachmentsPageStatus,
    (notesStatus, attachmentsStatus) => {
        if (!notesStatus) {
            return null;
        }
        return notesStatus == 'incomplete' || attachmentsStatus == 'incomplete'
            ? 'incomplete'
            : 'complete';
    }
);

// wizard flight selectors
export const getFlightPageStatus = createSelector(getNewReportQuestionsState, questions.getFlightPageStatusSelector);
export const getIsFlight = createSelector(getNewReportQuestionsState, questions.getIsFlightSelector);
export const getCrewSequences = createSelector(getNewReportQuestionsState, questions.getCrewSequencesSelector);
export const getFlightSelection = createSelector(getNewReportQuestionsState, questions.getFlightSelectionSelector);
