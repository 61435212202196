import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../store';
import { NotificationService } from 'app/shared/error-handler-notify/services';

@Component({
    selector: 'app-question-with-hyperlinks',
    templateUrl: './question-with-hyperlinks.component.html'
})

export class QuestionWithHyperlinksComponent {
    @Input() hyperlinks: string[];
    @Input() questionText: string;

    constructor(
        private store: Store<State>,
        private notifyService: NotificationService
    ) {

    }

    onLinkClicked(onelink: string) {
        if (window.navigator.onLine) {
            this.navigatetoUrl(onelink);
        } else {
            alert('You are offline');
        }
    }
    navigatetoUrl(onelink: string) {
        onelink = onelink.trim();
        if (onelink && onelink.length > 0) {
            // remove trailing ;
            if (onelink[onelink.length - 1] === ';') {
                onelink = onelink.substring(0, onelink.length - 1);
            }
            window.open(onelink);
        }
    }
}
