import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import * as actions from './wizard.actions';
import { CategoryDetailsService } from '@shared/reportStructure/categoryDetails/categoryDetails.service';
import { NotificationService } from '@shared/error-handler-notify/services/notification.service';
import { switchMap, map, catchError, mergeMap } from 'rxjs/operators';
import { Observable, EMPTY as empty, of } from 'rxjs';
import { SSEHubNewReportService } from 'app/shared/SSEHubClient/new-report.service';

@Injectable()
export class NewReportWizardSectionEffects {


    loadCategoryDetail$: Observable<Action> = createEffect(() => this.action$.pipe(
        ofType<actions.LoadSectionDetail>(actions.LOAD_SECTION_DETAIL),
        switchMap(action => {
            return this.categoryDetailsService.getDetails(action.profileId, action.categoryId).pipe(
                map(detail => {
                    if (detail) {
                        const newDetail: any = detail;
                        return new actions.PopulateSectionDetail(detail);
                    } else {
                        const errorMessage = 'Error loading category details';
                        this.notificationService.showError(errorMessage);
                        return new actions.ErrorSection({ hasError: true, errorMessage: errorMessage });
                    }
                }),
                catchError(error => {
                    console.log(error);
                    const errorMessage = 'Error loading category details';
                    this.notificationService.showError(errorMessage);
                    return of(new actions.ErrorSection({ hasError: true, errorMessage: errorMessage }));
                })
            );
        })
    ));

    constructor(
        private action$: Actions,
        private categoryDetailsService: CategoryDetailsService,
        private notificationService: NotificationService,
    ) { }

}
