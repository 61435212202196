<div
    class="modal fade"
    tabindex="-1"
    aria-labelledby="myModalLabel"
    [ngClass]="{ in: isOpened }"
    (click)="close()"
    #modal
>
        <div class="modal-dialog" role="document" (click)="preventClose($event)">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-bs-dismiss="modal" (click)="close()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <ng-content select="modal-header"></ng-content>
                </div>
                <div class="modal-body">
                    <ng-content select="modal-content"></ng-content>
                </div>
                <div class="modal-footer">
                    <ng-content select="modal-footer"></ng-content>
                </div>
            </div>
        </div>
</div>
