import { Injectable, Inject, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { DynamicQuestionsService } from '@shared/SSEHubClient/dynamic-questions.service';
import { CategoryDetailService } from '../category-detail.service';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class QuestionViewService implements OnDestroy {
    private createNewModalSource = new Subject<any>();
    createNewModalStatus$ = this.createNewModalSource.asObservable();
    destroy$: Subject<void> = new Subject<void>();

    selectedQuestion: any;
    selectedAnswer: any;

    private activeEntity: any;
    constructor(
        public dynamicQuestionsService: DynamicQuestionsService,
        public categoryDetailService: CategoryDetailService,
    ) {
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    openCreateNew(group) {
        this.createNewModalSource.next({ status: 'Open', group: group });
    }
    closeCreateNew(group) {
        this.createNewModalSource.next({ status: 'Close', group: group });
    }
    saveCreateNew(group) {
        this.createNewModalSource.next({ status: 'Save', group: group });
    }
    setSelectedQuestion(question: any) {
        this.selectedQuestion = question;
    }
    getSelectedQuestion(question: any): any {
        return this.selectedQuestion;
    }
    clearSelectedQuestion() {
        this.selectedQuestion = null;
    }
    setSelectedAnswer(answer: any) {
        this.selectedAnswer = answer;
    }
    getSelectedAnswer(answer: any): any {
        return this.selectedAnswer;
    }
    clearSelectedAnswer() {
        this.selectedAnswer = null;
    }

    /**
     * Mapping question to category is used by both Question-Add and Question-List,
     * hence it is moved to this parent level service. If adding an involvement question,
     * ensure to pass the involvement type
     */
    mapQuestionToCategory(profileId, categoryId, addQuestionMetaData, onSuccess, onFailure) {
        this.dynamicQuestionsService.addQuestion(profileId, categoryId, addQuestionMetaData).pipe(takeUntil(this.destroy$))
            .subscribe(
                (result) => {
                    const questionMappingId = result.primaryKey;
                    if (this.isTextTypeQuestion(addQuestionMetaData.inputType)) {
                        this.addEmptyAnswerMapping(questionMappingId);
                    }
                    if (addQuestionMetaData.inputType.toUpperCase() === 'INVOLVEMENT' || addQuestionMetaData.inputType.toUpperCase() === 'QUESTIONGROUP') {
                        const answer = {
                            questionMappingId: questionMappingId,
                            // ANSWER can't have single space bc that's for text-type question and null is not allowed, so it needs a default value
                            // **NOTE: Parser looks for this value to distinguish between old and new involvements... do not change without caution!!
                            answer: addQuestionMetaData.inputType.toUpperCase() === 'INVOLVEMENT' ? 'involvement-answer' : 'question-group-answer',
                            displayOrder: 0
                        };
                        this.dynamicQuestionsService.addAnswer(answer).pipe(takeUntil(this.destroy$)).subscribe(() => {
                            this.categoryDetailService.answerAdded({ type: 'Question' });
                        });
                        // trigger answer added event so the question pulls down the answer data
                    }
                    this.categoryDetailService.questionAddedorModified(addQuestionMetaData.groupName);
                    if (onSuccess) { onSuccess(); }
                },
                (error) => {
                    console.error('Failed to add empty question mapping');
                    if (onFailure) { onFailure(); }
                }
            );
    }

    /**
     * Add an empty answer when a text type question is mapped
     */
    private addEmptyAnswerMapping(questionMappingId) {
        if (questionMappingId === null || questionMappingId === '' || questionMappingId === 0) {
            console.error('Got empty Question Mapping Id, could not map answer');
            return;
        }
        const answer = {
            questionMappingId: questionMappingId,
            answer: ' ', // This has to be a single space for the DB to work!!
            displayOrder: 0
        };
        this.dynamicQuestionsService.addAnswer(answer).pipe(takeUntil(this.destroy$))
            .subscribe(
                (result) => {
                },
                (error) => {
                    console.error('Failed to add empty answer mapping');
                }
            );
    }

    private isTextTypeQuestion(inputType) {
        return inputType
            && (inputType.toLowerCase() === 'textbox'
                || inputType.toLowerCase() === 'textarea'
                || inputType.toLowerCase() === 'string'
                || inputType.toLowerCase() === 'date'
                || inputType.toLowerCase() === 'time'
                || inputType.toLowerCase() === 'number');
    }
}
