import { Component, OnInit, Input, OnChanges, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Narrative } from '../../report-history';
import { NarrativeRetrievalObj } from '../../report-history';
import { Store } from '@ngrx/store';
import { State } from '@app/store';
import { AddNarrative, getReportDetails, getReportDetailsRecommendation } from 'app/report-history/report-history-store/detail';
import { Observable, Subject } from 'rxjs';
import { ExpandCollapseService } from 'app/shared/common-ux/components/component-services/expand-collapse.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-recommendation-summary',
    templateUrl: './recommendation-summary.component.html',
    styleUrls: ['./recommendation-summary.component.scss', '../summary.component.scss']
})

export class RecommendationSummaryComponent implements OnInit, OnChanges, OnDestroy {

    isLoading = true;
    @Input() hideAddButton;
    @Input() allExpandState: Subject<any>;
    @ViewChild('section', { static: true }) sectionElem: ElementRef;
    reportId: number;
    iconStyle = 'icon-expand icon-large';
    recommendation: NarrativeRetrievalObj[] = [];
    sectionRecomSummary$: Observable<any>;
    destroy$: Subject<void> = new Subject<void>();
    constructor(
        private store: Store<State>,
        private expandCollapseService: ExpandCollapseService,
    ) {
        this.sectionRecomSummary$ = this.store.select(getReportDetailsRecommendation);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        // this.store.select(getReportDetailsRecommendation).subscribe(result => this.recommendation = result);
        this.store.select(getReportDetails).pipe(takeUntil(this.destroy$)).subscribe(result => this.reportId = result.detail.reportId);
        this.allExpandState.pipe(takeUntil(this.destroy$)).subscribe(event => {
            if (event) {
                this.expandCollapseService.expand(this.sectionElem);
            } else {
                this.expandCollapseService.collapse(this.sectionElem);
            }
        });
    }

    ngOnChanges() {
        this.sectionRecomSummary$.pipe(takeUntil(this.destroy$)).subscribe(detail => this.loadRecommendation(detail));
    }

    loadRecommendation(detail) {
        if (detail) {
            this.isLoading = false;
            this.recommendation = detail;
        } else {
            this.isLoading = true;
        }
    }

    onSave(newRecommendationText) {
        const recommendation = new Narrative();
        recommendation.reportId = this.reportId;
        recommendation.narrativeTypeCd = 'AS';
        recommendation.narrativeText = newRecommendationText;

        this.store.dispatch(new AddNarrative(recommendation));
        this.isLoading = true;
    }

    private toggleIcon(): void {
        if (this.iconStyle === 'icon-expand icon-large') {
            this.iconStyle = 'icon-collapse icon-large';
        } else {
            this.iconStyle = 'icon-expand icon-large';
        }
    }
}
