import { Injectable, OnDestroy } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { Level } from './log-level';
import { Store } from '@ngrx/store';
import { getUser } from '@app/store/user/user.reducer';
import { IUser } from '@app/store/user';
import { ActivatedRoute, Params, Router, NavigationEnd } from '@angular/router';
import { State } from '../../../store';
import { SSEHubLogService } from '@shared/SSEHubClient/log.service';
import { ServerErrorMessage } from '@models';
import { takeUntil } from 'rxjs/operators';
const CONSOLE_DEBUG_METHOD = console['debug'] ? 'debug' : 'log';


@Injectable()
export class LoggerService implements OnDestroy {
    // Set log level
    // TODO: Use some form of external configuration
    private level: Level = Level.INFO;

    private currentUser: IUser;
    currentUrl: string;
    logUrl = '/api/error';
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<State>,
        private activatedRoute: ActivatedRoute,
        private router: Router,
        private sseHubLogService: SSEHubLogService
    ) {
        this.router.events.pipe(takeUntil(this.destroy$)).subscribe((val) => {
            if (val instanceof NavigationEnd) {
                this.currentUrl = val.url;
            }
        });
        this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(user => this.currentUser = user);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    error(message: any, ...optionalParams: any[]) {
        if (this.isErrorEnabled()) {
            // console.error.apply(console, arguments); // preferable? arguments is throwing an error here
            console.log(message?.toString());

            const serverErrorMessage = new ServerErrorMessage();
            serverErrorMessage.url = this.currentUrl;
            serverErrorMessage.source = 'CERS website';
            // serverErrorMessage.Message = message; // originally
            serverErrorMessage.message = message && message.message ? message.message : '';
            serverErrorMessage.optional1 = message && message.stack ? message.stack : '';
            serverErrorMessage.optional2 = '';

            if (window.navigator.onLine) {
                this.sseHubLogService.logError(serverErrorMessage);
            }
        }
    }

    warn(message: any, ...optionalParams: any[]) {
        this.isWarnEnabled() && console.warn.apply(console, arguments);
    }

    info(message?: any, ...optionalParams: any[]) {
        this.isInfoEnabled() && console.log.apply(console, arguments);
    }

    debug(message: any, ...optionalParams: any[]) {
        this.isDebugEnabled() && (<any>console)[CONSOLE_DEBUG_METHOD].apply(console, arguments);
    }

    log(message: any, ...optionalParams: any[]) {
        this.isLogEnabled() && console.log.apply(console, arguments);
    }

    isErrorEnabled = (): boolean => this.level >= Level.ERROR;
    isWarnEnabled = (): boolean => this.level >= Level.WARN;
    isInfoEnabled = (): boolean => this.level >= Level.INFO;
    isDebugEnabled = (): boolean => this.level >= Level.DEBUG;
    isLogEnabled = (): boolean => this.level >= Level.LOG;

    private logErrormessageOnServer(message: ServerErrorMessage): Observable<any> {
        return of(1);
    }
}

