import { NgModule } from '@angular/core';
import { CategoryDetailsService } from './categoryDetails/categoryDetails.service';

@NgModule({
    declarations: [],
    imports: [],
    providers: [
        CategoryDetailsService
    ],
    exports: []
})

export class ReportStructureModule { }

