<modal modalClass="modal-md" #confirmFlightChange>
    <modal-header>
        <h4>Confirm flight change</h4>
    </modal-header>
    <modal-content>
        <p>Changing flight information will reset all questions on the Questions page. Do you want to proceed?</p>
    </modal-content>
    <modal-footer>
        <button class="btn-cancel" (click)="cancel()">Cancel</button>
        <button class="btn-primary leftMargin remove" type="button" (click)="confirm()">Confirm</button>
    </modal-footer>
</modal>