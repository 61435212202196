import { Injectable, Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'SortByTimeReversePipe'
})
@Injectable()
export class SortByTimeReversePipe implements PipeTransform {
    constructor() { }
    transform(items: any, args?: any): any {
        const dateStampKey = args || 'Date';
        return items.sort((a: any, b: any) => {
            return new Date(a[dateStampKey]).getTime() - new Date(b[dateStampKey]).getTime();
        });
    }
}
