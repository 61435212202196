<div class="dynamicInputDiv" [ngModelGroup]="question.questionMappingId">
    <label>
        <i *ngIf="questionAttrs['isRequired']" class="icon-cers-required"></i>
        {{ question.questionText }}
    </label>
    <div>
        <input type="number" class="form-control" [name]="question.questionMappingId" [(ngModel)]="question.userAnswer" (focusout)="removeLeadingZeros(); showFlightChangeConfirm($event);" [appDynamicValidation]="questionAttrs" [appFocus]="{questionId: question.questionId, questionMappingId: question.questionMappingId}"
            [disabled]="question.isReadOnly" [readonly]="this.questionAttrs['limitedSearch']" #form="ngModel">
        <div *ngFor="let error of form.errors | keyvalue">
            <label class="errorText" *ngIf="error.key !== 'isRequired'"> {{error.value}} </label>
        </div>
        <div *ngIf="questionAttrs['displayInjuries']">
            <app-display-injuries [question]="question" [questionAttrs]="questionAttrs" [parentComponent]="'number'">
            </app-display-injuries>
        </div>
        <app-confirm-flight-change [flightDetail]="currentFlightDetails" (confirmUpdate)="saveToStore($event)" (cancelUpdate)="cancelUpdate()"></app-confirm-flight-change>
    </div>