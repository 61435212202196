<app-aa-title-bar></app-aa-title-bar>
<nav class="navbar navbar-expand-lg navbar-light menu-container">
    <div class="container-fluid menu-header">
        <div class="logo" ui-sref="home">
            <a routerLink="home">
                <img alt="CERS" height="70" src="assets/cers-icon.png" />
            </a>
        </div>
        <p class="title"><a routerLink="home" style="text-decoration: none;">CERS</a></p>
    </div>
    <div class="navbar-collapse aa-tab-bar nav" 
            id="navbarNavDropdown">
        <ul *ngIf="currentActiveRoute != '/notifications'" class="nav nav-tabs nav-fill">
            <li class="nav-item">
                <a routerLink="home" routerLinkActive="active" class="nav-link">
                    {{menuService.items.home.text}}
                </a>
            </li>
            <li class="nav-item">
                <a routerLink="newreport" routerLinkActive="active" class="nav-link">
                    {{menuService.items.newreport.text}}
                </a>
            </li>
            <li class="nav-item">
                <a routerLink="myreports" routerLinkActive="active" id="history_default" class="nav-link">
                    {{menuService.items.myreports.text}}
                </a>
            </li>
            <li *ngIf="canViewFillOutReport">
                <a routerLink="todo" routerLinkActive="active" class="nav-item">
                    {{menuService.items.todo.text}}
                    </a>
                </li>
                <li class="nav-item"><a routerLink="help" routerLinkActive="active" class="nav-link">
                        Help
                </a></li>
        </ul>
      </div>
    <div class="aa-logo">
        <img alt="AA" height="50" src="assets/AAFlag.png" />
    </div>
  </nav>