import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { Subscription, Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

// services
import { SSEHubCategoriesService } from '@shared/SSEHubClient/categories.service';
import { CategoryDetailService } from '../category-detail.service';
import { QuestionViewService } from '../question-view/question-view.service';
import { DynamicQuestionsService } from 'app/shared/SSEHubClient/dynamic-questions.service';

// interfaces
import { SectionInfo } from '@shared/reportStructure/categoryDetails/reportSections';
import { Question } from '@shared/common-ux/models/QuestionAnswer';

// state management
import { State } from '@app/store';
import { getFlightSectionInfo, getCategoryId, getSelectedProfileId, getFlightSection, getFlightQuestions, getCategoryDescription } from '../../category-management-store';
import * as Actions from '../../category-management-store/detail/sections.actions';
import { ISectionInfo } from '../../category-management-store/detail';
import { SelectQuestionForQuestionMappingTag } from '../../category-management-store/tag';

@Component({
    selector: 'app-flight-view',
    templateUrl: 'flight-view.component.html',
    styleUrls: ['flight-view.component.scss'],
})

export class FlightViewComponent implements OnInit, OnDestroy {
    destroy$: Subject<void> = new Subject<void>();
    private subscriptions: Subscription = new Subscription();
    flightQuestions: Question[];
    sectionInfo$: Observable<ISectionInfo>;
    group = 'Flight';
    sectionInfo: SectionInfo;
    categoryId: number;
    profileId: number;
    categoryDescription: string;
    @ViewChild('answerAddModal', { static: true }) public answerAddModal;
    @ViewChild('addTemplateModal', { static: true }) public addTemplateModal;
    @ViewChild('answerEditModal', { static: true }) public answerEditModal;
    @ViewChild('editQuestionModal', { static: true }) public editQuestionModal;
    @ViewChild('confirmQuestionRemove', { static: true }) public confirmQuestionRemove;
    @ViewChild('confirmAnswerRemove', { static: true }) public confirmAnswerRemove;
    @ViewChild('flightQuestionsListModal', { static: true }) public flightQuestionsListModal;
    @ViewChild('createNewFlightQuestion', { static: true }) public createNewFlightQuestion;
    @ViewChild('sortOrderEditModal', { static: true }) public sortOrderEditModal;

    constructor(
        private router: Router,
        private store: Store<State>,
        private sseHubCategoriesService: SSEHubCategoriesService,
        private categoryDetailService: CategoryDetailService,
        public questionViewService: QuestionViewService,
        public dynamicQuestionsService: DynamicQuestionsService
    ) { }

    ngOnInit() {

        this.store.select(getFlightQuestions).pipe(takeUntil(this.destroy$))
            .subscribe(questions => this.flightQuestions = questions);
        this.store.select(getCategoryId).pipe(takeUntil(this.destroy$))
            .subscribe(id => this.categoryId = id);
        this.store.select(getSelectedProfileId).pipe(takeUntil(this.destroy$))
            .subscribe(id => this.profileId = id);
        this.store.select(getFlightSectionInfo).pipe(takeUntil(this.destroy$))
            .subscribe(section => this.sectionInfo = section);
        this.store.select(getCategoryDescription).pipe(takeUntil(this.destroy$)).
            subscribe(description => this.categoryDescription = description);

        this.sectionInfo$ = this.store.select(getFlightSection);

        this.startLoadingState();
        this.loadFlightQuestions();

        const answerAddedSubscription = this.categoryDetailService.answerAdded$.pipe(takeUntil(this.destroy$)).subscribe(
            parentQuestion => {
                if (parentQuestion.type === 'Flight') {
                    this.startLoadingState();
                    this.loadFlightQuestions();
                }
            });
        this.subscriptions.add(answerAddedSubscription);

        const questionAddedSubscription = this.categoryDetailService.questionAddedorModified$.pipe(takeUntil(this.destroy$)).subscribe(
            questionType => {
                if (questionType === 'Flight') {
                    this.startLoadingState();
                    this.loadFlightQuestions();
                }
            });
        this.subscriptions.add(questionAddedSubscription);

        const createNewModalSubsctiption = this.questionViewService.createNewModalStatus$.pipe(takeUntil(this.destroy$)).subscribe(
            data => {
                this.handleCreateNewModalChange(data);
            });
        this.subscriptions.add(createNewModalSubsctiption);
    }
    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.destroy$.next();
        this.destroy$.complete();
    }
    actionSortQuestion(eventSource) {
        this.sortOrderEditModal.open(eventSource);
    }
    actionSortAnswer(eventSource) {
        this.sortOrderEditModal.open(eventSource);
    }

    private loadFlightQuestions(): void {
        this.store.dispatch(new Actions.LoadFlightQuestions(this.profileId, this.categoryId, this.group));
    }

    private loadTemplateQuestions(): void {
        const templateMetadata = {
            profileId: this.profileId,
            categoryId: this.categoryId,
            templateName: 'Template-Flight',
            groupName: this.group
        };

        this.dynamicQuestionsService.addTemplateQuestions(0, templateMetadata)
            .pipe(takeUntil(this.destroy$)).subscribe((result) => {
                this.loadFlightQuestions();
            },
                (error) => this.store.dispatch(new Actions.CategorySectionsError(this.group,
                    'Error reseting flight questions.', 'Load Template Questions failed : ' + error))
            );
    }

    private deleteFlightQuestions(): void {
        this.sseHubCategoriesService.deleteSection(this.profileId, this.categoryId, this.group)
            .pipe(takeUntil(this.destroy$)).subscribe(
                (result) => {
                    this.loadTemplateQuestions();
                },
                (error) => this.store.dispatch(new Actions.CategorySectionsError(this.group,
                    'Error deleting flight questions.', 'Deleting Flight Questions failed ' + error))
            );
    }

    resetSection(event) {
        this.startLoadingState();
        this.deleteFlightQuestions();
        this.store.dispatch(new Actions.ResetViewBySection(this.group));
    }

    public editFligt(): void {
        this.router.navigate(['/admin/categorymanagement/profile/' + this.profileId + '/category/' + this.categoryId + '/flight/']);
    }

    actionAddQuestion(eventData) {
        // Triggered by the context menu
        this.questionViewService.setSelectedAnswer(eventData);
        this.flightQuestionsListModal.open();
    }

    actionAddTemplate(eventData) {
        this.addTemplateModal.open();
    }

    actionAddAnswer(event) {
        event.type = 'Flight';
        this.answerAddModal.open(event);
    }

    actionEditAnswer(event) {
        event.type = 'Flight';
        this.answerEditModal.open(event);
    }

    actionEditMetaData(event) {
        this.editQuestionModal.open(event);
    }

    actionRemoveQuestion(eventData) {
        this.categoryDetailService.setSelectedQuestion(eventData);
        this.confirmQuestionRemove.open();
    }

    actionRemoveAnswer(eventData) {
        this.categoryDetailService.setSelectedAnswer(eventData);
        this.confirmAnswerRemove.open();
    }

    actionManageTags(event) {
        this.store.dispatch(new SelectQuestionForQuestionMappingTag({questionMappingId: event.questionMappingId, questionText: event.questionText, categoryDescription: this.categoryDescription}));
        this.router.navigate(['/admin/categorymanagement/profile/' + this.profileId + '/category/' + this.categoryId + '/question/'+event.questionMappingId+'/tags']);
    }

    onQuestionDelete() {
        this.startLoadingState();
        if (this.categoryDetailService.state.selectedQuestion && this.categoryDetailService.state.selectedQuestion.questionMappingId) {
            this.sseHubCategoriesService.deleteQuestionsByMappingId(this.categoryDetailService.state.selectedQuestion.questionMappingId)
                .pipe(takeUntil(this.destroy$)).subscribe(
                    (result) => {
                        if (result) {
                            this.loadFlightQuestions();
                        } else {
                            this.store.dispatch(new Actions.CategorySectionsError(this.group));
                        }
                    },
                    (error) => this.store.dispatch(new Actions.CategorySectionsError(this.group, 'Error deleting question.'))
                );
        }
    }

    onAnswerDelete() {
        this.startLoadingState();
        if (this.categoryDetailService.state.selectedAnswer && this.categoryDetailService.state.selectedAnswer.answerId) {
            this.sseHubCategoriesService.deleteAnswerById(this.categoryDetailService.state.selectedAnswer.answerId)
                .pipe(takeUntil(this.destroy$)).subscribe(
                    (result) => {
                        if (result) {
                            this.loadFlightQuestions();
                        } else {
                            this.store.dispatch(new Actions.CategorySectionsError(this.group));
                        }
                    },
                    (error) => this.store.dispatch(new Actions.CategorySectionsError(this.group, 'Error deleting answer.'))
                );
        }
    }

    private startLoadingState() {
        this.store.dispatch(new Actions.SetLoadingState('flight'));
    }

    private handleCreateNewModalChange(data: any) {
        if (data.group !== 'Flight') { return; }

        if (data.status === 'Open') {
            this.createNewFlightQuestion.open();
        }
        if (data.status === 'Close' || data.status === 'Save') {
            if (data.group === 'Flight') {
                this.loadFlightQuestions();
                this.flightQuestionsListModal.open();
            }
        }
    }
}
