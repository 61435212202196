import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { getCategoriesList, State } from '../new-report-store';
import { ActivatedRoute, Router, } from '@angular/router';
import { combineLatest, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoadSectionDetail } from '../new-report-store/wizard';
import { getUser } from '@app/store/user';
import * as _ from 'lodash';
import { ICategory, SetSelectedCategory } from '../new-report-store/list';
import { NotificationService } from 'app/shared/error-handler-notify/services';

@Component({
    selector: 'app-new-report-reroute',
    templateUrl: './new-report-reroute.component.html',
    styleUrls: ['../new-report.component.scss']
})
export class NewReportRerouteComponent implements OnInit, OnDestroy {
    destroy$: Subject<void> = new Subject<void>();
    constructor(
        private route: ActivatedRoute,
        private store: Store<State>,
        private router: Router,
        private notification: NotificationService
    ) {
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        combineLatest([this.route.params, this.store.select(getUser), this.store.select(getCategoriesList)])
            .pipe(takeUntil(this.destroy$)).subscribe(([params, user, catList]) => {
                const categoryId = params && params['categoryId'] ? params['categoryId'] : null;
                if (categoryId && user && catList?.list?.length > 0) {
                    const category = <ICategory>catList.list.find(cat => cat.id.toString() === categoryId.toString().trim());
                    if (category) {
                        if (category.categoryActive) {
                            this.store.dispatch(new SetSelectedCategory(category));
                            this.store.dispatch(new LoadSectionDetail(user.profileId, category.id));
                            this.router.navigate(['newreport/wizard/flight']);
                        } else {
                            const errorText = category.description + ' is an inactive category. Unable to fill out this report.';
                            this.notification.showError(errorText);
                            this.router.navigate(['newreport']);
                        }
                    } else {
                        this.notification.showError('Invalid category. Unable to fill out this report.');
                        this.router.navigate(['newreport']);
                    }
                }
            });
    }

}
