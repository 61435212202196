// Code referenced from https://github.com/stevepapa/ng-autosize/blob/master/src/autosize.directive.ts

import { Input, AfterViewInit, ElementRef, HostListener, Directive } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: 'textarea[aaAutosize]'
})

export class AutosizeDirective implements AfterViewInit {

  private el: HTMLElement;
  private _minHeight: string;
  private _maxHeight: string;
  private _lastHeight: number;
  private _clientWidth: number;
  private _lineHeight: number;

  @Input() minRows: string;
  @Input() maxRows: string;

  @HostListener('window:resize', ['$event.target'])
    onResize(textArea: HTMLTextAreaElement): void {
      // Only apply adjustment if element width had changed.
      if (this.el.clientWidth === this._clientWidth) {
          return;
      }
      this._clientWidth = this.element.nativeElement.clientWidth;
      this.adjust();
    }

  @HostListener('input', ['$event.target'])
    onInput(textArea: HTMLTextAreaElement): void {
      this.adjust();
    }

  constructor(public element: ElementRef) {
    this.el = element.nativeElement;
    this._clientWidth = this.el.clientWidth;
    this.updateHeights();
  }

  ngAfterViewInit(): void {
    // set element resize allowed manually by user
    const style = window.getComputedStyle(this.el, null);
    if (style.resize === 'both') {
      this.el.style.resize = 'horizontal';
    } else if (style.resize === 'vertical') {
      this.el.style.resize = 'none';
    }
    // run first adjust
    this.adjust();
  }

  adjust(): void {
    // update min/max heights if lineheight has changed
    if (this._lineHeight !== parseInt(getComputedStyle(this.el).lineHeight.replace('px', ''), 10)) {
      this.updateHeights();
    }

    // perform height adjustments after input changes, if height is different
    if (getComputedStyle(this.el).height === this.element.nativeElement.scrollHeight + 'px') {
      return;
    }

    this.el.style.overflow = 'auto';
    this.el.style.height = 'auto';
    this.el.style.height = this.el.scrollHeight + 10 + 'px';
  }

  updateHeights(): void {
    this._lineHeight = parseInt(getComputedStyle(this.el).lineHeight.replace('px', ''), 10);
    if (this.minRows) {
      this._minHeight = (this._lineHeight * parseInt(this.minRows, 10)) + 'px';
      this.el.style.minHeight = this._minHeight;
    }

    if (this.maxRows) {
      this._maxHeight = (this._lineHeight * parseInt(this.maxRows, 10)) + 'px';
      this.el.style.maxHeight = this._maxHeight;
    }
  }

  getMinHeight(): void {
    // Set textarea min height if input defined
    this.el.style.minHeight = this._minHeight + 'em';
  }

  getMaxHeight(): void {
    // Set textarea max height if input defined
    this.el.style.maxHeight = this._maxHeight + 'em';
  }

}
