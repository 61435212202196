<div class="container">

    <h1> CERS Notification verification </h1>
    <app-aa-spinner [loading]="isLoading"></app-aa-spinner>
    <div *ngIf="notificationRecords.profiles.length">
        <h4> Select a profile to view notification settings </h4>
        <select class="form-control dropdown" [(ngModel)]="selectedProfile" (change)="getProfileNotificationMethods()">
            <option *ngFor="let profile of notificationRecords.profiles"
                [selected]="profile.profileSk === selectedProfile.profileSk" [ngValue]="profile">{{profile.profileName}}
            </option>
        </select>
    </div>
    <div *ngIf="!notificationRecords.profiles.length && !isLoading">
        <h4> No notification profiles available </h4>
    </div>
    <div *ngIf="notificationMethods.length">
        <table>
            <tr>
                <th class="selectRadio">
                    <h4>Select</h4>
                </th>
                <th>
                    <h4>Message type</h4>
                </th>
                <th>
                    <h4>Vendor name</h4>
                </th>
                <th>
                    <h4>Email prefix/Phone number</h4>
                </th>
                <th>
                    <h4>Last verified date</h4>
                </th>
            </tr>
            <tr *ngFor="let record of notificationMethods">
                <td class="radio-btns">
                    <input type="radio" [checked]="record.selected" name="record.notificationSk" value="record.notificationSk" (change)="selectionChanged(record);">
                </td>
                <td>
                    <select class="form-control dropdown" name="record.messageType" [(ngModel)]="record.messageTypeSk" (change)="selectionChanged(record);">
                        <option *ngFor="let mt of messageTypes" [ngValue]="mt.messageTypeSk"
                            [selected]="record.messageType === mt.messageType">
                            {{mt.messageType}}
                        </option>
                    </select>
                </td>
                <td>
                    <select class="form-control dropdown" name="record.vendorName" [(ngModel)]="record.vendorName" (change)="selectionChanged(record);">
                        <option *ngFor="let v of vendors" [ngValue]="v.vendorName"
                            [selected]="record.vendorName === v.vendorName">
                            {{v.vendorName}}
                        </option>
                    </select>
                </td>
                <td>
                    <span [ngClass]="{'email-input': record.isEmail}">
                        <input type="text" class="form-control" name="record.notificationValue"
                            (change)="selectionChanged(record);" [formControl]="record.validationRules" />
                        <div class="email-ext" *ngIf="record.isEmail">{{'@' + record.vendorExt}}</div>
                    </span>
                    <span *ngIf="record.validationRules.errors">
                        <span class="errorText" *ngIf="record.validationRules.errors.pattern">Must be a valid
                            phone</span>
                    <span class="errorText" *ngIf="record.validationRules.errors.required">Cannot be blank</span>
                    <span class="errorText" *ngIf="record.validationRules.errors.prefix">Email prefix only</span>
                    <span class="errorText" *ngIf="record.validationRules.errors.invalid">Must be a valid
                            email</span>
                    </span>
                </td>
                <td>
                    <label>{{record.lastVerifiedDate }}</label>
                </td>
            </tr>
        </table>

        <table>
            <tr>
                <td>
                    <button type="button" class="btn-primary" (click)="ConfirmAllPopup()" [disabled]="confirmAllDisabled || updateLoading">Confirm all</button>
                    <button type="button" class="btn-primary" (click)="ConfirmSave()" [disabled]="updateLoading">Save</button>
                    <button type="button" class="btn-primary" (click)="getNotificationRecords()" [disabled]="!selectedMethod || updateLoading">Undo All</button>
                    <button type="button" class="btn-primary" (click)="ConfirmDelete()" [disabled]="updateLoading">Delete</button>
                    <button type="button" class="btn-primary" (click)="ShowAttributes()" [disabled]="updateLoading">Attributes</button>
                </td>
                <td class="message-text">
                    <app-aa-spinner-no-logo [loading]="updateLoading"></app-aa-spinner-no-logo>
                    <label>
                        <h4>{{responseMessage}}</h4>
                    </label>
                </td>
            </tr>
        </table>
        <table>
            <div *ngIf="selectedMethod && showFactor && selectedMethod.notificationFactors.length >0">
                <tr>
                    <th>Factor type</th>
                    <th>Description</th>
                </tr>
                <tr *ngFor="let notificationFactor of selectedMethod.notificationFactors">
                    <td>{{notificationFactor.factorType}}</td>
                    <td>{{notificationFactor.hierarchyDescription}}</td>
                </tr>
            </div>
            <div *ngIf="selectedMethod && showFactor && selectedMethod.notificationFactors.length === 0 ">
                <label>No Factors</label>
            </div>
        </table>
    </div>
</div>
<app-confirmation-popup #saveConfirmation [title]="'Confirm to save?'" [message]="'This action is going to save the selected notification method. Do you want to proceed?'" (callAction)="Save()">
</app-confirmation-popup>
<app-confirmation-popup #deleteConfirmation [title]="'Confirm to delete?'" [message]="'This action will send a delete request to your Notification Adminitrator. Do you want to proceed?'" (callAction)="Delete()">
</app-confirmation-popup>
<app-confirmation-popup #confirmAll [title]="'Confirm all notifications?'" [message]="'This action is going to mark all the notification as confirmed and reload all notifications. Ensure that changes have been saved. Do you want to proceed?'" (callAction)="ConfirmAll()">
</app-confirmation-popup>
