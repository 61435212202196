export interface IEmployeeModel {
    firstName: string;
    lastName: string;
    employeeId: string;
    profileId: number;
    address1: string;
    address2: string;
    country: string;
    city: string;
    state: string;
    zip: string;
    passport: string;
    phoneWork: string;
    phoneHome: string;
    sex: string;
    emailWork: string;
    jobTitle: string;
    costCenter: string;
    personnelSubAreaId: string;
    baseStation: string;
    companyId: number;
    stationCode: string;
    departmentDesc: string;
    supEmployeeId: string;
    supFirstName: string;
    supLastName: string;
    supPhoneNumb: string;

}

export const limitedSearchAttributeArray: Array<string> = [
    'firstName',
    'lastName',
    'costCenter',
    'jobTitle',
    'employeeId',
    'stationCode',
    'departmentDesc',
    'supEmployeeId',
    'supFirstName',
    'supLastName',
    'supPhoneNumb'
];