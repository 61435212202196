import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();
  // This is for Dynatrace tracking. We need to inject a script into the index.html (or any page we want to track).
  // There are multiple injections for multiple environments (test and prod for CERS).
  const script = document.createElement('script');
  script.src = 'https://ingest.aa.com:443/jstag/managed/daa15b35-f63b-46fe-8465-781f95df871a/dd4ab1b7321899ea_complete.js';
  script.type = 'text/javascript';
  script.crossOrigin = 'anonymous';
  document.head.appendChild(script);
} else if (environment.envName === 'test') {
    const script = document.createElement('script');
    script.src = 'https://ingest.aa.com:443/jstag/managed/cff28573-f815-4847-935f-ba99fd7e336f/16a858946c7fc9c4_complete.js';
    script.type = 'text/javascript';
    script.crossOrigin = 'anonymous';
    document.head.appendChild(script);
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(success => console.log(`Bootstrap success`))
  .catch(err => console.error(err));
