import { Component, ViewChild, EventEmitter, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Router } from '@angular/router';
import { ResetSelectedCategory } from '@newreport/new-report-store/list';
import { WizardReset } from '@newreport/new-report-store/wizard';
import { State } from '../../../new-report-store';
import * as _ from 'lodash';

@Component({
    selector: 'app-confirm-cancel',
    templateUrl: './confirm-cancel.component.html',
    styleUrls: ['../../modals.component.scss']
})

export class ConfirmCancelComponent {

    @ViewChild('confirmCancel', { static: true }) public modal;
    @Output() cancel = new EventEmitter<boolean>(); // notify the parent that we did not cancel the Report

    constructor(
        private store: Store<State>,
        private router: Router
    ) { }

    displayModal() {
        this.modal.open();
    }

    // cancel report and return to initial fill out report page
    yes() {
        this.modal.close();
        this.store.dispatch(new ResetSelectedCategory());
        this.store.dispatch(new WizardReset());
        this.cancel.emit(true);
        this.router.navigate(['newreport']);
    }

    // don't cancel the report and leave the user where they are
    no() {
        this.modal.close();
        this.cancel.emit(false);
    }
}
