import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { LoggerService, NotificationService } from '@shared/error-handler-notify/services';
import * as _ from 'lodash';
import { DynamicQuestionsService } from '@shared/SSEHubClient/dynamic-questions.service';
import { QuestionMetaDataConstants, DataTypeConstants, DataTypeMetaDataConstants } from './metadata.constant';
import { forkJoin, Subject } from 'rxjs';
import { ExtractQuestionUrls } from 'app/shared/common-ux/models/ExtractQuestionUrls';
import { takeUntil } from 'rxjs/operators';

export interface ValRule {
    key: string;    // attribute name
    val: any;       // attribute value
    vals?: Array<DropdownOption>; // dropdown values
    errorMessage?: string;  // error message string
    inputType: string;  // input type
    viewOnly: boolean;  // attribute that's viewable but not changeable
    description?: string;   // alternate text to display
    display?: boolean;      // display or not
    allowMultiple?: boolean;
}

// Drop down if there is a data type
export interface DropdownOption {
    value: string;
    description: string;
}

// these rules cannot be updated in the admin section i.e. don't display them
export const doNotDisplayAttributes = [
    'type',
    'disallowSpecialChars',
    'autoPopulateValuesKey',
    'displayPropertyKey',
    'valuePropertyKey',
    'fieldName',
    'personMapping',
    'visibleRows'
];
// rules to view but not update
export const viewOnly = [
    'isActive',
    'isHidden',
    'hideFollowupFromReportDetail'
];

// input types that have a dataType option (could be more in the future), these will have dropdown
export const inputTypesWithDataTypes = [
    'number',
    'numeric',
    // 'multi',
    'textbox',
    'dropdown'
];

export const dataTypeMappingDictionary = {
    'EMPLOYEE_ID': 'employeeId'
};

// defines the input type for each attribute
export const inputTypeDictionary = {
    'isRequired': 'checkbox',
    'includeInEmail': 'checkbox',
    'minDate': 'number',
    'maxDate': 'number',
    'minLength': 'number',
    'maxLength': 'number',
    'minValue': 'number',
    'maxValue': 'number',
    'naOption': 'checkbox',
    'uppercase': 'checkbox',
    'maxInvolvements': 'number',
    'maxQuestionGroups': 'number',
    'hideFromReportDetail': 'checkbox',
    'hideFollowupFromReportDetail': 'checkbox',
    'dataType': 'dropdown',
    'disallowOwnEmployeeId': 'checkbox',
    'stationCodeValidations': 'checkbox',
    'displayInjuries': 'checkbox',
    'removeLeadingZeros': 'checkbox'
};

// text to display for each attribute
export const descriptionDictionary = {
    'isRequired': 'Required',
    'includeInEmail': 'Include in email notification',
    'minDate': 'Min date',
    'maxDate': 'Max date',
    'minLength': 'Min length',
    'maxLength': 'Max length',
    'minValue': 'Min value',
    'maxValue': 'Max value',
    'naOption': 'NA option',
    'crewSearch': 'Display crew search',
    'paxSearch': 'Display passenger search',
    'uppercase': 'Uppercase',
    'maxInvolvements': 'Max involvements',
    'maxQuestionGroups': 'Max allowed',
    'hideFromReportDetail': 'Hide from report detail',
    'hideFollowupFromReportDetail': 'Hide followup from report detail',
    'dataType': 'Data type',
    'disallowOwnEmployeeId': 'Disallow user\'s employee id',
    'amsAddress': 'AMS Addresses',
    'stationCodeValidations': 'Station code validations',
    'displayInjuries': 'Display employee injuries',
    'removeLeadingZeros': 'Remove leading zeros'
};

@Component({
    selector: 'app-question-edit',
    templateUrl: './question-edit.component.html',
    styleUrls: ['./question-edit.component.scss'],
})
export class QuestionEditComponent implements OnInit, OnDestroy {
    @Input() categoryId: number;
    @Input() profileId: number;
    @ViewChild('editQuestionMetadata', { static: true }) public modal;

    tags: Array<string> = [];
    rules: Array<ValRule> = [];
    unselectedRules: ValRule[] = [];
    selectedRules: ValRule[] = [];
    addOptionalRule = false;
    selectedRule: ValRule;
    isLoading = true;
    hasError = false;
    isReady = false;
    notsaved = true;
    updateInputType = false;
    questionText: string;
    isInvolvement = false;
    questionInputType: string;
    isQuestionEditMode = false;
    questionTextInvalid = false;
    questionMetaData: any = {};
    currentQuestion;
    validationRules: object = {};
    displayUnselectedRules = false;
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        public dynamicQuestionsService: DynamicQuestionsService,
        public logger: LoggerService,
        public notificationService: NotificationService,
    ) {
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    ngOnInit() {
    }

    open(question) {
        this.questionTextInvalid = false;
        this.isQuestionEditMode = false;
        this.currentQuestion = question;
        this.questionText = question.questionText;
        this.isInvolvement = question.answerInputType.toUpperCase() === 'INVOLVEMENT';
        this.notsaved = true;
        this.validationRules = {};
        this.updateInputType = false;
        this.modal.open();
        this.questionInputType = question.answerInputType; // question input type is case sensative
        this.addOptionalRule = false;
        this.rules = [];
        this.unselectedRules = [];
        this.selectedRules = [];
        this.displayUnselectedRules = false;
    }

    onOpen(event?) {
        this.loadQuestionsMetaData();
    }

    onClose(event?) {
        this.startLoadingState();
    }

    startQuestionTextEdit() {
        this.isQuestionEditMode = true;
    }

    capitalizeFirstLetter(text: string) {
        text = text.toLowerCase();
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    private loadQuestionsMetaData(): void {
        if (this.currentQuestion.questionMappingId == null) {
            this.onError();
            return;
        }

        forkJoin(this.dynamicQuestionsService.getQuestionsMetaData(this.currentQuestion.questionMappingId),
            this.dynamicQuestionsService.getAnswers(this.currentQuestion.questionMappingId)
        ).pipe(takeUntil(this.destroy$)).subscribe(([questionMetaData, answers]) => {
            if (questionMetaData) {
                this.tags = questionMetaData.tags;
                this.questionMetaData = questionMetaData;

                for (const key of Object.keys(this.questionMetaData.validateRules)) {
                    if (this.questionMetaData.validateRules[key] === null) {
                        delete this.questionMetaData.validateRules[key];
                    }
                }

                this.questionMetaData.validateRules = this.questionMetaData.validateRules ?? {};
                this.validationRules = Object.assign({}, this.questionMetaData.validateRules ?? {});    // deep clones
                // isRequired is always asked
                if (!this.validationRules.hasOwnProperty('isRequired')) {
                    this.validationRules['isRequired'] = false;
                }
                // always ask hideFromReportDetail unless hideFollowupFromReportDetail exists for the question
                if (!this.validationRules.hasOwnProperty('hideFromReportDetail') &&
                    !this.validationRules.hasOwnProperty('hideFollowupFromReportDetail')) {
                    this.validationRules['hideFromReportDetail'] = false;
                }
                // personMapping is for IT use only. dataType is what the admins have control over. If the personMapping is also a dataType with
                // optional rules (with the valid corresponding input type) we want those rules to display.
                // This will make dataType = personMapping forevermore so this question mapping can reap those benefits.
                // example: personMapping = 'EMPLOYEE_ID' and dataType = 'employeeId' with inputType = 'number'
                if (!this.validationRules.hasOwnProperty('dataType') && this.validationRules.hasOwnProperty('personMapping')) {
                    const dataType = dataTypeMappingDictionary[this.validationRules['personMapping']];
                    const validDataTypes = this.returnDataTypes();
                    if (dataType && validDataTypes && validDataTypes.findIndex(x => x.value === dataType) > -1) {
                        this.validationRules['dataType'] = dataType;
                    }
                }
                // this makes the dataType dropdown display, if applicable for the inputType
                if (!this.validationRules.hasOwnProperty('dataType') && inputTypesWithDataTypes.includes(this.questionInputType.toLowerCase())) {
                    this.validationRules['dataType'] = '';
                }
                // verify there is an SOSQuestionSK for this question.
                // if not, we cannot update the includeInEmail attribute so don't display that rule.
                // also don't display for involvement questions
                if (this.questionMetaData.inputType.toUpperCase() !== 'INVOLVEMENT' &&
                    this.questionMetaData.inputType.toUpperCase() !== 'QUESTIONGROUP' &&
                    !this.validationRules['includeInEmail'] &&
                    answers) {
                    for (const answer of answers) {
                        // commenting this out since SOSQuestionSK is legacy. TBD new implementation.
                        // if (answer.SOSQuestionSK > 0) {
                        this.validationRules['includeInEmail'] = false;
                        break;
                        // }
                    }
                }
                this.createForm();
                this.onReady();
            } else {
                this.onError();
            }
            this.isLoading = false;
        },
            (error) => {
                this.logger.error('Error ' + error);
                this.onError();
            }
        );
    }

    createForm() {
        // this.validationRules = all the rules that are currently in the json and will be displayed in the UI
        // ruleMeta contains all the optional rules.

        let ruleMeta = this.returnMetaData();
        if (this.validationRules.hasOwnProperty('dataType')) {
            ruleMeta = [...ruleMeta, ...this.returnDataTypeMetaData(this.validationRules['dataType'])];
        }

        for (const [key, val] of Object.entries(this.validationRules)) {
            const index = ruleMeta.findIndex(r => r.value === key);

            if (index > -1) {
                // these are the optional rules already applied to this question
                this.selectedRules.push({
                    key: key,
                    val: val,
                    viewOnly: viewOnly.includes(key),
                    inputType: inputTypeDictionary[key] !== undefined ? inputTypeDictionary[key] :
                        (val && (val.toString() === 'true' || val.toString() === 'false')) ? 'checkbox' : 'text',
                    display: true,
                    description: descriptionDictionary[key] !== undefined ? descriptionDictionary[key] : key
                });
            } else if (!doNotDisplayAttributes.includes(key)) {
                // these are the rules that always display in the upper section
                this.rules.push({
                    key: key,
                    val: val,
                    vals: this.returnDataTypes(),
                    viewOnly: viewOnly.includes(key),
                    inputType: inputTypeDictionary[key] !== undefined ? inputTypeDictionary[key] :
                        (val && (val.toString() === 'true' || val.toString() === 'false')) ? 'checkbox' : 'text',
                    display: true,
                    description: descriptionDictionary[key] !== undefined ? descriptionDictionary[key] : key
                });

                // special case, this is not editable
                // if (this.questionInputType.toLowerCase() === 'multi' && key.toLowerCase() === 'datatype') {
                //     this.rules[this.rules.length - 1].viewOnly = true;
                // }
            }
        }

        // Always allow includeInEmail to be updated.
        const includeInEmailIndex = this.rules.findIndex(rule => rule.key === 'includeInEmail');
        if (includeInEmailIndex > -1) {
            this.rules[includeInEmailIndex].viewOnly = false;
            this.rules[includeInEmailIndex].errorMessage = null;
        }

        for (const rule of ruleMeta) {
            const index = this.selectedRules.findIndex(r => r.key === rule.value);
            if (index === -1) {
                this.unselectedRules.push({
                    key: rule.value,
                    val: null,
                    viewOnly: viewOnly.includes(rule.value),
                    inputType: inputTypeDictionary[rule.value] !== undefined ? inputTypeDictionary[rule.value] : 'text',
                    display: true,
                    description: rule.description,
                    errorMessage: null,
                });
            }
        }

        for (const rule of this.rules) {
            this.isRequiredNaOption(rule);
        }

        // alphabetize the top rules
        this.rules.sort((a, b) => (a.key > b.key) ? 1 : -1);
        this.selectedRules.sort((a, b) => (a.key > b.key) ? 1 : -1);

        this.updateDisplayUnselectedRules();
    }

    // this is once the user selects a rule from the dropdown
    addRuleEvent(selectedRule) {
        this.addOptionalRule = false;
        this.selectedRules.push(selectedRule);
        if (selectedRule.inputType.toLowerCase() === 'checkbox') {
            selectedRule.val = true;
        }
        if (!selectedRule.allowMultiple) {
            const index = this.unselectedRules.findIndex(r => r.key === selectedRule.key);
            if (index > -1) {
                this.unselectedRules.splice(index, 1);
                this.updateDisplayUnselectedRules();
            }
        }
    }

    // this is when they click the "add validation rule" link
    addRule() {
        this.selectedRule = null;
        const rulesToDisplay = this.displayFilterRules(this.unselectedRules) || [];
        if (rulesToDisplay.length === 1) {
            const index = this.unselectedRules.findIndex(r => r.key === rulesToDisplay[0].key);
            if (index > -1) {
                if (this.unselectedRules[index].inputType.toLowerCase() === 'checkbox') {
                    this.unselectedRules[index].val = true;
                }
                this.selectedRules.push(this.unselectedRules[index]);
                this.unselectedRules.splice(index, 1);
                this.updateDisplayUnselectedRules();
            }
            this.addOptionalRule = false;
        } else if (rulesToDisplay.length > 1) {
            this.addOptionalRule = true;
        }
    }

    // this is when they click the delete icon
    removeRule(rule) {
        rule.val = null;
        this.unselectedRules.push(rule);
        this.updateDisplayUnselectedRules();
        const index = this.selectedRules.findIndex(r => r.key === rule.key);
        if (index > -1) {
            this.selectedRules.splice(index, 1);
        }
    }

    displayFilterRules(rules: ValRule[]): ValRule[] {
        if (rules && rules.length > 0) {
            return rules.filter(rule => rule.display) || [];
        }
        return [];
    }

    updateDisplayUnselectedRules() {
        if (this.unselectedRules && this.unselectedRules.length > 0) {
            this.displayUnselectedRules = (this.unselectedRules.filter(rule => rule.display) || []).length > 0;
        }
        this.unselectedRules.sort((a, b) => (a.key > b.key) ? 1 : -1);
    }

    // naOption is only an available option for required questions
    isRequiredNaOption(rule) {
        if (rule && rule.key && rule.key.toLowerCase() === 'isrequired') {
            let index: number;
            if (!rule.val) { // isRequired was just set to false so naOption needs to be unselected
                index = this.selectedRules.findIndex(r => r.key.toLowerCase() === 'naoption');
                if (index > -1) {
                    this.removeRule(this.selectedRules[index]);
                }
            }
            // if isRequired is true then we set naOption.display to true and vice versa
            index = this.unselectedRules.findIndex(r => r.key.toLowerCase() === 'naoption');
            if (index > -1) {
                this.unselectedRules[index].display = rule.val;
                this.updateDisplayUnselectedRules();
            }
        }
    }

    // must fire before model change to remove all the old value's optional rules
    deleteDataTypeOptionalRules(rule) {
        if (rule && rule.key && rule.key.toLowerCase() === 'datatype') {

            // remove all the optional rules regardless of whether or not they've been selected
            this.returnDataTypeMetaData(rule.val).forEach(d => {
                this.rules = this.rules.filter(r => r.key.toLowerCase() !== d.value.toLowerCase());
                this.selectedRules = this.selectedRules.filter(r => r.key.toLowerCase() !== d.value.toLowerCase());
                this.unselectedRules = this.unselectedRules.filter(r => r.key.toLowerCase() !== d.value.toLowerCase());
            });
            this.updateDisplayUnselectedRules();
        }
    }

    // must fire after model change to add the new selection's optional rules
    addDataTypeOptionalRules(rule) {
        if (rule && rule.key && rule.key.toLowerCase() === 'datatype') {
            if (rule.val) {
                this.returnDataTypeMetaData(rule.val).forEach(r => {
                    this.unselectedRules.push({
                        key: r.value,
                        val: null,
                        viewOnly: viewOnly.includes(r.value),
                        inputType: inputTypeDictionary[r.value] !== undefined ? inputTypeDictionary[r.value] : 'text',
                        display: true,
                        description: r.description,
                        errorMessage: null,
                        allowMultiple: r.allowMultiple || false
                    });
                });
            }
            // automatically add validation limit for 60 chars emails if the data type is for emails
            if (rule.val && rule.val.toLowerCase && (rule.val.toLowerCase().includes('additionalaaemails') || rule.val.toLowerCase().includes('additionalemails'))) {
                this.addRuleEvent({
                    allowMultiple: false, description: 'Max Email length', display: true,
                    errorMessage: null, inputType: 'text', key: 'maxEmailLength', val: 60, viewOnly: false
                });
            }
            this.updateDisplayUnselectedRules();
        }
    }

    // this validation is run every change. I run it for everything because min/max values dynamically play off each other
    validate() {
        const allRules = [];
        allRules.push(...this.rules);
        allRules.push(...this.selectedRules);
        let noErrors = true;

        for (const rule of allRules) {
            if (!viewOnly.includes(rule.key)) {
                let thisKeyHasError = false;

                if (rule.inputType.toLowerCase() === 'number') {

                    // make sure min is <= max
                    if (rule.key.substring(0, 3) === 'min') {
                        const minWhat = rule.key.substring(3);
                        let maxKey = 'max' + minWhat;
                        const foundMax = this.selectedRules.find(r => {
                            return r.key === maxKey && r.val !== null;
                        });
                        if (foundMax && foundMax.val !== null) {
                            if (rule.description) {
                                maxKey = foundMax.description;
                            }
                            try {
                                if (parseInt(rule.val, 10) > parseInt(foundMax.val, 10)) {
                                    rule.errorMessage = 'Must be less than or equal to ' + foundMax.val + ' (' + maxKey + ')';
                                    thisKeyHasError = true;
                                }
                            } catch (e) {
                                rule.errorMessage = 'Must be a number';
                                thisKeyHasError = true;
                            }
                        }
                    }

                    // make sure max >= min
                    if (rule.key.substring(0, 3) === 'max') {
                        const minWhat = rule.key.substring(3);
                        let minKey = 'min' + minWhat;
                        const foundMin = this.selectedRules.find(r => {
                            return r.key === minKey && r.val !== null;
                        });
                        if (foundMin && foundMin.val !== null) {
                            if (rule.description) {
                                minKey = foundMin.description;
                            }
                            try {
                                if (parseInt(rule.val, 10) < parseInt(foundMin.val, 10)) {
                                    rule.errorMessage = 'Must be greater than or equal to ' + foundMin.val + ' (' + minKey + ')';
                                    thisKeyHasError = true;
                                }
                            } catch (e) {
                                rule.errorMessage = 'Must be a number';
                                thisKeyHasError = true;
                            }
                        }
                    }

                    if (rule.key.toLowerCase() === 'minlength' || rule.key.toLowerCase() === 'maxlength' ||
                        rule.key.toLowerCase() === 'mindate' || rule.key.toLowerCase() === 'maxdate') {
                        if (!/^[0-9]*$/.test(rule.val)) {
                            rule.errorMessage = 'Enter a positive whole number';
                            thisKeyHasError = true;
                        }
                    }

                    if (rule.key.toLowerCase() === 'maxinvolvements' || rule.key.toLowerCase() === 'maxquestiongroups') {
                        if (!/^[1-9]\d*$/.test(rule.val)) {
                            rule.errorMessage = 'Enter a number greater than zero';
                            thisKeyHasError = true;
                        }
                    }

                    if ((rule.val !== 0 && !rule.val) || !/^-?[0-9]*$/.test(rule.val)) {
                        if (/\./.test(rule.val)) {
                            rule.errorMessage = 'Enter an integer, not a decimal';
                        } else {
                            rule.errorMessage = 'Enter a valid number';
                        }
                        thisKeyHasError = true;
                    }

                } else if (rule.inputType.toLowerCase() !== 'checkbox' && rule.inputType.toLowerCase() !== 'dropdown'
                    && (!rule.val || rule.val.length < 1)) {
                    rule.errorMessage = 'This field cannot be empty';
                    thisKeyHasError = true;
                }

                if (thisKeyHasError) {
                    noErrors = false;
                } else {
                    rule.errorMessage = null;
                }
            }
        }

        return noErrors;
    }

    // this returns the validity state of the entire modal
    validateAll(): boolean {
        if (this.validate() && this.questionText && this.questionText.length > 0) {
            return true;
        }
        return false;
    }

    updateQuestionsMetaData(): void {
        if (this.validateAll() && (this.updateValidationRules() || this.questionText !== this.questionMetaData.question || this.updateInputType)) {
            if (this.questionText.trim() === '') {
                this.questionTextInvalid = true;
                return;
            }

            Object.keys(this.validationRules).forEach((key) => {
                const value = this.validationRules[key];
                if (!value || value.toString().length < 1) {
                    delete this.validationRules[key];
                }
            });

            if (this.isQuestionEditMode || this.updateInputType) {
                this.onQuestionEditSave();
            }

            // create validation object and json.stringify
            this.questionMetaData.validateRules = this.validationRules;
            if (this.notsaved === true) {
                this.startLoadingState();
                this.notsaved = false;
                this.dynamicQuestionsService.updateQuestionsMetaData(this.currentQuestion.questionMappingId, this.questionMetaData)
                    .pipe(takeUntil(this.destroy$)).subscribe(
                        (result) => {
                            this.currentQuestion.attrs = this.questionMetaData.validateRules;
                            // if (this.currentQuestion.answerInputType.toLowerCase() === 'multi') {
                            //     if (this.validationRules.hasOwnProperty('dataType')) {
                            //         this.currentQuestion.answers.forEach(answer =>
                            //             answer.answerInputType = 'Multi-' + this.validationRules['dataType']);
                            //     } else {
                            //         this.currentQuestion.answers.forEach(answer =>
                            //             answer.answerInputType = 'Multi');
                            //     }
                            // }
                            this.modal.close();
                            this.onReady();
                        },
                        (error) => {
                            this.logger.error(error);
                            this.onError();
                        }
                    );
            } else {
                this.logger.error('service not been called');
            }
        } else {
            this.notificationService.showInfo('No changes made.');
            this.modal.close();
        }
    }

    onQuestionEditSave() {
        this.isQuestionEditMode = false;
        this.questionTextInvalid = false;

        if (this.currentQuestion == null) {
            this.logger.error('Something went wrong. Current question cannot be null');
            return;
        }
        const questionObject: any = {};
        questionObject.questionIdPk = this.currentQuestion.questionId;
        questionObject.inputType = this.questionInputType;
        questionObject.question = this.questionText;
        this.startLoadingState();
        this.dynamicQuestionsService.editQuestion(questionObject)
            .pipe(takeUntil(this.destroy$)).subscribe(
                (result) => {
                    if (result) {
                        this.currentQuestion.questionText = this.questionText;
                        this.currentQuestion.answerInputType = this.questionInputType; // this field is case-sensative

                        if (this.questionInputType.toUpperCase() === 'RADIO') {
                            const newexUtil = new ExtractQuestionUrls(this.currentQuestion);
                            if (newexUtil && newexUtil !== undefined) {
                                this.currentQuestion.hyperLinks = newexUtil.hyperLinks;
                                this.currentQuestion.displayQuestionText = newexUtil.displayQuestionText;
                            }
                        }
                        this.onReady();

                    } else {
                        this.onError();
                    }
                },
                (error) => {
                    this.logger.error('Error ' + error);
                    this.onError();
                }
            );
    }

    updateValidationRules(): boolean {

        let changesMade = false;
        const allRules: ValRule[] = [...this.rules];
        allRules.push(...this.selectedRules);

        // iterate over the rules and selectedRules
        for (const rule of allRules) {
            if (rule.inputType.toLowerCase() === 'checkbox' && !rule.val) { // delete true/false rules with a value of false
                delete this.validationRules[rule.key];
                if (this.questionMetaData.validateRules.hasOwnProperty(rule.key)) {
                    changesMade = true;
                }
            } else {    // a change was made to a value
                if ((this.validationRules.hasOwnProperty(rule.key) && rule.val !== this.validationRules[rule.key]) ||
                    !this.validationRules.hasOwnProperty(rule.key)) {
                    changesMade = true;
                }
                this.validationRules[rule.key] = rule.val;
            }
        }

        // delete any unselected rules from the metadata
        for (const rule of this.unselectedRules) {
            if (this.validationRules.hasOwnProperty(rule.key)) {
                delete this.validationRules[rule.key];
                changesMade = true;
            }
        }

        // delete any dataType optional rules that no longer apply to the currently selected dataType (or null)
        const dataTypeIndex = allRules.findIndex(r => r.key === 'dataType');
        const notApplicableDataTypeRules = dataTypeIndex > -1 ?
            _.filter(DataTypeMetaDataConstants.allDataTypeRules, (ar) => {
                return !this.returnDataTypeMetaData(allRules[dataTypeIndex].val).includes(ar);
            }) : DataTypeMetaDataConstants.allDataTypeRules;
        for (const rule of notApplicableDataTypeRules) {
            if (this.validationRules.hasOwnProperty(rule.value)) {
                delete this.validationRules[rule.value];
                if (this.questionMetaData.validateRules.hasOwnProperty(rule.value)) {
                    changesMade = true;
                }
            }
        }

        return changesMade;
    }

    returnDataTypes(): Array<DropdownOption> {
        switch (this.questionMetaData.inputType.toLowerCase()) {
            case 'numeric':
            case 'number':
                return DataTypeConstants.numericDataType;
            case 'textbox':
                return DataTypeConstants.textboxDataType;
            case 'date':
                return DataTypeConstants.dateDataType;
            case 'dropdown':
                return DataTypeConstants.dropdownDataType;
            default:
                return [];
        }
    }

    returnDataTypeMetaData(dataType): Array<any> {
        dataType = dataType ? dataType.toLowerCase() : dataType;
        switch (dataType) {
            case 'employeeid':
                return DataTypeMetaDataConstants.employeeId;
            case 'stationcodes':
                return DataTypeMetaDataConstants.stationCode;
            case 'additionalaaemails':
                return DataTypeMetaDataConstants.additionalEmailsMetaData;
            case 'additionalemails':
                return DataTypeMetaDataConstants.additionalEmailsMetaData;
            case 'injuryBodyPart':
                return [];
            case 'injuryCause':
                return [];
            case 'injuryNature':
                return [];
            case 'eventDate':
                return [];
            default:
                return [];
        }
    }

    returnMetaData(): any {
        if (this.isInvolvement) {
            return QuestionMetaDataConstants.involvementMetaData;
        }
        switch (this.questionMetaData.inputType.toLowerCase()) {
            case 'date':
                return QuestionMetaDataConstants.dateMetaData;
            case 'textbox':
                return QuestionMetaDataConstants.textMetaData;
            case 'numeric':
            case 'number':
                return QuestionMetaDataConstants.numericMetaData;
            case 'questiongroup':
                return QuestionMetaDataConstants.questionGroupMetaData;
            default:
                return [];
        }
    }

    private onError() {
        this.isLoading = false;
        this.isReady = false;
        this.hasError = true;
    }

    private onReady() {
        this.isLoading = false;
        this.isReady = true;
        this.hasError = false;
    }

    private startLoadingState() {
        this.isLoading = true;
        this.isReady = false;
        this.hasError = false;
    }

    returnType() {
        switch (this.questionMetaData.inputType.toLowerCase()) {
            case 'date':
                return 'date';
            case 'time':
                return 'time';
            case 'textbox':
                return 'text';
            case 'numeric':
            case 'number':
                return 'number';
            case 'phone':
                return 'tel';
            case 'email':
                return 'email';
            case 'textarea':
                return 'textarea';
        }
    }
}
