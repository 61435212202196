import { Injectable, Inject } from '@angular/core';
import { Subject } from 'rxjs';

import { IReportHistoryFilter } from 'app/report-history/report-history-store/list';

export class ReportHistoryIdentifier {
  selectedFilter: any;
  selectedReportOption: string;
}

@Injectable()
export class ReportHistoryService {

  // Observable string sources
  private reportDetailInfoLoadedSource = new Subject<string>();
  // private reportDetailReplySource = new Subject<string>();
  isAllReportView = false;

  // Observable string streams
  reportDetailInfoLoaded$ = this.reportDetailInfoLoadedSource.asObservable();
  // reportDetailReply$ = this.reportDetailReplySource.asObservable();

  state: ReportHistoryIdentifier = new ReportHistoryIdentifier();
  constructor() {
  }

  public reportDetailInfoLoaded(data: any) {
    this.reportDetailInfoLoadedSource.next(data);
  }

  public setSelectedFilter(filter: IReportHistoryFilter) {
    this.state.selectedFilter = filter;
  }
  public getSelectedReportView() {
    return this.isAllReportView;
  }
  public setSelectedReportView(data: any) {
    this.isAllReportView = data;
  }
  // public reportDetailReply() {
  //   this.reportDetailReplySource.next();
  // }
  public setSelectedReport(reportType: string) {
    this.state.selectedReportOption = reportType;
  }
  public getSelectedReport() {
    return this.state.selectedReportOption;
  }
}

