import { Component, Input } from '@angular/core';
import { QuestionViewService } from '@admin/category-management/category-details/question-view/question-view.service';
@Component({
    selector: 'app-open-modal-button',
    templateUrl: './open-modal-button.component.html',
    styleUrls: ['./open-modal-button.component.scss']
})
export class OpenModalButtonComponent {
    @Input() modal: any;
    @Input() title: string;
    @Input() iconClass: string;
    @Input() profileId: string;
    @Input() group: string;
    isLoading = false;
    canEdit = false;
    constructor(
        public questionViewService: QuestionViewService
    ) {

    }

    public onButtonClick() {
        this.questionViewService.clearSelectedAnswer();
        this.modal.open(this.group);
    }
}
