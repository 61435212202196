import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { getGeneralPageStatus, getFlightPageStatus, getCombinedNotesAndAttachmentsPageStatus, State } from '@newreport/new-report-store';
import { PageStatusTypes } from '../new-report-store/wizard';
import { WizardService } from '../wizard/wizard.service';
import { NotificationService } from 'app/shared/error-handler-notify/services';
import { UnfinishedReportDatabaseService } from 'app/shared/report-services/unfinished-report-database.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-fill-out-report-wizard',
    templateUrl: './wizard.component.html',
    styleUrls: ['./wizard.component.scss']
})
export class WizardComponent implements OnInit, OnDestroy {

    flightPageStatus$: Observable<PageStatusTypes>;
    questionsPageStatus$: Observable<PageStatusTypes>;
    notesPageStatus$: Observable<PageStatusTypes>;
    destroy$: Subject<void> = new Subject<void>();
    activated = {
        flight: false,
        questions: false,
        notes: false,
        summary: false
    };

    constructor(
        private store: Store<State>,
        private router: Router,
        private wizardService: WizardService,
        public activatedRoute: ActivatedRoute,
        public notificationService: NotificationService,
        public unfinishedReport: UnfinishedReportDatabaseService
    ) {
        this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event: NavigationEnd) => {
            this.activated.flight = this.router.isActive('/newreport/wizard/flight', true);
            this.activated.questions = this.router.isActive('/newreport/wizard/questions', true);
            this.activated.notes = this.router.isActive('/newreport/wizard/notes', true);
            this.activated.summary = this.router.isActive('/newreport/wizard/summary', true);
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    ngOnInit() {

        this.flightPageStatus$ = this.store.select(getFlightPageStatus);
        this.questionsPageStatus$ = this.store.select(getGeneralPageStatus);
        this.notesPageStatus$ = this.store.select(getCombinedNotesAndAttachmentsPageStatus);

        window.addEventListener('offline', () => {
            if (!window.indexedDB) {
                this.notificationService.showError('Internet connection has been lost. \nYour report will not be saved.');
            }
        });

        if (!this.unfinishedReport.loadingUnfinishedReport) {
            this.wizardService.getNotesSection();
            this.wizardService.loadAllQuestions();
        }
        this.unfinishedReport.doneLoadingUnfinishedReport();
    }
}

