<div class="dynamicInputDiv" [ngModelGroup]="question.questionMappingId">
    <label>
        <i *ngIf="questionAttrs['isRequired']" class="icon-cers-required"></i>
        {{ question.questionText | sentenceCasePipe }}
    </label>
    <div>
        <button *ngIf="questionAttrs['naOption']" type="button" class="btn-dynamic-form" (click)="autofillTextNA()">N/A</button>
    </div>
    <app-emails-input *ngIf="isEmailDataType" [focusEvent]="focusEmail" (emails)="updateEmails($event)" [form]="form">
    </app-emails-input>
    <input type="text" id="textbox-input-{{question.questionMappingId}}" class="form-control"
            [name]="question.questionMappingId"
            (input)="replaceInput($event)"
            (focusout)="sanitizeAnswer(); showFlightChangeConfirm($event);"
            (focus)="focusIn()"
            [(ngModel)]="question.userAnswer"
            [appDynamicValidation]="questionAttrs"
            (change)="onChange(question.userAnswer)"
            [appFocus]="{questionId: question.questionId, questionMappingId: question.questionMappingId}"
            [matAutocomplete]="auto"
            [disabled]="question.isReadOnly"
            [readonly]="this.questionAttrs['limitedSearch']"
            #form="ngModel">
    <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of options" [value]="option.airportId">
            {{option.airportId + ' - ' + option.airportDesc}}
        </mat-option>
    </mat-autocomplete>
    <div *ngFor="let error of form.errors | keyvalue">
        <label class="errorText" *ngIf="error.key !== 'isRequired'"> {{error.value}} </label>
    </div>
    <app-confirm-flight-change [flightDetail]="currentFlightDetails" (confirmUpdate)="saveToStore(question.userAnswer)" (cancelUpdate)="cancelUpdate()"></app-confirm-flight-change>
</div>
