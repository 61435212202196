import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
// Toast is imported this way due to the issue referenced here (https://github.com/twbs/bootstrap/issues/36223)
// TODO: revisit how imports are done in light of this
import Toast from 'bootstrap/js/dist/toast.js';
import { fromEvent, take } from 'rxjs';
import { ToastEvent, ToastType } from '../toaster/toast-models';

@Component({
    selector: 'app-toast',
    templateUrl: './toast.component.html',
    styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
    @ViewChild('toastElement', { static: true }) toastEl: ElementRef;
    @Output() dispose = new EventEmitter();
    @Input() toastEvent: ToastEvent;
    toast: Toast;

    ngOnInit() {
        this.show();
    }

    show() {
        this.toast = new Toast(
            this.toastEl.nativeElement,
            {
                delay: 2500,
                animation: true
            }
        );

        fromEvent(this.toastEl.nativeElement, 'hidden.bs.toast')
            .pipe(take(1))
            .subscribe(() => this.hide());

        this.toast.show();
    }

    hide() {
        this.toast.dispose();
        this.dispose.emit();
    }
}
