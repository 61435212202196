import { ICategory, IProfile, ProfilesEntity, CategoriesEntity } from './list.model';
import * as ListActions from './list.actions';
import * as _ from 'lodash';
import { createEntityAdapter, EntityAdapter } from '@ngrx/entity';

export interface CategoriesModel {
    all: CategoriesEntity;
    byProfile: CategoriesEntity;
    isByProfileLoading: boolean;
    isAllLoading: boolean;
    error: any;
}
export interface ProfilesModel {
    list: ProfilesEntity;
    isLoading: boolean;
    error: any;
    selected: IProfile;
}

export interface ListModel {
    categories: CategoriesModel;
    profiles: ProfilesModel;
}

// Entity adapters
export const profilesAdapter: EntityAdapter<IProfile> = createEntityAdapter<IProfile>({
    selectId: (p: IProfile) => p.profileId,
    sortComparer: sortByProfile
});
export const categoriesAdapter: EntityAdapter<ICategory> = createEntityAdapter<ICategory>({
    selectId: (c: ICategory) => c.id,
    sortComparer: sortByDisplayNumber
});
export const categoriesByProfileAdapter: EntityAdapter<ICategory> = createEntityAdapter<ICategory>({
    selectId: (c: ICategory) => c.id,
    sortComparer: sortByDisplayNumber
});

// Entity Adapter selectors
const category = categoriesAdapter.getSelectors();
const profile = profilesAdapter.getSelectors();

// initialize adapters
export const initProfiles: ProfilesModel = {
    list: profilesAdapter.getInitialState({ profilesId: null }),
    isLoading: false,
    error: null,
    selected: null
};
export const initCategories: CategoriesModel = {
    all: categoriesAdapter.getInitialState({ categoriesId: null }),
    byProfile: categoriesByProfileAdapter.getInitialState({ categoriesId: null }),
    isAllLoading: false,
    isByProfileLoading: false,
    error: null
};

export const initList: ListModel = {
    categories: initCategories,
    profiles: initProfiles
};

// reducer
export function listReducer(state = initList, action: ListActions.Actions): ListModel {
    switch (action.type) {
        case ListActions.SELECT_PROFILE_BY_ID:
            return {
                ...state,
                profiles: {
                    ...state.profiles,
                    selected: {
                        ...state.profiles.selected,
                        profileId: action.payload
                    }
                }
            };
            return state;
        case ListActions.SELECT_PROFILE:
            return {
                ...state,
                profiles: {
                    ...state.profiles,
                    selected: action.payload
                }
            };
        case ListActions.LOAD_PROFILES:
            return state;

        case ListActions.POPULATE_PROFILES:
            return {
                ...state,
                profiles: {
                    ...state.profiles,
                    list: profilesAdapter.addMany(action.payload, state.profiles.list)
                }
            };
        case ListActions.LOAD_CATEGORIES:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    isAllLoading: true
                }
            };
        case ListActions.LOAD_CATEGORIES_BY_PROFILE:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    isByProfileLoading: true
                }
            };
        case ListActions.POPULATE_CATEGORIES:
            // return difference between all categories and those already selected
            const byProfileId = category.selectAll(state.categories.byProfile);
            const all = (byProfileId.length > 0) ? _.differenceBy(action.payload, byProfileId, 'id') : action.payload;

            return {
                ...state,
                categories: {
                    ...state.categories,
                    isAllLoading: false,
                    all: categoriesAdapter.addMany(all, state.categories.all)
                }
            };
        case ListActions.POPULATE_CATEGORIES_BY_PROFILE:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    isByProfileLoading: false,
                    byProfile: categoriesByProfileAdapter.setAll(action.payload, state.categories.byProfile)
                }
            };
        case ListActions.ADD_CATEGORY_SUCCESS:
            // return difference between all categories and those already selected
            const newAdapter = categoriesByProfileAdapter.addOne(action.payload, state.categories.byProfile);
            const newAll = _.differenceBy(category.selectAll(state.categories.all), category.selectAll(newAdapter), 'Id');

            return {
                ...state,
                categories: {
                    ...state.categories,
                    byProfile: newAdapter,
                    all: categoriesAdapter.addMany(newAll, state.categories.all),
                    isAllLoading: false
                }
            };
        case ListActions.ADD_CATEGORY:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    isAllLoading: true
                }
            };
        case ListActions.DELETE_CATEGORY_SUCCESS:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    byProfile: categoriesByProfileAdapter.removeOne(action.payload, state.categories.byProfile)
                }
            };
        case ListActions.CATEGORIES_ERROR:
            return {
                ...state,
                categories: {
                    ...state.categories,
                    error: action.payload,
                    isAllLoading: false
                }
            };
        case ListActions.PROFILES_ERROR:
            return {
                ...state,
                profiles: {
                    ...state.profiles,
                    error: action.payload
                }
            };
        default: return state;
    }
}



// profiles selectors
export const getProfiles = (state: ListModel) => state.profiles;
export const getSelectedProfile = (state: ListModel) => state.profiles.selected;
export const getSelectedProfileId = (state: ListModel) => (state.profiles.selected) ? state.profiles.selected.profileId : null;
export const getByProfileLoading = (state: ListModel) => state.categories.isByProfileLoading;
export const getProfilesList = (state: ListModel) => profile.selectAll(state.profiles.list);

// categories selectors
export const getCategories = (state: ListModel) => state.categories;
export const getCategoriesByProfileId = (state: ListModel) => category.selectAll(state.categories.byProfile);
export const getAllLoading = (state: ListModel) => state.categories.isAllLoading;
export const getAllCategories = (state: ListModel) => category.selectAll(state.categories.all);

// errors
export const getCategoriesError = (state: ListModel) => state.categories.error;
export const getProfilesError = (state: ListModel) => state.profiles.error;

// compare function to sort categories by 'Display Order'
function sortByDisplayNumber(item1: ICategory, item2: ICategory) {
    return item1.displayOrder - item2.displayOrder;
}
function sortByProfile(item1: IProfile, item2: IProfile) {
    return item2.displayOrder - item1.displayOrder;
}

