<modal #involvementEditModal (onOpen)="onOpen($event)" (onClose)="onClose($event)">
    <modal-header>
        <h4>Edit Involvement</h4>
    </modal-header>
    <modal-content>
        <app-aa-spinner [loading]="isLoading"></app-aa-spinner>
        <table *ngIf="!isLoading">
            <tr>
                <td class="option-text" [ngClass]="{'disabledText' : !isPersonSearchEnabled}">Display passenger search :
                </td>
                <td><input [disabled]="!isPersonSearchEnabled" type="checkbox" [(ngModel)]="isPaxSearchSelected"
                        class="checkbox option-input" (ngModelChange)="updateAttrs('paxSearch', $event)" /></td>
            </tr>
            <tr>
                <td class="option-text" [ngClass]="{'disabledText' : !isPersonSearchEnabled}">Display crew search :</td>
                <td><input [disabled]="!isPersonSearchEnabled" type="checkbox" [(ngModel)]="isCrewSearchSelected"
                        class="checkbox option-input" (ngModelChange)="updateAttrs('crewSearch', $event)" />
                </td>
            </tr>
            <tr>
                <td class="option-text" [ngClass]="{'disabledText' : !isPersonSearchEnabled}">Display employee search :</td>
                <td>
                    <select name="empSearchType" id="empSearchType" [disabled]="!isPersonSearchEnabled"
                        [(ngModel)]="employeeSearchOption" (change)="updateAttrs('employeeSearch', employeeSearchOption)" >
                        <option *ngFor="let option of employeeSearchDisplayOptions"
                            [value]="option.value"> {{ option.description }} </option>
                    </select>
                </td>
            </tr>
        </table>
        <td *ngIf="!isPersonSearchEnabled" class="error-text">People searching is not available. No followup
            questions map to person data.</td>
    </modal-content>
    <modal-footer>
        <button type="button" class="btn btn-cancel" (click)="onClose()">Close</button>
        <button type="submit" class="btn btn-primary leftMargin" (click)="onSave()">Save</button>
    </modal-footer>
</modal>
