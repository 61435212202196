<app-aa-spinner [loading]="(reportsDetails$ | async).isLoading"></app-aa-spinner>
<section *ngIf="!(reportsDetails$ | async).isLoading">
    <ol class="breadcrumb">
        <li>
            <a routerLink="/home">
                <i class="icon-home"></i> Home</a>
        </li>
        <!-- Normal. -->
        <li *ngIf="!(isReportManagementAccess || isGeneralMgr)">
            <a routerLink="/myreports">My reports</a>
        </li>
        <!-- Superuser or admin. -->
        <li *ngIf="isReportManagementAccess || isGeneralMgr">
            <a routerLink="/reporthistory">Report history</a>
        </li>
        <li *ngIf="isReportSearch">
            <a routerLink="/reporthistory/reportsearch">Report search</a>
        </li>
        <li class="active">{{reportId}}</li>
    </ol>
    <div *ngIf="isReportNotFound">
        <p class="errorText">Your report is still processing, we are unable to display the content of your report this
            time. Please try again later.
            If problem persists then contact the help desk (with report id).</p>
    </div>
    <div *ngIf="(reportsDetails$ | async).detail !== null && !isReportNotFound">
        <div class="report-detail-title-bar">
            <h1 class="report-title col-md-6 col-sm-6 col-xs-12">{{(reportsDetails$ | async).detail.title}}</h1>
            <div class="report-actions col-md-6 col-sm-6 col-xs-12">
                <a class="printLink" (click)="printReportContent()">Print</a>
            </div>
        </div>
    </div>
    <div *ngIf="(reportsDetails$ | async).detail !== null && !isReportNotFound">
        <div *ngIf="isCoreAdministrator()" class="reportStatus">
            <label>Report status</label>
            <p class="errorText" *ngIf="(reportStatusList$ | async).error">{{ (reportStatusList$ | async).error }}</p>
            <select class="form-control" *ngIf="!(reportStatusList$ | async).error" [(ngModel)]="reportStatus"
                (ngModelChange)="updateReportStatus()">
                <option *ngFor="let status of (reportStatusList$ | async).detail" value="{{status.statusSk}}">
                    {{status.statusDescription}}</option>
            </select>
            <label *ngIf="inValidStatus" class="validation-fail">Report submitter has requested a Reply to this report.
                Please reply before closing the report status.</label>
        </div>
    </div>
    <div id="ReportContent" *ngIf="(reportsDetails$ | async).detail !== null && !isReportNotFound">
        <div *ngIf="(reportsDetails$ | async).detail.etn !== null" class="row">
            <h3 class="ETN-text">ETN: {{ (reportsDetails$ | async).detail.etn }} </h3>
        </div>
        <div class="report-detail-main">
            <div class="container">
                <h2>Submitter info</h2>
                <div class="row">
                    <div class="report-summary col-md-3 col-sm-6 col-xs-12">
                        <div class="summary-item">
                            <label class="summary-header">Submitter</label>
                            <label class="summary-value">{{(reportsDetails$ | async).detail.lastName}},
                                {{(reportsDetails$ | async).detail.firstName}}</label>
                        </div>

                        <div class="summary-item">
                            <span class="summary-header">Report id</span>
                            <span class="summary-value">{{(reportsDetails$ | async).detail.reportId}}</span>
                        </div>
                    </div>
                    <div class="report-summary col-md-3 col-sm-6 col-xs-12">
                        <div class="summary-item">
                            <span class="summary-header">Employee number</span>
                            <span class="summary-value">{{(reportsDetails$ | async).detail.employeeId}}</span>
                        </div>
                        <div class="summary-item">
                            <span class="summary-header">Submit date</span>
                            <span class="summary-value">{{(reportsDetails$ | async).detail.createDate | date :
                                "MM/dd/yyyy"}}</span>
                        </div>
                    </div>
                    <div class="report-summary col-md-3 col-sm-6 col-xs-12">
                        <div class="summary-item">
                            <span class="summary-header">Bid status</span>
                            <span class="summary-value">{{(reportsDetails$ |
                                async).detail.permCrewBase}}/{{(reportsDetails$
                                |
                                async).detail.seat}}/{{(reportsDetails$ | async).detail.equipment}}/{{(reportsDetails$ |
                                async).detail.division}}</span>
                        </div>
                        <div class="summary-item">
                            <span class="summary-header">Report status</span>
                            <span class="summary-value">{{(reportsDetails$ | async).detail.reportStatusDesc}}</span>
                        </div>
                    </div>
                    <div class="report-summary col-md-3 col-sm-6 col-xs-12">
                        <div class="summary-item">
                            <span class="summary-header">Reply requested</span>
                            <span class="summary-value">{{(reportsDetails$ | async).detail.responseRequested}}</span>
                        </div>
                        <div class="summary-item">
                            <span class="summary-header">Report type</span>
                            <span class="summary-value">{{(reportsDetails$ | async).detail.reportTypeDesc}}</span>
                        </div>
                    </div>
                </div>
                <app-flight-summary></app-flight-summary>
            </div>
            <div class="section-options hide-print">
                <button (click)="allExpand.next(false)">collapse all</button>
                <button (click)="allExpand.next(true)">expand all</button>
            </div>
            <div class="line"></div>
            <app-description-summary [allExpandState]="allExpand" [hideAddButton]="featureAccessForSubmitter()">
            </app-description-summary>
            <div class="line"></div>
            <app-recommendation-summary [allExpandState]="allExpand" [hideAddButton]="featureAccessForSubmitter()">
            </app-recommendation-summary>
            <div class="line"></div>
            <app-question-summary [allExpandState]="allExpand"></app-question-summary>
            <!-- <div class="line"></div>
            <app-reply-summary [allExpandState]="allExpand" [hideReplyButton]="featureAccessForCoreRecipient()">
            </app-reply-summary> -->
            <div class="line"></div>

            <div class="admin-discussion hide-print" *ngIf="isAnyAdministrator()">
                <app-request-response-summary [allExpandState]="allExpand"
                    [featureAccessForCoreRecipient]="featureAccessForCoreRecipient()"
                    [featureAccessForFwdRecipient]="featureAccessForFwdRecipient()"></app-request-response-summary>
                <div class="line"></div>
            </div>

            <app-activity-summary class="hide-print" [allExpandState]="allExpand"></app-activity-summary>
            <div class="line"></div>

            <app-attachment-summary [allExpandState]="allExpand"></app-attachment-summary>
            <div class="line"></div>

            <a id="scroll-top" (click)="scrollToTop()">scroll to top</a>

        </div>
    </div>
</section>