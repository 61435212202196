<ol class="breadcrumb">
    <li>
        <a routerLink="/home">
            <i class="icon-home"></i> Home</a>
    </li>
    <li>
        <ng-container *ngIf="iodFlag">
            {{selectedOption}}
        </ng-container>
    </li>
</ol>
<h1>
    <ng-container *ngIf="iodFlag">
        {{selectedOption}}
    </ng-container>
</h1>
<p class="note">*Click on the report id to view details</p>

<ng-container *ngIf="((selectedList$ | async) === 'myreports')">
    <a href="https://cers.aa.com/#/reporthistory/myreports" target="_blank">Click here if a report you submitted is
        not listed.</a>
    <br>
    <p>CERS is transitioning websites. Not all report types are viewable here.</p>
</ng-container>

<ng-container *ngIf="((selectedList$ | async) === 'allreports')">
    <a href="https://cers.aa.com/#/reporthistory/allreports" target="_blank">Click here if a report is not
        listed.</a>
    <br>
    <p>CERS is transitioning websites. Not all report types are viewable here.</p>
</ng-container>

<div class="row">
    <div class="col-lg-12">
        <ng-container *ngIf="!iodFlag">
            <div class="btn-div">
                <button class="btn-myreports" [ngClass]="{'selected-report':((selectedList$ | async) === 'myreports')}" (click)="displayReports('myreports')">My reports</button>
            </div>

            <div class="btn-div">
                <button class="btn-myreports" *ngIf="isReportManagementAccess" [ngClass]="{'selected-report':((selectedList$ | async) === 'allreports')}" (click)="displayReports('allreports')">All reports</button>
            </div>
        </ng-container>
        <div class="btn-div cognoslink">
            <span class="" *ngIf="isReportManagementAccess && ((selectedList$ | async) === 'allreports')">
                <a target="_blank" href="{{cognosUrl}}">Cognos Advanced Search</a>
                <i class="icon-newpage" aria-hidden="true"></i>
            </span>
        </div>
        <app-table-my-reports *ngIf="((selectedList$ | async) === 'myreports')" [employeeId]=employeeId>My reports
        </app-table-my-reports>
        <app-table-all-reports *ngIf="((selectedList$ | async) === 'allreports') && isReportManagementAccess" [employeeId]=employeeId [isReportManagementAccess]=isReportManagementAccess>All reports
        </app-table-all-reports>
    </div>
</div>
