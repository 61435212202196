import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { NotificationService } from '@shared/error-handler-notify/services/notification.service';
import { Store } from '@ngrx/store';
import { State } from 'app/store';
import { Observable, Subject } from 'rxjs';
import { GetActivityLog, getActivityLog, IActivityLogDetails, IActivityLog, getReportDetailsReportId } from 'app/report-history/report-history-store/detail';
import { ExpandCollapseService } from 'app/shared/common-ux/components/component-services/expand-collapse.service';
import { DataTableDirective } from 'angular-datatables';
import { takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
    selector: 'app-activity-summary',
    templateUrl: './activity-summary.component.html',
    styleUrls: ['./activity-summary.component.scss', '../summary.component.scss']
})

export class ActivitySummaryComponent implements OnInit, OnDestroy {
    @Input() allExpandState: Subject<any>;
    @ViewChild('section', { static: true }) sectionElem: ElementRef;
    @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
    dtOptions: any = {};
    reportId: number;
    activityLog$: Observable<IActivityLogDetails>;
    activityLogList: IActivityLog[] = [];
    additionalEmailList: IActivityLog[] = [];
    additionalEmailOptions: any;
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<State>,
        private expandCollapseService: ExpandCollapseService,
        private notificationService: NotificationService
    ) {
        this.activityLog$ = this.store.select(getActivityLog);
        this.activityLog$.pipe(takeUntil(this.destroy$)).subscribe((v: IActivityLogDetails) => {
            this.activityLogList = [];
            this.additionalEmailList = [];
            if (v && v.detail && v.detail.length) {
                v.detail.map((activity) => {
                    if (activity.additionalEmail) {
                        this.addtoList(this.additionalEmailList, activity);
                    } else if (activity.firstName || activity.lastName) {
                        this.addtoList(this.activityLogList, activity);
                    }
                });
            }
        });
    }

    addtoList(theList: IActivityLog[], act: IActivityLog) {
        const searchIndex = _.findIndex(theList, function (o) { return _.isEqual(o, act); });
        if (searchIndex === -1) {
            theList.push(act);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        this.store.select(getReportDetailsReportId).pipe(takeUntil(this.destroy$)).subscribe(result => {
            this.reportId = result;
            this.getActivityList(result);
        });

        this.setDataTableOptions();
        this.allExpandState.pipe(takeUntil(this.destroy$)).subscribe(event => {
            if (event) {
                this.expandCollapseService.expand(this.sectionElem);
            } else {
                this.expandCollapseService.collapse(this.sectionElem);
            }
        });
    }

    getActivityList(reportId) {
        this.store.dispatch(new GetActivityLog(reportId));
    }

    private setDataTableOptions() {
        this.dtOptions = {
            'paging': false,
            'bInfo': false,
            'searching': false,
            'scrollCollapse': true,
            'ordering': false,
            'scroller': true,
            'fixedColumns': true,
            'autoWidth': false,
            'columns': [
                {
                    'name': 'dateandtime',
                    'title': 'Date',
                    'class': 'dtleft',
                    'width': '130px'
                },
                {
                    'name': 'updatedBy',
                    'title': 'Name',
                    'class': 'dtleft',
                    'width': 'auto'
                },
                {
                    'name': 'empid',
                    'title': 'Emp id',
                    'class': 'dtleft',
                    'width': '80px'
                },
                {
                    'name': 'station',
                    'title': 'Station',
                    'class': 'dtleft',
                    'width': '50px'
                },
                {
                    'name': 'department',
                    'title': 'Department',
                    'class': 'dtleft',
                    'width': 'auto'
                },
                {
                    'name': 'job',
                    'title': 'Job title',
                    'class': 'dtleft',
                    'width': 'auto'
                },
                {
                    'name': 'profileDesc',
                    'title': 'Profile',
                    'class': 'dtleft',
                    'width': 'auto'
                },
                {
                    'name': 'action',
                    'title': 'Action',
                    'class': 'dtleft',
                    'width': 'auto'
                }
            ]
        };
        this.additionalEmailOptions = {
            ...this.dtOptions, 'columns': [
                {
                    'name': 'dateandtime',
                    'title': 'Date',
                    'class': 'dtleft',
                    'width': '130px'
                },
                {
                    'name': 'email',
                    'title': 'Email',
                    'class': 'dtleft',
                    'width': '160px'
                },
                {
                    'name': 'action',
                    'title': 'Action',
                    'class': 'dtleft',
                    'width': 'auto'
                }
            ]
        };
    }
}
