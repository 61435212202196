import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import * as _ from 'lodash';

import { NotificationService } from '@shared/error-handler-notify/services/notification.service';
import { DynamicQuestionsService } from '@shared/SSEHubClient/dynamic-questions.service';
import { switchMap, map, catchError } from 'rxjs/operators';
import { Observable, forkJoin, EMPTY as empty, of } from 'rxjs';

import * as sectionsActions from './sections.actions';
import { Question } from 'app/shared/common-ux/models/QuestionAnswer';
import { ExtractQuestionUrls } from 'app/shared/common-ux/models/ExtractQuestionUrls';
import { resourceLimits } from 'worker_threads';

@Injectable()
export class CategorySectionEffects {
    // TODO: Split CategorySectionsError into error actions for each section, setting an error property


    loadFlightQuestions$: Observable<Action> = createEffect(() => this.action$.pipe(
        ofType<sectionsActions.LoadFlightQuestions>(sectionsActions.LOAD_FLIGHT_QUESTIONS),
        switchMap(action => {
            return this.dynamicQuestionsService.loadRootQuestions(action.profileId, action.categoryId, action.group).pipe(
                map(result => {
                    if (result) {
                        if (result.questions) {
                            for (const question of result.questions) {
                                if (question.attrs) {
                                    for (const key of Object.keys(question.attrs)) {
                                        if (question.attrs[key] === null) {
                                            delete question.attrs[key];
                                        }
                                    }
                                }
                            }
                        }
                        return new sectionsActions.PopulateFlightQuestions(result.questions);
                    } else {
                        this.notificationService.showError('Error Loading Flight Questions');
                        return new sectionsActions.CategorySectionsError('Error Loading Flight Questions');
                    }
                }),
                catchError(error => {
                    console.log(error);
                    this.notificationService.showError('Error Loading Flight Questions');
                    return of(new sectionsActions.CategorySectionsError('Error Loading Flight Questions'));
                })
            );
        })
    ));

    loadNotesQuestions$: Observable<Action> = createEffect(() => this.action$.pipe(
        ofType<sectionsActions.LoadNotesQuestions>(sectionsActions.LOAD_NOTES_QUESTIONS),
        switchMap(action => {
            return this.dynamicQuestionsService.loadRootQuestions(action.profileId, action.categoryId, action.group).pipe(
                map(result => {
                    if (result) {
                        if (result.questions) {
                            for (const question of result.questions) {
                                if (question.attrs) {
                                    for (const key of Object.keys(question.attrs)) {
                                        if (question.attrs[key] === null) {
                                            delete question.attrs[key];
                                        }
                                    }
                                }
                            }
                        }
                        return new sectionsActions.PopulateNotesQuestions(result.questions);
                    } else {
                        this.notificationService.showError('Error Loading Notes Questions');
                        return new sectionsActions.CategorySectionsError('Error Loading Notes Questions');
                    }
                }),
                catchError(error => {
                    console.log(error);
                    this.notificationService.showError('Error Loading Notes Questions');
                    return of(new sectionsActions.CategorySectionsError('Error Loading Notes Questions'));
                })
            );
        })
    ));

    loadGeneralQuestions$: Observable<Action> = createEffect(() => this.action$.pipe(
        ofType<sectionsActions.LoadGeneralQuestions>(sectionsActions.LOAD_GENERAL_QUESTIONS),
        switchMap(action => {
            return this.dynamicQuestionsService.loadRootQuestions(action.profileId, action.categoryId, 'General').pipe(
                map((result) => {
                    if (result) {
                        if (result.questions && result.questions.length > 0) {
                            for (const question of result.questions) {
                                if (question.attrs) {
                                    for (const key of Object.keys(question.attrs)) {
                                        if (question.attrs[key] === null) {
                                            delete question.attrs[key];
                                        }
                                    }
                                }
                                if (question.answerInputType && question.answerInputType.toUpperCase() === 'RADIO') {
                                    const newexUtil = new ExtractQuestionUrls(question);
                                    if (newexUtil && newexUtil !== undefined) {
                                        question.hyperLinks = newexUtil.hyperLinks;
                                        question.displayQuestionText = newexUtil.displayQuestionText;
                                    }
                                }
                            }
                        }
                        let generalQuestions = result.questions;
                        try {
                            generalQuestions = _.sortBy(generalQuestions, ['Display_Order']);
                        } catch (error) {
                            console.log(error);
                            this.notificationService.showError('Error loading General Questions');
                            return new sectionsActions.CategorySectionsError('Error loading General Questions');
                        }
                        return new sectionsActions.PopulateGeneralQuestions(generalQuestions);
                    } else {
                        this.notificationService.showError('Error loading General Questions');
                        return new sectionsActions.CategorySectionsError('Error loading General Questions');
                    }
                }),
                catchError(error => {
                    console.log(error);
                    this.notificationService.showError('Error loading General Questions');
                    return of(new sectionsActions.CategorySectionsError('Error loading General Questions'));
                })
            );
        })
    ));

    constructor(
        private action$: Actions,
        private notificationService: NotificationService,
        private dynamicQuestionsService: DynamicQuestionsService
    ) { }

}
