import { Component, OnInit } from '@angular/core';
import { IReportHistory, ResetTodo } from 'app/report-history/report-history-store/list';
import { Store } from '@ngrx/store';
import { State } from '@app/store';
@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

    private resetTodo: IReportHistory;
  constructor(private store: Store<State>) { }
    ngOnInit() {
        this.store.dispatch(new ResetTodo(this.resetTodo));
  }

}
