<div>
    <div class="panel-heading" *ngIf="question">
        <!--QUESTION-->
        <h4 [attr.class]="'panel-title question' + (highlighted ? '-highlighted' : '')">
            <i [ngClass]="checkIfRequired(question.attrs) ? 'icon-cers-required' : 'icon-cers-required-space'"></i>
            <label *ngIf="!(question.hyperLinks && question.hyperLinks.length >0)">{{question.questionText}}</label>
            <label *ngIf="question.hyperLinks && question.hyperLinks.length >0">
                <app-question-with-hyperlinks [hyperlinks]="question.hyperLinks"
                    [questionText]="question.displayQuestionText"></app-question-with-hyperlinks>
            </label>
            <label class="inputType">{{question.answerInputType | capitalizeInputType}}</label>
            <app-overflow-menu [context]="context" [profileId]=profileId [isDisabled]=checkIfRequired(question.attrs)
                (callAction)="callAction($event)"></app-overflow-menu>
            <button class="arrowContainer" [attr.aria-expanded]="isOpen" (click)="onExpandChildPanel()"><i
                    *ngIf="(answers && answers.length) || (followupQuestions && followupQuestions.length)"
                    [class]="iconStyle"></i></button>
        </h4>
    </div>

    <div class="panel-body" *ngIf="answer">
        <!--ANSWER-->
        <h4 [attr.class]="'panel-title answer' + (highlighted ? '-highlighted' : '')">
            <label *ngIf="isAnswerGroup">Follow Up Questions</label>
            <label *ngIf="!isAnswerGroup">{{index+1}} .
                {{answer.answerText | multiAnswerDisplayAdmin: answer.attrs}}</label>
            <app-overflow-menu [context]="answerContext" [profileId]="profileId" [isDisabled]="isAnswerMandatory()"
                (callAction)="callAction($event)">
            </app-overflow-menu>
            <button [ngClass]="{hiddenArrow: isAnswerGroup}" class="arrowContainer" [attr.aria-expanded]="isOpen"
                (click)="onExpandChildPanel()"><i [class]="iconStyle"></i></button>
        </h4>
    </div>

    <!--FOLLOWUP/ANSWERS-->
   <div #section id="{{componentId}}" [ngbCollapse]="!isOpen" (ngbCollapseChange)="isOpen = $event" class="panel-collapse collapse" role="tabpanel"
        aria-labelledby="headingOne">
        <div class="panel-body answer" *ngIf="answers && answers.length">
            <p *ngIf="context !== 'question-involvement' && context !== 'question-group'" class="help-text-label">
                Answers:
            </p>
            <div *ngFor="let a of answers;let i=index;" class="panel-body">
                <app-question-answer-dynamic-component [profileId]="profileId" [categoryId]="categoryId" [group]="group"
                    [context]="childContext" [index]="i" [answer]="a">
                </app-question-answer-dynamic-component>
            </div>
        </div>
        <div class="panel-body" *ngIf="followupQuestions && followupQuestions.length">
            <p *ngIf="!isAnswerGroup" class="help-text-label">Follow Up Questions:</p>
            <div class="panel-body">
                <div *ngFor="let fq of followupQuestions;let i=index;">
                    <app-question-answer-dynamic-component [question]="fq" [profileId]="profileId"
                        [categoryId]="categoryId" [group]="group" [index]="i" [context]="childContext">
                    </app-question-answer-dynamic-component>
                </div>
            </div>
        </div>
        <div class="panel-body" *ngIf="answer && (!answers.length && !followupQuestions.length)">
            <label>No followups mapped</label>
        </div>
    </div>
</div>