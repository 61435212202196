import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { SSEHubSectionService } from '@shared/SSEHubClient/section.service';
import { SectionInfo } from '@shared/reportStructure/categoryDetails/reportSections';
import { Store } from '@ngrx/store';
import { getUser } from '@app/store/user/user.reducer';
import { IUser, User } from '@app/store/user';
import { State } from '@app/store';
import { CategoryDetailService } from '../category-detail.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
    selector: 'app-section-visibility',
    templateUrl: './section-visibility.component.html',
    styleUrls: ['./section-visibility.component.scss']
})
export class SectionVisibilityComponent implements OnInit, OnDestroy {
    @Input() categoryId: number;
    @Input() profileId: number;
    @Input() sectionInfo: SectionInfo;
    @Input() sectionId: number;
    currentVisibility: number;
    canEdit = true;
    isEditingSectionVisibility = false;
    sectionVisibilityOptions = [
        { value: '0', description: 'Hidden' },
        { value: '1', description: 'Optional' },
        { value: '2', description: 'Mandatory' }
    ];

    private subscription: Subscription;
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<State>,
        private sseHubSectionService: SSEHubSectionService,
        private categoryDetailService: CategoryDetailService
    ) {
        this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(user => this.setEditPermissions(user));
    }

    ngOnInit() {
        this.subscription = this.categoryDetailService.categoryInitialized$.pipe(takeUntil(this.destroy$)).subscribe(
            data => {
                this.generalSectionVisiblity();
            });
    }
    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
        this.subscription.unsubscribe();
    }

    getVisibilityDescription() {
        let desc = '';
        if (isNaN(parseInt(String(this.currentVisibility), 10))) {
            this.currentVisibility = this.sectionInfo.visibility;
        }
        desc = this.sectionVisibilityOptions[this.currentVisibility].description;

        return desc;
    }

    private onEditSectionVisibility() {
        this.currentVisibility = 0;
        if (this.sectionInfo.visibility) {
            this.currentVisibility = this.sectionInfo.visibility;
        }
        this.isEditingSectionVisibility = !this.isEditingSectionVisibility;
    }

    private onSectionVisibilitySave(): void {
        this.sseHubSectionService.modifySectionVisibility(this.profileId, this.categoryId, this.sectionId, this.currentVisibility)
        .pipe(takeUntil(this.destroy$)).subscribe((value) => {
                this.sectionInfo.visibility = this.currentVisibility;
                this.sectionInfo.sectionVisibility = this.sectionVisibilityOptions[this.currentVisibility].description;
                this.isEditingSectionVisibility = false;
            }, (error) => {
                this.isEditingSectionVisibility = false;
                console.error(error);
            });
    }
    private setEditPermissions(user: IUser) {
        if (user) {
            this.canEdit = User.hasPrivilegeArray(user, [
                'WEB_ADMIN_CATEGORYMGNT_EDIT_' + this.profileId,
                'WEB_ADMIN_CATEGORYMGNT_EDIT_ALL',
                'WEB_SUADMIN'
            ]);
        }
    }

    public generalSectionVisiblity() {
        // call this service to make the SectionVisibility to 'required' for General Section under CatDet.
        this.sectionInfo.visibility = 2;

        // TODO: This is weird, why are we calling this on init??
        this.sseHubSectionService.modifySectionVisibility(this.profileId, this.categoryId, '9', this.sectionInfo.visibility)
        .pipe(takeUntil(this.destroy$)).subscribe(() => {
                this.sectionInfo.sectionVisibility = 'Required';
            }, (error) => {
                console.error(error);
            });
    }

    verifySectionVisibility() {
        let hasVisibility = false;
        if (this.sectionInfo.visibility > -1) {
            hasVisibility = true;
        }
        return hasVisibility;
    }

}
