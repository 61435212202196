import { Injectable, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'app/store';
import { SSEHubCategoriesService, SSEHubSettingsService, SSEHubEmployeesService } from '../SSEHubClient';
import { getUser, User, IUser } from 'app/store/user';
import { SSEHubNewReportService } from '../SSEHubClient/new-report.service';
import { SyncDatabaseService, ISyncData } from './sync-database.service';
import { forkJoin, Subject } from 'rxjs';
import { SSEHubAirportService } from '../SSEHubClient/airport.service';
import { MenuService } from '../common-ux/components/nav-bar/menu.service';
import { environment } from 'environments/environment';
import { takeUntil } from 'rxjs/operators';

export interface IQuestionSync {
    categoryId: number;
    questionId: number;
    questionMappingId: number;
    question: string;
    involvement: string;
    inputType: string;
    isRequired: boolean;
    mandatory: boolean;
    displayOrder: number;
    isRoot: boolean;
    min: number;
    max: number;
    profileId: number;
    groupName: string;
    referenceSk: number;
    validateRules: object;
    answerId: number;
    templateName: string;
}

export interface IAnswerSync {
    questionMappingId: number;
    id: number;
    answer: string;
    displayOrder: number;
}

export interface IFollowupSync {
    id: number;
    answerMappingId: number;
    questionMappingId: number;
    inputType: string;
}

// -------------------------------------------------------------------------------------
// WE ARE DISABLING OFFLINE-SYNC FOR THE TIME BEING SO CALLS ARE NO LONGER MADE AT LOGIN
// ONLY COMMENTING OUT THE CODE, NOT REMOVING SO THAT THIS FUNCTIONALITY CAN BE
// REVISITED AT A LATER TIME
// -------------------------------------------------------------------------------------

@Injectable()
export class OfflineSyncService implements OnDestroy {
    loadingAnswers = false;
    loadingFollowups = false;
    loadingQuestions = false;
    loadingCategories = false;
    loadingDetails = false;
    loadingFlights = false;
    loading = false;

    errorAnswers = false;
    errorFollowups = false;
    errorQuestions = false;
    errorCategories = false;
    errorDetails = false;
    errorFlights = false;

    lastSyncDate: Date = null;
    profileId: number = null;
    employeeId: string = null;
    isPwa = false;
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<State>,
        private ssehubCategoriesService: SSEHubCategoriesService,
        private sseHubNewReportService: SSEHubNewReportService,
        private ssehubSettings: SSEHubSettingsService,
        private ssehubAirport: SSEHubAirportService,
        private ssehubEmployee: SSEHubEmployeesService,
        private menuService: MenuService,
        private syncDB: SyncDatabaseService
    ) {
        // load categories when user profile is loaded
        this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe((user: IUser) => {
            if (user && user.profileId !== null && user.profileId !== undefined) {
                this.profileId = user.profileId;
                this.employeeId = user.id;
                this.isPwa = (window.matchMedia('(display-mode: standalone)').matches || (environment.envName && environment.envName.includes('dev')));
                // flag database
                this.menuService.setPrivileges(user);
                this.checkSyncedData();
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    checkSyncedData() {
        if (window.navigator.onLine && this.isPwa) {
            // @TODO uncomment line below to kick-off sync process when ready
            this.syncDB.getLastSyncData().then((val: ISyncData) => {
                if (!val) return;
                if (val.profile !== this.profileId) {
                    // different profile loaded
                    this.sync();
                } else {
                    this.lastSyncDate = val.lastSynced;
                }
            }).catch((err) => {
                // the app has not previously synced data
                this.sync();
            });
        }
    }

    async sync() {

        // disabling offline-sync for the time being
        return;

        // main function to kick-off sync for all FOR categories
        if (this.profileId !== null && this.profileId !== undefined) {

            // start loading flags
            this.loadingAnswers = true;
            this.loadingFollowups = true;
            this.loadingQuestions = true;
            this.loadingCategories = true;
            this.loadingDetails = true;
            this.loadingFlights = true;
            this.loading = true;
            this.lastSyncDate = new Date();

            // reset error flags
            this.errorAnswers = false;
            this.errorFollowups = false;
            this.errorQuestions = false;
            this.errorCategories = false;
            this.errorFlights = false;
            this.errorDetails = false;

            const sources = [
                this.ssehubCategoriesService.getByProfileId(this.profileId),
                this.sseHubNewReportService.getQuestions(this.profileId),
                this.sseHubNewReportService.getFollowups(this.profileId),
                this.sseHubNewReportService.getAnswers(this.profileId),
                this.ssehubEmployee.getCrewSequencesByEmployeeId(this.employeeId),
                this.ssehubSettings.getAll(),
                this.ssehubAirport.getAll()
            ];

            // gather all data. This will allow us to sequentially save the data
            // in IndexedDB once we have all of it.
            forkJoin(
                sources
            ).pipe(takeUntil(this.destroy$)).subscribe(async ([categories, questions, followups, answers, crewSequences, settings, airportCodes]) => {

                try {
                    await this.syncDB.syncCategories(categories);
                } catch (err) {
                    this.loadingCategories = false;
                    this.errorCategories = true;
                    console.error(err);
                }
                this.loadingCategories = false;

                try {
                    await this.syncDB.syncAllQuestions(questions);
                } catch (err) {
                    this.loadingQuestions = false;
                    this.errorQuestions = true;
                    console.error(err);
                }
                this.loadingQuestions = false;

                try {
                    await this.syncDB.syncAllAnswers(answers);
                } catch (err) {
                    this.loadingAnswers = false;
                    this.errorAnswers = true;
                    console.error(err);
                }
                this.loadingAnswers = false;

                try {
                    await this.syncDB.syncAllFollowups(followups);
                } catch (err) {
                    this.loadingFollowups = false;
                    this.errorFollowups = true;
                    console.error(err);
                }
                this.loadingFollowups = false;

                try {
                    await this.syncDB.syncCrewSequence(crewSequences);
                } catch (err) {
                    this.loadingFlights = false;
                    this.errorFlights = true;
                    console.error(err);
                }
                this.loadingFlights = false;

                try {
                    await this.syncDB.syncDetailsSettings(settings);
                    await this.syncDB.syncAirportCodes(airportCodes);
                    await this.syncDB.updateLastSyncData(this.profileId);
                } catch (err) {
                    this.loadingDetails = false;
                    this.errorDetails = true;
                    console.error(err);
                }
                this.loadingDetails = false;

                // update last date synced for UI
                this.checkSyncedData();
                this.loading = false;
            });
        }
    }
}
