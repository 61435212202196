import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import * as _ from 'lodash';

@Component({
    selector: 'app-report-history-reroute',
    templateUrl: './report-history-reroute.component.html',
    styleUrls: ['../report-history.component.scss']
})
export class ReportHistoryRerouteComponent implements OnInit, OnDestroy {
    destroy$: Subject<void> = new Subject<void>();
    
    constructor(
        private route: ActivatedRoute,
        private router: Router,
    ) {
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        // check casing of the option parameter
        this.route.params.subscribe(x => {
            const reportType = x.option.toLowerCase() && (x.option.toLowerCase() == 'myreports' || x.option.toLowerCase() == 'allreports') ? x.option.toLowerCase() : 'myreports';
            this.router.navigate(['reporthistory/' + reportType]);
        });
    }

}
