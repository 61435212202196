<modal #confirmCancel>
    <modal-header>
        <h4>Cancel report</h4>
    </modal-header>
    <modal-content>
        <p>Do you want to cancel this report? All entered information will be lost.</p>
    </modal-content>
    <modal-footer>
        <button class="btn-cancel" (click)="no()">No</button>
        <button class="btn-primary leftMargin remove" type="button" (click)="yes()">Yes</button>
    </modal-footer>
</modal>