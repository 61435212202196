<div *ngFor="let question of questions">
    <!-- <div class="questionItem" *ngIf="question.answerInputType.toUpperCase() === 'MULTI' &&
                                     question.attrs &&
                                     question.attrs['dataType']?.toLowerCase() === 'amsaddress' &&
        question.userAnswer[0]">
        <p class="questionHeader">{{ question.questionText }}</p>
        <ul class="list-basic checkbox">
            <div *ngFor="let answer of question.userAnswer">
                <li class="list-basic checkbox">{{ answer }}</li>
            </div>
        </ul>
    </div> -->
    <div class="questionItem" *ngIf="question | shouldDisplayQuestionText">
        <p class="questionHeader">{{ question.questionText }}</p>
        <span [ngSwitch]="question.dataType">
            <div *ngSwitchCase="'additionalEmails'" class="questionValueEmail">
                <ul *ngFor="let email of parseEmailStringToList(question.userAnswer)" class="aa-ul-list-basic">
                    <li class="list-basic">{{email}}</li>
                </ul>
            </div>
            <div *ngSwitchCase="'additionalAAEmails'" class="questionValueEmail">
                <ul *ngFor="let email of parseEmailStringToList(question.userAnswer)" class="aa-ul-list-basic">
                    <li class="list-basic">{{email}}</li>
                </ul>
            </div>
            <p *ngSwitchDefault class="questionValue">{{ question | formatSummaryAnswer }}</p>
        </span>
        <app-display-injuries *ngIf="question.attrs && question.attrs['displayInjuries']" [question]='question'
            [questionAttrs]='question.attrs' [parentComponent]="'summary'">
        </app-display-injuries>
    </div>
    <div class="questionItem" *ngIf="isCheckboxAnswered(question)">
        <p class="questionHeader">{{ question.questionText }}</p>
        <ul class="list-basic checkbox">
            <div *ngFor="let answer of question.answers">
                <div *ngIf="answer.isChecked">
                    <li class="list-basic checkbox">{{ answer.answerText }}</li>
                </div>
                <div class="childForm" *ngIf="answer.followupQuestions && answer.followupQuestions.length > 0">
                    <app-summary-question-display [questions]="answer.followupQuestions">
                    </app-summary-question-display>
                </div>
            </div>
        </ul>
    </div>
    <div class="childForm" *ngIf="question.followupQuestions && question.followupQuestions.length > 0">
        <app-summary-question-display [questions]="question.followupQuestions"></app-summary-question-display>
    </div>
</div>