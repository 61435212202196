import { Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { IUser, User, ProxyUser, ProxyRemove, getProxyMode, getUser } from '@app/store/user';
import * as ReportHistoryStore from 'app/report-history/report-history-store/list';
import { UserService } from '@shared/user/user.service';
import { NotificationService } from '@shared/error-handler-notify/services';
import { State } from '@app/store';
import { takeUntil } from 'rxjs/operators';
import { ErrorHandlerService } from 'app/shared/error-handler/error-handler.service';

@Component({
    selector: 'app-aa-title-bar',
    templateUrl: './title-bar.component.html',
    styleUrls: ['./title-bar.component.scss']
})
export class TitleBarComponent implements OnDestroy {
    userName: string;
    proxyUserId = '';
    proxyMode$: Observable<any>;
    isProxyUser = false;
    isAdminUser = false;
    isGeneralMgr = false;
    isReportManagementAccess = false;
    destroy$: Subject<void> = new Subject<void>();
    constructor(
        private router: Router,
        private store: Store<State>,
        public notificationService: NotificationService,
        public userService: UserService,
        public errorService: ErrorHandlerService
    ) {
        this.store.select(getUser).pipe(takeUntil(this.destroy$)).subscribe(user => this.onUserLoaded(user));
        this.proxyMode$ = this.store.select(getProxyMode);
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onUserLoaded(user: IUser) {
        if (user) {
            this.userName = User.nameAndProfile(user);
            this.isProxyUser = user.isProxyUser;
            this.isAdminUser = this.IsAdminUser(user);
            this.isGeneralMgr = user.profileId === 7;
            this.isReportManagementAccess = User.hasPrivilege(user, 'WEB_HISTORY_MANAGEMENT_ACCESS');
        }
    }

    public loadProxyUser() {
        this.verifyProxyUser(this.proxyUserId);
    }

    public exitProxyMode() {
        this.store.dispatch(new ProxyRemove());
        this.store.dispatch(new ReportHistoryStore.Reset());
        this.router.navigate(['/home']);
        this.proxyUserId = '';
    }

    private IsAdminUser(user: IUser) {
        const suAdmin = User.hasPrivilege(user, 'WEB_SUADMIN');
        const categoryMgnt = User.hasPrivilegeSubStr(user, 'WEB_ADMIN_CATEGORYMGNT');
        if (suAdmin || categoryMgnt) {
            return true;
        } else {
            return false;
        }
    }

    private async verifyProxyUser(id: string) {
        const proxyUser: IUser = await this.userService.getEmployee(id);
        if (proxyUser && proxyUser.profileId >= 0) {
            if (!User.isActiveEmployee(proxyUser)) {
                this.notificationService.showWarning(this.errorService.getErrorTextProxy(proxyUser.id, proxyUser.status));
            }
            this.store.dispatch(new ProxyUser(proxyUser));
            this.store.dispatch(new ReportHistoryStore.Reset());
            // stays in notification if the user is there, otherwise navigates to home
            this.router.navigate([this.router.url.includes('/notifications') ? '/notifications' : '/home']);
        } else {
            this.notificationService.showWarning('Proxy user not found');
        }
    }
}
