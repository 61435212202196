import { Component, OnInit, Input, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'app/store';
import { getReportDetailsQuestions, getReportDetailsInvolvements, ReportDetailInvolvement } from 'app/report-history/report-history-store/detail';
import { Subject } from 'rxjs';
import { ExpandCollapseService } from 'app/shared/common-ux/components/component-services/expand-collapse.service';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-question-summary',
    templateUrl: './question-summary.component.html',
    styleUrls: [
        './question-summary.component.scss',
        '../summary.component.scss',
    ],
})
export class QuestionSummaryComponent implements OnInit, OnDestroy {
    @Input() allExpandState: Subject<any>;
    @ViewChild('section', { static: true }) sectionElem: ElementRef;
    questionAndAnswers: any;
    involvementDetails: Array<ReportDetailInvolvement>;
    destroy$: Subject<void> = new Subject<void>();

    constructor(
        private store: Store<State>,
        private expandCollapseService: ExpandCollapseService) { }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngOnInit() {
        this.store.select(getReportDetailsQuestions).pipe(takeUntil(this.destroy$)).subscribe((result) => (this.questionAndAnswers = result));
        this.store.select(getReportDetailsInvolvements).pipe(takeUntil(this.destroy$)).subscribe(result => {
            return this.involvementDetails = result;
        });
        this.allExpandState.pipe(takeUntil(this.destroy$)).subscribe((event) => {
            if (event) {
                this.expandCollapseService.expand(this.sectionElem);
            } else {
                this.expandCollapseService.collapse(this.sectionElem);
            }
        });
    }

    isEmailsDataType(attrs: object) {
        if (attrs) {
            if (attrs &&
                attrs['dataType'] &&
                attrs['dataType'].toLowerCase().includes('emails')) return true;
        }
        return false;
    }
}
