import { Component, ViewChild, EventEmitter, Output } from '@angular/core';

@Component({
    selector: 'app-confirm-navigate',
    templateUrl: './confirm-navigate.component.html',
    styleUrls: ['../../modals.component.scss']
})

export class ConfirmNavigateComponent {

    @ViewChild('confirmNavigate', { static: true }) public modal;
    @Output() navResult = new EventEmitter<boolean>();

    constructor(
    ) { }

    displayModal() {
        this.modal.open();
    }

    yes() {
        this.modal.close();
        this.navResult.emit(true);
    }

    no() {
        this.modal.close();
        this.navResult.emit(false);
    }
}
