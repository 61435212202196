<div class="dynamicInputDiv" [ngModelGroup]="question.questionMappingId">
    <i *ngIf="questionAttrs['isRequired']" class="icon-cers-required"></i>
    <label>{{ question.questionText | sentenceCasePipe }} </label>

    <table>
        <tr>
            <td class="inc-dec-row" (click)="incrementHours(1)">
                <fa-icon [icon]="angleUp" class="inc-dec-value" id="increase-hours">
                </fa-icon>
            </td>
            <td></td>
            <td class="inc-dec-row" (click)="incrementMins(1)">
                <fa-icon [icon]="angleUp" class="inc-dec-value" id="increase-mins">
                </fa-icon>
            </td>
        </tr>
        <tr>
            <td><input type="text" id="hour-input-{{question.questionMappingId}}"
                    name="hour-{{question.questionMappingId}}" class="form-control time-form" [(ngModel)]="hours"
                    maxlength="2" [appDynamicValidation]="hoursAttrs"
                    [appFocus]="{questionId: question.questionId, questionMappingId: question.questionMappingId, skip: hoursControl.valid}"
                    #hoursControl="ngModel" [matAutocomplete]="autoHours" numbersOnly>
                <mat-autocomplete #autoHours="matAutocomplete" panelWidth="auto">
                    <mat-option *ngFor="let hour of hours24" [value]="hour">
                        {{ hour }}
                    </mat-option>
                </mat-autocomplete>
            </td>
            <td>
                <div class="time-colon">:</div>
            </td>
            <td><input type="text" id="minute-input-{{question.questionMappingId}}"
                    name="minute-{{question.questionMappingId}}" class="form-control time-form" [(ngModel)]="mins"
                    maxlength="2" [appDynamicValidation]="minsAttrs" #minsControl="ngModel"
                    [appFocus]="{questionId: question.questionId, questionMappingId: question.questionMappingId, skip: minsControl.valid || !hoursControl.valid}"
                    [matAutocomplete]="autoMinutes" numbersOnly>
                <mat-autocomplete #autoMinutes="matAutocomplete" panelWidth="auto">
                    <mat-option *ngFor="let minute of minutes60" [value]="minute">
                        {{ minute }}
                    </mat-option>
                </mat-autocomplete>
            </td>
        </tr>
        <tr class="topPadding">
            <td class="inc-dec-row bottom-time-buttom-padding" (click)="incrementHours(-1)">
                <fa-icon [icon]="angleDown" class="inc-dec-value" id="decrease-hours">
                </fa-icon>
            </td>
            <td></td>
            <td class="inc-dec-row bottom-time-buttom-padding" (click)="incrementMins(-1)">
                <fa-icon [icon]="angleDown" class="inc-dec-value" id="decrease-mins">
                </fa-icon>
            </td>
        </tr>
    </table>

    <div *ngFor="let error of hoursControl.errors | keyvalue">
        <label class="errorText" *ngIf="error.key !== 'isRequired'"> {{error.value}} </label>
    </div>
    <div *ngFor="let error of minsControl.errors | keyvalue">
        <label class="errorText" *ngIf="error.key !== 'isRequired'"> {{error.value}} </label>
    </div>
</div>
